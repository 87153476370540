import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  STATUS_APPROVED,
  STATUS_CREATED,
  STATUS_UPDATE_DONE,
  MESSAGE_STATUS_APPROVED,
  STATUS_REOPENED,
  Party,
  MESSAGE_STATUS_REOPENED,
} from 'Common/constants';
import MatterPropsJS from 'Common/Utils/MatterProps';
import { orangeTheme, Theme } from 'Common/Utils/theme';
import { useAppDispatch, useAppSelector } from 'App/State/Store';
import { CardIndex, QuestionCard } from 'Common/Data/Types/appSections';
import getNames from 'Common/Utils/getNames';
import IconClock from 'Common/Assets/images/icon-clock.svg';
import IconTickBoth from 'Common/Assets/images/icon-tick-both.svg';
import { canApproveCard } from 'Common/Utils/cardHelpers';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import Loader from 'Common/UI/Loader/Loader';
import GenericButton from 'Common/UI/Button/GenericButton';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import EditButton from 'Common/UI/Button/EditButton';
import useForm from 'Common/Utils/Hooks/useForm';
import IconTick from 'Common/Assets/images/icon-tick-black.svg';
import { updateMatterSectionAction } from 'App/State/MatterActions';
import MessageTextBox from 'Common/UI/Statements/MessageTextBox';
import {
  SCREEN_STATE_VIEW,
  SCREEN_STATE_ACTION,
} from 'Common/UI/Division/constants';

const ActionButton = styled(GenericButton)<{
  theme: Theme;
}>`
  padding: 10px 16px 9px;
  text-align: left;
  width: 100%;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    min-width: 350px;
  }
`;

const StyledParagraph = styled(Paragraph)<{
  theme: Theme;
}>`
  margin: 0;
  display: flex;

  img {
    margin-right: ${({ theme }) => theme.spacing[2]};
  }
`;

const StyledApproveButton = styled(ActionButton)`
  background-image: url(${IconTick});
  background-position: center right 16px;
  background-repeat: no-repeat;
`;

const ApproveButton: React.FC<{
  onClick: () => void;
  disabled: boolean;
  children: React.ReactNode;
}> = ({ children, ...props }) => (
  <ThemeProvider theme={orangeTheme}>
    <StyledApproveButton {...props}>{children}</StyledApproveButton>
  </ThemeProvider>
);

const ButtonBar = styled.div`
  text-align: left;
`;

const MessagingBar = styled.div`
  position: relative;
  top: -10px;
`;

const ActionBar = styled.div<{
  theme: Theme;
  inOdie?: boolean;
  state: string;
}>`
  background: ${props => props.theme.colours.offWhite};
  color: ${props => props.theme.colours.black};
  flex-shrink: 0;
  padding: 24px 40px;
  position: relative;
  overflow: hidden;

  ${props =>
    props.inOdie &&
    `
    padding: 24px 0 0;
  `};

  ${ButtonBar} {
    opacity: ${props => (props.state === SCREEN_STATE_ACTION ? '0' : '1')};
    transition: all 0.5s;
  }
`;

const StyledEditButton = styled(EditButton)`
  background: transparent;
  padding: 0;
  color: #393189;

  &:hover {
    background: transparent;
    border-color: transparent;
    text-decoration: underline;
  }
`;

const isCreator = (creator: Party) => creator === MatterPropsJS('self.party');
const getCanRequestChange = ({
  status,
  creator,
}: {
  status: string;
  creator: Party;
}) =>
  isCreator(creator) &&
  (status === STATUS_CREATED ||
    status === STATUS_REOPENED ||
    status === STATUS_UPDATE_DONE);

const getStatementActionBarText = (card: QuestionCard) => ({
  promptText: 'Write a message...',
  approveStatus: `Approve ${card.typeName}`,
});

type Props = {
  card: QuestionCard;
  onSendMessage: (message: string) => Promise<void>;
  updateState: (state: string) => void;
  state: string;
  isMessageSending: boolean;
  updateMessageSending: (arg1: boolean) => void;
  inOdie: boolean;
  onClickDone: () => void;
};

const StatementActionBar: React.FC<Props> = ({
  card,
  onSendMessage,
  updateState,
  state,
  isMessageSending,
  updateMessageSending,
  inOdie,
  onClickDone,
}) => {
  const { cardIDAsArray, data, status, typeName } = card;
  const [isLoading, setIsLoading] = useState(false);

  const matter = useAppSelector(reduxState => reduxState.matter);

  const dispatch = useAppDispatch();

  const updateMatterSection = (
    section: [string, CardIndex],
    sectionData: any,
    messageData: any
  ) =>
    dispatch(updateMatterSectionAction({ section, sectionData, messageData }));

  const onApprove = () => {
    const messageData = {
      owner: MatterPropsJS('self.party'),
      section: cardIDAsArray,
      status: MESSAGE_STATUS_APPROVED,
      checkTone: false,
    };

    setIsLoading(true);

    updateMatterSection(
      cardIDAsArray,
      { ...data, status: STATUS_APPROVED },
      messageData
    ).then(() => setIsLoading(false));
  };

  const { inputs, handleInputChange, updateInputValue, handleSubmit } = useForm(
    () => {
      setIsLoading(true);
      updateMessageSending(true);
      onSendMessage(inputs.message as string).then(() => {
        updateInputValue('message', '');
        updateState(SCREEN_STATE_VIEW);
        updateMessageSending(false);
        setIsLoading(false);
      });
    }
  );

  const { promptText, approveStatus } = getStatementActionBarText(card);

  const names = getNames();
  const selfParty = getPartyKeys().self;

  return (
    <ActionBar inOdie={inOdie} state={state}>
      {status === STATUS_APPROVED && (
        <>
          <img src={IconTickBoth} alt="" />
          <H1Conversational>
            Great! You&apos;ve reached agreement on this {typeName}
          </H1Conversational>

          <StyledEditButton
            data-cy="button-reopen-card"
            onClick={() => {
              setIsLoading(true);
              updateMatterSection(
                card.cardIDAsArray,
                { ...card.data, status: STATUS_REOPENED },
                {
                  owner: selfParty,
                  section: card.cardIDAsArray,
                  status: MESSAGE_STATUS_REOPENED,
                  checkTone: false,
                }
              ).finally(() => setIsLoading(false));
            }}
          >
            Re-open {card.typeName}
          </StyledEditButton>

          <br />
          <br />

          <GenericButton wide onClick={onClickDone}>
            Done
          </GenericButton>
        </>
      )}
      {status !== STATUS_APPROVED && (
        <>
          <ButtonBar>
            {canApproveCard(card, matter) && (
              <ApproveButton
                data-cy="button-card-approve"
                onClick={onApprove}
                disabled={state === SCREEN_STATE_ACTION}
              >
                {approveStatus}
              </ApproveButton>
            )}
            {!canApproveCard(card, matter) && (
              <StyledParagraph>
                <img src={IconClock} alt="" /> Waiting for {names.other} to
                review this statement
              </StyledParagraph>
            )}
          </ButtonBar>

          <MessagingBar>
            <MessageTextBox
              onMessageSubmit={handleSubmit}
              onMessageInput={handleInputChange}
              messageInput={inputs.message as string}
              prompt="Explain what you want to be changed"
              disabled={isMessageSending}
              placeholder={promptText}
              onFocus={() => updateState(SCREEN_STATE_ACTION)}
              showPrompt={state === SCREEN_STATE_ACTION}
              state={state}
              isSubmitting={isMessageSending}
            />
          </MessagingBar>
        </>
      )}
      {isLoading && <Loader floating />}
    </ActionBar>
  );
};

export default StatementActionBar;
