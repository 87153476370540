import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import styled, { ThemeProvider } from 'styled-components';

import { ODRS_DASH_HOME_ROUTE } from 'Common/routes';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { blackTheme } from '../Utils/theme';

export const Content = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

const Footer = styled.div`
  flex: 0;
`;

export const Link = styled.a(props => ({
  ...props.theme.typography.clickable.button,
  color: props.theme.buttonTextPrimary,
  backgroundColor: props.theme.buttonBackgroundPrimary,
  padding: props.small ? '10px 16px 9px' : '14px 16px 13px',
  width: '100%',
  border: 0,
  borderRadius: '4px',
  transition: 'all 500ms',
  textDecoration: 'none',
  display: 'inline-block',
  textAlign: 'center',
}));

export const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: url('${props => props.theme.backgroundImage}') no-repeat
    ${props => props.theme.backgroundColour};
  background-size: cover;
  background-blend-mode: multiply;
  color: ${props => props.theme.textColour};
  padding: ${props => props.theme.padding.medium}px;
`;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.SENTRY_DSN) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // render fallback UI
      return (
        <ThemeProvider theme={blackTheme}>
          <Wrapper>
            <Content>
              <H1Conversational>
                Unfortunately, an error has occurred.
              </H1Conversational>
              <Paragraph>
                We are aware of it and are currently working on fixing it as
                soon as possible. In the meantime, please try again. If the
                issue persists, please{' '}
                <a
                  target="_blank"
                  href="https://www.amica.gov.au/help-and-support.html"
                  rel="noreferrer"
                >
                  get in touch
                </a>{' '}
                with our support team.
              </Paragraph>
            </Content>
            <Footer>
              <Link href={ODRS_DASH_HOME_ROUTE}>Back to dashboard</Link>
            </Footer>
          </Wrapper>
        </ThemeProvider>
      );
    }

    // when there's not an error, render children untouched
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
