import Signup from 'App/Pages/Signup';
import DiviRoute from 'App/UI/DiviRoute';
import {
  ODRS_SUA_ABOUTYOU_SELF_ROUTE,
  ODRS_SUA_ABOUTYOU_OTHER_ROUTE,
  ODRS_DASH_FINANCES_ROUTE,
  ODRS_SUA_YOURFINANCES_SELF_ROUTE,
  ODRS_SUA_YOURFINANCES_OTHER_ROUTE,
  ODRS_SHAREDFINANCES_ROUTE,
  ODRS_DASH_RELATIONSHIP_ROUTE,
  ODRS_DASH_PROPERTY_ROUTE,
  ODRS_EDIT_STATEMENT_ROUTE,
  ODRS_START_ROUTE,
  ODRS_STATEMENT_DETAILS,
  ODRS_SUA_YOURDETAILS,
  ODRS_RELATIONSHIP_ROUTE,
  ODRS_CHILDREN_ROUTE,
  ODRS_NOCHILDREN_ROUTE,
  ODRS_PROPERTIES_ROUTE,
  ODRS_SUA_YOURSUPERANNUATION_SELF_ROUTE,
  ODRS_SUA_YOURSUPERANNUATION_OTHER_ROUTE,
  ODRS_SUA_YOURCONTRIBUTIONS_OTHER_ROUTE,
  ODRS_SUA_YOURCONTRIBUTIONS_SELF_ROUTE,
  ODRS_VEHICLES_ROUTE,
  ODRS_DIVISION_INTRO,
  ODRS_DIVISION_VIEW_SUGGESTED,
} from 'Common/routes';
import theme, {
  blueGreyMobileTheme,
  darkerNoBackground,
} from 'Common/Utils/theme';
import { Location } from 'history';
import React from 'react';
import { Switch, useLocation, Redirect } from 'react-router-dom';
import Odie from 'Common/UI/Odie/Odie';
import ODRSAboutYouA from './ODRSAboutYouA';
import ODRSAboutYouB from './ODRSAboutYouB';
import ODRSDashboardRelationship from './ODRSDashboardRelationship';
import ODRSYourFinancesA from './ODRSYourFinancesA';
import ODRSYourFinancesB from './ODRSYourFinancesB';
import ODRSYourFinancesShared from './ODRSYourFinancesShared';
import ODRSYourSuperannuationA from './ODRSYourSuperannuationA';
import ODRSYourSuperannuationB from './ODRSYourSuperannuationB';
import ODRSYourContributionsA from './ODRSYourContributionsA';
import ODRSYourContributionsB from './ODRSYourContributionsB';
import ODRSStatementDetails from './ODRSStatementDetails';
import ODRSEditStatement from './ODRSEditStatement';
import ODRSDashboardFinances from './ODRSDashboardFinances';
import ODRSYourDetails from './ODRSYourDetails';
import ODRSRelationship from './ODRSRelationship';
import ODRSChildren from './ODRSChildren';
import ODRSVehicles from './ODRSVehicles';
import ODRSNoChildren from './ODRSNoChildren';
import ODRSDashboardProperty from './ODRSDashboardProperty';
import ODRSProperties from './ODRSProperties';
import ODRSDivisionIntro from './ODRSDivisionIntro';
import SuggestedDivision from '../Pages/SuggestedDivision';

const Routes: React.FC = () => {
  const location = useLocation<{ background?: Location }>();
  const background = location.state && location.state.background;

  return (
    <>
      <Switch location={background || location}>
        <DiviRoute path={ODRS_START_ROUTE} component={Signup} />
        <DiviRoute
          path={ODRS_DASH_RELATIONSHIP_ROUTE}
          component={ODRSDashboardRelationship}
          theme={darkerNoBackground}
        />
        <DiviRoute
          path={ODRS_DASH_PROPERTY_ROUTE}
          component={ODRSDashboardProperty}
          theme={darkerNoBackground}
        />
        <DiviRoute
          path={`${ODRS_EDIT_STATEMENT_ROUTE}/:cardID`}
          component={ODRSEditStatement}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_SUA_YOURDETAILS}/:updating?`}
          component={ODRSYourDetails}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_SUA_ABOUTYOU_SELF_ROUTE}/:updating?`}
          component={ODRSAboutYouA}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_SUA_ABOUTYOU_OTHER_ROUTE}/:updating?`}
          component={ODRSAboutYouB}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_RELATIONSHIP_ROUTE}/:updating?`}
          component={ODRSRelationship}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_DASH_FINANCES_ROUTE}
          component={ODRSDashboardFinances}
          theme={darkerNoBackground}
        />
        <DiviRoute
          path={`${ODRS_SUA_YOURFINANCES_SELF_ROUTE}/:updating?`}
          component={ODRSYourFinancesA}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_SUA_YOURFINANCES_OTHER_ROUTE}/:updating?`}
          component={ODRSYourFinancesB}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_SHAREDFINANCES_ROUTE}/:updating?`}
          component={ODRSYourFinancesShared}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_NOCHILDREN_ROUTE}/:index(\\d+)/:updating?`}
          component={ODRSNoChildren}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_CHILDREN_ROUTE}/:index(\\d+)/:updating?`}
          component={ODRSChildren}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_PROPERTIES_ROUTE}/:index(\\d+)/:updating?`}
          component={ODRSProperties}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_VEHICLES_ROUTE}/:index(\\d+)/:updating?`}
          component={ODRSVehicles}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_SUA_YOURSUPERANNUATION_SELF_ROUTE}/:updating?`}
          component={ODRSYourSuperannuationA}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_SUA_YOURSUPERANNUATION_OTHER_ROUTE}/:updating?`}
          component={ODRSYourSuperannuationB}
        />
        <DiviRoute
          path={`${ODRS_SUA_YOURCONTRIBUTIONS_SELF_ROUTE}/:updating?`}
          component={ODRSYourContributionsA}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_SUA_YOURCONTRIBUTIONS_OTHER_ROUTE}/:updating?`}
          component={ODRSYourContributionsB}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_DIVISION_INTRO}
          component={ODRSDivisionIntro}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_DIVISION_VIEW_SUGGESTED}
          component={SuggestedDivision}
          theme={theme}
        />
        <Redirect to={ODRS_DASH_RELATIONSHIP_ROUTE} />
      </Switch>

      {/* This allows us to open the drawer with the dashboard in the background */}

      <Odie>
        {background && (
          <DiviRoute
            path={`${ODRS_STATEMENT_DETAILS}/:section/:index?`}
            component={ODRSStatementDetails}
            theme={blueGreyMobileTheme}
          />
        )}
      </Odie>
    </>
  );
};

export default Routes;
