import styled, { css } from 'styled-components';

const ResponsiveButtonContainer = styled.div`
  width: 100%;

  ${({ fullWidth, theme }) =>
    !fullWidth &&
    css`
      @media ${theme.sizes.sm.breakpoint} {
        max-width: ${theme.sizes.sm.buttons};
      }

      @media ${theme.sizes.md.breakpoint} {
        max-width: ${theme.sizes.md.buttons};
      }

      @media ${theme.sizes.lg.breakpoint} {
        max-width: ${theme.sizes.lg.buttons};
      }

      @media ${theme.sizes.xl.breakpoint} {
        max-width: ${theme.sizes.xl.buttons};
      }

      @media ${theme.sizes.xxl.breakpoint} {
        max-width: ${theme.sizes.xxl.buttons};
      }
    `}
  margin: 0;
`;

export default ResponsiveButtonContainer;
