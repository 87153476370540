const SuggestedDivisionContent = {
  title: 'We suggest it would be fair for you to receive:',
  suggestionAccountText:
    'This suggestion takes into account the length of the relationship, both parties’ age and health, how much you both earn, future needs, and how much you each contributed to the relationship, and child care responsibilities.',
  subheading: "What's next?",
  signUpHeading: 'Sign up to amica with your former partner',
  signupText:
    "When you sign up to amica, you'll be able to invite your former partner and work through similar questions together.  This means you and your former partner can work together to reach an agreed division of assets.",
  helpHeading: 'Get help or support',
  helpText: 'Do you want support or have questions? We’re here to help.',
  amicaButton: 'Go to amica',
  amicaLink: 'https://app.amica.gov.au/',
  pricingButton: 'View pricing',
  pricingLink: 'https://amica.gov.au/how-it-works/pricing',
  helpButton: 'Get support',
  helpLink: 'https://www.amica.gov.au/help-and-support.html',
};

export default SuggestedDivisionContent;
