import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import formatCurrency from 'Common/Utils/formatCurrency';
import Paragraph from 'Common/UI/Text/Paragraph';
import FullScreenModal from 'Common/UI/Layout/FullScreenModal';
import GenericButton from 'Common/UI/Button/GenericButton';
import FormLabel from 'Common/UI/Form/FormLabel';
import Popup from './Popup';

const modalPortal = document.getElementById('modal');

const StyledPopup = styled(Popup)`
  padding-top: ${props => props.theme.spacing[8]};
  min-height: 100%;

  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
`;

// In the questions files, formAttributes can contain:
// hasValue: bool
// hasDate: bool
// popupTitle: 'List accounts',
// buttonText: 'Enter information',
// textLabel: 'Account name',
// numberLabel: 'Amounts',
// dateLabel: 'Date',
// textPlaceholder: 'Enter a description of the account',
// numberPlaceholder: 'Enter amount',

const MultiItems = ({ label, value, formAttributes, onChange, ...rest }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => setIsModalVisible(true);
  const hideModal = () => {
    setIsModalVisible(false);
    // Need to filter out any empty list items
    onChange(value.filter(v => v.text !== ''));
  };

  const {
    popupTitle = 'Enter items',
    buttonText = 'Enter information',
    type = 'value',
    hasValue = false,
  } = formAttributes;

  const portal = createPortal(
    <FullScreenModal
      show={isModalVisible}
      onClose={hideModal}
      title={popupTitle}
      closeButtonType={
        Array.isArray(value) && value.length > 0 ? 'done' : 'icon'
      }
    >
      <StyledPopup
        label={label}
        value={value}
        formAttributes={formAttributes}
        type={type}
        onChange={onChange}
        {...rest}
      />
    </FullScreenModal>,
    modalPortal
  );

  return (
    <>
      <FormLabel label={label} />
      <GenericButton
        onClick={showModal}
        type="button"
        data-cy="question-multi-items-button"
      >
        {buttonText}
      </GenericButton>

      {hasValue && value.length > 0 && (
        <>
          <br />
          <br />
          <Paragraph>
            You&apos;ve currently entered {value.length}{' '}
            {value.length > 1 ? 'items' : 'item'} valued at{' '}
            {formatCurrency(
              value.reduce((acc, cur) => (acc += Number(cur.number)), 0)
            )}
          </Paragraph>
        </>
      )}

      {!hasValue && value.length > 0 && (
        <>
          <br />
          <br />
          <Paragraph>
            You&apos;ve currently entered {value.length}{' '}
            {value.length > 1 ? 'items' : 'item'}.
          </Paragraph>
        </>
      )}

      {portal}
    </>
  );
};

MultiItems.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      number: PropTypes.number,
    })
  ),
  onChange: PropTypes.func.isRequired,
  formAttributes: PropTypes.shape({}),
};

MultiItems.defaultProps = {
  formAttributes: {},
  value: [],
};

export default MultiItems;
