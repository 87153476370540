import MatterProps from 'Common/Utils/MatterProps';
import {
  DEFAULT_SELF_TEXT,
  DEFAULT_OTHER_TEXT,
  PARTY_A,
  PARTY_B,
} from 'Common/constants';

const getNames = () => ({
  self: MatterProps('self.firstname', DEFAULT_SELF_TEXT) as string,
  other: MatterProps('other.firstname', DEFAULT_OTHER_TEXT) as string,
  [PARTY_A]: MatterProps(`party${PARTY_A}.firstname`) as string,
  [PARTY_B]: MatterProps(`party${PARTY_B}.firstname`) as string,
});

export default getNames;
