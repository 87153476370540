import { useAppSelector } from 'App/State/Store';
import { Theme } from 'Common/Utils/theme';
import {
  getCardQuestionFlowMetadata,
  getCard,
} from 'Common/Data/SingleUser/appSections';
import { QuestionCard } from 'Common/Data/Types/appSections';
import { ODRS_DASH_HOME_ROUTE } from 'Common/routes';
import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import RoundButton from 'Common/UI/Button/RoundButton';
import { Row } from 'Common/UI/Layout/Flex';
import { H2Conversational, Heading2 } from 'Common/UI/Text/Headings';
import {
  QuestionFlowHeader,
  StyledQuestionsFlowWrapper,
  TitleHeadingWrapper,
  TitleIconImage,
  EditableStatement,
} from 'App/UI/Questions/common';

const Header = styled(Row)<{
  theme: Theme;
}>`
  padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[6]}`};
  position: relative;
  background: ${({ theme }) => theme.colours.offWhite};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[12]}`};
  }
`;

const StyledRoundButton = styled(RoundButton)`
  z-index: 10;
  text-decoration: none;
  text-align: center;
`;

const EditStatement = () => {
  const { cardID } = useParams<{ cardID: string }>();

  const matter = useAppSelector(state => state.matter);

  const maybeCard = getCard(cardID);

  if (!maybeCard) {
    throw new Error('Card does not exist');
  }

  const card = maybeCard as QuestionCard;

  const { title, statement: statementFunction, data } = card;

  const { icon } = getCardQuestionFlowMetadata(card.cardID);

  const statement = statementFunction({
    matter,
    data,
    card,
    withUpdateLink: true,
  });
  const cancelRoute = card.dashboardRoute || ODRS_DASH_HOME_ROUTE;

  return (
    <>
      <Header justifyContent="space-between">
        <Heading2>Editing statement</Heading2>
        <StyledRoundButton subtle as={Link} to={cancelRoute}>
          Cancel
        </StyledRoundButton>
      </Header>
      <StyledQuestionsFlowWrapper onSubmit={() => {}} id="questionForm">
        <QuestionFlowHeader>
          <TitleHeadingWrapper>
            {icon && <TitleIconImage src={icon} />}
            {title}
          </TitleHeadingWrapper>
        </QuestionFlowHeader>
        <H2Conversational>Statement</H2Conversational>

        <EditableStatement statement={statement} showEditedByYou />
      </StyledQuestionsFlowWrapper>
    </>
  );
};

export default EditStatement;
