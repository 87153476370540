import React from 'react';

import isNumeric from 'Common/Utils/isNumeric';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import {
  getUpdatedItemValues,
  getHighlightStatementValuesFunction,
  getWasUpdatedFunction,
  getRedactFunction,
} from 'Common/Utils/statementHelpers';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import isEmailAddress from 'Common/Utils/isEmailAddress';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';

export const yourDetails: QuestionAny[] = [
  {
    name: 'name',
    label: 'What is your first name?',
    placeholder: 'Enter your first name',
    hint: '',
    type: 'text',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'email',
    label: 'What is your email address?',
    placeholder: 'Enter email address',
    type: 'email',
    optional: true,
    validate: (email, _, consent) => {
      // no email, can skip question
      if (!email) return [true];

      // invalid email
      if (!isEmailAddress(email)) return [false, 'Invalid email address'];

      // valid email, needs consent to proceed
      if (isEmailAddress(email) && consent) {
        return [true];
      }
      return [false];
    },
    index: 1,
    consent: true,
    consentText:
      'I consent to participating in amica one’s future market research',
    afterContent: (
      <Paragraph marginTop="16px">
        Providing your email address is optional.
      </Paragraph>
    ),
    help: {
      label: 'Why are you asking this?',
      content: (
        <>
          <H1Conversational>Why are you asking this?</H1Conversational>
          <Paragraph>
            We will only use your email to contact you if you choose to
            participate in our market research. Your information won&apos;t be
            saved if you opt out of sharing your email.
          </Paragraph>
          <Paragraph>
            To learn more about how we use your information, you can read our{' '}
            <ReferralLink href="https://amica.gov.au/privacy-statement">
              privacy policy.
            </ReferralLink>
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'postcode',
    label: 'What is your postcode?',
    placeholder: 'Enter your postcode',
    hint: '',
    type: 'text',
    defaultValue: '',
    help: {
      label: 'Why are you asking this?',
      content: (
        <>
          <H1Conversational>Why are you asking this?</H1Conversational>
          <Paragraph large>
            Entering your postcode gives us a better understanding of who is
            using our services in Australia and helps us to improve amica.
          </Paragraph>
          <Paragraph large>
            To learn more about how we use your information, you can read our{' '}
            <ReferralLink href="https://amica.gov.au/privacy-statement">
              privacy policy.
            </ReferralLink>
          </Paragraph>
        </>
      ),
    },
    index: 2,
    validate: value =>
      /^[0-9]{4}$/.test(value)
        ? [true]
        : [false, 'Please enter a valid Australian post code'],
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 3,
  },
];

export const yourDetailsStatement = ({
  matter,
  data,
  card,
  withUpdateLink = false,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'name',
    'email',
    'postcode',
  ]);

  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(yourDetails, data, hideSensitive);

  const name = highlight`Your first name is ${updated(
    'name',
    redact('name', data.name)
  )}.`;

  const email = data.email
    ? highlight`Your email address is ${updated(
        'email',
        redact('email', data.email)
      )}.`
    : '';

  const postcode = highlight`Your postcode is ${updated(
    'postcode',
    redact('postcode', data.postcode)
  )}.`;

  if (plainText) {
    return `${name} ${email} ${postcode}`;
  }

  return (
    <>
      {name} {email} {postcode}
    </>
  );
};
