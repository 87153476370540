/* eslint-disable react/forbid-prop-types */
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  FunctionComponent,
  ReactNode,
  ChangeEvent,
} from 'react';

import styled, { ThemeProvider } from 'styled-components';

import { DOCUMENT_STATUS_INPROGRESS } from 'Common/constants';
import { Theme, orangeTheme, singleUserTheme } from 'Common/Utils/theme';
import push from 'Common/Utils/push';
import { useAppDispatch, useAppSelector } from 'App/State/Store';
import {
  QuestionAny,
  QuestionCard,
  Statement,
} from 'Common/Data/Types/appSections';
import { useLocation } from 'react-router';
import Loader from 'Common/UI/Loader/Loader';
import HelpButton from 'Common/UI/Button/HelpButton';
import FlexDrawer from 'Common/UI/Layout/FlexDrawer';
import GenericButton from 'Common/UI/Button/GenericButton';
import LearnMoreButton from 'Common/UI/Button/LearnMoreButton';
import ValidationMessage from 'Common/UI/Form/ValidationMessage';
import Intro from 'Common/UI/Form/Intro';
import Carousel from 'Common/UI/Form/Carousel';
import { ProgressBarContext } from 'Common/Utils/ProgressBarContext';
import getActiveQuestion from 'Common/Utils/getActiveQuestion';
import Question from 'App/UI/Questions/Question';
import BackNextButtons from 'Common/UI/Questions/BackNextButtons';
import { CardQuestionFlowContext } from 'Common/UI/Questions/CardQuestionsFlowContext';
import CheckboxField from 'Common/UI/Form/CheckboxField';
import updateConsentToStore from 'Common/Utils/updateConsentToStore';
import {
  QuestionFlowHeader,
  StyledQuestionsFlowWrapper,
  TitleHeadingWrapper,
  TitleIconImage,
} from './common';

const StyledFlexDrawer = styled(FlexDrawer)`
  position: fixed;
`;

const QuestionsContainer = styled.div<{
  theme: Theme;
}>`
  flex: 1;
  position: relative;
  padding-top: ${({ theme }) => theme.spacing[8]};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-top: 0;
  }

  // If screen is too short then the DatePicker gets cut off
  // for smaller phones like 5S
  @media (max-height: 655px) {
    overflow: visible;
  }
`;

const QuestionsContainerInner = styled.div``;

const HelpButtonWrapper = styled.div<{
  theme: Theme;
}>`
  margin: ${({ theme }) => theme.spacing[6]} 0;
`;

const isAutoSubmitType = (question: QuestionAny) =>
  ['includeStatement'].includes(question.type);

type Props = {
  questions: QuestionAny[];
  onInputChange: (key: string, value: any) => void;
  values?: Record<string, unknown>;
  sectionID?: string;
  startingStepNumber?: number;
  onSubmit: () => Promise<unknown>;
  statement?: ({ matter, data, card }: Statement) => JSX.Element | string;
  card?: QuestionCard;
  title: string;
  titleIcon?: string;
  isUpdating?: boolean;
};

const QuestionsFlowWrapper: FunctionComponent<Props> = ({
  questions,
  onInputChange,
  values = {},
  statement,
  sectionID = '',
  startingStepNumber = 0,
  onSubmit,
  card,
  title,
  titleIcon,
  isUpdating = false,
}) => {
  const innerRef = useRef<HTMLDivElement>(null);

  const context = useContext(ProgressBarContext);
  const {
    setHeaderVisibility,
    setActiveQuestion: setContextActiveQuestion,
    setShowProgressBar,
  } = useContext(CardQuestionFlowContext);

  const { state: locationState = {} } =
    useLocation<{
      gotoQuestion?: string;
      timestamp?: number;
      previous?: any;
    }>();

  const initialActiveQuestion = locationState?.gotoQuestion;
  const timestamp = locationState?.timestamp;
  const previous = locationState?.previous;

  const [showLoading, setShowLoading] = useState(false);
  const [touched, setTouched] = useState<any>({}); // TODO update type
  const [focused, setFocused] = useState<string | null>(null);
  const [validationMessage, setValidationMessage] =
    useState<string | undefined>(undefined);
  const [activeQuestion, setActiveQuestion] = useState(questions[0]);
  const [showQuestionDrawer, setShowQuestionDrawer] = useState(false);
  const [questionDrawerContent, setQuestionDrawerContent] =
    useState<{
      content: ReactNode;
      buttonText: string;
    }>();
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    setActiveQuestion(
      initialActiveQuestion
        ? questions.find(question => question.name === initialActiveQuestion) ||
            questions[0]
        : questions[0]
    );
  }, [timestamp]);

  const { updateStep } = context;

  // make sure the progress indicator shows the right percentage on load
  useEffect(() => {
    if (updateStep) {
      updateStep(startingStepNumber + activeQuestion.index);
    }
  }, [activeQuestion]);

  useEffect(() => {
    setContextActiveQuestion(activeQuestion);

    if (activeQuestion.type === 'intro' || activeQuestion.type === 'carousel') {
      setHeaderVisibility(false);
      setShowProgressBar(false);
    } else {
      setHeaderVisibility(true);
      setShowProgressBar(true);
    }
  }, [activeQuestion]);

  const [matter, isModalShowing = false] = useAppSelector(state => [
    state.matter,
    state.app.isModalShowing,
  ]);

  const dispatch = useAppDispatch();
  const navigate = (route: string, state?: any) => dispatch(push(route, state));

  // runs a questions validation function (if it exists) against the input
  const validateInput = (): [boolean, string?] => {
    const testValue = values && values[activeQuestion.name];

    if (activeQuestion.exitOnSubmit) {
      return [true];
    }

    // fails validation on an empty field unless it is set as optional
    if (
      !activeQuestion.optional &&
      activeQuestion.type !== 'intro' &&
      activeQuestion.type !== 'info' &&
      activeQuestion.type !== 'carousel' &&
      activeQuestion.type !== 'number' &&
      !activeQuestion.disabled &&
      activeQuestion.type !== 'statement' &&
      (testValue === undefined || testValue === '')
    ) {
      return [false];
    }

    // fails validation on document uploader fields if an upload is in progress
    if (
      activeQuestion.type === 'docupload' &&
      Array.isArray(testValue) &&
      testValue.find(file => file && file.status === DOCUMENT_STATUS_INPROGRESS)
    ) {
      return [false];
    }

    const [validValue, valMessage] = activeQuestion.validate
      ? activeQuestion.validate(testValue, values, consent)
      : [true];

    return [validValue, valMessage];
  };

  // perform input validation and reset focused to null
  const onBlur = (field: string) => {
    const [validValue, valMessage] = validateInput();

    if (validValue) {
      setValidationMessage(undefined);
    } else {
      setValidationMessage(valMessage);
    }

    setTouched({ ...touched, [field]: true });
    setFocused(null);
  };

  // set the currently focused field
  const onFocus = (field: string) => {
    setFocused(field);
  };

  const getVisibleQuestions = () =>
    questions.filter(question =>
      question.visible && question.index !== activeQuestion.index
        ? question.visible(values)
        : true
    );

  const visibleQuestions = getVisibleQuestions();

  const getNextVisibleQuestion = () => {
    const nextQuestion = visibleQuestions.find(
      (question: QuestionAny) => question.index > activeQuestion.index
    );

    return nextQuestion || false;
  };

  const getPrevVisibleQuestion = () => {
    // create a copy of visibleQuestions so we can run `reverse`()` on it, because `reverse`()` mutates the array
    const visibleQuestionsCopy = [...visibleQuestions];
    const prevQuestion = visibleQuestionsCopy
      .reverse()
      .find((question: QuestionAny) => question.index < activeQuestion.index);

    if (!prevQuestion) {
      throw new Error('Previous visible question not found');
    }

    return prevQuestion;
  };

  const hasPrevVisibleQuestion = () => {
    try {
      return !!getPrevVisibleQuestion();
    } catch (e) {
      return false;
    }
  };

  const gotoQuestion = (index: number) => {
    setValidationMessage(undefined);

    const newActiveQuestion = getActiveQuestion(questions, index);

    setActiveQuestion(newActiveQuestion);
  };

  // gets the state of the question (e.g. previous/active/next/etc)
  // this function is really confusing due to the use of both the `stated` index (e.g. a key in the question object that sets an integer for the index)
  // and the `actual` index (the position of the question in a given array)
  const getQuestionState = (index: number) => {
    const activeIndex = activeQuestion.index;

    if (index === activeIndex) {
      return 'active';
    }

    const activeQIndex = visibleQuestions.findIndex(
      (question: QuestionAny) => question.index === activeQuestion.index
    );

    if (
      visibleQuestions[activeQIndex - 1] &&
      visibleQuestions[activeQIndex - 1].index === index
    ) {
      return 'previous';
    }

    if (index < activeIndex) {
      return 'answered';
    }

    return 'unanswered';
  };

  // goes to the next question, or submits the form if this is the last question
  const next = (e: any) => {
    const event = e || window.event;
    event.preventDefault();

    if (isModalShowing) {
      return;
    }

    const onBeforeNextWithLoading = () => {
      if (!activeQuestion.onBeforeNext) {
        return Promise.resolve();
      }

      // IF takes longer than 300ms then show loading screen
      const loadingTimeout = setTimeout(() => setShowLoading(true), 300);
      return activeQuestion.onBeforeNext(values, navigate).finally(() => {
        clearTimeout(loadingTimeout);
        setShowLoading(false);
      });
    };

    const [validValue, valMessage] = validateInput();

    if (validValue) {
      onBeforeNextWithLoading()
        .then(() => {
          setTimeout(
            () => {
              setValidationMessage(undefined);

              if (
                activeQuestion.exitOnSubmit ||
                activeQuestion.index ===
                  questions[questions.length - 1].index ||
                !getNextVisibleQuestion()
              ) {
                setShowLoading(true);
                onSubmit().then(() => setShowLoading(false));
              } else {
                const nextVisibleQuestion = getNextVisibleQuestion();

                if (nextVisibleQuestion) {
                  gotoQuestion(
                    Math.min(
                      nextVisibleQuestion.index,
                      questions[questions.length - 1].index
                    )
                  );
                } else {
                  gotoQuestion(questions[questions.length - 1].index);
                }
              }
            },
            isAutoSubmitType(activeQuestion) ? 200 : 0
          ); // delay the question scroll by a little bit if this is a yes/no question so you can see the button being highlighted
        })
        .catch((ex: any) => {
          setValidationMessage(ex.message);
        });
    } else {
      setValidationMessage(valMessage);
    }
  };

  const prev = (e: ChangeEvent) => {
    const event = e || window.event;
    event.preventDefault();

    gotoQuestion(getPrevVisibleQuestion().index);
  };

  // submits the current question and goes to the next if enter is pressed
  const nextOnEnter = (e: KeyboardEvent) => {
    if (
      (activeQuestion.type !== 'freetext' && e.keyCode === 13) ||
      (activeQuestion.type === 'freetext' && e.keyCode === 13 && e.shiftKey)
    ) {
      next(e);
    }

    return false;
  };

  const onQuestionInput = (key: string, value: any) => {
    if (!showQuestionDrawer && activeQuestion.checkShowDrawerOnInput) {
      const onSelectOptionData = activeQuestion.checkShowDrawerOnInput(value);

      if (onSelectOptionData.showDrawer) {
        setShowQuestionDrawer(true);
        setQuestionDrawerContent({
          content: onSelectOptionData.drawerContent,
          buttonText: onSelectOptionData.closeButton,
        });
      }
    }

    return onInputChange(key, value);
  };

  useEffect(() => {
    if (typeof activeQuestion.setValue !== 'undefined') {
      onInputChange(activeQuestion.name, activeQuestion.setValue);
    }
  }, [activeQuestion]);

  // had big issues with next not getting latest state when called via the listener
  // fix: https://github.com/facebook/react/issues/14699
  useEffect(() => {
    document.addEventListener('keydown', nextOnEnter, false);

    return () => document.removeEventListener('keydown', nextOnEnter, false);
  });

  const [validValue] = validateInput();

  const validationError =
    validationMessage && focused !== activeQuestion.name ? (
      <ValidationMessage>{validationMessage}</ValidationMessage>
    ) : null;

  let submitButtonName;
  if (activeQuestion.exitOnSubmit) {
    submitButtonName = 'Submit';
  } else if (
    activeQuestion.type === 'info' &&
    activeQuestion.submitButtonLabel
  ) {
    submitButtonName = activeQuestion.submitButtonLabel;
  } else {
    submitButtonName =
      !isUpdating && activeQuestion.index === questions.length - 1
        ? 'Submit'
        : 'Next';
  }

  const help = (
    <HelpButtonWrapper>
      {activeQuestion.help && (
        <LearnMoreButton
          ui={HelpButton}
          popUpContent={activeQuestion.help?.content}
          className="step-transition-fade-down"
          theme={singleUserTheme}
          help
        >
          {activeQuestion.help.label}
        </LearnMoreButton>
      )}
    </HelpButtonWrapper>
  );

  const consentCheck = activeQuestion.consent && (
    <CheckboxField
      value={consent}
      name="test"
      label={activeQuestion.consentText || 'I consent'}
      onChange={async checked => {
        await setConsent(checked);
        await updateConsentToStore(activeQuestion.name, checked);
      }}
      data-cy="question-consent"
    />
  );

  if (activeQuestion.type === 'intro') {
    return (
      <Intro
        goBack={() => navigate(previous)}
        goNext={next}
        heading={
          activeQuestion.heading ? activeQuestion.heading(matter) : undefined
        }
        content={
          activeQuestion.content ? activeQuestion.content(matter) : undefined
        }
        help={help}
      />
    );
  }

  if (activeQuestion.type === 'carousel') {
    return (
      <Carousel
        slides={activeQuestion.slides}
        goBack={() => navigate(previous)}
        goNext={next}
      />
    );
  }

  return (
    <>
      {showLoading && <Loader floating />}
      <StyledQuestionsFlowWrapper
        onSubmit={next}
        id="questionForm"
        data-cy="questions-wrapper"
      >
        <QuestionFlowHeader>
          <TitleHeadingWrapper>
            {titleIcon && <TitleIconImage src={titleIcon} />}
            {title}
          </TitleHeadingWrapper>
        </QuestionFlowHeader>
        <QuestionsContainer>
          <QuestionsContainerInner ref={innerRef}>
            <Question
              key={`${activeQuestion.name}${activeQuestion.index}`}
              focus
              onBlur={onBlur}
              onFocus={onFocus}
              onInput={onQuestionInput}
              sectionID={sectionID}
              state={getQuestionState(activeQuestion.index)}
              statement={
                statement &&
                card &&
                statement({
                  matter,
                  data: values,
                  card,
                  withUpdateLink: true,
                })
              }
              validationMessage={validationError}
              values={values}
              question={activeQuestion}
              isUpdating={isUpdating}
            />

            {activeQuestion?.afterContent}

            {consentCheck}

            {help}
          </QuestionsContainerInner>
        </QuestionsContainer>
        {activeQuestion.type !== 'statement' && (
          <div>
            <BackNextButtons
              hasPreviousQuestion={hasPrevVisibleQuestion()}
              onBack={prev}
              onNext={next}
              nextButtonLabel={submitButtonName}
              isQuestionTypeAutoSubmit={isAutoSubmitType(activeQuestion)}
              isQuestionTypeCode={activeQuestion.name === 'code'}
              isQuestionOptional={activeQuestion.optional}
              hasQuestionBeenTouched={!!values[activeQuestion.name]}
              isInputValid={validValue}
            />
          </div>
        )}
        <ThemeProvider theme={orangeTheme}>
          <StyledFlexDrawer isVisible={showQuestionDrawer}>
            {questionDrawerContent?.content}
            <GenericButton
              onClick={(e: any) => {
                e.preventDefault();
                setShowQuestionDrawer(false);
              }}
            >
              {questionDrawerContent?.buttonText}
            </GenericButton>
          </StyledFlexDrawer>
        </ThemeProvider>
      </StyledQuestionsFlowWrapper>
    </>
  );
};

export default QuestionsFlowWrapper;
