import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import moment from 'moment';
import Div100vh from 'react-div-100vh';
import { DATE_FORMAT_SHORT_WITH_TIME } from 'Common/constants';
import { INTRO_ROUTE, ODRS_BASE_ROUTE } from 'Common/routes';

import { APP_ID } from 'App/config';

import Analytics from 'Common/Utils/Analytics';

import ErrorBoundary from 'Common/UI/ErrorBoundary';

import Onboarding from 'App/Pages/Onboarding';
import Routes from 'App/Routes';

import Intro from 'App/Routes/Intro';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { singleUserTheme } from 'Common/Utils/theme';
import AppLoader from 'Common/UI/Loader/AppLoader';
import RouteTransition from 'App/UI/Transitions/RouteTransition';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import DiviRoute from './UI/DiviRoute';
import { reduxStore, history, persistor } from './State/Store';

// setting the default calendar date formats
moment.updateLocale('en', {
  calendar: {
    sameDay: '[Today], LT',
    nextDay: '[Tomorrrow], LT',
    nextWeek: 'dddd, LT',
    lastDay: '[Yesterday], LT',
    lastWeek: '[Last] dddd, LT',
    sameElse: DATE_FORMAT_SHORT_WITH_TIME,
  },
});

// eslint-disable-next-line react/prop-types
function App() {
  return (
    <ThemeProvider theme={singleUserTheme}>
      <ErrorBoundary>
        <Div100vh>
          <RouteTransition>
            <DiviRoute exact path="/" component={Onboarding} />
            <DiviRoute exact path={INTRO_ROUTE} component={Intro} />
            <DiviRoute path={ODRS_BASE_ROUTE} component={Routes} />
          </RouteTransition>
        </Div100vh>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

const Root = (
  <Provider store={reduxStore}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <App />
        <AppLoader />
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

Analytics.initGTM(process.env.GTM_TRACKING_ID);

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.STAGE,
    release: process.env.SENTRY_RELEASE,
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (
        event.exception &&
        (event.level === 'fatal' || event.level === 'error')
      ) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
  });
}

// Cypress code to access the store
declare global {
  interface Window {
    Cypress: any;
    store: any;
  }
}

if (window.Cypress) {
  window.store = reduxStore;
}

ReactDOM.render(Root, document.getElementById(APP_ID));

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
