import {
  STATUS_NEW_ONLY_SELF,
  STATUS_NEW_ONLY_THEM,
  STATUS_NEW_BOTH_PARTIES,
  DATA_SECTION,
  DATA_GROUP,
  CARD_NORMAL,
  SECTION_RELATIONSHIP,
  DATA_PAGE,
  PAGE_RELATIONSHIP_MAIN,
  SECTION_PARENTING,
  FLAG_MATTER_OPTED_IN,
  MESSAGE_STATUS_APPROVED,
  STATUS_APPROVED,
} from 'Common/constants';
import MatterPropsJS from 'Common/Utils/MatterProps';
import {
  ODRS_RELATIONSHIP_ROUTE,
  ODRS_SUA_ABOUTYOU_SELF_ROUTE,
  ODRS_SUA_ABOUTYOU_OTHER_ROUTE,
  ODRS_CHILDREN_ROUTE,
  ODRS_DASH_RELATIONSHIP_ROUTE,
  ODRS_BASE_ROUTE,
  ODRS_NOCHILDREN_ROUTE,
  ODRS_SUA_YOURDETAILS,
  HOME_ROUTE,
} from 'Common/routes';
import { getNextAvailableIndex } from 'Common/Utils/getNextAvailableIndex';
import IconRelationship from 'Common/Assets/images/icon-your-relationship.svg';
import IconAboutYou from 'Common/Assets/images/icon-about-you.svg';
import IconChildren from 'Common/Assets/images/icon-children.svg';
import BackgroundQuestionFlowRelationship from 'Common/Assets/images/demo-header.svg';
import colours from 'Common/Utils/colours';
import {
  localStorageRemove,
  localStorageSave,
} from 'Common/Utils/localStorage';
import { history, reduxStore } from 'App/State/Store';

import { getNavItemStatus } from 'Common/UI/Navigation';
import { getPageStatus } from 'App/Utils/sectionHelpers';
import getOtherParty from 'Common/Utils/getOtherParty';
import { CardNormal, DataSection } from 'Common/Data/Types/appSections';
import { Item } from 'Common/Data/Types/matter';
import {
  addEmptyChildrenAction,
  setMatterFlagsAction,
  updateMatterSectionAction,
  // eslint-disable-next-line import/no-relative-packages
} from '../../../../singleuser/frontend/src/app/State/MatterActions';
import {
  aboutYouQuestionsA,
  aboutYouQuestionsB,
  aboutYouStatementA,
  aboutYouStatementB,
  childrenQuestions,
  childrenStatement,
  noChildrenQuestions,
  noChildrenStatement,
  relationshipQuestions,
  relationshipStatement,
} from '../Questions/Relationship';
import { buildCard } from '../buildCard';
import { yourDetails, yourDetailsStatement } from '../Questions/yourdetails';

const generateRelationship = ({
  existingChildren,
}: {
  existingChildren?: any;
}): DataSection => {
  const generateStatus = () => {
    const { completed } = getPageStatus({
      section: SECTION_RELATIONSHIP,
      page: PAGE_RELATIONSHIP_MAIN,
    });

    const { app } = reduxStore.getState();

    return getNavItemStatus({
      isCompleted: completed,
      isLocked: app.flags.isDemoComplete,
    });
  };

  return {
    type: DATA_SECTION,
    id: SECTION_RELATIONSHIP,
    title: 'About you and your relationship',
    route: ODRS_DASH_RELATIONSHIP_ROUTE,
    questionFlowBackgroundImage: BackgroundQuestionFlowRelationship,
    questionFlowBackgroundColour: colours.offWhite,
    children: [
      {
        type: DATA_PAGE,
        id: PAGE_RELATIONSHIP_MAIN,
        title: 'Relationship',
        route: ODRS_DASH_RELATIONSHIP_ROUTE,
        generateStatus,
        children: [
          {
            type: DATA_GROUP,
            id: 'details',
            title: 'About you and your relationship',
            icon: IconAboutYou,
            hideOnDashboard: true,
            children: [
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'yourdetails',
                cardID: `yourdetails${MatterPropsJS('self.party', 'A')}`,
                title: 'Your details',
                sensitiveTitle: `About Party ${MatterPropsJS(
                  'self.party',
                  'A'
                )}`,
                questions: yourDetails,
                statement: yourDetailsStatement,
                index: MatterPropsJS('self.party', 'A'),
                questionsRoute: ODRS_SUA_YOURDETAILS,
                updateRoute: `${ODRS_SUA_YOURDETAILS}/update`,
                dashboardRoute: HOME_ROUTE,
                status: STATUS_NEW_ONLY_SELF,
                hideOnDashboard: true,
                singleUserApproved: true,
                autoApprove: true,
              }),
            ],
          },
          {
            type: DATA_GROUP,
            id: 'about',
            title: 'About you and your relationship',
            description:
              'We are going to ask a few questions about you and your relationship with your former partner.',
            icon: IconRelationship,
            children: [
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'yourdetails',
                cardID: `yourdetails${MatterPropsJS('self.party', 'A')}`,
                title: 'Your details',
                sensitiveTitle: `About Party ${MatterPropsJS(
                  'self.party',
                  'A'
                )}`,
                questions: yourDetails,
                statement: yourDetailsStatement,
                index: MatterPropsJS('self.party', 'A'),
                questionsRoute: ODRS_SUA_YOURDETAILS,
                updateRoute: `${ODRS_SUA_YOURDETAILS}/update`,
                dashboardRoute: ODRS_DASH_RELATIONSHIP_ROUTE,
                status: STATUS_NEW_ONLY_SELF,
                hideOnDashboard: true,
                singleUserApproved: true,
                autoApprove: true,
              }),
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'aboutyou',
                cardID: `aboutyou${MatterPropsJS('self.party', 'A')}`,
                title: 'About you',
                sensitiveTitle: `About Party ${MatterPropsJS(
                  'self.party',
                  'A'
                )}`,
                questions: aboutYouQuestionsA,
                statement: aboutYouStatementA,
                index: MatterPropsJS('self.party', 'A'),
                questionsRoute: ODRS_SUA_ABOUTYOU_SELF_ROUTE,
                updateRoute: `${ODRS_SUA_ABOUTYOU_SELF_ROUTE}/update`,
                dashboardRoute: ODRS_DASH_RELATIONSHIP_ROUTE,
                status: STATUS_NEW_ONLY_SELF,
                singleUserApproved: true,
                autoApprove: true,
              }),
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'aboutyou',
                cardID: `aboutyou${MatterPropsJS('other.party', 'B')}`,
                title: `About ${MatterPropsJS('other.firstname', 'Them')}`,
                sensitiveTitle: `About Party ${MatterPropsJS(
                  'other.party',
                  'B'
                )}`,
                questions: aboutYouQuestionsB,
                statement: aboutYouStatementB,
                index: MatterPropsJS('other.party', 'B'),
                questionsRoute: ODRS_SUA_ABOUTYOU_OTHER_ROUTE,
                updateRoute: `${ODRS_SUA_ABOUTYOU_OTHER_ROUTE}/update`,
                dashboardRoute: ODRS_DASH_RELATIONSHIP_ROUTE,
                status: STATUS_NEW_ONLY_THEM,
                singleUserApproved: true,
                autoApprove: true,
              }),
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'relationship',
                cardID: 'relationship',
                title: 'Your relationship',
                questions: relationshipQuestions,
                statement: relationshipStatement,
                questionsRoute: ODRS_RELATIONSHIP_ROUTE,
                updateRoute: `${ODRS_RELATIONSHIP_ROUTE}/update`,
                dashboardRoute: ODRS_DASH_RELATIONSHIP_ROUTE,
                status: STATUS_NEW_BOTH_PARTIES,
                singleUserApproved: true,
                autoApprove: true,
              }),
            ],
          },
          {
            type: DATA_GROUP,
            id: 'children',
            title: 'Children',
            isRepeatable: true,
            isIndividual: false,
            singular: 'child',
            plural: 'children',
            canAddCard: !MatterPropsJS('flags.hasNoItemsChildren'),
            promptCardData: {
              hasNoneState: false,
              initial: {
                title: 'Do you have children together?',
                content:
                  'You will need to add any children that you and your former partner have together.',
                yesButton: 'Yes, we do',
                noButton: "We don't have children",
                onClickYes: async () => {
                  const { dispatch } = reduxStore;
                  const { matter } = reduxStore.getState();
                  const optedInFlags =
                    matter?.flags[FLAG_MATTER_OPTED_IN] || {};

                  await dispatch(addEmptyChildrenAction());

                  await dispatch(
                    setMatterFlagsAction({
                      [FLAG_MATTER_OPTED_IN]: {
                        ...optedInFlags,
                        SECTION_PARENTING: true,
                      },
                    })
                  );

                  localStorageRemove(
                    `DismissedOverviewOptInCard_${SECTION_PARENTING}`
                  );

                  history.push(`${ODRS_BASE_ROUTE}/children/1`, {
                    previous: reduxStore.getState().router.location.pathname,
                  });
                },
                onClickNo: async () => {
                  const { dispatch } = reduxStore;
                  const { matter } = reduxStore.getState();
                  const {
                    self: { party },
                  } = matter;

                  const optedInFlags =
                    matter?.flags[FLAG_MATTER_OPTED_IN] || {};

                  await dispatch(
                    setMatterFlagsAction({ hasNoItemsChildren: true })
                  );

                  const messageData = {
                    section: 'nochildren1',
                    owner: party,
                    message: '',
                    status: MESSAGE_STATUS_APPROVED,
                    checkTone: false,
                  };

                  const sectionData = {
                    owner: getOtherParty(party),
                    status: STATUS_APPROVED,
                  };

                  await dispatch(
                    updateMatterSectionAction({
                      section: ['nochildren', 1],
                      sectionData,
                      messageData,
                    })
                  );

                  await dispatch(
                    setMatterFlagsAction({
                      [FLAG_MATTER_OPTED_IN]: {
                        ...optedInFlags,
                        SECTION_PARENTING: false,
                      },
                    })
                  );

                  localStorageSave(
                    `DismissedOverviewOptInCard_${SECTION_PARENTING}`,
                    true
                  );
                },
              },
            },
            icon: IconChildren,
            defaultRoute: `${ODRS_CHILDREN_ROUTE}`,
            children: (() => {
              /**
               * This function builds the cards that are shown on the dashboard.
               * It includes both cards that already exist in the database, and new empty cards.
               *
               * setup.numChildren is always the number of cards we need to display, so we loop through an array of that length
               * We populate the first spots with the existing children until there are no more existing children, which can
               * happen if there are some empty children cards that haven't been completed yet. Then, we move on to generating
               * the empty cards. Cards that already exist have a known index, and this index is added to a list on each iteration.
               * When we generate the empty cards, we choose its index by selecting the lowest integer that is *not* in the list
               * of indexes that are already in use. This avoids problems of empty cards trying to re-use indexes of existing cards.
               */
              const takenIndexes: number[] = [];

              const items = MatterPropsJS('items');
              const setup =
                items.find((item: Item) => item.SectionID === 'setup') || {};

              const { hasNoItemsChildren } = MatterPropsJS('flags');

              if (hasNoItemsChildren) {
                const noChildrenItem = items.find(
                  (item: Item) => item.SectionID === 'nochildren1'
                );

                let cardID;

                if (noChildrenItem) {
                  cardID = noChildrenItem.SectionID;
                } else {
                  cardID = 'nochildren1';
                }

                return [
                  buildCard<CardNormal>({
                    type: CARD_NORMAL,
                    baseID: 'nochildren',
                    cardID,
                    title: 'You do not have any children',
                    sensitiveTitle: 'Children',
                    questions: noChildrenQuestions,
                    statement: noChildrenStatement,
                    index: 1,
                    isRepeatable: true,
                    questionsRoute: `${ODRS_NOCHILDREN_ROUTE}/1`,
                    updateRoute: `${ODRS_NOCHILDREN_ROUTE}/1/update`,
                    dashboardRoute: ODRS_DASH_RELATIONSHIP_ROUTE,
                    status: STATUS_NEW_BOTH_PARTIES,
                    autoApprove: true,
                    singleUserApproved: true,
                    removeCardText: 'We do not have children together',
                    removeCardConfirmText:
                      'Confirming you don’t have children together',
                    notEditable: true,
                  }),
                ];
              }

              return Array.from(
                Array(setup.numChildren || 0),
                (_el, arrayIndex) => {
                  const item = existingChildren[arrayIndex] || {};
                  let index;
                  let displayIndex;
                  let cardID;

                  if (item.SectionID) {
                    index = item.CardIndex;
                    displayIndex = index;
                    cardID = item.SectionID;
                  } else {
                    index = getNextAvailableIndex(takenIndexes);
                    displayIndex = arrayIndex + 1;
                    cardID = `children${index}`;
                  }

                  takenIndexes.push(Number(index));

                  return buildCard<CardNormal>({
                    type: CARD_NORMAL,
                    baseID: 'children',
                    cardID,
                    title: item.childName || `Child ${displayIndex}`,
                    sensitiveTitle: `Child ${displayIndex}`,
                    questions: childrenQuestions,
                    statement: childrenStatement,
                    index,
                    isRepeatable: true,
                    questionsRoute: `${ODRS_CHILDREN_ROUTE}/${index}`,
                    updateRoute: `${ODRS_CHILDREN_ROUTE}/${index}/update`,
                    dashboardRoute: ODRS_DASH_RELATIONSHIP_ROUTE,
                    status: STATUS_NEW_BOTH_PARTIES,
                    autoApprove: true,
                    singleUserApproved: true,
                  });
                }
              );
            })(),
          },
        ],
      },
    ],
  };
};

export default generateRelationship;
