import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Heading3 } from 'Common/UI/Text/Headings';
import LockIconWhite from 'Common/Assets/images/icon-lock-locked-white.svg';
import TickIconWhite from 'Common/Assets/images/icon-tick-white.svg';
import CircleIconWhite from 'Common/Assets/images/icon-circle-white.svg';
import CircleIconOrange from 'Common/Assets/images/icon-circle-orange.svg';

const StyledIconImage = styled.img`
  height: 100%;
  margin-left: 8px;
  opacity: 0.5;
  width: ${p => p.width};
`;

export const NavHeading3 = styled(Heading3)`
  ${({ theme }) => theme.typography.clickable.button2};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    ${({ theme }) => theme.typography.clickable.button2.md};
  }

  @media ${({ theme }) => theme.sizes.xl.breakpoint} {
    ${({ theme }) => theme.typography.clickable.button2.xl};
  }

  padding: 6px ${({ theme }) => theme.padding.xsmall}px;
  border-radius: 16px;
  border: 2px solid ${({ theme }) => theme.navHighlightBorderColour};

  ${props =>
    props.disabled &&
    `
    pointer-events: none;
  `}

  transition: border 200ms;

  &.${props => props.activeClassName} {
    border: 2px solid ${({ theme }) => theme.headerNavBorderActive};

    ${StyledIconImage} {
      opacity: 1;
    }
  }

  @media ${({ theme }) => theme.sizes.lg.breakpoint} {
    &:hover {
      border: 2px solid ${({ theme }) => theme.headerNavBorderHover};
    }
  }
`;

const NavLinkIcon = ({
  to,
  children,
  isLocked,
  isTicked,
  isUpdated,
  isNotCompleted,
  active,
  as,
  lockedTooltip,
  ...props
}) => {
  let iconPath;
  let iconWidth;

  if (isTicked) {
    iconPath = TickIconWhite;
    iconWidth = '10px';
  }
  if (isLocked) {
    iconPath = LockIconWhite;
    iconWidth = '10px';
  }
  if (isUpdated) {
    iconPath = CircleIconWhite;
    iconWidth = '8px';
  }
  if (isNotCompleted) {
    iconPath = CircleIconOrange;
    iconWidth = '8px';
  }

  let icon;

  if (isLocked && lockedTooltip) {
    icon = (
      <StyledIconImage
        src={iconPath}
        role="presentation"
        width={iconWidth}
        data-tip={lockedTooltip}
        data-for="tooltip"
        data-prevent-nav
      />
    );
  } else {
    icon = (
      <StyledIconImage src={iconPath} role="presentation" width={iconWidth} />
    );
  }

  return (
    <NavHeading3
      as={as}
      exact
      activeClassName="active"
      className={active && 'active'}
      to={to}
      {...props}
    >
      {children}
      {iconPath && icon}
    </NavHeading3>
  );
};

NavLinkIcon.propTypes = {
  to: PropTypes.string,
  as: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  isLocked: PropTypes.bool,
  isTicked: PropTypes.bool,
  isUpdated: PropTypes.bool,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
};

NavLinkIcon.defaultProps = {
  to: null,
  as: NavLink,
  isLocked: false,
  isTicked: false,
  isUpdated: false,
  disabled: false,
  active: false,
};

export default NavLinkIcon;
