import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SpacedBox from 'Common/UI/Layout/SpacedBox';
import TextButton from 'Common/UI/Button/TextButton';
import FormLabel from 'Common/UI/Form/FormLabel';

import PasswordStrength from './PasswordStrength';
import Input from './Input';

const InputWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledTextButton = styled(TextButton)`
  position: absolute;
  right: 0;
  padding-top: 11px;
`;

class PasswordField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  render() {
    const {
      onChange,
      label,
      value,
      showStrength,
      disabled,
      className,
      onBlur,
      showConfirmField,
    } = this.props;

    const { password, passwordconfirm } = value;

    const { showPassword } = this.state;

    return (
      <div className={className}>
        <label htmlFor="password">
          <FormLabel label={label} />
          <InputWrapper>
            <StyledInput
              value={password}
              id="password"
              type={showPassword ? 'text' : 'password'}
              onChange={evt =>
                onChange('password', {
                  password: evt.target.value,
                  passwordconfirm,
                })
              }
              disabled={disabled}
            />
            <StyledTextButton
              className="small"
              type="button"
              onClick={e => {
                e.preventDefault();

                this.setState({
                  showPassword: !showPassword,
                });
              }}
            >
              {showPassword ? 'Hide' : 'Show'}
            </StyledTextButton>
          </InputWrapper>
          {showStrength ? <PasswordStrength password={password} /> : null}
        </label>
        {showConfirmField && (
          <SpacedBox marginTop={16}>
            <label htmlFor="passwordconfirm">
              <FormLabel label="Confirm your password" />
              <InputWrapper>
                <StyledInput
                  value={passwordconfirm}
                  id="passwordconfirm"
                  type={showPassword ? 'text' : 'password'}
                  onChange={evt =>
                    onChange('password', {
                      password,
                      passwordconfirm: evt.target.value,
                    })
                  }
                  disabled={disabled}
                  onBlur={onBlur}
                />
              </InputWrapper>
            </label>
          </SpacedBox>
        )}
      </div>
    );
  }
}

PasswordField.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.shape({
    password: PropTypes.string,
    passwordconfirm: PropTypes.string,
  }),
  showStrength: PropTypes.bool,
  disabled: PropTypes.bool,
  showConfirmField: PropTypes.bool,
};

PasswordField.defaultProps = {
  className: '',
  showStrength: false,
  value: { password: '', passwordconfirm: '' },
  disabled: false,
  showConfirmField: false,
};

export default PasswordField;
