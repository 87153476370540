import React from 'react';
import PropTypes from 'prop-types';

import Paragraph from 'Common/UI/Text/Paragraph';
import BubbleNotification from '../BubbleNotification';

const SoftNudge = ({ onClick }) => (
  <BubbleNotification onClick={onClick} buttonLabel="Got it">
    <Paragraph>
      You are making good progress, remember to keep things positive.
    </Paragraph>
  </BubbleNotification>
);

SoftNudge.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SoftNudge;
