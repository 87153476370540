import React from 'react';
import { createPortal } from 'react-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import Paragraph from 'Common/UI/Text/Paragraph';

const TooltipPopup = styled(ReactTooltip)`
  background: ${({ theme }) => theme.colours.lightOrange} !important;
  border: 3px solid ${({ theme }) => theme.colours.midDarkOrange} !important;
  border-radius: 16px !important;
  padding: 16px !important;
  width: 240px;
  height: auto;
  z-index: 35;
  color: black !important;
  opacity: 1 !important;

  &:after {
    display: none;
  }

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    &:after {
      display: block;
      border-bottom-color: ${({ theme }) =>
        theme.colours.midDarkOrange} !important;
      border-left-width: 12px !important;
      border-right-width: 12px !important;
      border-bottom-width: 12px !important;
      top: auto !important;
      bottom: 100% !important;
    }
  }
`;

const StyledParagraph = styled(Paragraph)`
  margin: 0;
`;

const tooltipPortal = document.getElementById('tooltip');

const Tooltip = props =>
  createPortal(
    <TooltipPopup
      id="tooltip"
      effect="solid"
      place="bottom"
      offset={{ top: -3 }}
      delayHide={500}
      getContent={content => <StyledParagraph>{content}</StyledParagraph>}
      {...props}
    />,
    tooltipPortal
  );

export default Tooltip;
