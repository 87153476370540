/**
 * THIS IS A VERSIONED FILE - see readme for more info
 */

import MatterProps from 'Common/Utils/MatterProps';
import { PARTY_A, PARTY_B } from 'Common/constants';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import DiviProps from 'Common/Data/App/diviProperties';
import {
  assetCategories,
  debtCategories,
  getValueModifier,
  getSoleAssetValue,
  getJointAssetValue,
  getPropertyValues,
  getVehicleValues,
  getSharesValue,
} from './VERSIONED_assetHelpers';

const AssetPropsFunc = () => {
  const divi = MatterProps('divi', {});

  const { TAP, agreedSplit, agreedToDivision } = DiviProps();

  const party = getPartyKeys();

  const {
    confirmedAssetTransfer: isAssetSplitCompleted = false,
    assetSplitRounds: rounds = [],
    owingParty: owingPartyAPI,
    owedParty: owedPartyAPI,
    assets = [],
  } = divi;

  const getByID = i => id => i.filter(item => item.SectionID === id);
  const getByType = i => type => i.filter(item => item.type === type);

  // calculates the value of all assets and debts for both parties
  // returns an object with individual values plus totals
  const getAssetData = ({
    onlyValues = false,
    parties = { owing: PARTY_A, owed: PARTY_B },
    isOwingParty = false,
  } = {}) => {
    const data = {
      assets: [
        ...getPropertyValues({
          onlyValues,
          parties,
          isOwingParty,
          isTransferrable: true,
        }),
        ...getVehicleValues({
          onlyValues,
          parties,
          isOwingParty,
          isTransferrable: true,
        }),
        ...getSoleAssetValue(
          {
            onlyValues,
            parties,
            isOwingParty,
            isTransferrable: true,
          },
          'Savings',
          'savings',
          'yourfinances',
          'personalSavings'
        ),
        ...getSoleAssetValue(
          {
            onlyValues,
            parties,
            isOwingParty,
            isTransferrable: false,
            combineValues: true,
          },
          'Cash',
          'cash',
          'yourfinances',
          'cash'
        ),
        ...getSharesValue({
          onlyValues,
          parties,
          isOwingParty,
          isTransferrable: true,
        }),
        ...getSoleAssetValue(
          {
            onlyValues,
            parties,
            isOwingParty,
            isTransferrable: false,
          },
          'Other assets',
          'otherAssets',
          'yourfinances',
          'otherAssets'
        ),
        ...getSoleAssetValue(
          {
            onlyValues,
            parties,
            isOwingParty,
            isTransferrable: false,
            combineValues: true,
          },
          'Household items',
          'householdItems',
          'yourfinances',
          'householdItemsValue'
        ),
        ...getSoleAssetValue(
          {
            onlyValues,
            parties,
            isOwingParty,
            isTransferrable: false,
            combineValues: true,
            additionalCheck: ['superannuationYesNo', true],
          },
          'Superannuation',
          'superannuation',
          'yoursuperannuation',
          'superannuationValue'
        ),
        ...getJointAssetValue(
          {
            onlyValues,
            parties,
            isOwingParty,
            isTransferrable: true,
          },
          'Shared savings',
          'sharedSavings',
          'sharedfinances',
          'sharedSavings'
        ),
        ...getJointAssetValue(
          {
            onlyValues,
            parties,
            isOwingParty,
            isTransferrable: true,
            canSplit: true,
          },
          'Other shared assets',
          'sharedAssets',
          'sharedfinances',
          'sharedAssets'
        ),
      ],
      debts: [
        ...getSoleAssetValue(
          {
            onlyValues,
            parties,
            isOwingParty,
            isTransferrable: false,
            isLiability: true,
          },
          'Credit cards',
          'creditCards',
          'yourfinances',
          'creditCards'
        ),
        ...getSoleAssetValue(
          {
            onlyValues,
            parties,
            isOwingParty,
            isTransferrable: false,
            isLiability: true,
          },
          'Other debts',
          'otherDebts',
          'yourfinances',
          'otherDebts'
        ),
        ...getJointAssetValue(
          {
            onlyValues,
            parties,
            isOwingParty,
            isTransferrable: true,
            isLiability: true,
            canSplit: true,
          },
          'Shared debts',
          'sharedDebts',
          'sharedfinances',
          'sharedDebts'
        ),
      ],
    };

    data.assets.getByID = getByID(data.assets);
    data.assets.getByType = getByType(data.assets);

    data.debts.getByID = getByID(data.debts);
    data.debts.getByType = getByType(data.debts);

    const getGroupValue = group =>
      group.reduce(
        (acc, item) => ({
          [parties.owing]: acc[parties.owing] + item[parties.owing],
          [parties.owed]: acc[parties.owed] + item[parties.owed],
        }),
        { [parties.owing]: 0, [parties.owed]: 0 }
      );

    // adds up the value of all of the assets and debts for both parties
    data.totalAssets = getGroupValue(data.assets);
    data.totalDebts = getGroupValue(data.debts);

    data.total = {
      [parties.owing]:
        data.totalAssets[parties.owing] - data.totalDebts[parties.owing],
      [parties.owed]:
        data.totalAssets[parties.owed] - data.totalDebts[parties.owed],
    };

    return data;
  };

  // get just the values of the asset pool so we can work out who is the owing and owed parties
  const assetPoolValues = getAssetData({
    onlyValues: true,
    parties: { owing: party.self, owed: party.other },
  });

  const selfAssetSplit = +parseFloat(assetPoolValues.total[party.self] / TAP);
  const selfAssetSplitValue = TAP * selfAssetSplit;
  const selfStartAmountToTransfer = selfAssetSplitValue - TAP * agreedSplit;

  let owingParty;
  let owedParty;

  if (selfStartAmountToTransfer > 0) {
    owingParty = party.self;
    owedParty = party.other;
  } else if (selfStartAmountToTransfer === 0) {
    owingParty = PARTY_B;
    owedParty = PARTY_A;
  } else {
    owingParty = party.other;
    owedParty = party.self;
  }

  if (
    (owingPartyAPI && owingParty !== owingPartyAPI) ||
    (owedPartyAPI && owedParty !== owedPartyAPI)
  ) {
    throw new Error('Local and API owing/owed parties dont match');
  }

  const isOwingParty = owingParty === party.self;
  const isOwedParty = owedParty === party.self;

  // now load the entire asset pool data, based on the owing party's perspective
  const data = getAssetData({
    parties: { owing: owingParty, owed: owedParty },
    isOwingParty,
  });

  let selectedAssets;

  if (isAssetSplitCompleted) {
    selectedAssets = assets;
  } else {
    selectedAssets = rounds.length > 0 ? rounds[rounds.length - 1].assets : [];
  }

  // remove shortfall if asset split isn't completed - it's only relevant for the API
  const shortfallData = selectedAssets.find(asset => asset.id === 'shortfall');
  selectedAssets = isAssetSplitCompleted
    ? selectedAssets
    : selectedAssets.filter(asset => asset.id !== 'shortfall');

  const owingAgreedSplit =
    owingParty === party.self ? agreedSplit : 1 - agreedSplit;

  const assetSplit = +parseFloat(assetPoolValues.total[owingParty] / TAP);
  const assetSplitValue = TAP * assetSplit;

  const startAmountToTransfer = assetSplitValue - TAP * owingAgreedSplit;
  const absoluteStartAmountToTransfer = Math.abs(startAmountToTransfer);
  const startPercentageToTransfer = Math.abs(
    +parseFloat(assetSplit - owingAgreedSplit)
  );
  const valueOfTransferringAssets = selectedAssets.reduce((acc, item) => {
    let isJointlyOwnedRealProperty = false;
    const asset = [...data.assets, ...data.debts].find(i => i.id === item.id);

    if (asset && asset.type === 'property' && asset.isJointlyOwned) {
      isJointlyOwnedRealProperty = true;
    }

    return (
      acc +
      item.value *
        getValueModifier(
          isOwingParty,
          item.isLiability,
          item.option,
          isJointlyOwnedRealProperty
        )
    );
  }, 0);
  const remainingAmountToTransfer =
    assetSplitValue - valueOfTransferringAssets - TAP * owingAgreedSplit;
  const absoluteRemainingAmountToTransfer = Math.abs(remainingAmountToTransfer);
  const remainingPercentageToTransfer = +parseFloat(
    remainingAmountToTransfer / TAP
  );
  const absoluteRemainingPercentageToTransfer = Math.abs(
    remainingPercentageToTransfer
  );
  const isTAPNegative =
    assetPoolValues.total[party.self] < 0 ||
    assetPoolValues.total[party.other] < 0;

  let shortfallAmount;
  let shortfallPayParty;

  if (isAssetSplitCompleted) {
    shortfallAmount = shortfallData.value;
    shortfallPayParty = shortfallAmount > 0 ? owingParty : owedParty;
  } else {
    shortfallAmount = remainingAmountToTransfer;
    shortfallPayParty = remainingAmountToTransfer > 0 ? owingParty : owedParty;
  }

  const lastOffer = rounds.length > 0 ? rounds[rounds.length - 1] : undefined;

  const hasAnyOfferBeenStarted = rounds.length > 0;
  const hasAnyOfferBeenMade = rounds.filter(round => !!round.sent).length > 0;
  const offers = rounds;
  const numberOfOffers = rounds.length;
  const lastSentOfferBy = hasAnyOfferBeenMade
    ? [...rounds].reverse().find(round => round.sent).owner
    : undefined;
  const hasMadeOffer = {
    [PARTY_A]: rounds.some(round => round.sent && round.owner === PARTY_A),
    [PARTY_B]: rounds.some(round => round.sent && round.owner === PARTY_B),
  };

  const getTransferrableAssets = offer => {
    let transferrableAssets = [
      ...data.assets
        .filter(asset => asset.isTransferrable)
        .map(asset => {
          // the ternary is to make sure we get a value for assets owned by the other party
          const returnData = {
            ...asset,
            value: asset[owingParty] > 0 ? asset[owingParty] : asset[owedParty],
          };

          if (asset.type === 'property') {
            returnData.mortgageValue = asset.onlyMortgage[owingParty]
              ? asset.onlyMortgage[owingParty]
              : asset.onlyMortgage[owedParty];
          }

          return returnData;
        }),
    ];

    transferrableAssets = transferrableAssets.map(asset => {
      const selected = offer
        ? offer.assets.find(item => item.id === asset.id)
        : selectedAssets.find(item => item.id === asset.id);

      return {
        ...asset,
        isSelected: !!selected,
        selectedOption: selected ? selected.option : null,
      };
    });

    return transferrableAssets;
  };

  const getTransferrableDebts = offer => {
    let transferrableDebts = [
      ...data.debts
        .filter(debt => debt.isTransferrable && debt[owingParty] > 0)
        .map(debt => ({ ...debt, value: debt[owingParty] })),
    ];

    transferrableDebts = transferrableDebts.map(debt => {
      const selected = offer
        ? offer.assets.find(item => item.id === debt.id)
        : selectedAssets.find(item => item.id === debt.id);

      return {
        ...debt,
        isSelected: !!selected,
        selectedOption: selected ? selected.option : null,
      };
    });

    return transferrableDebts;
  };

  const getSelectedItems = offer => {
    if (offer) {
      return offer.assets;
    }

    if (lastOffer) {
      return lastOffer.assets;
    }

    return [];
  };

  const transferrableAssets = getTransferrableAssets(lastOffer);
  const transferrableDebts = getTransferrableDebts(lastOffer);

  const haveAllJointAssetsBeenAssigned = [
    ...transferrableAssets,
    ...transferrableDebts,
  ]
    .filter(item => item.isJointlyOwned)
    .every(item => selectedAssets.map(i => i.id).includes(item.id));

  const haveAllJointAssetsExceptPropertiesBeenAssigned = [
    ...transferrableAssets,
    ...transferrableDebts,
  ]
    .filter(item => item.isJointlyOwned && item.type !== 'property')
    .every(item => selectedAssets.map(i => i.id).includes(item.id));

  let isOfferInProgress = false;
  let canStartOffer = false;
  let canWorkOnOffer = false;
  let canStartOrWorkOnOffer = false;
  let canSubmitOffer = false;

  // make sure these variables can only ever be true if the user has completed suggested division
  if (agreedToDivision) {
    isOfferInProgress = lastOffer && !lastOffer.sent;

    canStartOffer =
      !isAssetSplitCompleted &&
      (!hasAnyOfferBeenStarted ||
        (!isOfferInProgress && lastOffer?.owner === party.other));

    canWorkOnOffer =
      !isAssetSplitCompleted &&
      isOfferInProgress &&
      lastOffer.owner === party.self;

    canStartOrWorkOnOffer = canStartOffer || canWorkOnOffer;

    canSubmitOffer =
      canWorkOnOffer ||
      (!isOfferInProgress && canStartOffer && haveAllJointAssetsBeenAssigned);
  }

  const otherPartyIsMakingAnOffer = isOfferInProgress && !canWorkOnOffer;
  const isExactlyOneOfferAndMadeByOtherParty =
    rounds.length === 1 &&
    !isOfferInProgress &&
    lastSentOfferBy === party.other;
  const hasOtherPartyMadeCounterOffer =
    rounds.length > 1 && !isOfferInProgress && lastSentOfferBy === party.other;
  const isWaitingForOtherPartyToViewOffer =
    hasAnyOfferBeenMade && lastSentOfferBy === party.self && !isOfferInProgress;
  const lastCompletedOffer = hasAnyOfferBeenMade
    ? [...rounds].reverse().find(round => round.sent)
    : undefined;
  const shouldShowViewProposalDialog =
    !isAssetSplitCompleted &&
    lastSentOfferBy === party.other &&
    !isOfferInProgress;

  const hasOwedPartyViewedIntroCarousel = MatterProps(
    `party${owedParty}.hasSeenAssetTransferIntro`,
    false
  );

  // console.log('Owing agreed split', owingAgreedSplit);
  // console.log('Asset split', assetSplit);
  // console.log('Asset split value', assetSplitValue);
  // console.log('Start $ to transfer', startAmountToTransfer);
  // console.log('ABS start $ to transfer', absoluteStartAmountToTransfer);
  // console.log('Remaining $ to transfer', remainingAmountToTransfer);
  // console.log('TAP', TAP);
  // console.log('Remaining % to transfer', remainingPercentageToTransfer);
  // console.log('Owing party', owingParty);
  // console.log('Owed party', owedParty);

  return {
    getAssetData,
    assetSplit, // current split of assets (0-1) - always returns the split for the *owing* party
    selfAssetSplit, // current split of assets (0-1) - always returns the split for the current party
    // assetSplitValue, // value of the current party's assets
    startAmountToTransfer, // the amount the current party needs to transfer to equal the agreed split
    absoluteStartAmountToTransfer, // the absolute value of the amount the current party needs to transfer to equal the agreed split
    startPercentageToTransfer, // percentage (0-1) that the current party needs to transfer
    remainingAmountToTransfer, // the amount that the current party still needs to transfer
    absoluteRemainingAmountToTransfer, // the absolute value of the amount that the current party still needs to transfer
    remainingPercentageToTransfer, // percentage (0-1) that the current party still needs to transfer
    absoluteRemainingPercentageToTransfer, // the absolute value of the percentage (0-1) that the current party still needs to transfer
    TAP,
    owingParty, // the party (A/B) who owes assets
    owedParty, // the party (A/B) who is owed assets (and does not take part in the asset split section)
    isOwingParty, // bool - if the current party is the owing party
    isOwedParty, // bool - if the current party is the owed party
    isAssetSplitCompleted, // has asset split been fully completed and submitted by the owing party
    transferrableAssets, // an array of all transferrable assets
    transferrableDebts, // an array of all transferrable debts
    getTransferrableAssets, // func - load transferrable assets from a specific offer
    getTransferrableDebts, // func - load transferrable debts from a specific offer
    haveAllJointAssetsBeenAssigned, // bool - have all joint assets been dealt with (i.e. can the user submit their asset transfer)
    haveAllJointAssetsExceptPropertiesBeenAssigned, // bool - have all joint assets *except properties* been dealt with
    assetCategories, // array - list of all asset categories
    debtCategories, // array - list of all debt categories
    shortfallAmount,
    shortfallPayParty,
    hasOwedPartyViewedIntroCarousel,
    isTAPNegative,
    offers, // array of offers (both in progress and completed)
    lastOffer,
    isOfferInProgress, // bool - is the latest offer currently in progress or has it been sent
    lastSentOfferBy, // the party who made the last completed offer
    hasAnyOfferBeenStarted, // bool - has any offer been started (by either party)
    hasAnyOfferBeenMade, // bool - has any offer been made (and sent) yet (by either party)
    hasMadeOffer, // an object that indicates whether each party has made an offer yet
    canWorkOnOffer, // bool - can current party start an offer, or are they waiting on the other party
    canStartOrWorkOnOffer,
    canStartOffer,
    canSubmitOffer,
    otherPartyIsMakingAnOffer,
    isExactlyOneOfferAndMadeByOtherParty,
    hasOtherPartyMadeCounterOffer,
    isWaitingForOtherPartyToViewOffer,
    numberOfOffers,
    lastCompletedOffer,
    shouldShowViewProposalDialog, // bool - should we show the view proposal dialog
    getSelectedItems, // func - get a list of raw selected items from the database
    agreedAssetsToSplit: isAssetSplitCompleted ? selectedAssets : [], // array - list of selected assets that both parties have agreed to split

    /** Super spltting flags - not used in this version */
    superSplittingFlags: {},
  };
};

// used to memoize the output
const lastRefreshed = {};
const output = {};

const AssetProps = () => {
  const matterID = MatterProps('MatterID');
  const matterLastRefreshed = MatterProps('lastRefreshed');
  if (!output[matterID] || lastRefreshed[matterID] !== matterLastRefreshed) {
    lastRefreshed[matterID] = matterLastRefreshed;
    output[matterID] = AssetPropsFunc();
  }

  return output[matterID];
};

export default AssetProps;
