/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';

import theme, { orangeTheme, pastelPurpleTheme } from 'Common/Utils/theme';
import { ODRS_DIVISION_INTRO } from 'Common/routes';
import { addEmptyItemAction } from 'App/State/MatterActions';
import push from 'Common/Utils/push';
import OptionsDrawer from 'App/UI/OptionsDrawer/OptionsDrawer';
import { getAppSections, getSection } from 'Common/Data/SingleUser/appSections';
import {
  CONTENT_HIDE,
  DashboardPages,
  Sections,
  SHOW_AMICA_DEMO_BANNER_DISMISSED,
} from 'Common/constants';

import AppFooter from 'App/UI/AppFooter/AppFooter';
import MainDrawer from 'Common/UI/Layout/MainDrawer';
import Tooltip from 'Common/UI/Tooltip/Tooltip';
import { ThemeProvider } from 'styled-components';
import { generateAppStateAction } from 'App/State/AppActions';
import {
  DashboardProvider,
  DashboardConsumer,
} from 'Common/UI/DashboardContext';
import { TopMessageDrawer } from 'Common/UI/Layout/Drawer';
import Loader from 'Common/UI/Loader/Loader';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';
import { useAppDispatch, useAppSelector } from 'App/State/Store';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import PageContainer from 'Common/UI/Layout/PageContainer';
import ScrollerContainer from 'Common/UI/Layout/ScrollerContainer';
import { DrawerContent, HeaderContainer } from 'Common/UI/Dashboard/common';
import { sessionStorageLoad } from 'Common/Utils/sessionStorage';
import { DashboardPageProps } from 'Common/Data/Types/types';
import { DataGroup } from 'Common/Data/Types/appSections';
import QuickExitLink from '../QuickExitLink';
import NextPageButton from './common/NextPageButton';
import generateDashboardTabs from './common/generateDashboardTabs';
import DemoBanner from './common/DemoBanner';
import AppHeader from '../AppHeader';
import { hasSectionBeenCompleted } from '../../Utils/sectionHelpers';
import UsingDemoBanner from '../Banners/UsingDemoBanner';
import SuggestedDivisionReadyBanner from '../Banners/SuggestedDivisionReadyBanner';
import areAllDashboardsComplete from './common/areAllDashboardsComplete';

type DashboardProps = {
  section: Sections;
  tab: DashboardPages;
  pages: { [key: string]: React.FC<DashboardPageProps> };
  topContent?: JSX.Element;
  nextPage?: string;
};

const Dashboard: React.FC<DashboardProps> = ({
  tab,
  section,
  pages,
  topContent,
  nextPage,
}) => {
  const matter = useAppSelector(state => state.matter);
  const { appState: newAppState } = useAppSelector(state => state.app);
  const dispatch = useAppDispatch();

  const navigate = (to: string, state?: any) => dispatch(push(to, state));
  const addEmptyItem = (itemType: string) =>
    dispatch(addEmptyItemAction(itemType));

  const [isLoading, setIsLoading] = useState(false);
  const [showBanner, setShowBanner] = useState(true);

  const onDismissBanner = () => {
    setShowBanner(false);
  };

  // There are still some places that need to reload the appstate on changes,
  // eg banner dismissing saving to session storage
  const updateAppState = () => {
    dispatch(generateAppStateAction({ matter, section, page: tab }));
  };

  // We load the app state whenever the matter gets updated
  useEffect(() => {
    dispatch(generateAppStateAction({ matter, section, page: tab }));
  }, [matter, tab]);

  const sections = getAppSections();

  const sectionData = getSection(section);

  // There are a couple of race conditions in redux-persist that means
  // some of these aren't initialised yet.
  if (!sectionData || !newAppState) {
    return null;
  }

  const { appState, contentState } = newAppState;

  // if we're not in production, include the app state on the dashboard for cypress tests to check
  // eslint-disable-next-line
  let DevAppState = null;

  /// #if STAGE !== 'production'
  DevAppState = <span data-cy="appState" data-cy-appstate={appState} />;
  /// #endif

  const handleAddItemById = async (group: DataGroup) => {
    setIsLoading(true);

    const { id } = group;

    let incrementID;

    if ('isIndividual' in group && group.isIndividual) {
      const { self } = getPartyKeys();

      incrementID = `${id}${self}`;
    } else {
      incrementID = id;
    }

    await addEmptyItem(incrementID);
    await updateAppState();
    return setIsLoading(false);
  };

  const DashboardPageContent: React.FC = () => {
    if (contentState === CONTENT_HIDE) {
      return null;
    }

    const PageComponent = pages[tab];

    if (!PageComponent) {
      return null;
    }

    return (
      <DrawerContent>
        <PageComponent
          matter={matter}
          navigate={navigate}
          appState={appState}
          handleAddItemById={handleAddItemById}
        />
      </DrawerContent>
    );
  };

  // handles closing the banner the first time, as well previously closed
  const showDemoBanner =
    !sessionStorageLoad(SHOW_AMICA_DEMO_BANNER_DISMISSED) && showBanner;

  const tabs = generateDashboardTabs({ sections, matter });
  const isVisibleSuggestedDivisionReadyBanner = areAllDashboardsComplete(tabs);

  return (
    <ThemeProvider theme={sectionData.theme ? sectionData.theme : theme}>
      <DashboardProvider
        appState={appState}
        updateAppState={updateAppState}
        section={section}
        tab={tab}
      >
        <DashboardConsumer>
          {({
            openOptionsDrawer,
            optionsForCard,
            optionsDrawerItems,
            closeOptionsDrawer,
          }) => (
            <PageContainer id="scrollMemory">
              <QuickExitLink />
              <HeaderContainer className="transition-fade-up">
                <AppHeader
                  withNav
                  userName={matter?.items[0]?.name || 'guest'}
                  theme={sectionData.appHeaderTheme}
                  tabs={generateDashboardTabs({ sections, matter })}
                />
              </HeaderContainer>
              {showDemoBanner && (
                <TopMessageDrawer hasFlex open zindex="1" theme={orangeTheme}>
                  <DemoBanner
                    showBanner={showBanner}
                    onBannerButtonClick={onDismissBanner}
                  >
                    <UsingDemoBanner />
                  </DemoBanner>
                </TopMessageDrawer>
              )}
              {isVisibleSuggestedDivisionReadyBanner && (
                <TopMessageDrawer
                  hasFlex
                  open
                  zindex="1"
                  theme={pastelPurpleTheme}
                >
                  <DemoBanner
                    showBanner={isVisibleSuggestedDivisionReadyBanner}
                    onBannerButtonClick={() =>
                      dispatch(push(ODRS_DIVISION_INTRO))
                    }
                    buttonText="View my suggested division"
                    dataCy="suggested-division-banner"
                  >
                    <SuggestedDivisionReadyBanner />
                  </DemoBanner>
                </TopMessageDrawer>
              )}
              <MainDrawer>
                <ScrollerContainer>
                  {topContent}

                  <ResponsiveContainer>
                    <DashboardPageContent />
                  </ResponsiveContainer>

                  <ResponsiveContainer>
                    <NextPageButton
                      route={nextPage}
                      enabled={hasSectionBeenCompleted({ section })}
                    />
                  </ResponsiveContainer>
                </ScrollerContainer>
              </MainDrawer>
              <OptionsDrawer
                isOpen={openOptionsDrawer}
                card={optionsForCard}
                optionItems={optionsDrawerItems}
                handleClose={closeOptionsDrawer}
              />
              <AppFooter />
              {isLoading && <Loader floating />}
            </PageContainer>
          )}
        </DashboardConsumer>
      </DashboardProvider>
      <Tooltip />
      {DevAppState}
    </ThemeProvider>
  );
};

export default Dashboard;
