import { createSlice } from '@reduxjs/toolkit';
import theme from 'Common/Utils/theme';
import { ReactNode } from 'react';
import { DefaultTheme } from 'styled-components';

export const ODIE_STATE_HIDDEN = 'ODIE_HIDDEN';
export const ODIE_STATE_VISIBLE_CLOSED = 'ODIE_VISIBLE_CLOSED';
export const ODIE_STATE_OPEN = 'ODIE_OPEN';

export type OdieState = {
  state: string;
  message: ReactNode;
  theme: DefaultTheme;
  hideFooter: boolean;
  onClose?: (() => void) | null;
  up: boolean;
  noPadding: boolean;
  popupButtonText: string;
  prompt?: string;
};

const initialState: OdieState = {
  state: ODIE_STATE_HIDDEN,
  message: null,
  theme,
  hideFooter: false,
  onClose: null,
  up: false,
  noPadding: false,
  popupButtonText: 'Got it',
};

const odieSlice = createSlice({
  name: 'odie',
  initialState,
  reducers: {
    // case UPDATE_ODIE_ACTION:
    updateOdie(state, action) {
      return {
        ...state,
        message: action.payload.message,
        prompt: action.payload.prompt,
        ...(action.payload.theme && { theme: action.payload.theme }),
        hideFooter: action.payload.hideFooter,
        onClose: action.payload.onClose,
        popupButtonText: action.payload.popupButtonText,
        state:
          action.payload === null ? ODIE_STATE_VISIBLE_CLOSED : state.state,
        up: action.payload.up,
        noPadding: action.payload.noPadding || initialState.noPadding,
      };
    },
    // case HIDE_ODIE_ACTION:
    hideOdie(state) {
      return { ...state, state: ODIE_STATE_HIDDEN };
    },
    // case SHOW_ODIE_ACTION:
    // case CLOSE_ODIE_ACTION:
    showOdie(state) {
      return { ...state, state: ODIE_STATE_VISIBLE_CLOSED };
    },
    closeOdie(state) {
      return { ...state, state: ODIE_STATE_VISIBLE_CLOSED };
    },
    // case OPEN_ODIE_ACTION:
    openOdie(state) {
      return { ...state, state: ODIE_STATE_OPEN };
    },
    // case 'CLEAR_STORE':
    clearOdieStore() {
      return initialState;
    },
  },
});

export const {
  updateOdie,
  hideOdie,
  showOdie,
  closeOdie,
  openOdie,
  clearOdieStore,
} = odieSlice.actions;

export default odieSlice.reducer;
