import styled from 'styled-components';

export const OList = styled.ol`
  font-weight: normal;

  li {
    font-size: 14px;
    line-height: 1.44;
    margin: 8px 0;

    @media ${({ theme }) => theme.sizes.md.breakpoint} {
      font-size: 16px;
      margin: 12px 0;
    }

    @media ${({ theme }) => theme.sizes.xl.breakpoint} {
      font-size: 18px;
    }
  }
`;

export const UList = styled(OList).attrs({
  as: 'ul',
})`
  font-weight: normal;

  li {
    font-size: 14px;
    line-height: 1.44;
    margin: 8px 0;

    @media ${({ theme }) => theme.sizes.md.breakpoint} {
      font-size: 16px;
      margin: 16px 0;
    }

    @media ${({ theme }) => theme.sizes.xl.breakpoint} {
      font-size: 18px;
    }
  }
`;
