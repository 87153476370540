import {
  STATUS_NEW_BOTH_PARTIES,
  STATUS_NEW_ONLY_SELF,
  STATUS_NEW_ONLY_THEM,
  STATUS_REMOVED,
} from 'Common/constants';
import Matter from 'Common/Data/Types/matter';
import Card from 'App/UI/Card';
import React from 'react';
import styled from 'styled-components';
import { QuestionStateCard, QuestionCard } from 'Common/Data/Types/appSections';

const CalendarWrapper = styled.div`
  border-radius: 4px;
`;

const CardContent: React.FC<{
  noBox?: boolean;
  data: QuestionStateCard<QuestionCard>;
  isActive?: boolean;
}> = ({ noBox, data, isActive = false }) => {
  const { summaryComponent: SummaryComponent } = data;

  if (SummaryComponent) {
    return (
      <div style={{ flexGrow: 1 }}>
        <CalendarWrapper>
          <SummaryComponent isActive={isActive} />
        </CalendarWrapper>
      </div>
    );
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Card.Statement noBox={noBox} />
    </div>
  );
};

const ShowMore: React.FC<{ data: QuestionStateCard<QuestionCard> }> = ({
  data,
}) => {
  if ('showMoreText' in data && 'popUpContent' in data) {
    const { showMoreText, popUpContent } = data;

    return (
      <Card.LearnMoreButton
        spaced={{ marginTop: 'medium', marginBottom: 'medium' }}
        popUpContent={popUpContent}
      >
        {showMoreText}
      </Card.LearnMoreButton>
    );
  }

  return null;
};

type NormalCardProps = {
  data: QuestionStateCard<QuestionCard>;
  isActive?: boolean;
  themeName?: string;
  cardHeadingText?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  matter: Matter;
};

export const NormalCard: React.FC<NormalCardProps> = ({
  data,
  isActive,
  themeName,
  cardHeadingText,
}) => {
  const {
    navToQuestions,
    navToView,
    status,
    cardForParty,
    notEditable,
    isRepeatable,
  } = data;

  switch (status) {
    case STATUS_NEW_ONLY_SELF:
    case STATUS_NEW_BOTH_PARTIES:
    case STATUS_NEW_ONLY_THEM:
      return (
        <Card data={data} theme={themeName} active={isActive}>
          <Card.MainHeading
            cardForParty={isRepeatable ? undefined : cardForParty}
            enableMoreButton
          >
            {cardHeadingText}
          </Card.MainHeading>
          <Card.Paragraph />
          <ShowMore data={data} />
          <Card.Button onClick={navToQuestions}>Get Started</Card.Button>
        </Card>
      );

    case STATUS_REMOVED:
      return (
        <Card data={data} cardTheme="removed">
          <Card.MainHeading>{cardHeadingText}</Card.MainHeading>
          <Card.Paragraph />
          <ShowMore data={data} />
          <CardContent data={data} isActive={isActive} />
        </Card>
      );
    default:
      return (
        <Card cardTheme="self" data={data} active={isActive}>
          <Card.MainHeading
            enableMoreButton
            cardForParty={isRepeatable ? undefined : cardForParty}
            spaced={{ marginTop: 'xxsmall', marginBottom: 'medium' }}
          >
            {cardHeadingText}
          </Card.MainHeading>
          <div style={{ flexGrow: 1 }}>
            <CardContent data={data} isActive={isActive} />
          </div>
          <Card.LatestMessage />
          <ShowMore data={data} />
          {!notEditable && (
            <Card.Button size="fullWidth" onClick={navToView}>
              Edit
            </Card.Button>
          )}
        </Card>
      );
  }
};
