import React from 'react';
import { useAppDispatch } from 'App/State/Store';
import { setIsLoading } from 'App/State/AppReducer';

/**
 * Hook to help show a loading screen while a promise is running
 *
 * Wrap your promise with the `withLoading` function that is returned, and render the `Loader` somewhere to make it work
 */
const usePromiseWithLoading = () => {
  const dispatch = useAppDispatch();

  // helper wrapper to automatically handle toggling the loader
  const withLoading = (promise: any) => {
    dispatch(setIsLoading(true));
    return promise().finally((result: any) => {
      dispatch(setIsLoading(false));
      return result;
    });
  };

  return {
    withLoading,
  };
};

export default usePromiseWithLoading;
