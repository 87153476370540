import React from 'react';
import styled from 'styled-components';

import Paragraph from 'Common/UI/Text/Paragraph';
import { H2Conversational, Heading3 } from 'Common/UI/Text/Headings';
import theme from 'Common/Utils/theme';
import {
  SlideIcon,
  SlideInner,
  SlideText,
} from 'Common/UI/Carousel/ImageSlideWrapper';

import WhatDoesSeparatedCoupleMeanHelpButton from 'Common/UI/Signup/WhatDoesSeparatedCoupleMeanHelp';
import Carousel01 from './step-1-image.svg';
import Carousel02 from './step-2-image.svg';

const StyledNumber = styled(Heading3)`
  margin-bottom: ${theme.padding.xsmall}px;
  font-weight: 400;

  @media (min-width: 400px) {
    margin-bottom: ${theme.padding.small}px;
  }
`;

const StyledH2Conversational = styled(H2Conversational)`
  margin-bottom: ${theme.padding.xsmall}px;
  @media (min-width: 400px) {
    margin-bottom: ${theme.padding.xsmall}px;
  }
`;

const StyledParagraph = styled(Paragraph)`
  margin: 0 0 ${theme.padding.medium}px 0;
`;

export const WelcomeSlide01 = () => (
  <SlideInner>
    <SlideIcon className="slide-icon">
      <img alt="" src={Carousel01} srcSet={`${Carousel01} 2x`} />
    </SlideIcon>
    <SlideText data-cy="welcome-slide-1">
      <StyledNumber>Step 1</StyledNumber>
      <StyledH2Conversational>
        Here, you can get an idea of how amica works before inviting your former
        partner.
      </StyledH2Conversational>
      <StyledParagraph>
        Whether you want to see how amica can help you, or you’re just curious –
        amica one will show you how amica works without having to invite your
        former partner.
      </StyledParagraph>
      <StyledParagraph>
        amica is an online dispute resolution tool designed to help separating
        couples to agree on financial arrangements
      </StyledParagraph>
      <StyledParagraph>
        <WhatDoesSeparatedCoupleMeanHelpButton />
      </StyledParagraph>
    </SlideText>
  </SlideInner>
);

export const WelcomeSlide02: React.FC = () => (
  <SlideInner>
    <SlideIcon className="slide-icon">
      <img alt="" src={Carousel02} srcSet={`${Carousel02} 2x`} />
    </SlideIcon>
    <SlideText>
      <StyledNumber>Step 2</StyledNumber>
      <StyledH2Conversational>
        See what it’s like to use amica, with amica one.
      </StyledH2Conversational>
      <StyledParagraph>
        You&apos;ll answer questions about your relationship and your money and
        property.
      </StyledParagraph>
      <StyledParagraph>
        Once you’ve answered everything, we’ll provide you with a suggested
        division of your total asset pool.
      </StyledParagraph>
      <StyledParagraph>
        The suggested division in amica one is an estimate only. If you then
        choose to create an account and invite your former partner, you’ll get a
        more accurate suggestion that you can use to work out a division that’s
        right for you.
      </StyledParagraph>
      <br />
      <br />
    </SlideText>
  </SlideInner>
);
