import styled from 'styled-components';

const ScrollerContainer = styled.div`
  // padding: 24px 24px 0;
  position: relative;
  background-color: ${props =>
    props.backgroundColour ? props.backgroundColour : 'transparent'};
`;

export default ScrollerContainer;
