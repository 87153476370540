import { relationshipDashboardCompletedFlags } from 'Common/UI/Banners/RelationshipDashboardCompleted';
import { SECTION_RELATIONSHIP } from 'Common/constants';
import { SectionState } from 'Common/Data/Types/appState';

const data: SectionState = {
  [SECTION_RELATIONSHIP]: {
    pageBlock: [],
    state: [relationshipDashboardCompletedFlags],
  },
};

export default data;
