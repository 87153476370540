import React, { createContext, useState } from 'react';

type Context = {
  step: number | undefined;
  totalSteps: number | undefined;
  updateStep: (step: number) => void;
  updateTotalSteps: (step: number) => void;
};

export const ProgressBarContext = createContext<Context>({
  step: undefined,
  totalSteps: undefined,
  updateStep: () => {},
  updateTotalSteps: () => {},
});

export const ProgressBarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [step, updateStep] = useState<number | undefined>(0);
  const [totalSteps, updateTotalSteps] = useState(0);

  return (
    <ProgressBarContext.Provider
      value={{
        step,
        updateStep,
        totalSteps,
        updateTotalSteps,
      }}
    >
      {children}
    </ProgressBarContext.Provider>
  );
};
