import React from 'react';
import styled from 'styled-components';

import { HighlightedLabel } from 'Common/UI/Text/Label';
import IconPlus from 'Common/Assets/images/icon-plus.svg';
import RoundButton from './RoundButton';

const StyledRoundButton = styled(RoundButton)`
  min-width: 0;
  padding: 8px 16px;
  margin-top: 8px;
  background-image: url('${IconPlus}');
  background-size: 12px 12px;
  background-position: 75% center;
  background-repeat: no-repeat;
  padding-right: ${props => props.theme.padding.medium2}px;

  p {
    font-weight: 500;
  }
`;

const AddButton = props => (
  <StyledRoundButton {...props} subtle small>
    <HighlightedLabel>Add</HighlightedLabel>
  </StyledRoundButton>
);

export default AddButton;
