import React from 'react';

import { SECTION_FINANCES } from 'Common/constants';
import { ODRS_DASH_FINANCES_ROUTE } from 'Common/routes';
import { darkerNoBackground } from 'Common/Utils/theme';
import DiviRoute from 'Common/UI/DiviRoute';
import MatterGuard from './Guard/MatterGuard';
import DashboardFinances from '../Pages/DashboardFinance';

const ODRSDashboardFinances = () => (
  <MatterGuard>
    <DiviRoute
      path={ODRS_DASH_FINANCES_ROUTE}
      component={DashboardFinances}
      theme={darkerNoBackground}
      tab={SECTION_FINANCES}
    />
  </MatterGuard>
);
export default ODRSDashboardFinances;
