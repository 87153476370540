import React from 'react';
import CardQuestions from 'App/UI/Questions/CardQuestionsFlow';
import MatterGuard from './Guard/MatterGuard';

const ODRSChildren = (props: any) => (
  <MatterGuard>
    <CardQuestions baseID="children" indexFromParams {...props} />
  </MatterGuard>
);

export default ODRSChildren;
