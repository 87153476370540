import * as Sentry from '@sentry/browser';
import moment from 'moment';
import { cloneDeep } from 'lodash';

import MatterPropsJS from 'Common/Utils/MatterProps';

import { Item } from 'Common/Data/Types/matter';
import { createAppAsyncThunk } from './Store';
import mockSetupChildItem from '../mockData/setupItemChild';
import mockSetupPropertyItem from '../mockData/setupItemProperty';
import setupVehicleItem from '../mockData/setupItemVehicle';
import {
  matterDecrementChild,
  matterDecrementProperty,
  matterDecrementVehicle,
  matterIncrementChild,
  matterIncrementProperty,
  matterIncrementVehicle,
  matterLoadMatterSuccess,
  matterLoadSuggestedDivision,
  matterRemoveItem,
} from './MatterReducer';

// can't use destructuring here because of a bug in instanbul
// eslint-disable-next-line prefer-destructuring
const API_ENDPOINT_BASE = process.env.API_ENDPOINT_BASE;

const handleErrors = (response: Response, endpoint = '') => {
  if (response.status >= 400) {
    return response.json().then(data => {
      Sentry.setContext('API Error Info', {
        endpoint,
        statusCode: response.status,
        message: data && data.message ? data.message : undefined,
      });

      throw data;
    });
  }

  return Promise.resolve(response);
};

export const loadMatterAction = createAppAsyncThunk(
  'matter/loadMatter',
  (_, { dispatch, getState }) => {
    const { matter } = getState();
    return dispatch(matterLoadMatterSuccess({ matter: cloneDeep(matter) }));
  }
);

export const createMatterAction = createAppAsyncThunk(
  'matter/createMatter',
  () => {
    throw new Error('createMatterAction is not implemented');
  }
);

export const updateMatterSectionAction = createAppAsyncThunk(
  'matter/updateMatterSection',
  async (
    {
      section,
      sectionData,
      messageData,
    }: { section: any; sectionData: any; messageData: any }, // @TODO type this
    { dispatch, getState }
  ) => {
    if (!messageData) {
      throw Error('Message is required');
    }

    if (!Array.isArray(section)) {
      throw Error('`section` must be an array, strings no longer work');
    }

    const { matter } = getState();

    const newMatter = cloneDeep(matter);

    const { items } = newMatter;

    const sectionID = Array.isArray(section)
      ? `${section[0]}${section[1] || ''}`
      : section;

    const findFn = (item: Item) => item.SectionID === sectionID;

    const existingItem = items.find(findFn);

    if (existingItem) {
      const itemData = {
        ...existingItem,
        ...sectionData,
        MatterID: MatterPropsJS('MatterID'),
        SectionID: sectionID,
        BaseSection: Array.isArray(section) ? section[0] : section,
        CardIndex: Array.isArray(section) ? section[1] : undefined,
        modified: moment.now(),
      };

      const existingItemIndex = items.findIndex(findFn);

      items[existingItemIndex] = itemData;
    } else {
      const itemData = {
        ...sectionData,
        MatterID: MatterPropsJS('MatterID'),
        SectionID: sectionID,
        BaseSection: Array.isArray(section) ? section[0] : section,
        CardIndex: Array.isArray(section) ? section[1] : undefined,
        modified: moment.now(),
        created: moment.now(),
      };

      newMatter.items.push(itemData);
    }

    const { owner, status } = messageData;

    const messageToSave = {
      MatterID: matter.MatterID,
      MessageID: `${sectionID}|${moment().toISOString()}`,
      owner,
      section: sectionID,
      status,
      date: moment().toISOString(),
      created: moment().toISOString(),
      modified: moment().toISOString(),
    };

    newMatter.messages.push(messageToSave);

    return dispatch(matterLoadMatterSuccess({ matter: newMatter }));
  }
);

export const reopenItemAction = createAppAsyncThunk(
  'matter/reopenItem',
  (_item: Item) => {
    throw new Error('reopenItemAction is not implemented');
  }
);

export const removeItemAction = createAppAsyncThunk(
  'matter/removeItem',
  (item: Item, { dispatch }) => dispatch(matterRemoveItem({ item }))
);

export const finalizeCaptureAction = createAppAsyncThunk(
  'matter/finalizeCapture',
  (_, { dispatch, getState }) => {
    const { matter } = getState();

    return fetch(`${API_ENDPOINT_BASE}/division/finalize`, {
      method: 'POST',
      body: JSON.stringify({
        matter,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => handleErrors(response, 'finalizeCapture'))
      .then(response => response.json())
      .then(suggestedDivision => {
        dispatch(matterLoadSuggestedDivision(suggestedDivision));
        return true;
      })
      .catch(() => false);
  }
);

export const setPartyFlagsAction = createAppAsyncThunk(
  'matter/setPartyFlags',
  (
    flags: any, // @TODO type this
    { dispatch, getState }
  ) => {
    const { matter } = getState();
    const updatedMatter = {
      ...matter,
      self: {
        ...matter.self,
        ...flags,
      },
    };
    return dispatch(matterLoadMatterSuccess({ matter: updatedMatter }));
  }
);

export const setMatterFlagsAction = createAppAsyncThunk(
  'matter/setMatterFlags',
  (
    flags: any, // @TODO type this
    { dispatch, getState }
  ) => {
    const { matter } = getState();
    const updatedMatter = {
      ...matter,
      flags: {
        ...matter.flags,
        ...flags,
      },
    };
    return dispatch(matterLoadMatterSuccess({ matter: updatedMatter }));
  }
);

export const resetAgreementStateAction = () => () => {
  throw new Error('resetAgreementStateAction is not implemented');
};

/**
 * Depending on if we've setup yet, this function sets up the 'setup' item
 * This is used to keep track of multi-cards like children, property
 * If a setup card exists, it will increment the count
 * TODO: Check if noChildrenFlag is true, then don't do anything!
 * @param {string} section
 * @param {string} key
 * @returns
 */
export const addEmptyChildrenAction = createAppAsyncThunk(
  'matter/addEmptyChildrenAction',
  (_, { dispatch, getState }) => {
    const { matter } = getState();
    const { items } = matter;
    const setupItem = items.find(item => item.SectionID === 'setup');
    if (setupItem) {
      return dispatch(matterIncrementChild());
    }

    const newMatterWithSetup = {
      ...matter,
      items: [...matter.items, mockSetupChildItem],
    };

    return dispatch(matterLoadMatterSuccess({ matter: newMatterWithSetup }));
  }
);

export const addVehicleAction = createAppAsyncThunk(
  'matter/addVehicle',
  (_, { dispatch, getState }) => {
    const { matter } = getState();
    const { items } = matter;
    const setupItem = items.find(item => item.SectionID === 'setup');

    // Setup item should exist at this point in the flow
    if (setupItem) {
      return dispatch(matterIncrementVehicle());
    }

    const newMatterWithSetup = {
      ...matter,
      items: [...matter.items, setupVehicleItem],
    };

    return dispatch(matterLoadMatterSuccess({ matter: newMatterWithSetup }));
  }
);

export const addPropertyAction = createAppAsyncThunk(
  'matter/addProperty',
  (_, { dispatch, getState }) => {
    const { matter } = getState();
    const { items } = matter;
    const setupItem = items.find(item => item.SectionID === 'setup');

    // Setup item should exist at this point in the flow
    if (setupItem) {
      return dispatch(matterIncrementProperty());
    }

    const newMatterWithSetup = {
      ...matter,
      items: [...matter.items, mockSetupPropertyItem],
    };

    return dispatch(matterLoadMatterSuccess({ matter: newMatterWithSetup }));
  }
);

export const addEmptyItemAction = createAppAsyncThunk(
  'matter/addEmptyItem',
  (section: string, { dispatch }) => {
    switch (section) {
      case 'children':
        dispatch(addEmptyChildrenAction());
        break;
      case 'properties':
        dispatch(addPropertyAction());
        break;
      case 'vehicles':
        dispatch(addVehicleAction());
        break;
      default:
        console.log('What is this section?', section);
        break;
    }
  }
);

export const removeEmptyItemAction = createAppAsyncThunk(
  'matter/removEmptyItem',
  (section: string, { dispatch }) => {
    switch (section) {
      case 'children':
        dispatch(matterDecrementChild());
        break;
      case 'properties':
        dispatch(matterDecrementProperty());
        break;
      case 'vehicles':
        dispatch(matterDecrementVehicle());
        break;
      default:
        console.log('What is this section?', section);
        break;
    }
  }
);

export const sendNegotiationAction = createAppAsyncThunk(
  'matter/sendNegotiation',
  () => {
    throw new Error('sendNegotiationAction is not implemented');
  }
);

export const getDocumentUploadURLAction = createAppAsyncThunk(
  'matter/getDocumentUploadURL',
  () => {
    throw new Error('getDocumentUploadURLAction is not implemented');
  }
);

export const recoverMatterPostSignupAction = createAppAsyncThunk(
  'matter/recoverMatterPostSignup',
  () => {
    throw new Error('recoverMatterPostSignupAction is not implemented');
  }
);

export const getDocumentViewURL = (_filename: string) => {
  throw new Error('getDocumentViewURL is not implemented');
};

export const getDocumentViewURLAction = (filename: string) => () =>
  getDocumentViewURL(filename);

export const updateProfileInformationAction = createAppAsyncThunk(
  'matter/updateProfileInformation',
  () => {
    throw new Error('updateProfileInformationAction is not implemented');
  }
);
export const sendAssessmentEmailAction = createAppAsyncThunk(
  'matter/sendAssessmentEmail',
  () => {
    throw new Error('sendAssessmentEmailAction is not implemented');
  }
);

export const exportEmailAction = createAppAsyncThunk(
  'matter/exportEmail',
  () => {
    throw new Error('exportEmailAction is not implemented');
  }
);

export const getAgreementDocURLAction = createAppAsyncThunk(
  'matter/getAgreementDocURL',
  (_: { name: string }) => {
    throw new Error('getAgreementDocURLAction is not implemented');
  }
);

export const transferAssetAction = createAppAsyncThunk(
  'matter/transferAsset',
  () => {
    throw new Error('transferAssetAction is not implemented');
  }
);

export const sendAssetSplitOfferAction = createAppAsyncThunk(
  'matter/sendAssetSplitOffer',
  () => {
    throw new Error('transferAssetAction is not implemented');
  }
);

export const confirmAssetTransferAction = createAppAsyncThunk(
  'matter/confirmAssetTransfer',
  () => {
    throw new Error('transferAssetAction is not implemented');
  }
);

export const requestPaymentAction = createAppAsyncThunk(
  'matter/requestPayment',
  () => {
    throw new Error('requestPaymentAction is not implemented');
  }
);

export const getInvoiceAction = createAppAsyncThunk('matter/getInvoice', () => {
  throw new Error('getInvoiceAction is not implemented');
});

export const sendMessageAction = createAppAsyncThunk(
  'matter/sendMessage',
  () => {
    throw new Error('sendMessageAction is not implemented');
  }
);

export const removeSuperannuationAction = createAppAsyncThunk(
  'matter/removeSuperannuation',
  () => {
    throw new Error('removeSuperannuationAction is not implemented');
  }
);
