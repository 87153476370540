import { Theme } from 'Common/Utils/theme';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Heading3, HeadingSpacedContainer } from 'Common/UI/Text/Headings';
import { HighlightedParagraph } from 'Common/UI/Text/Paragraph';
import MoreButton from 'Common/UI/Button/MoreButton';
import CardContext from '../context';

const StyledHeadingSpacedContainer = styled(HeadingSpacedContainer)<{
  theme: Theme;
}>``;

const StyledText = styled.span<{
  theme: Theme;
}>`
  color: ${({ theme }) => theme.headingSideTextColour};
  font-size: 13px;
  letter-spacing: 0.01em;
  margin: 0;
`;

const StyledHighlightedParagraph = styled(HighlightedParagraph)<{
  theme: Theme;
}>`
  color: ${props => props.theme.colours.mediumGrey};
  margin: 0;
`;

const StyledMoreButton = styled(MoreButton)`
  margin-left: ${({ theme }) => theme.spacing[4]};
`;

type Props = {
  heading: ReactNode;
  text?: string;
  enableMoreButton?: boolean;
};

const HeadingWithSideText: React.FC<Props> = ({
  heading,
  text,
  enableMoreButton,
}) => (
  <CardContext.Consumer>
    {({ hasOptions, showOptionsDrawer }) => (
      <StyledHeadingSpacedContainer>
        {text === 'Optional' ? (
          <Heading3>{heading}</Heading3>
        ) : (
          <StyledHighlightedParagraph>{heading}</StyledHighlightedParagraph>
        )}
        {text && <StyledText>{text}</StyledText>}
        {enableMoreButton && hasOptions && (
          <div>
            <StyledMoreButton onClick={showOptionsDrawer} />
          </div>
        )}
      </StyledHeadingSpacedContainer>
    )}
  </CardContext.Consumer>
);

export default HeadingWithSideText;
