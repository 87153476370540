import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import Paragraph from 'Common/UI/Text/Paragraph';
import { Label } from 'Common/UI/Text/Label';

const EnteredAddress = styled(Paragraph)`
  margin-top: ${({ theme }) => theme.spacing[8]};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

type Props = {
  name: string;
  onBlur: () => void;
  onFocus: () => void;
  onInput: (name: string, value: string) => void;
  value: string;
  types?: string;
  placeholder?: string;
  hideHelp?: boolean;
};

const GoogleAddressAutoCompleteTextfield: React.FC<Props> = ({
  name,
  onBlur,
  onFocus,
  onInput,
  value,
  types = 'address',
  placeholder = 'Enter your address',
  hideHelp = false,
}) => (
  <div data-cy="question-google-places-autocomplete">
    <GooglePlacesAutocomplete
      apiKey="AIzaSyCLDz-tEQDZYgyIEGOaT_rkm5wns6D2qKE"
      autocompletionRequest={{
        componentRestrictions: {
          country: ['au'],
        },
        types: [types],
      }}
      selectProps={{
        value,
        classNamePrefix: 'question-google-places-select',
        onChange: (address: any) => {
          onInput(name, address?.label);
        },
        isClearable: true,
        placeholder,
        onBlur,
        onFocus,
      }}
    />
    {value && (
      <>
        <EnteredAddress>
          You have entered <strong>{value}</strong>
        </EnteredAddress>

        {!hideHelp && (
          <Label>
            You can change your address by entering a new one above.
          </Label>
        )}
      </>
    )}
  </div>
);

export default GoogleAddressAutoCompleteTextfield;
