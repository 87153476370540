import React from 'react';
import styled from 'styled-components';
import { Label } from 'Common/UI/Text/Label';
import { generateMessage } from 'App/UI/Messaging/Messages';
import CardContext from '../context';

const MessageContainer = styled.div`
  margin-bottom: 24px;
`;

const MessageWrap = styled.div`
  margin: 10px 0;
`;

const MessageCount: React.FC<{ count: number }> = ({ count }) => (
  <Label>{`(${count} messages)`}</Label>
);

const LatestMessage: React.FC = () => (
  <CardContext.Consumer>
    {({ getLatestUserMessage }) => {
      const { latest, count } = getLatestUserMessage();
      return (
        <MessageContainer>
          {count > 1 && <MessageCount count={count} />}
          <MessageWrap>{latest && generateMessage(latest)}</MessageWrap>
        </MessageContainer>
      );
    }}
  </CardContext.Consumer>
);

export default LatestMessage;
