import { PARTY_A, PARTY_DIVI } from 'Common/constants';
import formatCurrency from 'Common/Utils/formatCurrency';
import MatterProps from 'Common/Utils/MatterProps';
import colours from 'Common/Utils/colours';

const getSplit = (split, party) => (party === PARTY_A ? split : 1 - split);

const generateAgreedDivisionPerParty = split => {
  const { totalAssetPool: TAP } = MatterProps('divi');
  const { party: selfParty } = MatterProps('self');
  const { party: otherParty } = MatterProps('other');

  const offer = {
    self: {
      party: selfParty,
      percentage: Math.round(split * 100),
      amount: formatCurrency(Math.round(TAP * split)),
    },
    other: {
      party: otherParty,
      percentage: Math.round((1 - split) * 100),
      amount: formatCurrency(Math.round(TAP * (1 - split))),
    },
  };

  if (selfParty === PARTY_A) {
    offer.partyA = offer.self;
    offer.partyB = offer.other;
  } else {
    offer.partyA = offer.other;
    offer.partyB = offer.self;
  }

  return offer;
};

const DiviProps = () => {
  const party = MatterProps('self.party');
  const TAP = Number(MatterProps('divi.totalAssetPool'));
  const created = MatterProps('divi.created');

  const rounds = MatterProps('divi.rounds', []).map(round => {
    const split = getSplit(round.split, party);
    return {
      ...round,
      split,
    };
  });

  // Have one or both parties agreed to the division?
  const hasAgreed = MatterProps('divi.hasAgreed', { A: false, B: false });
  const agreedToDivision =
    typeof hasAgreed === 'object' &&
    Object.keys(hasAgreed).every(key => hasAgreed[key]);

  let lastRound;

  if (rounds.length > 0) {
    lastRound = rounds[rounds.length - 1];
  }

  const agreedSplit = agreedToDivision ? lastRound.split : null;

  const division =
    party === PARTY_A
      ? Number(MatterProps('divi.split'))
      : 1 - Number(MatterProps('divi.split'));

  const otherDivision = 1 - division;

  const messages = MatterProps('messages', []).filter(
    m => m.section === 'Division'
  );

  const agreed =
    rounds.length > 1 ? rounds[rounds.length - 1].isAgreement : false;

  const isMatterInDivision = !!MatterProps('divi.created');

  let canMakeOffer = false;

  if (isMatterInDivision && !agreedToDivision) {
    canMakeOffer = lastRound
      ? lastRound.party !== MatterProps('self.party')
      : true;
  }

  const lastOfferFrom = lastRound ? lastRound.party : PARTY_DIVI;

  // returns an array of offers - divi suggestion, then each _different_ offer made by the parties
  // this is different to rounds, which is an array of raw data that includes each action taken by the parties
  const getOffers = () => {
    if (division) {
      const diviOffer = {
        party: PARTY_DIVI,
        ...generateAgreedDivisionPerParty(division),
      };

      const roundOffers = rounds
        ? rounds
            .filter(round => !round.isAgreement)
            .map(round => ({
              party: round.party,
              ...generateAgreedDivisionPerParty(round.split),
            }))
        : [];

      return [diviOffer, ...roundOffers];
    }

    return {};
  };

  const hasMadeOffer = (entity = 'self') => {
    if (!['self', 'other'].includes(entity)) {
      console.log(`${entity} does not exist`);
      return false;
    }

    return (
      rounds &&
      rounds.length > 0 &&
      !!rounds.find(round => round.party === MatterProps(`${entity}.party`))
    );
  };

  const lastOfferBy = entity => {
    switch (entity) {
      case 'self':
        return lastOfferFrom === MatterProps('self.party');
      case 'other':
        return lastOfferFrom === MatterProps('other.party');
      case 'none':
        return !lastRound || (lastRound && lastRound.length <= 0);
      default:
        console.log(`${entity} should not exist`);
        return false;
    }
  };

  const getScheduleColours = person => {
    if (person === MatterProps('self.party')) {
      return { nightsColour: colours.midLightPurple };
    }

    if (person === MatterProps('other.party')) {
      return { nightsColour: colours.aqua };
    }

    return { nightsColour: colours.midLightPurple };
  };

  const getOfferColour = (offerParty, theme) => {
    if (offerParty === MatterProps('self.party')) {
      return {
        indicatorColour: colours.midLightPurple,
        textColour: colours.lightPurple,
      };
    }

    if (offerParty === MatterProps('other.party')) {
      return {
        indicatorColour: theme.otherPartyIndicatorColour,
        textColour: theme.otherPartyIndicatorTextColour,
      };
    }

    return {
      indicatorColour: colours.midDarkOrange,
      textColour: colours.orange,
    };
  };

  const hasOtherPartyAcceptedSuggestion =
    lastOfferBy('other') && rounds.length === 1 && lastRound.split === division;

  const hasConfirmedStatement = {
    self: MatterProps('self.hasConfirmedStatements', false),
    other: MatterProps('other.hasConfirmedStatements', false),
  };

  const bothPartiesReadyForSuggestedDivision = Object.values(
    hasConfirmedStatement
  ).every(item => item);

  return {
    created, // the date that division was started
    division, // the suggested split from divi for the current party (0-1)
    otherDivision, // the suggested split from divi for the other party (0-1)
    TAP, // the total asset pool
    rounds, // array of raw actions by the parties
    messages, // array of negotiation messages sent during suggested division
    agreed,
    lastRound,
    agreedSplit, // if agreement has been reached, this is the agreed split (the number always represents the amount that the current party gets)
    canMakeOffer,
    isMatterInDivision, // has division been reached
    lastOfferFrom, // the party (A, B, DIVI) that made the last offer
    lastOfferBy, // function - given 'self', 'other', or 'none', return a boolean to indicate if that party made the last offer
    hasMadeOffer, // function - self or other, returns bool to indicate if that party has made at least one offer
    hasOtherPartyAcceptedSuggestion,
    hasAgreed, // object indicating whether A and B have agreed to division or not
    getOffers, // array of offers
    agreedToDivision, // have both parties agreed to division
    hasConfirmedStatement,
    bothPartiesReadyForSuggestedDivision,
    getOfferColour,
    getScheduleColours,
  };
};

export const agreedDivisionText = () => {
  const { firstname: selfFirstName } = MatterProps('self');
  const { firstname: otherFirstName } = MatterProps('other');
  const { agreedSplit } = DiviProps();

  const {
    self: { percentage: selfPercentage, amount: selfAmount },
    other: { percentage: otherPercentage, amount: otherAmount },
  } = generateAgreedDivisionPerParty(agreedSplit);

  return `${selfFirstName} will receive ${selfPercentage}% (${selfAmount}) of the total asset pool. ${otherFirstName} will receive ${otherPercentage}% (${otherAmount}) of the total asset pool.`;
};

export default DiviProps;
