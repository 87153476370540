import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import formatCurrency from 'Common/Utils/formatCurrency';
import { Label } from 'Common/UI/Text/Label';
import DeleteIconButton from 'Common/UI/Button/DeleteIconButton';
import AddAnotherButton from 'Common/UI/Button/AddAnotherButton';
import FormLabel from 'Common/UI/Form/FormLabel';
import Input from '../Input';
import DateField from '../Date';

const TextNumberContainer = styled.div`
  display: flex;
  max-width: 400px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledLabel = styled(Label)`
  margin-top: ${({ theme }) => theme.padding.xsmall}px;
  margin-bottom: ${({ theme }) => theme.padding.xxxsmall}px;
`;

const StyledFormLabel = styled(FormLabel)`
  flex-basis: 100%;
`;

const StyledTextField = styled(Input)`
  width: 257px;
`;

const StyledNumberField = styled(Input)`
  width: 140px;
`;

const StyledDateField = styled(DateField)`
  width: 140px;
`;

const StyledDeleteIconButton = styled(DeleteIconButton)`
  border-radius: 0;
`;

const ListItem = ({
  value,
  onRemove,
  onChange,
  onAdd,
  isLastItem,
  formAttributes,
}) => {
  const {
    textLabel = 'Description',
    numberLabel = 'Amount',
    dateLabel = 'Date',
    textPlaceholder = 'Enter text',
    hasDate = false,
    dateInfo = undefined,
    hasValue = true,
  } = formAttributes || {};

  return (
    <TextNumberContainer data-cy="multi-item">
      <FormGroup>
        <StyledFormLabel label={textLabel} />
        <StyledTextField
          type="text"
          value={value.text}
          placeholder={textPlaceholder}
          onChange={event =>
            onChange({
              ...value,
              ...{ text: event.target.value },
            })
          }
          data-cy="multi-item-text"
        />
        <StyledDeleteIconButton onClick={onRemove}>
          Remove
        </StyledDeleteIconButton>
      </FormGroup>
      {hasValue && (
        <>
          {hasDate && (
            <FormGroup>
              <StyledFormLabel label={dateLabel} />
              <StyledDateField
                label=""
                name="miDate"
                data-cy="multi-item-date"
                value={value.date}
                onChange={date =>
                  onChange({
                    ...value,
                    ...{ date },
                  })
                }
                formAttributes={{ max: new Date() }}
              />
              {dateInfo && <StyledLabel>{dateInfo}</StyledLabel>}
            </FormGroup>
          )}
          <FormGroup>
            <StyledFormLabel label={numberLabel} />
            <StyledNumberField
              value={formatCurrency(value.number, false)}
              type="text"
              onChange={event =>
                onChange({
                  ...value,
                  ...{
                    number: Number(
                      event.target.value.replace(/[^0-9.-]+/g, '')
                    ),
                  },
                })
              }
              placeholder={textPlaceholder}
              currency
              data-cy="multi-item-currency"
            />
            {isLastItem && (
              <AddAnotherButton data-cy="multi-item-add-button" onClick={onAdd}>
                Add another
              </AddAnotherButton>
            )}
          </FormGroup>
        </>
      )}
      {!hasValue && isLastItem && (
        <AddAnotherButton data-cy="multi-item-add-button" onClick={onAdd}>
          Add another
        </AddAnotherButton>
      )}
    </TextNumberContainer>
  );
};

ListItem.propTypes = {
  value: PropTypes.shape({ text: PropTypes.string, number: PropTypes.number }),
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  isLastItem: PropTypes.bool.isRequired,
};

ListItem.defaultProps = {
  value: { text: '', number: 0 },
};

export default ListItem;
