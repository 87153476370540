/**
 * THIS IS A VERSIONED FILE - see readme for more info
 */

import calculateMultiItemsValue from 'Common/Utils/calculateMultiItemsValue';
import MatterProps, { getMatter } from 'Common/Utils/MatterProps';
import {
  TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED,
  TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING,
  TRANSFER_OPTION_SELL,
  TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING,
  STATUS_REMOVED,
  TRANSFER_OPTION_TRANSFER_SOLE_TO_OWED,
  TRANSFER_OPTION_SPLIT_EVENLY,
  PARTY_NONE,
  Party,
  PartyAny,
  AssetSplitOption,
  PARTY_A,
  PARTY_B,
} from 'Common/constants';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import Matter, {
  AssetSplitAsset,
  AssetSplitShortfall,
  Division,
  Item,
} from 'Common/Data/Types/matter';
import {
  AssetContentOptions,
  AssetDebtCategory,
  BuildSoleTransferrableCardsProps,
  BuildJointTransferrableCardsProps,
  AssetContentScenarioTypes,
  AssetContentTypes,
  AssetCardContentType,
  AssetOnlyValue,
  AssetFull,
  AssetEnhanced,
  AssetTypeProperty,
} from 'Common/Data/Types/assets';
import React from 'react';
import { assetContent, defaultContent } from './assetContent';

const getNamesForContent = (
  matter: Matter,
  parties: { owing: Party; owed: Party },
  isOwingParty: boolean
) => {
  const { owing, owed } = parties;

  if (isOwingParty) {
    return {
      [owing]: 'you',
      [owed]: matter?.other?.firstname ?? 'your former partner',
    };
  }

  return {
    [owing]: matter?.other?.firstname ?? 'your former partner',
    [owed]: 'you',
  };
};

// builds an array containing values for A/B based on who is the owing party
// this function lets Typescript know what values to expect
export const buildPartyValuesObject = ({
  owingParty,
  owingValue,
  owedValue,
}: {
  owingParty: Party;
  owingValue: number;
  owedValue: number;
}) => {
  if (owingParty === PARTY_A) {
    return {
      [PARTY_A]: owingValue,
      [PARTY_B]: owedValue,
    };
  }

  return {
    [PARTY_A]: owedValue,
    [PARTY_B]: owingValue,
  };
};

// eslint-disable-next-line import/no-mutable-exports
let getContentForType: (
  assetType: string,
  options: AssetContentOptions
) => (
  option: AssetContentTypes,
  choice: AssetContentScenarioTypes
) => React.ReactNode;

///////////////////////////
/////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
/////////
getContentForType = (type, data) => {
  const content = assetContent.find(item => item.type === type);

  return (option, choice) => {
    if (!option || !choice) {
      return '';
    }

    if (content) {
      const optionContent = content[option];
      const func = optionContent && optionContent[choice];

      if (func && typeof func === 'function') {
        return func(data);
      }
    }

    return defaultContent[option][choice](data);
  };
};
//////////

export const assetCategories: AssetDebtCategory[] = [
  {
    type: 'property',
    title: 'Property',
  },
  {
    type: 'vehicle',
    title: 'Vehicles',
  },
  {
    type: 'shares',
    title: 'Shares',
  },
  {
    type: 'savings',
    title: 'Bank Accounts',
  },
  {
    type: 'cash',
    title: 'Cash',
  },
  {
    type: 'otherAssets',
    title: 'Other Assets',
  },
  {
    type: 'householdItems',
    title: 'Household Items',
  },
  {
    type: 'sharedSavings',
    title: 'Shared Savings',
  },
  {
    type: 'sharedAssets',
    title: 'Shared Assets',
  },
  {
    type: 'superannuation',
    title: 'Superannuation',
    heading: ({ isOwingParty, owingName }) =>
      isOwingParty
        ? `You can only split accumulation type funds or funds that have a withdrawal benefit of more than $5000. Only select one of your superannuation accounts to split.`
        : `You can only select one of ${owingName}'s superannuation accounts to split. You cannot split funds that are not accumulation funds or funds that have less than $5000`,
  },
];

export const debtCategories: AssetDebtCategory[] = [
  {
    type: 'creditCards',
    title: 'Credit cards',
  },
  {
    type: 'otherDebts',
    title: 'Other debts',
  },
  {
    type: 'sharedDebts',
    title: 'Shared debts',
  },
];

const getSplitAmounts = (
  parties: { owing: Party; owed: Party },
  owner: PartyAny,
  amount: number
) => {
  const value = Number(amount);

  if (owner === 'both') {
    return [value / 2, value / 2];
  }

  if (owner === parties.owing) {
    return [value, 0];
  }

  return [0, value];
};

const getIsJointlyOwned = (owingValue: number, owedValue: number) => {
  if (owingValue > 0 && owedValue > 0 && owingValue === owedValue) {
    return true;
  }

  return false;
};

const isOwnedByMe = (owner: Party) => {
  const party = getPartyKeys();

  return party.self === owner;
};

// NOTE ABOUT PROPERTY
// jointly owned property that is being *sold* does not need to be modified because the value is already pos/neg depending on who its going to
export const getValueModifier = (
  isLiability = false,
  transferOption: AssetSplitOption,
  isJointlyOwnedRealProperty = false
) => {
  let modifier = 1;

  if (
    (!isLiability &&
      transferOption === TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING) ||
    (isLiability &&
      transferOption === TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED) ||
    transferOption === TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING
  ) {
    modifier = -1;
  }

  if (
    (transferOption === TRANSFER_OPTION_SELL ||
      transferOption === TRANSFER_OPTION_SPLIT_EVENLY) &&
    !isJointlyOwnedRealProperty
  ) {
    modifier = 0;
  }

  return modifier;
};

const buildJointTransferrableCards = ({
  assetType,
  assetFriendlyType,
  assetName,
  owingName,
  owedName,
  options: {
    canSell = true,
    canSplit = false,
    isJointlyOwned = false,
    isLiability = false,
    canOnlySplit,
  },
  extraData = {},
}: BuildJointTransferrableCardsProps) => {
  const cards: AssetCardContentType[] = [];

  const getContent = getContentForType(assetType, {
    assetType,
    assetFriendlyType,
    assetName,
    owingName,
    owedName,
    isJointlyOwned,
    isOwnedByMe: true,
    ...extraData,
  });

  const splitOption = isLiability ? 'discharge' : 'splitEvenly';

  if (canOnlySplit) {
    cards.push({
      option: TRANSFER_OPTION_SPLIT_EVENLY,
      title: getContent(splitOption, 'title'),
      description: getContent(splitOption, 'description'),
      info: getContent(splitOption, 'info'),
      selectedDescription: getContent(splitOption, 'selectedDescription'),
      statement: getContent(splitOption, 'statement'),
      statementOwed: getContent(splitOption, 'statementOwed'),
    });
  } else {
    cards.push({
      option: TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING,
      title: getContent('jointToOwing', 'title'),
      description: getContent('jointToOwing', 'description'),
      info: getContent('jointToOwing', 'info'),
      selectedDescription: getContent('jointToOwing', 'selectedDescription'),
      statement: getContent('jointToOwing', 'statement'),
      statementOwed: getContent('jointToOwing', 'statementOwed'),
    });
    cards.push({
      option: TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED,
      title: getContent('jointToOwed', 'title'),
      description: getContent('jointToOwed', 'description'),
      info: getContent('jointToOwed', 'info'),
      selectedDescription: getContent('jointToOwed', 'selectedDescription'),
      statement: getContent('jointToOwed', 'statement'),
      statementOwed: getContent('jointToOwed', 'statementOwed'),
    });

    if (canSell) {
      cards.push({
        option: TRANSFER_OPTION_SELL,
        title: getContent('sell', 'title'),
        description: getContent('sell', 'description'),
        info: getContent('sell', 'info'),
        selectedDescription: getContent('sell', 'selectedDescription'),
        statement: getContent('sell', 'statement'),
        statementOwed: getContent('sell', 'statementOwed'),
      });
    }

    if (canSplit) {
      cards.push({
        option: TRANSFER_OPTION_SPLIT_EVENLY,
        title: getContent(splitOption, 'title'),
        description: getContent(splitOption, 'description'),
        info: getContent(splitOption, 'info'),
        selectedDescription: getContent(splitOption, 'selectedDescription'),
        statement: getContent(splitOption, 'statement'),
        statementOwed: getContent(splitOption, 'statementOwed'),
      });
    }
  }

  return cards;
};

const buildSoleTransferrableCards = ({
  assetType,
  assetFriendlyType,
  assetName,
  owingName,
  owedName,
  options: {
    canSell = true,
    isJointlyOwned = false,
    owner,
    isOwingParty,
    value = 0,
  },
}: BuildSoleTransferrableCardsProps) => {
  const cards: AssetCardContentType[] = [];

  const getContent = getContentForType(assetType, {
    assetType,
    assetFriendlyType,
    assetName,
    owingName,
    owedName,
    isJointlyOwned,
    value,
    isOwnedByMe: isOwnedByMe(owner),
  });

  if (
    (isOwnedByMe(owner) && !isOwingParty) ||
    (!isOwnedByMe(owner) && isOwingParty)
  ) {
    cards.push({
      option: TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING,
      title: getContent('soleToOwing', 'title'),
      description: getContent('soleToOwing', 'description'),
      info: getContent('soleToOwing', 'info'),
      selectedDescription: getContent('soleToOwing', 'selectedDescription'),
      statement: getContent('soleToOwing', 'statement'),
      statementOwed: getContent('soleToOwing', 'statementOwed'),
    });
  } else {
    cards.push({
      option: TRANSFER_OPTION_TRANSFER_SOLE_TO_OWED,
      title: getContent('soleToOwed', 'title'),
      description: getContent('soleToOwed', 'description'),
      info: getContent('soleToOwed', 'info'),
      selectedDescription: getContent('soleToOwed', 'selectedDescription'),
      statement: getContent('soleToOwed', 'statement'),
      statementOwed: getContent('soleToOwed', 'statementOwed'),
    });
  }

  if (canSell) {
    cards.push({
      option: TRANSFER_OPTION_SELL,
      title: getContent('sell', 'title'),
      description: getContent('sell', 'description'),
      info: getContent('sell', 'info'),
      selectedDescription: getContent('sell', 'selectedDescription'),
      statement: getContent('sell', 'statement'),
      statementOwed: getContent('sell', 'statementOwed'),
    });
  }

  return cards;
};

const getBuildTransferrableCardsFunction = (isJointlyOwned: boolean) =>
  isJointlyOwned ? buildJointTransferrableCards : buildSoleTransferrableCards;

// get the value from a card with an optional custom additional check
// this is used for cases where the value in the database might not be correct
// e.g. if a user entered superannuation but then updated the card to say that
// they don't have superannuation, the value persists in the database so we also
// need to check that they answered yes to the superannuation question
const getValueWithAdditionalCheck = (
  dataKey: string,
  item?: Item,
  additionalCheck?: [string, unknown]
) => {
  if (!item) {
    return 0;
  }

  if (additionalCheck && Array.isArray(additionalCheck)) {
    const [checkKey, checkValue] = additionalCheck;

    return item[checkKey] === checkValue ? item[dataKey] || 0 : 0;
  }

  return item[dataKey] || 0;
};

type GetAssetValueOptions = {
  parties: { owing: Party; owed: Party };
  isOwingParty?: boolean;
  isTransferrable?: boolean;
  isLiability?: boolean;
  combineValues?: boolean;
  onlyValues?: boolean;
  canSplit?: boolean;
  canOnlySplit?: boolean;
  additionalCheck?: [string, unknown];
  selectedAssets?: (AssetSplitAsset | AssetSplitShortfall)[];
};

type GetAssetValueProps = {
  options: GetAssetValueOptions;
  groupName: string;
  type: string;
  baseSection: string;
  dataKey: string;
};

export const getSoleAssetValue = ({
  options: {
    parties,
    isOwingParty = false,
    isTransferrable = false,
    isLiability = false,
    combineValues = false,
    additionalCheck,
    onlyValues,
  },
  groupName,
  type,
  baseSection,
  dataKey,
}: GetAssetValueProps): (AssetFull | AssetOnlyValue)[] => {
  const matter = getMatter();
  const { items } = matter;

  const names = getNamesForContent(matter, parties, isOwingParty);

  const valueModifier = 1;

  const owingItem = items.find(
    item => item.SectionID === `${baseSection}${parties.owing}`
  );
  const owedItem = items.find(
    item => item.SectionID === `${baseSection}${parties.owed}`
  );

  // quit out if theres no value as it means nothing was entered for the item
  if (
    (!owingItem || owingItem[dataKey] === undefined) &&
    (!owedItem || owedItem[dataKey] === undefined)
  ) {
    return [];
  }

  const owingValue = getValueWithAdditionalCheck(
    dataKey,
    owingItem,
    additionalCheck
  );
  const owedValue = getValueWithAdditionalCheck(
    dataKey,
    owedItem,
    additionalCheck
  );

  let selfItems: (AssetFull | AssetOnlyValue)[] = [];
  let otherItems: (AssetFull | AssetOnlyValue)[] = [];

  if (Array.isArray(owingValue) || Array.isArray(owedValue)) {
    const selfData = calculateMultiItemsValue(owingValue);

    const base = {
      repeatable: false,
      isJointlyOwned: false,
      isMultiItem: true,
      isLiability,
      isTransferrable,
    };

    selfItems = selfData.items.map((item, index) => {
      const valueData: AssetOnlyValue = {
        type,
        valueData: buildPartyValuesObject({
          owingParty: parties.owing,
          owingValue: Number(item.number) * valueModifier,
          owedValue: 0,
        }),
      };

      if (onlyValues) {
        return valueData;
      }

      return {
        ...valueData,
        ...base,
        id: `${type}${parties.owing}${index}`,
        assetId: [`${baseSection}${parties.owing}`, dataKey, index],
        name: item.text,
        cards: isTransferrable
          ? buildSoleTransferrableCards({
              assetType: type,
              assetFriendlyType: item.text,
              assetName: item.text,
              owingName: names[parties.owing],
              owedName: names[parties.owed],
              options: {
                canSell: false,
                isJointlyOwned: false,
                owner: parties.owing,
                isOwingParty,
              },
            })
          : undefined,
      };
    });

    const otherData = calculateMultiItemsValue(owedValue);

    otherItems = otherData.items.map((item, index) => {
      const valueData: AssetOnlyValue = {
        type,
        valueData: buildPartyValuesObject({
          owingParty: parties.owing,
          owingValue: 0,
          owedValue: Number(item.number) * valueModifier,
        }),
      };

      if (onlyValues) {
        return valueData;
      }

      return {
        ...valueData,
        ...base,
        id: `${type}${parties.owed}${index}`,
        assetId: [`${baseSection}${parties.owed}`, dataKey, index],
        name: item.text,
        cards: isTransferrable
          ? buildSoleTransferrableCards({
              assetType: type,
              assetFriendlyType: item.text,
              assetName: item.text,
              owingName: names[parties.owing],
              owedName: names[parties.owed],
              options: {
                canSell: false,
                isJointlyOwned: false,
                owner: parties.owed,
                isOwingParty,
              },
            })
          : undefined,
      };
    });

    return [...selfItems, ...otherItems];
  }

  const base = {
    type,
    name: groupName,
    repeatable: false,
    isLiability,
    isTransferrable,
    isJointlyOwned: false,
  };

  // if this asset is marked as combineValues, it means we should combine both parties
  // value for this item in to one row instead of two separate rows
  if (combineValues) {
    return [
      {
        ...base,
        id: `${type}${parties.owing}`,
        assetId: [`${baseSection}${parties.owing}`, dataKey],
        valueData: buildPartyValuesObject({
          owingParty: parties.owing,
          owingValue: Number(owingValue) * valueModifier,
          owedValue: Number(owedValue) * valueModifier,
        }),
      },
    ];
  }

  return [
    {
      ...base,
      type,
      id: `${type}${parties.owing}`,
      assetId: [`${baseSection}${parties.owing}`, dataKey],
      valueData: buildPartyValuesObject({
        owingParty: parties.owing,
        owingValue: Number(owingValue) * valueModifier,
        owedValue: 0,
      }),
    },
    {
      ...base,
      type,
      id: `${type}${parties.owed}`,
      assetId: [`${baseSection}${parties.owed}`, dataKey],
      valueData: buildPartyValuesObject({
        owingParty: parties.owing,
        owingValue: 0,
        owedValue: Number(owedValue) * valueModifier,
      }),
    },
  ];
};

export const getJointAssetValue = ({
  options: {
    onlyValues,
    parties,
    isOwingParty = false,
    isTransferrable = false,
    isLiability = false,
    canSplit = false,
    canOnlySplit = false,
  },
  groupName,
  type,
  baseSection,
  dataKey,
}: GetAssetValueProps): (AssetFull | AssetOnlyValue)[] => {
  const matter = getMatter();
  const { items } = matter;
  const names = getNamesForContent(matter, parties, isOwingParty);

  const valueModifier = 1;

  const item = items.find(i => i.SectionID === baseSection);

  // quit out if theres no item matching the baseSection
  if (!item) {
    return [];
  }

  const asset = item[dataKey];

  // quit out if theres no value as it means nothing was entered for the item
  if (asset === undefined) {
    return [];
  }

  const base = {
    repeatable: false,
    isJointlyOwned: true,
    isLiability,
    isTransferrable,
  };

  const value = asset || 0;

  // multi items
  if (Array.isArray(value)) {
    const assetData = calculateMultiItemsValue(value);

    return assetData.items.map((i, index) => {
      const data: AssetOnlyValue = {
        type,
        valueData: buildPartyValuesObject({
          owingParty: parties.owing,
          owingValue: (Number(i.number) / 2) * valueModifier,
          owedValue: (Number(i.number) / 2) * valueModifier,
        }),
      };

      if (onlyValues) {
        return data;
      }

      return {
        ...base,
        ...data,
        id: `${type}${index}`,
        assetId: [baseSection, type, index],
        name: i.text,
        isMultii: true,
        cards: isTransferrable
          ? buildJointTransferrableCards({
              assetType: type,
              assetFriendlyType: i.text,
              assetName: i.text,
              owingName: names[parties.owing],
              owedName: names[parties.owed],
              options: {
                canSell: false,
                canSplit,
                canOnlySplit,
                isLiability,
              },
            })
          : undefined,
      };
    });
  }

  const amount = (Number(value) / 2) * valueModifier;

  const data: AssetOnlyValue = {
    type,
    valueData: buildPartyValuesObject({
      owingParty: parties.owing,
      owingValue: amount,
      owedValue: amount,
    }),
  };

  if (onlyValues) {
    return [data];
  }

  return [
    {
      ...data,
      id: type,
      assetId: [baseSection, type],
      name: groupName,
      cards: isTransferrable
        ? buildJointTransferrableCards({
            assetType: type,
            assetFriendlyType: type,
            assetName: type,
            owingName: names[parties.owing],
            owedName: names[parties.owed],
            options: {
              canSell: false,
              canSplit: isLiability,
              isLiability,
            },
          })
        : undefined,
    },
  ];
};

export const getSharesValue = (
  options: GetAssetValueOptions
): (AssetFull | AssetOnlyValue)[] => {
  const data = getSoleAssetValue({
    options,
    groupName: 'Shares',
    type: 'shares',
    baseSection: 'yourfinances',
    dataKey: 'shares',
  });

  return data.map(item => ({
    ...item,
    isJointlyOwned: false,
  }));
};

export const getPropertyValues = ({
  onlyValues,
  parties,
  isOwingParty = false,
  isTransferrable = false,
}: GetAssetValueOptions): (AssetTypeProperty | AssetOnlyValue)[] => {
  const matter = getMatter();
  const { items } = matter;
  const names = getNamesForContent(matter, parties, isOwingParty);

  const properties = items.filter(
    item => item.BaseSection === 'properties' && item.status !== STATUS_REMOVED
  );

  const assets: (AssetTypeProperty | AssetOnlyValue)[] = properties.map(
    asset => {
      const [owingValue, owedValue] = getSplitAmounts(
        parties,
        asset.propertyTitle,
        asset.propertyValue
      );
      const [selfMortgage, otherMortgage] = getSplitAmounts(
        parties,
        asset.propertyTitle,
        asset.propertyMortgage
      );
      const selfEquity = owingValue - selfMortgage;
      const otherEquity = owedValue - otherMortgage;

      const isJointlyOwned = getIsJointlyOwned(owingValue, owedValue);

      const data: AssetOnlyValue = {
        type: 'property',
        valueData: buildPartyValuesObject({
          owingParty: parties.owing,
          owingValue: selfEquity,
          owedValue: otherEquity,
        }),
      };

      if (onlyValues) {
        return data;
      }

      return {
        ...data,
        isJointlyOwned,
        repeatable: true,
        isLiability: false,
        isTransferrable,
        id: asset.SectionID,
        assetId: [`${asset.BaseSection}${asset.CardIndex}`, 'propertyValue'],
        mortgageId: `mortgages${asset.CardIndex}`,
        mortgageAssetId: [`mortgages${asset.CardIndex}`, 'propertyWhoseName'],
        name: asset.propertyAddress,
        onlyMortgage: {
          [parties.owing]: selfMortgage,
          [parties.owed]: otherMortgage,
        },
        cards: isTransferrable
          ? getBuildTransferrableCardsFunction(isJointlyOwned)({
              assetType: 'property',
              assetFriendlyType: 'property',
              assetName: asset.propertyAddress,
              owingName: names[parties.owing],
              owedName: names[parties.owed],
              options: {
                canSell: true,
                canSplit: false,
                isJointlyOwned,
                owner: asset.propertyTitle,
                isOwingParty,
              },
            })
          : undefined,
      };
    }
  );

  return assets;
};

export const getVehicleValues = ({
  onlyValues,
  parties,
  isOwingParty = false,
  isTransferrable = false,
}: GetAssetValueOptions): (AssetFull | AssetOnlyValue)[] => {
  const matter = getMatter();
  const { items } = matter;
  const names = getNamesForContent(matter, parties, isOwingParty);

  const vehicles = items.filter(
    item => item.BaseSection === 'vehicles' && item.status !== STATUS_REMOVED
  );

  return vehicles.map(vehicle => {
    const [selfSplit, otherSplit] = getSplitAmounts(
      parties,
      vehicle.vehicleOwner,
      vehicle.vehicleValue
    );
    const isJointlyOwned = getIsJointlyOwned(selfSplit, otherSplit);

    const data = {
      type: 'vehicle',
      valueData: buildPartyValuesObject({
        owingParty: parties.owing,
        owingValue: selfSplit,
        owedValue: otherSplit,
      }),
    };

    if (onlyValues) {
      return data;
    }

    return {
      ...data,
      isTransferrable,
      isJointlyOwned,
      repeatable: true,
      isLiability: false,
      id: vehicle.SectionID,
      assetId: [`${vehicle.BaseSection}${vehicle.CardIndex}`, 'vehicleValue'],
      name: vehicle.vehicleRego || vehicle.vehicleMakeModel,
      cards: isTransferrable
        ? getBuildTransferrableCardsFunction(isJointlyOwned)({
            assetType: 'vehicle',
            assetFriendlyType: 'vehicle',
            assetName: vehicle.vehicleRego || vehicle.vehicleMakeModel,
            owingName: names[parties.owing],
            owedName: names[parties.owed],
            options: {
              canSell: false,
              canSplit: false,
              isJointlyOwned,
              owner: vehicle.vehicleOwner,
              isOwingParty,
            },
          })
        : undefined,
    };
  });
};

export const getSuperannuationValues = ({
  onlyValues,
  parties,
  isOwingParty = false,
  selectedAssets,
}: GetAssetValueOptions): (AssetFull | AssetOnlyValue)[] => {
  const matter = getMatter();
  const { items } = matter;
  const names = getNamesForContent(matter, parties, isOwingParty);

  const superannuations = items.filter(
    item =>
      item.BaseSection === 'yoursuperannuation' &&
      item.status !== STATUS_REMOVED
  );

  return superannuations.map(superannuation => {
    const [selfSplit, otherSplit] = getSplitAmounts(
      parties,
      superannuation.creator,
      superannuation.superannuationValue
    );

    // if a superannuation account meets these conditions then it is transferrable:
    // - it is owned by the owing party
    // - it is an accumulation interest or partially vested accumulation interest type
    // - it has a value of $5000 or above
    const isTransferrable =
      superannuation.creator === parties.owing &&
      (superannuation.superannuationType === 'Accumulation interest' ||
        superannuation.superannuationType ===
          'Partially vested accumulation interest') &&
      Number(superannuation.superannuationValue) >= 5000;

    const data: AssetOnlyValue = {
      type: 'superannuation',
      valueData: buildPartyValuesObject({
        owingParty: parties.owing,
        owingValue: selfSplit,
        owedValue: otherSplit,
      }),
    };

    if (onlyValues) {
      return data;
    }

    let value = 0;

    if (selectedAssets) {
      const selectedSuperannuation = selectedAssets.find(
        selectedAsset => selectedAsset.id === superannuation.SectionID
      );

      if (selectedSuperannuation) {
        value = selectedSuperannuation.value;
      }
    }

    return {
      ...data,
      isTransferrable,
      isJointlyOwned: false,
      repeatable: true,
      isLiability: false,
      id: superannuation.SectionID,
      assetId: [superannuation.SectionID, 'superannuationValue'],
      name: superannuation.superannuationName,
      cards: isTransferrable
        ? buildSoleTransferrableCards({
            assetType: 'superannuation',
            assetFriendlyType: 'superannuation',
            assetName: superannuation.superannuationName,
            owingName: names[parties.owing],
            owedName: names[parties.owed],
            options: {
              canSell: false,
              canSplit: false,
              isJointlyOwned: false,
              owner: superannuation.creator,
              isOwingParty,
              value,
            },
          })
        : undefined,
    };
  });
};

// takes the assets from two offers and returns an object of added, updated, removed, and unchanged assets
export const getOfferDiff = (
  offerAssets: AssetEnhanced[],
  lastOfferAssets: AssetEnhanced[],
  { isAssetSplitCompleted = false } = {}
) => {
  // if asset split is completed, return them all as unchanged to avoid confusing styling
  if (isAssetSplitCompleted) {
    return {
      added: [],
      updated: [],
      removed: [],
      unchanged: offerAssets,
    };
  }

  const added = offerAssets.filter(
    asset => !lastOfferAssets.find(lastAsset => asset.id === lastAsset.id)
  );

  const updated = offerAssets.filter(asset => {
    const prevOfferAsset = lastOfferAssets.find(
      lastAsset => asset.id === lastAsset.id
    );

    return (
      prevOfferAsset && asset.selectedOption !== prevOfferAsset.selectedOption
    );
  });

  const removed = lastOfferAssets.filter(
    lastAsset => !offerAssets.find(asset => asset.id === lastAsset.id)
  );

  const unchanged = offerAssets.filter(
    asset =>
      ![...added, ...updated, ...removed].find(
        handledAsset => handledAsset.id === asset.id
      )
  );

  return {
    added,
    updated,
    removed,
    unchanged,
  };
};

// get the party who this asset is transferring to
export const getReceivingPartyForAsset = (asset: AssetSplitAsset) => {
  const { option } = asset;
  const divi: Division = MatterProps('divi', {});
  const { owingParty, owedParty } = divi;

  if (
    option === TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED ||
    option === TRANSFER_OPTION_TRANSFER_SOLE_TO_OWED
  ) {
    return owedParty;
  }

  if (
    option === TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING ||
    option === TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING
  ) {
    return owingParty;
  }

  return PARTY_NONE;
};
