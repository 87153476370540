import { CardAny } from 'Common/Data/Types/appSections';
import { REMOVE_STATEMENT } from 'Common/UI/OptionsDrawer/constants';

/**
 * Determines if a card can be removed
 * @param card
 * @returns
 */
const generateOptionsForCard = (card: CardAny): [string[], boolean] => {
  const options: string[] = [];

  // make sure it is a valid card type
  if ('cardForParty' in card) {
    const { isRepeatable, cannotBeRemoved, cardForParty } = card;

    const currentPartyCanManageCard = cardForParty !== 'other';

    if (isRepeatable && currentPartyCanManageCard && !cannotBeRemoved) {
      options.push(REMOVE_STATEMENT);
    }

    return [options, options.length > 0];
  }

  return [options, false];
};

export default generateOptionsForCard;
