import React from 'react';

import {
  ODRS_DASH_PROPERTY_ROUTE,
  ODRS_DIVISION_VIEW_SUGGESTED,
} from 'Common/routes';
import Carousel from 'Common/UI/Carousel';
import push from 'Common/Utils/push';
import { CarouselButton } from 'Common/UI/Carousel/ImageSlideWrapper';
import { updateFlag } from 'App/State/AppReducer';
import DivisionSlide1 from './DivisionSlide1';
import DivisionSlide2 from './DivisionSlide2';
import { useAppDispatch } from '../../State/Store';

const LastSlideControls = () => {
  const dispatch = useAppDispatch();

  const navigate = (to: string) => dispatch(push(to));
  return (
    <CarouselButton
      onClick={async () => {
        await dispatch(updateFlag({ isDemoComplete: true }));
        navigate(ODRS_DIVISION_VIEW_SUGGESTED);
      }}
      data-cy="carousel-finish-button"
    >
      Next
    </CarouselButton>
  );
};

const DivisionIntroCarousel = () => {
  const dispatch = useAppDispatch();
  const navigate = (to: string) => dispatch(push(to));

  return (
    <Carousel
      onClose={() => navigate(ODRS_DASH_PROPERTY_ROUTE)}
      LastSlideControls={LastSlideControls}
    >
      <DivisionSlide1 />
      <DivisionSlide2 />
    </Carousel>
  );
};

export default DivisionIntroCarousel;
