import React from 'react';
import styled from 'styled-components';
import { Theme } from 'Common/Utils/theme';
import Paragraph from 'Common/UI/Text/Paragraph';
import CardContext from 'Common/UI/Card/context';

const StatementWrap = styled.div<{
  theme: Theme;
}>`
  padding: ${props => props.theme.padding.xsmall}px
    ${props => props.theme.padding.small}px;
  background-color: ${props => props.theme.statementBackground};
  margin-bottom: 8px;
  border-radius: 4px;
  flex-grow: 1;
  position: relative;
`;

const StatementContent = styled(Paragraph)`
  margin-top: 0;
  margin-bottom: 0;
  overflow-wrap: break-word;
`;

type Props = {
  noBox?: boolean;
};

const Statement: React.FC<Props> = ({ noBox = false }) => (
  <CardContext.Consumer>
    {({ statement: StatementElement }) =>
      noBox ? (
        <StatementContent as="div">
          <StatementElement />
        </StatementContent>
      ) : (
        <StatementWrap>
          <StatementContent as="div">
            <StatementElement />
          </StatementContent>
        </StatementWrap>
      )
    }
  </CardContext.Consumer>
);

export default Statement;
