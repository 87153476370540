import { Theme } from 'Common/Utils/theme';
import { PartyKeys } from 'Common/constants';
import styled, { css } from 'styled-components';

export const UpdatedTextCSS = css`
  color: ${({ theme }) => theme.otherPartyIndicatorTextColour};
  background-color: ${({ theme }) => theme.colours.highlightAqua};
  border-radius: 2px;
  padding: 2px;
  font-weight: bold;
  text-decoration: none;
`;

const UpdatedText = styled.span<{
  theme: Theme;
  lastUpdatedBy: PartyKeys;
  withUpdateLink: boolean;
  onClick?: () => void;
}>`
  ${UpdatedTextCSS}

  background-color: ${({ theme }) => theme.otherPartyIndicatorBackgroundColour};
  color: ${({ theme }) => theme.colours.black};

  ${({ lastUpdatedBy, theme }) =>
    lastUpdatedBy === 'self' &&
    css`
      background-color: ${theme.selfPartyIndicatorBackgroundColour};
    `}

  ${({ withUpdateLink, lastUpdatedBy, theme }) =>
    withUpdateLink &&
    css`
      transition: background-color 0.3s;
      cursor: pointer;

      color: ${lastUpdatedBy === 'self'
        ? theme.selfPartyIndicatorTextColour
        : theme.otherPartyIndicatorTextColour};

      &:hover {
        background-color: ${lastUpdatedBy === 'self'
          ? theme.selfPartyIndicatorBackgroundColourHover
          : theme.otherPartyIndicatorBackgroundColourHover};
      }
    `}
`;

export default UpdatedText;
