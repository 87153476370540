import React from 'react';

import { H2Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  SlideInner,
  SlideText,
  SlideIcon,
} from 'Common/UI/Carousel/ImageSlideWrapper';
import DivisionImage02 from 'Common/Assets/images/divi-background-two-messages.png';

const DivisionSlide2 = () => (
  <SlideInner>
    <SlideIcon className="slide-icon" backgroundImage={DivisionImage02}>
      <img alt="" src={DivisionImage02} srcSet={`${DivisionImage02} 2x`} />
    </SlideIcon>
    <SlideText data-cy="suggested-division-slide-2">
      <H2Conversational>
        amica one&apos;s suggested division is based on the information you&apos;ve entered. 
      </H2Conversational>
      <Paragraph>
        amica one is a demo version of amica, our suggested division is a range based on the information you entered. If amica is right for you, you can create an account and invite your former partner. Once you both enter your information, your suggested division may change.
      </Paragraph>
    </SlideText>
  </SlideInner>
);

export default DivisionSlide2;
