export const formatCurrencyAsNumber = amount => {
  if (typeof amount === 'string') {
    return amount.replace(/\D/g, '');
  }
  return amount;
};

const formatCurrency = (amount, prefix = true) => {
  const sign = Number(amount) < 0 ? '-' : '';
  const formattedNumber = Math.abs(Math.round(formatCurrencyAsNumber(amount)));
  const prefixChar = prefix ? '$' : '';
  return `${sign}${prefixChar}${Number(formattedNumber).toLocaleString()}`;
};

export const formatCurrencyWithNil = (amount, prefix = true) => {
  const value = formatCurrency(amount, prefix);

  if ((prefix && value === '$0') || (!prefix && value === '0')) {
    return 'NIL';
  }

  return value;
};

export default formatCurrency;
