import MatterProps from 'Common/Utils/MatterProps';

export const isWaitingForOtherToResetSuggestedDivision = (): boolean => {
  const self = MatterProps('self', {});
  const other = MatterProps('other', {});

  return (
    self.readyToResetSuggestedDivision &&
    !other.notReadyToResetSuggestedDivision
  );
};

export const isOtherNotReadyToResetSuggestedDivision = (): boolean => {
  const self = MatterProps('self', {});
  const other = MatterProps('other', {});

  return (
    self.readyToResetSuggestedDivision && other.notReadyToResetSuggestedDivision
  );
};

export const isWaitingForSelfToResetSuggestedDivision = (): boolean => {
  const self = MatterProps('self', {});
  const other = MatterProps('other', {});

  return (
    other.readyToResetSuggestedDivision &&
    !self.notReadyToResetSuggestedDivision
  );
};

export const isInResetSuggestedDivisionFlow = () =>
  isWaitingForOtherToResetSuggestedDivision() ||
  isOtherNotReadyToResetSuggestedDivision() ||
  isWaitingForSelfToResetSuggestedDivision();
