/**
 * THIS IS A VERSIONED FILE - see readme for more info
 */

import calculateMultiItemsValue from 'Common/Utils/calculateMultiItemsValue';
import MatterProps from 'Common/Utils/MatterProps';
import {
  TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED,
  TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING,
  TRANSFER_OPTION_SELL,
  TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING,
  STATUS_REMOVED,
  TRANSFER_OPTION_TRANSFER_SOLE_TO_OWED,
  TRANSFER_OPTION_SPLIT_EVENLY,
  PARTY_NONE,
} from 'Common/constants';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import { assetContent, defaultContent } from './assetContent';

const getNamesForContent = (parties, isOwingParty) => {
  const { owing, owed } = parties;

  if (isOwingParty) {
    return {
      [owing]: 'you',
      [owed]: 'your former partner',
    };
  }

  return {
    [owing]: 'your former partner',
    [owed]: 'you',
  };
};

// eslint-disable-next-line import/no-mutable-exports
let getContentForType;

///////////////////////////
///////////////////
//////////////////////
/////////////////////
///////
/////////
getContentForType = (type, data = {}) => {
  const content = assetContent.find(item => item.type === type);

  return (option, choice) => {
    if (
      content &&
      content[option] &&
      content[option][choice] &&
      typeof content[option][choice] === 'function'
    ) {
      return content[option][choice](data);
    }

    return defaultContent[option][choice](data);
  };
};
//////////

export { getContentForType };

export const assetCategories = [
  {
    type: 'property',
    title: 'Property',
  },
  {
    type: 'vehicle',
    title: 'Vehicles',
  },
  {
    type: 'shares',
    title: 'Shares',
  },
  {
    type: 'savings',
    title: 'Bank Accounts',
  },
  {
    type: 'cash',
    title: 'Cash',
  },
  {
    type: 'otherAssets',
    title: 'Other Assets',
  },
  {
    type: 'householdItems',
    title: 'Household Items',
  },
  {
    type: 'sharedSavings',
    title: 'Shared Savings',
  },
  {
    type: 'sharedAssets',
    title: 'Shared Assets',
  },
  {
    type: 'superannuation',
    title: 'Superannuation',
  },
];

export const debtCategories = [
  {
    type: 'creditCards',
    title: 'Credit cards',
  },
  {
    type: 'otherDebts',
    title: 'Other debts',
  },
  {
    type: 'sharedDebts',
    title: 'Shared debts',
  },
];

export const getSplitAmounts = (parties, owner, amount) => {
  const value = Number(amount);

  if (owner === 'both') {
    return [value / 2, value / 2];
  }

  if (owner === parties.owing) {
    return [value, 0];
  }

  return [0, value];
};

export const getIsJointlyOwned = (owingValue, owedValue) => {
  if (owingValue > 0 && owedValue > 0 && owingValue === owedValue) {
    return true;
  }

  return false;
};

const isOwnedByMe = owner => {
  const party = getPartyKeys();

  return party.self === owner;
};

// NOTE ABOUT PROPERTY
// jointly owned property that is being *sold* does not need to be modified because the value is already pos/neg depending on who its going to
export const getValueModifier = (
  isOwingParty,
  isLiability = false,
  transferOption,
  isJointlyOwnedRealProperty = false
) => {
  let modifier = 1;

  if (
    (!isLiability &&
      transferOption === TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING) ||
    (isLiability &&
      transferOption === TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED) ||
    transferOption === TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING
  ) {
    modifier = -1;
  }

  if (
    (transferOption === TRANSFER_OPTION_SELL ||
      transferOption === TRANSFER_OPTION_SPLIT_EVENLY) &&
    !isJointlyOwnedRealProperty
  ) {
    modifier = 0;
  }

  return modifier;
};

export const buildJointTransferrableCards = (
  assetType,
  assetFriendlyType,
  assetName,
  owingName,
  owedName,
  {
    canSell = true,
    canSplit = false,
    isJointlyOwned = false,
    isLiability = false,
    canOnlySplit,
  },
  extraData = {}
) => {
  const cards = [];

  const getContent = getContentForType(assetType, {
    assetType,
    assetFriendlyType,
    assetName,
    owingName,
    owedName,
    isJointlyOwned,
    ...extraData,
  });

  const splitOption = isLiability ? 'discharge' : 'splitEvenly';

  if (canOnlySplit) {
    cards.push({
      option: TRANSFER_OPTION_SPLIT_EVENLY,
      title: getContent(splitOption, 'title'),
      description: getContent(splitOption, 'description'),
      info: getContent(splitOption, 'info'),
      selectedDescription: getContent(splitOption, 'selectedDescription'),
      statement: getContent(splitOption, 'statement'),
      statementOwed: getContent(splitOption, 'statementOwed'),
    });
  } else {
    cards.push({
      option: TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING,
      title: getContent('jointToOwing', 'title'),
      description: getContent('jointToOwing', 'description'),
      info: getContent('jointToOwing', 'info'),
      selectedDescription: getContent('jointToOwing', 'selectedDescription'),
      statement: getContent('jointToOwing', 'statement'),
      statementOwed: getContent('jointToOwing', 'statementOwed'),
    });
    cards.push({
      option: TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED,
      title: getContent('jointToOwed', 'title'),
      description: getContent('jointToOwed', 'description'),
      info: getContent('jointToOwed', 'info'),
      selectedDescription: getContent('jointToOwed', 'selectedDescription'),
      statement: getContent('jointToOwed', 'statement'),
      statementOwed: getContent('jointToOwed', 'statementOwed'),
    });

    if (canSell) {
      cards.push({
        option: TRANSFER_OPTION_SELL,
        title: getContent('sell', 'title'),
        description: getContent('sell', 'description'),
        info: getContent('sell', 'info'),
        selectedDescription: getContent('sell', 'selectedDescription'),
        statement: getContent('sell', 'statement'),
        statementOwed: getContent('sell', 'statementOwed'),
      });
    }

    if (canSplit) {
      cards.push({
        option: TRANSFER_OPTION_SPLIT_EVENLY,
        title: getContent(splitOption, 'title'),
        description: getContent(splitOption, 'description'),
        info: getContent(splitOption, 'info'),
        selectedDescription: getContent(splitOption, 'selectedDescription'),
        statement: getContent(splitOption, 'statement'),
        statementOwed: getContent(splitOption, 'statementOwed'),
      });
    }
  }

  return cards;
};

export const buildSoleTransferrableCards = (
  assetType,
  assetFriendlyType,
  assetName,
  owingName,
  owedName,
  { canSell = true, isJointlyOwned = false, owner, isOwingParty }
) => {
  const cards = [];

  const getContent = getContentForType(assetType, {
    assetType,
    assetFriendlyType,
    assetName,
    owingName,
    owedName,
    isJointlyOwned,
  });

  if (
    (isOwnedByMe(owner) && !isOwingParty) ||
    (!isOwnedByMe(owner) && isOwingParty)
  ) {
    cards.push({
      option: TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING,
      title: getContent('soleToOwing', 'title'),
      description: getContent('soleToOwing', 'description'),
      info: getContent('soleToOwing', 'info'),
      selectedDescription: getContent('soleToOwing', 'selectedDescription'),
      statement: getContent('soleToOwing', 'statement'),
      statementOwed: getContent('soleToOwing', 'statementOwed'),
    });
  } else {
    cards.push({
      option: TRANSFER_OPTION_TRANSFER_SOLE_TO_OWED,
      title: getContent('soleToOwed', 'title'),
      description: getContent('soleToOwed', 'description'),
      info: getContent('soleToOwed', 'info'),
      selectedDescription: getContent('soleToOwed', 'selectedDescription'),
      statement: getContent('soleToOwed', 'statement'),
      statementOwed: getContent('soleToOwed', 'statementOwed'),
    });
  }

  if (canSell) {
    cards.push({
      option: TRANSFER_OPTION_SELL,
      title: getContent('sell', 'title'),
      description: getContent('sell', 'description'),
      info: getContent('sell', 'info'),
      selectedDescription: getContent('sell', 'selectedDescription'),
      statement: getContent('sell', 'statement'),
      statementOwed: getContent('sell', 'statementOwed'),
    });
  }

  return cards;
};

export const getBuildTransferrableCardsFunction = isJointlyOwned =>
  isJointlyOwned ? buildJointTransferrableCards : buildSoleTransferrableCards;

// get the value from a card with an optional custom additional check
// this is used for cases where the value in the database might not be correct
// e.g. if a user entered superannuation but then updated the card to say that
// they don't have superannuation, the value persists in the database so we also
// need to check that they answered yes to the superannuation question
const getValueWithAdditionalCheck = (item, dataKey, additionalCheck) => {
  if (additionalCheck && Array.isArray(additionalCheck)) {
    const [checkKey, checkValue] = additionalCheck;

    return item[checkKey] === checkValue ? item[dataKey] || 0 : 0;
  }

  return item[dataKey] || 0;
};

export const getSoleAssetValue = (
  {
    parties,
    isOwingParty,
    isTransferrable = false,
    isLiability = false,
    combineValues = false,
    additionalCheck,
  },
  groupName,
  type,
  baseSection,
  dataKey
) => {
  const items = MatterProps('items', []);
  const names = getNamesForContent(parties, isOwingParty);

  const valueModifier = 1;

  const owingItem =
    items.find(item => item.SectionID === `${baseSection}${parties.owing}`) ||
    {};
  const owedItem =
    items.find(item => item.SectionID === `${baseSection}${parties.owed}`) ||
    {};

  // quit out if theres no value as it means nothing was entered for the item
  if (owingItem[dataKey] === undefined && owedItem[dataKey] === undefined) {
    return [];
  }

  const owingValue = getValueWithAdditionalCheck(
    owingItem,
    dataKey,
    additionalCheck
  );
  const owedValue = getValueWithAdditionalCheck(
    owedItem,
    dataKey,
    additionalCheck
  );

  let selfItems = [];
  let otherItems = [];

  if (Array.isArray(owingValue) || Array.isArray(owedValue)) {
    const selfData = calculateMultiItemsValue(owingValue);

    const base = {
      type,
      repeatable: false,
      isJointlyOwned: false,
      isMultiItem: true,
      isLiability,
      isTransferrable,
    };

    selfItems = selfData.items.map((item, index) => ({
      ...base,
      id: `${type}${parties.owing}${index}`,
      assetId: [`${baseSection}${parties.owing}`, dataKey, index],
      name: item.text,
      [parties.owing]: Number(item.number) * valueModifier,
      [parties.owed]: 0,
      cards: isTransferrable
        ? buildSoleTransferrableCards(
            type,
            item.text,
            item.text,
            names[parties.owing],
            names[parties.owed],
            {
              canSell: false,
              isJointlyOwned: false,
              owner: parties.owing,
              isOwingParty,
            }
          )
        : undefined,
    }));

    const otherData = calculateMultiItemsValue(owedValue);

    otherItems = otherData.items.map((item, index) => ({
      ...base,
      id: `${type}${parties.owed}${index}`,
      assetId: [`${baseSection}${parties.owed}`, dataKey, index],
      name: item.text,
      [parties.owing]: 0,
      [parties.owed]: Number(item.number) * valueModifier,
      cards: isTransferrable
        ? buildSoleTransferrableCards(
            type,
            item.text,
            item.text,
            names[parties.owing],
            names[parties.owed],
            {
              canSell: false,
              isJointlyOwned: false,
              owner: parties.owed,
              isOwingParty,
            }
          )
        : undefined,
    }));

    return [...selfItems, ...otherItems];
  }

  const base = {
    type,
    name: groupName,
    repeatable: false,
    isLiability,
    isTransferrable,
    isJointlyOwned: false,
  };

  // if this asset is marked as combineValues, it means we should combine both parties
  // value for this item in to one row instead of two separate rows
  if (combineValues) {
    return [
      {
        ...base,
        id: `${type}${parties.owing}`,
        assetId: [`${baseSection}${parties.owing}`, dataKey],
        [parties.owing]: Number(owingValue) * valueModifier,
        [parties.owed]: Number(owedValue) * valueModifier,
      },
    ];
  }

  return [
    {
      ...base,
      id: `${type}${parties.owing}`,
      assetId: [`${baseSection}${parties.owing}`, dataKey],
      [parties.owing]: Number(owingValue) * valueModifier,
      [parties.owed]: 0,
    },
    {
      ...base,
      id: `${type}${parties.owed}`,
      assetId: [`${baseSection}${parties.owed}`, dataKey],
      [parties.owing]: 0,
      [parties.owed]: Number(owedValue) * valueModifier,
    },
  ];
};

export const getJointAssetValue = (
  {
    onlyValues,
    parties,
    isOwingParty = false,
    isTransferrable = false,
    isLiability = false,
    canSplit = false,
    canOnlySplit = false,
  },
  groupName,
  type,
  baseSection,
  dataKey
) => {
  const items = MatterProps('items', []);
  const names = getNamesForContent(parties, isOwingParty);

  const valueModifier = 1;

  const item = (items.find(i => i.SectionID === baseSection) || {})[dataKey];

  const base = {
    type,
    repeatable: false,
    isJointlyOwned: true,
    isLiability,
    isTransferrable,
  };

  // quit out if theres no value as it means nothing was entered for the item
  if (item === undefined) {
    return [];
  }

  const value = item || 0;

  // multi items
  if (Array.isArray(value)) {
    const assetData = calculateMultiItemsValue(value);

    return assetData.items.map((item, index) => {
      const data = {
        ...base,
        [parties.owing]: (Number(item.number) / 2) * valueModifier,
        [parties.owed]: (Number(item.number) / 2) * valueModifier,
      };

      if (onlyValues) {
        return data;
      }

      return {
        ...data,
        id: `${type}${index}`,
        assetId: [baseSection, type, index],
        name: item.text,
        isMultiItem: true,
        cards: isTransferrable
          ? buildJointTransferrableCards(
              groupName,
              item.text,
              item.text,
              names[parties.owing],
              names[parties.owed],
              {
                canSell: false,
                canSplit,
                canOnlySplit,
                isLiability,
              }
            )
          : undefined,
      };
    });
  }

  const amount = (Number(value) / 2) * valueModifier;

  const data = {
    ...base,
    [parties.owing]: amount,
    [parties.owed]: amount,
  };

  if (onlyValues) {
    return [data];
  }

  return [
    {
      ...data,
      id: type,
      assetId: [baseSection, type],
      name: groupName,
      cards: isTransferrable
        ? buildJointTransferrableCards(
            groupName,
            type,
            type,
            names[parties.owing],
            names[parties.owed],
            {
              canSell: false,
              canSplit: isLiability,
              isLiability,
            }
          )
        : undefined,
    },
  ];
};

export const getSharesValue = opts => {
  const data = getSoleAssetValue(
    opts,
    'Shares',
    'shares',
    'yourfinances',
    'shares'
  );

  return data.map(item => ({
    ...item,
    isJointlyOwned: false,
  }));
};

export const getPropertyValues = ({
  onlyValues,
  parties,
  isOwingParty,
  isTransferrable = false,
}) => {
  const items = MatterProps('items', []);
  const names = getNamesForContent(parties, isOwingParty);

  const properties = items.filter(
    item => item.BaseSection === 'properties' && item.status !== STATUS_REMOVED
  );

  return properties.map(asset => {
    const [owingValue, owedValue] = getSplitAmounts(
      parties,
      asset.propertyTitle,
      asset.propertyValue
    );
    const [selfMortgage, otherMortgage] = getSplitAmounts(
      parties,
      asset.propertyTitle,
      asset.propertyMortgage
    );
    const selfEquity = owingValue - selfMortgage;
    const otherEquity = owedValue - otherMortgage;

    const isJointlyOwned = getIsJointlyOwned(owingValue, owedValue);

    const data = {
      type: 'property',
      [parties.owing]: selfEquity,
      [parties.owed]: otherEquity,
    };

    if (onlyValues) {
      return data;
    }

    return {
      ...data,
      isJointlyOwned,
      repeatable: true,
      isLiability: false,
      isTransferrable,
      id: asset.SectionID,
      assetId: [`${asset.BaseSection}${asset.CardIndex}`, 'propertyValue'],
      mortgageId: `mortgages${asset.CardIndex}`,
      mortgageAssetId: [`mortgages${asset.CardIndex}`, 'propertyWhoseName'],
      name: asset.propertyAddress,
      onlyValue: {
        [parties.owing]: owingValue,
        [parties.owed]: owedValue,
      },
      onlyMortgage: {
        [parties.owing]: selfMortgage,
        [parties.owed]: otherMortgage,
      },
      cards: isTransferrable
        ? getBuildTransferrableCardsFunction(isJointlyOwned)(
            'property',
            'property',
            asset.propertyAddress,
            names[parties.owing],
            names[parties.owed],
            {
              canSell: true,
              canSplit: false,
              isJointlyOwned,
              owner: asset.propertyTitle,
              isOwingParty,
            }
          )
        : undefined,
    };
  });
};

export const getVehicleValues = ({
  onlyValues,
  parties,
  isOwingParty,
  isTransferrable = false,
}) => {
  const items = MatterProps('items', []);
  const names = getNamesForContent(parties, isOwingParty);

  const vehicles = items.filter(
    item => item.BaseSection === 'vehicles' && item.status !== STATUS_REMOVED
  );

  return vehicles.map(vehicle => {
    const [selfSplit, otherSplit] = getSplitAmounts(
      parties,
      vehicle.vehicleOwner,
      vehicle.vehicleValue
    );
    const isJointlyOwned = getIsJointlyOwned(selfSplit, otherSplit);

    const data = {
      type: 'vehicle',
      [parties.owing]: selfSplit,
      [parties.owed]: otherSplit,
    };

    if (onlyValues) {
      return data;
    }

    return {
      ...data,
      isTransferrable,
      isJointlyOwned,
      repeatable: true,
      isLiability: false,
      id: vehicle.SectionID,
      assetId: [`${vehicle.BaseSection}${vehicle.CardIndex}`, 'vehicleValue'],
      name: vehicle.vehicleRego || vehicle.vehicleMakeModel,
      cards: isTransferrable
        ? getBuildTransferrableCardsFunction(isJointlyOwned)(
            'vehicle',
            'vehicle',
            vehicle.vehicleRego || vehicle.vehicleMakeModel,
            names[parties.owing],
            names[parties.owed],
            {
              canSell: false,
              canSplit: false,
              isJointlyOwned,
              owner: vehicle.vehicleOwner,
              isOwingParty,
            }
          )
        : undefined,
    };
  });
};

// takes the assets from two offers and returns an object of added, updated, removed, and unchanged assets
export const getOfferDiff = (
  offerAssets,
  lastOfferAssets,
  { isAssetSplitCompleted = false } = {}
) => {
  // if asset split is completed, return them all as unchanged to avoid confusing styling
  if (isAssetSplitCompleted) {
    return {
      added: [],
      updated: [],
      removed: [],
      unchanged: offerAssets,
    };
  }

  const added = offerAssets.filter(
    asset => !lastOfferAssets.find(lastAsset => asset.id === lastAsset.id)
  );

  const updated = offerAssets.filter(asset => {
    const prevOfferAsset = lastOfferAssets.find(
      lastAsset => asset.id === lastAsset.id
    );

    return (
      prevOfferAsset && asset.selectedOption !== prevOfferAsset.selectedOption
    );
  });

  const removed = lastOfferAssets.filter(
    lastAsset => !offerAssets.find(asset => asset.id === lastAsset.id)
  );

  const unchanged = offerAssets.filter(
    asset =>
      ![...added, ...updated, ...removed].find(
        handledAsset => handledAsset.id === asset.id
      )
  );

  return {
    added,
    updated,
    removed,
    unchanged,
  };
};

// get the party who this asset is transferring to
export const getReceivingPartyForAsset = (asset = {}) => {
  const { option } = asset;
  const { owingParty, owedParty } = MatterProps('divi', {});

  if (
    option === TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED ||
    option === TRANSFER_OPTION_TRANSFER_SOLE_TO_OWED
  ) {
    return owedParty;
  }

  if (
    option === TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING ||
    option === TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING
  ) {
    return owingParty;
  }

  return PARTY_NONE;
};
