import React from 'react';

import formatCurrency from 'Common/Utils/formatCurrency';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import { PARTY_A, PARTY_B, DEFAULT_OTHER_TEXT, Party } from 'Common/constants';
import capitaliseText from 'Common/Utils/capitaliseText';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
  getSingleUserFullName,
} from 'Common/Utils/statementHelpers';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import { Statement, QuestionAny } from 'Common/Data/Types/appSections';
import Matter from 'Common/Data/Types/matter';

export const vehiclesLabel = 'Vehicles';

export const vehiclesQuestions: QuestionAny[] = [
  {
    name: 'intro',
    heading: (matter: Matter) => (
      <H1Conversational>
        Hi {getSingleUserFullName(matter)}, we’re going to ask you about the
        vehicles that you owned or shared with your former partner.
      </H1Conversational>
    ),
    content: () => (
      <Paragraph>
        These questions are important because we will base our suggestions for a
        fair division on the information you give.
      </Paragraph>
    ),
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'vehicleOwner',
    label: 'Who is the owner of this vehicle?',
    hint: '',
    type: 'select',
    options: _MatterPropsJS => [
      { label: 'I am', value: _MatterPropsJS('self.party') },
      {
        label: `${capitaliseText(
          _MatterPropsJS('other.firstname', DEFAULT_OTHER_TEXT)
        )}`,
        value: _MatterPropsJS('other.party', PARTY_A),
      },
      { label: 'Both of us', value: 'both' },
    ],
    defaultValue: '',
    index: 1,
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            The owner of the vehicle is the person who owns it or was given it as a gift.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'vehicleValue',
    label: 'How much is it worth?',
    hint: '',
    type: 'currency',
    defaultValue: '',
    index: 2,
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            This is the second-hand market value of your vehicle today, not the
            value it was when you originally purchased it.
          </Paragraph>
          <Paragraph large>
            You can check the market value of your car on{' '}
            <ReferralLink href="https://www.redbook.com.au">
              https://www.redbook.com.au
            </ReferralLink>
            .
          </Paragraph>
          <Paragraph large>
            You might need to get the vehicle formally valued by a professional,
            especially if it is a collector&apos;s, classic or speciality
            vehicle.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 3,
  },
];

export const vehiclesStatement = ({
  matter,
  data,
  card,
  plainText = false,
  withUpdateLink = false,
  hideSensitive = false,
}: Statement) => {
  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'vehicleOwner',
    'vehicleValue',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(vehiclesQuestions, data, hideSensitive);

  const partyNames = {
    A: capitaliseText(getFirstName(matter.partyA, PARTY_A, hideSensitive)),
    B: capitaliseText(getFirstName(matter.partyB, PARTY_B, hideSensitive)),
  };

  let owner;

  if (data.vehicleOwner === 'both') {
    owner = highlight`${updated('vehicleOwner', partyNames.A)} and ${updated(
      'vehicleOwner',
      DEFAULT_OTHER_TEXT
    )} jointly own a`;
  } else {
    owner = highlight`${updated(
      'vehicleOwner',
      partyNames[data.vehicleOwner as Party]
    )} ${data.vehicleOwner === PARTY_B ? ' owns a' : ' own a'}`;
  }

  const vehicle = highlight` vehicle that is worth ${updated(
    'vehicleValue',
    redact('vehicleValue', formatCurrency(data.vehicleValue))
  )}.`;

  if (plainText) {
    return `${owner} ${vehicle}`;
  }

  return (
    <>
      {owner}
      {vehicle}
    </>
  );
};
