import React from 'react';
import styled from 'styled-components';
import Paragraph from 'Common/UI/Text/Paragraph';

type Props = {
  className?: string;
  children: React.ReactNode;
};

const ValidationMessage: React.FC<Props> = ({ children, className }) => (
  <div className={className} data-cy="input-validation-error">
    <Paragraph>{children}</Paragraph>
  </div>
);

const StyledValidationMessage = styled(ValidationMessage)`
  background-color: ${props => props.theme.colours.orange};
  padding: 6px 16px;
  display: block;
  border-radius: 4px;
  background: ${props => props.theme.colours.orange};
  padding: 10px 13px 9px;

  ${Paragraph} {
    margin: 0;
  }
`;

export default StyledValidationMessage;
