import React from 'react';
import styled from 'styled-components';

import Paragraph from 'Common/UI/Text/Paragraph';
import { jurisdictionStateLocationsMap } from 'Common/Utils/jurisdictionHelpers';

export const TextBlock = styled.div`
  display: block;
  margin-top: ${({ theme }) => theme.padding.small}px;
  margin-bottom: ${({ theme }) => theme.padding.medium}px;

  ${Paragraph} {
    font-size: 16px;
  }
  > ${Paragraph} {
    font-weight: bold;
    margin: ${({ theme }) => theme.padding.xxsmall}px 0;
  }
  > div > ${Paragraph} {
    margin: 0;
  }
`;

export const JurisdictionListPopUpContent = () => (
  <>
    {Object.keys(jurisdictionStateLocationsMap).map(state => (
      <TextBlock key={state}>
        <Paragraph large>{state}</Paragraph>
        <div>
          {jurisdictionStateLocationsMap[state].map(court => (
            <Paragraph key={court}>{court}</Paragraph>
          ))}
        </div>
      </TextBlock>
    ))}
  </>
);
