import React from 'react';
import styled from 'styled-components';

import { Theme } from 'Common/Utils/theme';
import GenericButton from './GenericButton';
import { SpacerProps } from '../Layout/spacer';

const StyledImage = styled.img`
  margin: 0 ${({ theme }) => theme.spacing[2]};
`;

const IconImage: React.FC<{ src: string }> = ({ src, ...props }) => (
  <StyledImage
    {...props}
    style={{ verticalAlign: 'middle' }}
    alt=""
    aria-hidden
    src={src}
  />
);

const StyledIconTextButton = styled(GenericButton).attrs((props: any) => ({
  // this button is hardcoded to be small and I needed to use it as a large button and didn't want to break the existing implementation or add a new button type
  // so instead I added this prop to allow it to be large but keep it as small by default
  small: !props.$normalSize,
}))<{
  theme: Theme;
  display?: string;
}>`
  display: ${({ display }) => display || 'flex'};
  align-items: center;
  justify-content: center;
  min-height: 40px;
  text-decoration: none;

  &:disabled {
    background: ${props => props.theme.colours.inactiveGrey} !important;
    color: ${props => props.theme.colours.black} !important;
  }
`;

type Props = {
  className?: string;
  disabled?: boolean;
  icon: string;
  as?: any; // TODO work out what type this should be
  href?: string;
  fullWidth?: boolean;
  subtle?: boolean;
  help?: boolean;
  display?: string;
  iconOnLeft?: boolean;
  small?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  $normalSize?: boolean;
  reverse?: boolean;
};

const IconTextButton: React.FC<Props & SpacerProps> = ({
  children,
  icon,
  iconOnLeft = false,
  ...props
}) => (
  <StyledIconTextButton {...props}>
    {iconOnLeft && <IconImage src={icon} />}
    {children}
    {!iconOnLeft && <IconImage src={icon} />}
  </StyledIconTextButton>
);

export default IconTextButton;
