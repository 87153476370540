import React from 'react';
import styled from 'styled-components';
import { PartyKeys, PARTY_BOTH } from 'Common/constants';
import { Theme } from 'Common/Utils/theme';
import getNames from 'Common/Utils/getNames';
import { Label } from 'Common/UI/Text/Label';
import Paragraph from 'Common/UI/Text/Paragraph';
import IconBoth from 'Common/Assets/images/icon-users-both.svg';
import IconSelf from 'Common/Assets/images/icon-users-self.svg';
import IconOther from 'Common/Assets/images/icon-users-other.svg';
import CardContext from '../context';

const StatementWrap = styled.div<{
  theme: Theme;
}>`
  padding: ${props => props.theme.padding.xsmall}px
    ${props => props.theme.padding.small}px;
  background-color: ${props => props.theme.statementBackground};
  margin-bottom: 8px;
  border-radius: 4px;
  flex-grow: 1;
  position: relative;
`;

const StatementContent = styled(Paragraph)`
  margin-top: 0;
  margin-bottom: 0;
  overflow-wrap: break-word;
`;

const Date = styled(Label)<{
  theme: Theme;
}>`
  color: ${({ theme }) => theme.headingSideTextColour};
  margin: 0 0 ${({ theme }) => theme.spacing[4]};
`;

const WhoCanEdit = styled(Label)<{
  theme: Theme;
}>`
  margin-top: ${({ theme }) => theme.spacing[8]};
  display: flex;
  align-items: center;
  font-weight: bold;

  img {
    margin-right: ${({ theme }) => theme.spacing[4]};
  }
`;

type Props = {
  noBox?: boolean;
  date?: string;
  cardForParty?: PartyKeys;
  notEditable?: boolean;
};

const Statement: React.FC<Props> = ({
  noBox = false,
  date,
  cardForParty,
  notEditable = false,
}) => (
  <CardContext.Consumer>
    {({ statement: StatementElement }) =>
      noBox ? (
        <StatementContent as="div">
          <StatementElement />
        </StatementContent>
      ) : (
        <StatementWrap>
          {date && <Date>{date}</Date>}
          <StatementContent as="div">
            <StatementElement />
          </StatementContent>
          {!notEditable && cardForParty && (
            <WhoCanEdit>
              {cardForParty === PARTY_BOTH && (
                <>
                  <img src={IconBoth} alt="" />
                  You both can edit
                </>
              )}
              {cardForParty === 'self' && (
                <>
                  <img src={IconSelf} alt="" /> Only you can edit
                </>
              )}
              {cardForParty === 'other' && (
                <>
                  <img src={IconOther} alt="" /> Only {getNames().other} can
                  edit
                </>
              )}
            </WhoCanEdit>
          )}
        </StatementWrap>
      )
    }
  </CardContext.Consumer>
);

export default Statement;
