import {
  defaultFlags,
  generatePageAppStateMap,
} from 'App/AppState/generateAppState';
import { DashboardPages, Sections } from 'Common/constants';
import Matter from 'Common/Data/Types/matter';
import { updateAppState } from './AppReducer';
import { createAppAsyncThunk } from './Store';

export const generateAppStateAction = createAppAsyncThunk(
  'app/generateAppState',
  (
    {
      matter,
      section,
      page,
    }: {
      matter: Matter;
      section: Sections;
      page?: DashboardPages;
    },
    { dispatch }
  ) => {
    const appStateMap = generatePageAppStateMap(section, page);
    const appData = appStateMap.find(row => row.check(matter, section, page));
    // Use below to test appstates
    // const appData = appStateMap.find(state => state.id === 'parentingPaymentRequired');

    console.log(appData?.flags?.appState || '');

    if (appData) {
      dispatch(updateAppState({ ...defaultFlags, ...appData.flags }));
    } else {
      dispatch(updateAppState(defaultFlags));
    }
  }
);
