import React from 'react';
import styled, { css } from 'styled-components';

import { Theme } from 'Common/Utils/theme';
import RoundButton from 'Common/UI/Button/RoundButton';
import FormLabel from 'Common/UI/Form/FormLabel';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const YesNoButton = styled(RoundButton)<{
  theme: Theme;
  selected?: boolean;
}>`
  min-width: 120px;

  ${props =>
    !props.selected &&
    css`
      background: ${props.theme.colours.lightestGrey};
      color: ${props.theme.colours.black};
    `}

  margin-right: 16px;
`;

type Props = {
  onChange: (value: boolean) => void;
  label?: string | JSX.Element;
  name: string;
  value?: boolean;
  disabled?: boolean;
};

const YesNo: React.FC<Props> = ({
  onChange,
  label = '',
  name,
  value = null,
  disabled = false,
}) => {
  const onInputChange = (e: any, changeValue: boolean) => {
    e.preventDefault();
    e.stopPropagation();

    onChange(changeValue);
  };

  return (
    <div>
      <label htmlFor={name}>
        <FormLabel label={label} />
        <ButtonContainer>
          <YesNoButton
            selected={value === true}
            onClick={e => onInputChange(e, true)}
            disabled={disabled}
            subtle
          >
            Yes
          </YesNoButton>
          <YesNoButton
            selected={value === false}
            onClick={e => onInputChange(e, false)}
            disabled={disabled}
            subtle
          >
            No
          </YesNoButton>
        </ButtonContainer>
      </label>
    </div>
  );
};

export default YesNo;
