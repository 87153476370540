import React from 'react';
import styled from 'styled-components';
import IconUpload from 'Common/Assets/images/icon-upload.svg';
import RoundButton from './RoundButton';

const StyledFileUploadButton = styled(RoundButton)`
  display: flex;
  flex-direction: row;
  align-content: space-between;
  min-width: 180px;
`;

const Label = styled.div`
  text-align: left;
  flex: 1;
`;

const Icon = styled.img`
  margin-left: ${({ theme }) => theme.spacing[2]};
`;

const FileUploadButton = ({ text = 'Select a file', ...props }) => (
  <StyledFileUploadButton
    subtle
    type="button"
    {...props}
    data-cy="select-file-upload-button"
  >
    <Label>{text}</Label>
    <Icon src={IconUpload} />
  </StyledFileUploadButton>
);

export default FileUploadButton;
