/* eslint-disable no-case-declarations */
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { v1 as uuidv1 } from 'uuid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Matter, {
  Item,
  Message,
  SingleUserMultiItemBaseSection,
  SuggestedDivisionRound,
} from 'Common/Data/Types/matter';

import {
  PARTY_A,
  PARTY_B,
  DEFAULT_SELF_TEXT,
  DEFAULT_OTHER_TEXT,
} from 'Common/constants';
import getChildrenData from 'Common/Utils/getChildrenData';
import capitaliseText from 'Common/Utils/capitaliseText';
import getFlagForMultiItem from 'App/State/Utils/getFlagForMultiItem';
import updateNumForMultiItem from './Utils/updateNumForMultiItem';

const initialState: Matter = {
  MatterID: uuidv1(),
  created: '',
  modified: '',
  version: '',
  flags: {},
  section: 'personal',
  isSetupComplete: true,
  lastRefreshed: moment.now(),
  hydrated: true,
  self: {
    firstname: capitaliseText(DEFAULT_SELF_TEXT),
    party: PARTY_A,
    sub: '',
    lastname: '',
    email: '',
  },
  partyA: {
    firstname: capitaliseText(DEFAULT_SELF_TEXT),
    party: PARTY_A,
    sub: '',
    lastname: '',
    email: '',
  },
  other: {
    firstname: capitaliseText(DEFAULT_OTHER_TEXT),
    party: PARTY_B,
    sub: '',
    lastname: '',
    email: '',
  },
  partyB: {
    firstname: capitaliseText(DEFAULT_OTHER_TEXT),
    party: PARTY_B,
    sub: '',
    lastname: '',
    email: '',
  },
  items: [],
  itemHistory: [],
  messages: [],
  childrenData: {
    raw: [],
    namesArray: [],
    haveBeenConfirmed: false,
    moreThanOneChild: false,
  },
  suggestedDivision: {},
};

const matterSlice = createSlice({
  name: 'matter',
  initialState: initialState as Matter,
  reducers: {
    matterIncrementChild(state) {
      return updateNumForMultiItem({
        state,
        countKey: 'numChildren',
        flag: 'haveChildren',
        counter: 1,
      });
    },
    matterDecrementChild(state) {
      return updateNumForMultiItem({
        state,
        countKey: 'numChildren',
        flag: 'haveChildren',
        counter: -1,
      });
    },
    matterIncrementProperty(state) {
      return updateNumForMultiItem({
        state,
        countKey: 'numProperties',
        flag: 'ownProperties',
        counter: 1,
      });
    },
    matterDecrementProperty(state) {
      return updateNumForMultiItem({
        state,
        countKey: 'numProperties',
        flag: 'ownProperties',
        counter: -1,
      });
    },
    matterIncrementVehicle(state) {
      return updateNumForMultiItem({
        state,
        countKey: 'numVehicles',
        flag: 'ownVehicles',
        counter: 1,
      });
    },
    matterDecrementVehicle(state) {
      return updateNumForMultiItem({
        state,
        countKey: 'numVehicles',
        flag: 'ownVehicles',
        counter: -1,
      });
    },
    matterLoadSuggestedDivision(state, action) {
      const suggestedDivision = action.payload;
      return {
        ...state,
        suggestedDivision,
      };
    },
    matterLoadMatterSuccess(_state, action: PayloadAction<{ matter: Matter }>) {
      const { matter } = action.payload;

      let messages: any = [];
      const divi = { ...matter.divi } || {};
      let mergedRounds = [];

      // add an empty object for matter flags if it doesn't exist
      // to prevent errors when trying to read them in the app
      if (!matter.flags) {
        matter.flags = {};
      }

      if (matter && matter.messages) {
        messages = matter.messages.map((message: Message) => {
          const [section, date] = message.MessageID.split('|');

          return {
            ...message,
            section,
            date,
          };
        });
      }

      // join division messages to rounds
      if (divi && divi.rounds) {
        mergedRounds = divi.rounds.map(
          (round: SuggestedDivisionRound, index: number) => {
            const roundMessage = messages.find(
              (message: Message) => message.section === `division${index + 1}`
            );

            return { ...round, message: roundMessage };
          }
        );
        divi.rounds = mergedRounds;
      }

      const childrenData = getChildrenData(matter.items);

      const other = { ...matter.other } || {};
      const partyB = { ...matter.partyB } || {};

      // if the other party hasn't been invited yet, set their firstname to `your former partner` instead of null
      if (!matter.other.email) {
        other.firstname = DEFAULT_OTHER_TEXT;
        partyB.firstname = DEFAULT_OTHER_TEXT;
      }

      // @TODO remove this! i'm just too lazy to click 3 buttons to get the ItemID so I added this
      (window as any).m = () => console.log(matter.MatterID);

////////////////////////////////////
////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////

///////////////
//////////////////
///////////////////
///////////////////
//////////////////////
//////////////////////////
//////////////////////////////////
////////////////////////////////
////////////////////////////////////
/////////////////////////
////////////
///////////////////
//////////////////////
//////////////////////////
//////////////////////////////////
////////////////////////////////
////////////////////////////////////
/////////////////////////
////////////
////////////////
//////////////////
///////////////////

/////////////////////////////
//////////////////
/////////////////////////////////
//////////////////////////////
//////////////////
///////////////////
/////////////////////////////
/////////////////////////////
//////////

//////////////////////////////
//////////////////////////////
/////////////////////////
////////////////////////////
//////////////////////
//////////

////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////
///////////
//////////
////////
////////////////

      const newMatter: Matter = {
        ...matter,
        messages,
        divi,
        childrenData,
        other,
        partyB,
      };

      const { MatterID, self: { sub = undefined } = {} } = matter;

      Sentry.setUser({
        matterID: MatterID,
        id: sub,
      });

      return { ...newMatter, lastRefreshed: moment.now() };
    },
    matterRemoveItem(state, action: PayloadAction<{ item: Item }>) {
      const newState = { ...state };

      const { item } = action.payload;

      // @TODO remove this typecasting and make it work properly
      const itemFlag = getFlagForMultiItem(
        item.BaseSection as SingleUserMultiItemBaseSection
      );

      return {
        ...newState,
        items: state.items.filter(
          stateItem => stateItem.SectionID !== item.SectionID
        ),
        flags: {
          ...state.flags,
          ...(itemFlag && { [itemFlag]: false }),
        },
      };
    },
    clearMatterStore() {
      return initialState;
    },
  },
});

export const {
  matterIncrementChild,
  matterDecrementChild,
  matterIncrementProperty,
  matterDecrementProperty,
  matterIncrementVehicle,
  matterDecrementVehicle,
  matterLoadSuggestedDivision,
  matterLoadMatterSuccess,
  matterRemoveItem,
  clearMatterStore,
} = matterSlice.actions;

export default matterSlice.reducer;
