// January 14, 2021 00:00:00 GMT+10:00
export const PAYMENT_LAUNCH_DATE = '2021-01-13T14:00:00.000Z';

// App Section ID
export const APP_SECTION_ID_CONSENT_ORDER = 'APP_SECTION_ID_CONSENT_ORDER';
export const APP_SECTION_ID_DASHBOARD = 'APP_SECTION_ID_DASHBOARD';

// SECTIONS
export const SECTION_HOME = 'SECTION_HOME';
export const SECTION_RELATIONSHIP = 'SECTION_RELATIONSHIP';
export const SECTION_FINANCES = 'SECTION_FINANCES';
export const SECTION_PARENTING = 'SECTION_PARENTING';
export const SECTION_PROPERTY = 'SECTION_PROPERTY';
export const SECTION_CONSENT_ORDERS = 'SECTION_CONSENT_ORDERS';
export const SECTION_SUGGESTED_DIVISION = 'SECTION_SUGGESTED_DIVISION';

export type Sections =
  | 'SECTION_HOME'
  | 'SECTION_RELATIONSHIP'
  | 'SECTION_PARENTING'
  | 'SECTION_PROPERTY'
  | 'SECTION_CONSENT_ORDERS'
  | 'SECTION_FINANCES'
  | 'SECTION_SUGGESTED_DIVISION';

export const SECTIONS = [
  SECTION_HOME,
  SECTION_RELATIONSHIP,
  SECTION_PARENTING,
  SECTION_PROPERTY,
  SECTION_CONSENT_ORDERS,
  SECTION_FINANCES,
];

export const PAGE_RELATIONSHIP_MAIN = 'PAGE_RELATIONSHIP_MAIN';
export const PAGE_PROPERTY_MAIN = 'PAGE_PROPERTY_MAIN';
export const PAGE_SUGGESTED_DIVISION_MAIN = 'PAGE_SUGGESTED_DIVISION_MAIN';

export const PAGE_PROPERTY_MONEY_AND_PROPERTY =
  'PAGE_PROPERTY_MONEY_AND_PROPERTY';
export const PAGE_PROPERTY_CONTRIBUTIONS = 'PAGE_PROPERTY_CONTRIBUTIONS';
export const PAGE_PROPERTY_ASSET_DIVISION = 'PAGE_PROPERTY_ASSET_DIVISION';
export const PAGE_PROPERTY_COMPLETE = 'PAGE_PROPERTY_COMPLETE';

export const PAGE_PARENTING_ESSENTIALS = 'PAGE_PARENTING_ESSENTIALS';
export const PAGE_PARENTING_SCHEDULE = 'PAGE_PARENTING_SCHEDULE';
export const PAGE_PARENTING_ADDITIONS = 'PAGE_PARENTING_ADDITIONS';
export const PAGE_PARENTING_COMPLETE = 'PAGE_PARENTING_COMPLETE';

export const PAGE_CONSENT_ORDERS_GET_STARTED =
  'PAGE_CONSENT_ORDERS_GET_STARTED';
export const PAGE_CONSENT_ORDERS_RELATIONSHIP =
  'PAGE_CONSENT_ORDERS_RELATIONSHIP';
export const PAGE_CONSENT_ORDERS_ASSETS = 'PAGE_CONSENT_ORDERS_ASSETS';
export const PAGE_CONSENT_ORDERS_FINANCES = 'PAGE_CONSENT_ORDERS_FINANCES';
export const PAGE_CONSENT_ORDERS_COMPLETED = 'PAGE_CONSENT_ORDERS_COMPLETED';

export const PAGE_FINANCES_MAIN = 'PAGE_FINANCES_MAIN';

export type DashboardPages =
  | 'PAGE_RELATIONSHIP_MAIN'
  | 'PAGE_PROPERTY_MONEY_AND_PROPERTY'
  | 'PAGE_PROPERTY_CONTRIBUTIONS'
  | 'PAGE_PROPERTY_ASSET_DIVISION'
  | 'PAGE_PROPERTY_COMPLETE'
  | 'PAGE_PARENTING_ESSENTIALS'
  | 'PAGE_PARENTING_SCHEDULE'
  | 'PAGE_PARENTING_ADDITIONS'
  | 'PAGE_PARENTING_COMPLETE'
  | 'PAGE_CONSENT_ORDERS_GET_STARTED'
  | 'PAGE_CONSENT_ORDERS_RELATIONSHIP'
  | 'PAGE_CONSENT_ORDERS_ASSETS'
  | 'PAGE_CONSENT_ORDERS_FINANCES'
  | 'PAGE_CONSENT_ORDERS_COMPLETED'
  | 'PAGE_RELATIONSHIP_MAIN'
  | 'PAGE_FINANCES_MAIN'
  | 'PAGE_PROPERTY_MAIN'
  | 'PAGE_SUGGESTED_DIVISION_MAIN';

// how long to wait in seconds before refreshing the matter on page load

export const MATTER_REFRESH_PERIOD = 60;

// default text
export const DEFAULT_OTHER_TEXT = 'your former partner';

export const DEFAULT_OTHER_THIRD_PERSON_TEXT = 'their former partner';
export const DEFAULT_SELF_TEXT = 'you';

export const EnumParty = {
  A: 'A' as const,
  B: 'B' as const,
  Both: 'BOTH' as const,
  Divi: 'DIVI' as const,
  None: 'NONE' as const,
};

export const EnumPartyKeys = {
  Self: 'self' as const,
  Other: 'other' as const,
  Both: 'BOTH' as const,
};

export type Party = 'A' | 'B';
export type PartyAny = Party | 'BOTH' | 'DIVI' | 'NONE' | 'both';
export type PartyKeys = 'self' | 'other' | 'BOTH';

// PARTY
export const PARTY_A = EnumParty.A;
export const PARTY_B = EnumParty.B;
export const PARTY_BOTH = EnumParty.Both;
export const PARTY_DIVI = EnumParty.Divi;
export const PARTY_NONE = EnumParty.None;
export const PARTY_SELF = EnumPartyKeys.Self;
export const PARTY_OTHER = EnumPartyKeys.Other;

// DATE
export const DATE_FORMAT_SHORT = 'D MMM';
export const DATE_FIELD_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_SHORT_WITH_TIME = `${DATE_FORMAT_SHORT}, LT`;
export const DATE_FORMAT_LONG = 'DD MMMM, YYYY';
export const TIME_HOUR_MINUTE_FORMAT = 'HH:mm';
export const TIME_HOUR_MINUTE_AM_PM_FORMAT = 'h:mm a';

// Suggested Division diff percents
export const SUGGESTED_DIVISION_DIFF_WARNING_PERCENT = 0.05;
export const SUGGESTED_DIVISION_DIFF_INVALID_PERCENT = 0.2;

// Generic App States

export const CONTENT_SHOW = 'CONTENT_SHOW';
export const CONTENT_HIDE = 'CONTENT_HIDE';
export const BANNER_HIDE = 'BANNER_HIDE';
export const BANNER_SHOW = 'BANNER_SHOW';
export const DASHBOARD_TAB_LOCKED = 'DASHBOARD_TAB_LOCKED';

// Check Invite Party
export const INVITE_OTHER_PARTY = 'INVITE_OTHER_PARTY';

// Relationship
export const RELATIONSHIP_AGREEMENT_MADE = 'RELATIONSHIP_AGREEMENT_MADE';
export const RELATIONSHIP_LOCKED = 'RELATIONSHIP_LOCKED';
export const RELATIONSHIP_DASHBOARD_COMPLETED =
  'RELATIONSHIP_DASHBOARD_COMPLETED';
export const RELATIONSHIP_DASHBOARD_COMPLETED_AND_LOCKED =
  'RELATIONSHIP_DASHBOARD_COMPLETED_AND_LOCKED';

// Parenting
export const PARENTING_PLAN_HAVE_NO_CHILDREN =
  'PARENTING_PLAN_HAVE_NO_CHILDREN';
export const PARENTING_PLAN_START = 'PARENTING_PLAN_START';
export const PARENTING_PLAN_AGREED = 'PARENTING_PLAN_AGREED';

// Parenting Agreement
export const PARENTING_PLAN_WAITING_FOR_OTHER =
  'PARENTING_PLAN_WAITING_FOR_OTHER';
export const PARENTING_PLAN_NEEDS_CONFIRMATION =
  'PARENTING_PLAN_NEEDS_CONFIRMATION';
export const PARENTING_PLAN_SELF_NOT_READY = 'PARENTING_PLAN_SELF_NOT_READY';
export const PARENTING_PLAN_OTHER_NOT_READY = 'PARENTING_PLAN_OTHER_NOT_READY';
export const PARENTING_PLAN_PAYMENT_REQUIRED =
  'PARENTING_PLAN_PAYMENT_REQUIRED';

// Parenting Plan
export const NEW_PARENTING_PLAN_SELF_NOT_READY =
  'NEW_PARENTING_PLAN_SELF_NOT_READY';
export const NEW_PARENTING_PLAN_OTHER_NOT_READY =
  'NEW_PARENTING_PLAN_OTHER_NOT_READY';
export const NEW_PARENTING_PLAN_WAITING_FOR_OTHER =
  'NEW_PARENTING_PLAN_WAITING_FOR_OTHER';
export const NEW_PARENTING_PLAN_NEEDS_CONFIRMATION =
  'NEW_PARENTING_PLAN_NEEDS_CONFIRMATION';

// Suggested Division
export const SUGGESTED_DIVISION_AGREED = 'SUGGESTED_DIVISION_AGREED';
export const SUGGESTED_DIVISION_LOCKED = 'SUGGESTED_DIVISION_LOCKED';
export const SUGGESTED_DIVISION_OTHER_MADE_OFFER =
  'SUGGESTED_DIVISION_OTHER_MADE_OFFER';
export const SUGGESTED_DIVISION_READY = 'SUGGESTED_DIVISION_READY';
export const SUGGESTED_DIVISION_START = 'SUGGESTED_DIVISION_START';
export const SUGGESTED_DIVISION_WAITING = 'SUGGESTED_DIVISION_WAITING';
export const SUGGESTED_DIVISION_YOU_MADE_OFFER =
  'SUGGESTED_DIVISION_YOU_MADE_OFFER';
export const SUGGESTED_DIVISION_OTHER_MADE_COUNTER_OFFER =
  'SUGGESTED_DIVISION_OTHER_MADE_COUNTER_OFFER';
export const RELATIONSHIP_DASHBOARD_NOT_COMPLETED =
  'RELATIONSHIP_DASHBOARD_NOT_COMPLETED';
export const SUGGESTED_DIVISION_CANT_CONTINUE =
  'SUGGESTED_DIVISION_CANT_CONTINUE';

// Asset Split
export const ASSET_SPLIT_SELF_PARTY_COMPLETING =
  'ASSET_SPLIT_SELF_PARTY_COMPLETING';
export const ASSET_SPLIT_OTHER_PARTY_COMPLETING =
  'ASSET_SPLIT_OTHER_PARTY_COMPLETING';
export const ASSET_SPLIT_OTHER_PARTY_INITIAL_OFFER =
  'ASSET_SPLIT_OTHER_PARTY_INITIAL_OFFER';
export const ASSET_SPLIT_OTHER_PARTY_COUNTER_OFFER =
  'ASSET_SPLIT_OTHER_PARTY_COUNTER_OFFER';
export const ASSET_SPLIT_OFFER_SENT = 'ASSET_SPLIT_OFFER_SENT';
export const PROPERTY_DASHBOARD_COMPLETED = 'PROPERTY_DASHBOARD_COMPLETED';
export const ASSET_SPLIT_VIEWED_BY_OWED_PARTY =
  'ASSET_SPLIT_VIEWED_BY_OWED_PARTY';

export const DOCUMENT_TYPES_OTHER_NOT_READY = 'DOCUMENT_TYPES_OTHER_NOT_READY';
export const DOCUMENT_TYPES_WAITING_FOR_OTHER =
  'DOCUMENT_TYPES_WAITING_FOR_OTHER';
export const PROPERTY_AGREEMENT_NEEDS_CONFIRMATION =
  'PROPERTY_AGREEMENT_NEEDS_CONFIRMATION';
export const CONSENT_ORDER_NEEDS_CONFIRMATION =
  'CONSENT_ORDER_NEEDS_CONFIRMATION';
// You both have completed both document types
export const DOCUMENT_TYPES_COMPLETED = 'DOCUMENT_TYPES_COMPLETED';
export const DOCUMENT_TYPES_PROPERTY_AGREEMENT_PAYMENT_REQUIRED =
  'DOCUMENT_TYPES_PROPERTY_AGREEMENT_PAYMENT_REQUIRED';
export const DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED =
  'DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED';
export const CO_DASHBOARD_DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED =
  'CO_DASHBOARD_DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED';
export const CONSENT_ORDER_SUPERANNUATION_NOT_SUITABLE =
  'CONSENT_ORDER_SUPERANNUATION_NOT_SUITABLE';

export const RESET_SUGGESTED_DIVISION_OTHER_NOT_READY =
  'RESET_SUGGESTED_DIVISION_OTHER_NOT_READY';
export const RESET_SUGGESTED_DIVISION_WAITING_FOR_OTHER =
  'RESET_SUGGESTED_DIVISION_WAITING_FOR_OTHER';
export const RESET_SUGGESTED_DIVISION_WAITING_FOR_SELF =
  'RESET_SUGGESTED_DIVISION_WAITING_FOR_SELF';

export const SUPER_SPLITTING_CONSENT_ORDER_NO_CHANGES_PAYEE =
  'SUPER_SPLITTING_CONSENT_ORDER_NO_CHANGES_PAYEE';
export const SUPER_SPLITTING_CONSENT_ORDER_CHANGES_COMPLETE_PAYEE =
  'SUPER_SPLITTING_CONSENT_ORDER_CHANGES_COMPLETE_PAYEE';
export const SUPER_SPLITTING_CONSENT_ORDER_CHANGES_ACCEPTED_PAYER =
  'SUPER_SPLITTING_CONSENT_ORDER_CHANGES_ACCEPTED_PAYER';
export const SUPER_SPLITTING_CONSENT_ORDER_CHANGES_WAITING_FOR_OTHER_PAYER =
  'SUPER_SPLITTING_CONSENT_ORDER_CHANGES_WAITING_FOR_OTHER_PAYER';
export const SUPER_SPLITTING_CONSENT_ORDER_CHANGES_WAITING_FOR_SELF_PAYEE =
  'SUPER_SPLITTING_CONSENT_ORDER_CHANGES_WAITING_FOR_SELF_PAYEE';
export const SUPER_SPLITTING_CONSENT_ORDER_NO_CHANGES_PAYER =
  'SUPER_SPLITTING_CONSENT_ORDER_NO_CHANGES_PAYER';
export const SUPER_SPLITTING_CONSENT_ORDER_WAITING_FOR_OTHER_TO_SEND_PAYEE =
  'SUPER_SPLITTING_CONSENT_ORDER_WAITING_FOR_OTHER_TO_SEND_PAYEE';
export const SUPER_SPLITTING_CONSENT_ORDER_VIEW_PFL_PAYER =
  'SUPER_SPLITTING_CONSENT_ORDER_VIEW_PFL_PAYER';
export const SUPERANNUATION_BALANCE_REQUEST_CANCELLED_REQUESTEE =
  'SUPERANNUATION_BALANCE_REQUEST_CANCELLED_REQUESTEE';
export const SUPERANNUATION_REQUEST_BALANCE_STARTED_REQUESTER =
  'SUPERANNUATION_REQUEST_BALANCE_STARTED_REQUESTER';
export const SUPERANNUATION_REQUEST_BALANCE_UPLOAD_LETTER_REQUESTER =
  'SUPERANNUATION_REQUEST_BALANCE_UPLOAD_LETTER_REQUESTER';
export const SUPERANNUATION_REQUEST_BALANCE_COMPLETED_REQUESTER =
  'SUPERANNUATION_REQUEST_BALANCE_COMPLETED_REQUESTER';
export const SUPERANNUATION_REQUEST_BALANCE_IN_PROGRESS_REQUESTEE =
  'SUPERANNUATION_REQUEST_BALANCE_IN_PROGRESS_REQUESTEE';
export const SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_SELF =
  'SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_SELF';
export const SUPER_SPLITTING_REMOVE_SUPERANNUATION_APPROVED_REQUESTER =
  'SUPER_SPLITTING_REMOVE_SUPERANNUATION_APPROVED_REQUESTER';
export const SUPER_SPLITTING_REMOVE_SUPERANNUATION_APPROVED_REQUESTEE =
  'SUPER_SPLITTING_REMOVE_SUPERANNUATION_APPROVED_REQUESTEE';
export const SUPER_SPLITTING_REMOVE_SUPERANNUATION_REJECTED_REQUESTEE =
  'SUPER_SPLITTING_REMOVE_SUPERANNUATION_REJECTED_REQUESTEE';
export const SUPER_SPLITTING_REMOVE_SUPERANNUATION_REJECTED_REQUESTER =
  'SUPER_SPLITTING_REMOVE_SUPERANNUATION_REJECTED_REQUESTER';
export const SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_OTHER =
  'SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_OTHER';

export const DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE =
  'DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE';
export const DOCUMENT_CARD_REQUIRED_SECTIONS_COMPLETE =
  'DOCUMENT_CARD_REQUIRED_SECTIONS_COMPLETE';
export const DOCUMENT_CARD_SELF_REQUESTED = 'DOCUMENT_CARD_SELF_REQUESTED';
export const DOCUMENT_CARD_OTHER_REQUESTED = 'DOCUMENT_CARD_OTHER_REQUESTED';
export const DOCUMENT_CARD_PAYMENT_REQUIRED = 'DOCUMENT_CARD_PAYMENT_REQUIRED';
export const DOCUMENT_CARD_COMPLETE = 'DOCUMENT_CARD_COMPLETE';
export const DOCUMENT_CARD_BLOCKED = 'DOCUMENT_CARD_BLOCKED';

export type DocumentCardStatus =
  | 'DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE'
  | 'DOCUMENT_CARD_REQUIRED_SECTIONS_COMPLETE'
  | 'DOCUMENT_CARD_SELF_REQUESTED'
  | 'DOCUMENT_CARD_OTHER_REQUESTED'
  | 'DOCUMENT_CARD_PAYMENT_REQUIRED'
  | 'DOCUMENT_CARD_COMPLETE'
  | 'DOCUMENT_CARD_BLOCKED';

// CARD STATUS

/**
 * Defaults
 * */
// not started, either party can fill out
export const STATUS_NEW_BOTH_PARTIES = 'STATUS_NEW_BOTH_PARTIES';
// not started, only other party can fill out
export const STATUS_NEW_ONLY_THEM = 'STATUS_NEW_ONLY_THEM';
// not started, only you can fill out
export const STATUS_NEW_ONLY_SELF = 'STATUS_NEW_ONLY_SELF';
// info only
export const STATUS_INFO_ONLY = 'STATUS_INFO_ONLY';

/**
 * CREATED
 * */
// card created and questions filled out, reay for other party to view
export const STATUS_CREATED = 'STATUS_CREATED';

/**
 * UPDATE
 * */
// other party requested an update to be made
export const STATUS_UPDATE_REQUESTED = 'STATUS_UPDATE_REQUESTED';
// you made an update
export const STATUS_UPDATE_DONE = 'STATUS_UPDATE_DONE';

/**
 * DISCUSSION
 * */
// either party has sent a message but is NOT part of updating a request - this one is tricky
export const STATUS_DISCUSSION = 'STATUS_DISCUSSION';

/**
 * APPROVE
 * */
// other party has approved a card
export const STATUS_APPROVED = 'STATUS_APPROVED';

export const STATUS_FOR_VERIFICATION = 'STATUS_FOR_VERIFICATION';

/**
 * REMOVED
 * */
// either party has removed the card (only non-required cards like children etc)
export const STATUS_REMOVED = 'STATUS_REMOVED';

/**
 * REOPENED
 * */
// either party has reopened a previously approved card
export const STATUS_REOPENED = 'STATUS_REOPENED';

// statuses that can be used in the Matter data stored in the DB
export type ItemStatus =
  | 'STATUS_CREATED'
  | 'STATUS_UPDATE_REQUESTED'
  | 'STATUS_UPDATE_DONE'
  | 'STATUS_DISCUSSION'
  | 'STATUS_APPROVED'
  | 'STATUS_REMOVED'
  | 'STATUS_REOPENED';

// statuses that can be used in the data model - it includes all of the ones above, plus a few more that are used for card states that are never stored
export type CardStatus =
  | ItemStatus
  | 'STATUS_INFO_ONLY'
  | 'STATUS_NEW_BOTH_PARTIES'
  | 'STATUS_NEW_ONLY_THEM'
  | 'STATUS_NEW_ONLY_SELF';

export const STATUS_INCOMPLETE = 'STATUS_INCOMPLETE';
export const STATUS_NOT_STARTED = 'STATUS_NOT_STARTED';

// MESSAGE
export const MESSAGE_STATUS_APPROVED = 'MESSAGE_STATUS_APPROVED';
export const MESSAGE_STATUS_MESSAGE = 'MESSAGE_STATUS_MESSAGE';
export const MESSAGE_STATUS_STATEMENT_CREATED =
  'MESSAGE_STATUS_STATEMENT_CREATED';
export const MESSAGE_STATUS_UPDATE_DONE = 'MESSAGE_STATUS_UPDATE_DONE';
export const MESSAGE_STATUS_UPDATE_REQUESTED =
  'MESSAGE_STATUS_UPDATE_REQUESTED';
export const MESSAGE_STATUS_REOPENED = 'MESSAGE_STATUS_REOPENED';
export const MESSAGE_STATUS_REMOVED = 'MESSAGE_STATUS_REMOVED';
export const MESSAGE_STATUS_OFFER = 'MESSAGE_STATUS_OFFER';
export const MESSAGE_STATUS_OFFER_ACCEPTED = 'MESSAGE_STATUS_OFFER_ACCEPTED';
export const MESSAGE_STATUS_SUGGESTED_DIVISION_OFFER =
  'MESSAGE_STATUS_SUGGESTED_DIVISION_OFFER';
export const MESSAGE_STATUS_SUGGESTED_DIVISION_OFFER_ACCEPTED =
  'MESSAGE_STATUS_SUGGESTED_DIVISION_OFFER_ACCEPTED';
export const MESSAGE_STATUS_ASSET_SPLIT_OFFER =
  'MESSAGE_STATUS_ASSET_SPLIT_OFFER';
export const MESSAGE_STATUS_ASSET_SPLIT_OFFER_ACCEPTED =
  'MESSAGE_STATUS_ASSET_SPLIT_OFFER_ACCEPTED';
export const MESSAGE_STATUS_TONECOP_REJECTED =
  'MESSAGE_STATUS_TONECOP_REJECTED';

export type MessageStatus =
  | 'MESSAGE_STATUS_APPROVED'
  | 'MESSAGE_STATUS_MESSAGE'
  | 'MESSAGE_STATUS_STATEMENT_CREATED'
  | 'MESSAGE_STATUS_UPDATE_DONE'
  | 'MESSAGE_STATUS_UPDATE_REQUESTED'
  | 'MESSAGE_STATUS_REOPENED'
  | 'MESSAGE_STATUS_REMOVED'
  | 'MESSAGE_STATUS_OFFER'
  | 'MESSAGE_STATUS_OFFER_ACCEPTED'
  | 'MESSAGE_STATUS_SUGGESTED_DIVISION_OFFER'
  | 'MESSAGE_STATUS_SUGGESTED_DIVISION_OFFER_ACCEPTED'
  | 'MESSAGE_STATUS_ASSET_SPLIT_OFFER'
  | 'MESSAGE_STATUS_ASSET_SPLIT_OFFER_ACCEPTED'
  | 'MESSAGE_STATUS_TONECOP_REJECTED';

// DOCUMENT STATUS
export const DOCUMENT_STATUS_EXISTING = 'existing';
export const DOCUMENT_STATUS_NEW = 'new';
export const DOCUMENT_STATUS_INPROGRESS = 'inprogress';
export const DOCUMENT_STATUS_UPLOADED = 'uploaded';
export const DOCUMENT_STATUS_FAILED = 'failed';
export const DOCUMENT_STATUS_DELETED = 'deleted';

// DATA MODEL TAXONOMY
// data model sections
export const DATA_SECTION = 'DATA_SECTION';
// data model pages
export const DATA_PAGE = 'DATA_PAGE';
// data model groups
export const DATA_GROUP = 'DATA_GROUP';

// CARD TYPES
// ** if these get changed at all, make sure you update the types in `Data/*/Types/appSections.ts` too!
// "normal" cards, e.g. the ones that are used for settlement and a bit in parenting plans
export const CARD_NORMAL = 'CARD_NORMAL';
/** A card that has a custom component instead of the normal question flow, e.g. the parenting schedule card */
export const CARD_CUSTOM = 'CARD_CUSTOM';
// Jurisdiction card with more info button
export const CARD_JURISDICTION = 'CARD_JURISDICTION';
// special card used on conesent orders. to coninue on a card that has already
// been started
export const CARD_CONTINUE = 'CARD_CONTINUE';
// special card that has a checkbox that the user needs to accept - this card can't be clicked in to
export const CARD_AGREEMENT = 'CARD_AGREEMENT';
// optional individual statement cards
// this is a normal card (can be used with the normal question flow etc), but the dashboard styling is different
export const CARD_STATEMENT = 'CARD_STATEMENT';
// sub-headings, e.g. "Education", "Health", etc in parenting plan
export const CARD_SUBHEADING = 'CARD_SUBHEADING';
// renders a react component
export const CARD_COMPONENT = 'CARD_COMPONENT';
// Used in consent orders page asset
export const CARD_AGREED_DIVISION = 'CARD_AGREED_DIVISION';
export const CARD_DIVIDING_ASSETS = 'CARD_DIVIDING_ASSETS';
export const CARD_VIEW = 'CARD_VIEW';
export const CARD_INFO = 'CARD_INFO';
export const CARD_BUTTON = 'CARD_BUTTON';

// custom card for superannuation on the "Money and Property" tag of the Property dashboard so we can handle form 6 requests
export const CARD_YOUR_SUPERANNUATION = 'CARD_YOUR_SUPERANNUATION';

export type CardType =
  | 'CARD_NORMAL'
  | 'CARD_CUSTOM'
  | 'CARD_JURISDICTION'
  | 'CARD_CONTINUE'
  | 'CARD_AGREEMENT'
  | 'CARD_STATEMENT'
  | 'CARD_SUBHEADING'
  | 'CARD_COMPONENT'
  | 'CARD_AGREED_DIVISION'
  | 'CARD_DIVIDING_ASSETS'
  | 'CARD_VIEW'
  | 'CARD_INFO'
  | 'CARD_BUTTON'
  | 'CARD_YOUR_SUPERANNUATION';

// agreement statuses (used mainly in the Tracker)
export const AGREEMENT_INCOMPLETE = 'AGREEMENT_INCOMPLETE';
export const AGREEMENT_READY_TO_EXPORT = 'AGREEMENT_READY_TO_EXPORT';
export const AGREEMENT_WARNING = 'AGREEMENT_WARNING';
export const AGREEMENT_WAITING_FOR_OTHER = 'AGREEMENT_WAITING_FOR_OTHER';
export const AGREEMENT_NEEDS_CONFIRMATION = 'AGREEMENT_NEEDS_CONFIRMATION';
export const AGREEMENT_CREATED = 'AGREEMENT_CREATED';
export const AGREEMENT_OTHER_NOT_READY = 'AGREEMENT_OTHER_NOT_READY';

export type AgreementStatus =
  | typeof AGREEMENT_INCOMPLETE
  | typeof AGREEMENT_READY_TO_EXPORT
  | typeof AGREEMENT_WARNING
  | typeof AGREEMENT_WAITING_FOR_OTHER
  | typeof AGREEMENT_NEEDS_CONFIRMATION
  | typeof AGREEMENT_CREATED
  | typeof AGREEMENT_OTHER_NOT_READY;

// document types that can be generated in amica
export const DOC_TYPE_PROPERTY_AGREEMENT = 'Property';
export const DOC_TYPE_PARENTING_AGREEMENT = 'Parenting';
export const DOC_TYPE_PARENTING_PLAN = 'ParentingPlan';
export const DOC_TYPE_CONSENT_ORDERS = 'ConsentOrders';
export const DOC_TYPE_MINUTES_OF_CONSENT = 'MinutesOfConsent';
export const DOC_TYPE_CONSENT_ORDERS_DRAFT = 'ConsentOrdersDraft';
export const DOC_TYPE_MINUTES_OF_CONSENT_DRAFT = 'MinutesOfConsentDraft';
export const DOC_TYPE_PROCEDURAL_FAIRNESS_LETTER = 'ProceduralFairnessLetter';

// the agreement types
export type Agreements =
  | typeof DOC_TYPE_PROPERTY_AGREEMENT
  | typeof DOC_TYPE_PARENTING_AGREEMENT
  | typeof DOC_TYPE_PARENTING_PLAN
  | typeof DOC_TYPE_CONSENT_ORDERS
  | typeof DOC_TYPE_MINUTES_OF_CONSENT
  | typeof DOC_TYPE_CONSENT_ORDERS_DRAFT
  | typeof DOC_TYPE_MINUTES_OF_CONSENT_DRAFT
  | typeof DOC_TYPE_PROCEDURAL_FAIRNESS_LETTER;

// map of document types to the names of keys used in the data model, e.g. `canExportProperty`
export const DOC_TYPES_TO_KEYS: Record<Agreements, string> = {
  [DOC_TYPE_PROPERTY_AGREEMENT]: 'Property',
  [DOC_TYPE_PARENTING_AGREEMENT]: 'Parenting',
  [DOC_TYPE_PARENTING_PLAN]: 'ParentingPlan',
  [DOC_TYPE_CONSENT_ORDERS]: 'ConsentOrders',
  [DOC_TYPE_MINUTES_OF_CONSENT]: 'ConsentOrders',
  [DOC_TYPE_CONSENT_ORDERS_DRAFT]: 'ConsentOrders',
  [DOC_TYPE_MINUTES_OF_CONSENT_DRAFT]: 'ConsentOrders',
  [DOC_TYPE_PROCEDURAL_FAIRNESS_LETTER]: 'ConsentOrders',
};

export const SUGGESTED_DIVISION = 'SUGGESTED_DIVISION';





export const PAYMENT_ERROR_PAYMENT_MADE = 'PAYMENT_ERROR_PAYMENT_MADE';
export const PAYMENT_ERROR_UNKNOWN = 'PAYMENT_ERROR_UNKNOWN';

// TRANSFER OPTIONS
export const TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING =
  'TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING';
export const TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED =
  'TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED';
export const TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING =
  'TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING';
export const TRANSFER_OPTION_TRANSFER_SOLE_TO_OWED =
  'TRANSFER_OPTION_TRANSFER_SOLE_TO_OWED';
export const TRANSFER_OPTION_SELL = 'TRANSFER_OPTION_SELL';
export const TRANSFER_OPTION_SPLIT_EVENLY = 'TRANSFER_OPTION_SPLIT_EVENLY';

export type AssetSplitOption =
  | 'TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING'
  | 'TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED'
  | 'TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING'
  | 'TRANSFER_OPTION_TRANSFER_SOLE_TO_OWED'
  | 'TRANSFER_OPTION_SELL'
  | 'TRANSFER_OPTION_SPLIT_EVENLY';

// Consent orders
export const CONSENT_ORDER_GET_STARTED_LOCKED =
  'CONSENT_ORDER_GET_STARTED_LOCKED';
export const CONSENT_ORDER_GET_STARTED_COMPLETE =
  'CONSENT_ORDER_GET_STARTED_COMPLETE';
export const CONSENT_ORDERS_GET_STARTED_REQUIREMENTS =
  'CONSENT_ORDERS_GET_STARTED_REQUIREMENTS';
export const CONSENT_ORDERS_GET_STARTED_NOT_SUITABLE =
  'CONSENT_ORDERS_GET_STARTED_NOT_SUITABLE';

export const CONSENT_ORDER_RELATIONSHIP_COMPLETE =
  'CONSENT_ORDER_RELATIONSHIP_COMPLETE';
export const CONSENT_ORDER_RELATIONSHIP_LOCKED =
  'CONSENT_ORDER_RELATIONSHIP_LOCKED';
export const CONSENT_ORDER_FINANCES_COMPLETE =
  'CONSENT_ORDER_FINANCES_COMPLETE';
export const CONSENT_ORDER_FINANCES_LOCKED = 'CONSENT_ORDER_FINANCES_LOCKED';
export const CONSENT_ORDER_ASSETS_COMPLETE = 'CONSENT_ORDER_ASSETS_COMPLETE';
export const CONSENT_ORDER_ASSETS_LOCKED = 'CONSENT_ORDER_ASSETS_LOCKED';
export const CONSENT_ORDER_COMPLETED_COMPLETE =
  'CONSENT_ORDER_COMPLETED_COMPLETE';
export const CONSENT_ORDER_COMPLETED_LOCKED = 'CONSENT_ORDER_COMPLETED_LOCKED';

export const BANNER_GENERIC = 'BANNER_GENERIC';
export const BANNER_GENERIC_CARDS_UNLOCKED = 'BANNER_GENERIC_CARDS_UNLOCKED';

export const INCOME_SUPPORT_TYPES = [
  'Age Pension',
  'Austudy',
  'Carer Payment',
  'Disability Support Pension',
  'Farm Household Allowance',
  'Income Support Supplement',
  'JobSeeker Payment',
  'Parenting Payment',
  'Partner Allowance',
  'Service Pension',
  'Sickness Allowance',
  'Special Benefit',
  'Widow Allowance',
  'Youth Allowance',
  'Veteran Payment',
  'Other',
];

export const SECTION_STATUS_OPT_IN = 'SECTION_STATUS_OPT_IN';
export const SECTION_STATUS_NOT_STARTED = 'SECTION_STATUS_NOT_STARTED';
export const SECTION_STATUS_IN_PROGRESS = 'SECTION_STATUS_IN_PROGRESS';
export const SECTION_STATUS_COMPLETED = 'SECTION_STATUS_COMPLETED';
export const SECTION_STATUS_LOCKED = 'SECTION_STATUS_LOCKED';

export type SectionStatus =
  | typeof SECTION_STATUS_OPT_IN
  | typeof SECTION_STATUS_NOT_STARTED
  | typeof SECTION_STATUS_IN_PROGRESS
  | typeof SECTION_STATUS_COMPLETED
  | typeof SECTION_STATUS_LOCKED;

export const AMICA_IS_FREE_NOTIFICATION_DISMISSED_LOCALSTORAGE_KEY =
  'amicaIsFreeNotificationDismissed';
// the threshold that both parties incomes need to be under for amica to be free
export const AMICA_FREE_INDIVIDUAL_INCOME_THRESHOLD = 40000;

// FLAGS
// use prefix `FLAG_MATTER_` when it is a matter-wide flag, and `FLAG_PARTY` when it is a party flag
export const FLAG_MATTER_OPTED_IN = 'optedInSections';
export const FLAG_MATTER_OPTED_IN_DEFAULT = {
  SECTION_PARENTING: false,
  SECTION_PROPERTY: false,
  SECTION_CONSENT_ORDERS: false,
};

// list of cards that can be deleted
export const PARENTING_DELETABLE_CARDS = [
  'birthdays',
  'travelling',
  'mothersFathersDay',
  'schoolHolidayShort',
  'schoolHolidayLong',
  'christmas',
  'easter',
  'extracurricular',
  'parentTeacherInterviews',
  'doctorsAppointments',
  'medication',
  'expenses',
  'communicationMethod',
  'communication',
  'cooperating',
  'contactDetails',
  'religionAndCulture',
];

export const SHOW_AMICA_DEMO_BANNER_DISMISSED = 'AMICA_DEMO_BANNER_Dismissed';
export const SUA_SUGGESTED_DIVISION_BANNER_DISMISSED =
  'SUA_SUGGESTED_DIVISION_BANNER_Dismissed';

export const FEDERAL_COURT_AUSTRALIA = 'FCA';

export const DIALOG_RESTART_SUGGESTED_DIVISION =
  'DIALOG_RESTART_SUGGESTED_DIVISION';
export const DIALOG_SUPER_CONFIRM_NO_CLAUSE_CHANGES =
  'DIALOG_SUPER_CONFIRM_NO_CLAUSE_CHANGES';
export const DIALOG_CANCEL_SUPERANNUATION_BALANCE_REQUEST =
  'DIALOG_CANCEL_SUPERANNUATION_BALANCE_REQUEST';
export const DIALOG_REMOVE_SUPERANNUATION = 'DIALOG_REMOVE_SUPERANNUATION';

export type Dialogs =
  | typeof DIALOG_RESTART_SUGGESTED_DIVISION
  | typeof DIALOG_SUPER_CONFIRM_NO_CLAUSE_CHANGES
  | typeof DIALOG_CANCEL_SUPERANNUATION_BALANCE_REQUEST
  | typeof DIALOG_REMOVE_SUPERANNUATION;

export const DIALOG_COMPLETE_ACTION_GOTO_NEXT_QUESTION =
  'DIALOG_COMPLETE_ACTION_GOTO_NEXT_QUESTION';

export type DialogCompleteActions =
  typeof DIALOG_COMPLETE_ACTION_GOTO_NEXT_QUESTION;

export const REMOVE_SUPERANNUATION_START_FLOW =
  'REMOVE_SUPERANNUATION_START_FLOW';
export const REMOVE_SUPERANNUATION_REQUESTEE_APPROVE =
  'REMOVE_SUPERANNUATION_REQUESTEE_APPROVE';
export const REMOVE_SUPERANNUATION_REQUESTEE_REJECT =
  'REMOVE_SUPERANNUATION_REQUESTEE_REJECT';
export const REMOVE_SUPERANNUATION_REQUESTEE_DISMISS_CONFIRMATION_BANNER =
  'REMOVE_SUPERANNUATION_REQUESTEE_DISMISS_CONFIRMATION_BANNER';
export const REMOVE_SUPERANNUATION_REQUESTER_DISMISS_CONFIRMATION_BANNER =
  'REMOVE_SUPERANNUATION_REQUESTER_DISMISS_CONFIRMATION_BANNER';

// list of valid versions
// these need to be in chronological order from earliest to latest!
// ** IF YOU CREATE A NEW VERSION, ADD IT TO THE CHANGELOG - SEE README.md **
export const VERSIONS = [
  '20200101',
  '20211123',
  '20221124',
  '20230101',
  '20230215',
];

// the first version of the data model - used for matters that don't have a version set, as they were created before versioning
export const VERSION_DEFAULT = VERSIONS[0]; // 20200101

// the current version that all new matters should use
export const VERSION_CURRENT = VERSIONS[VERSIONS.length - 1]; // 20230215

/** The number of days a matter needs to be inactive to be sent through to Drip */
export const DRIP_DAYS_MATTER_INACTIVE = 30;
