import styled, { css } from 'styled-components';
import { Heading2, Heading3 } from 'Common/UI/Text/Headings';
import { Label } from 'Common/UI/Text/Label';
import defaultTheme from 'Common/Utils/theme';
import NavButton from 'Common/UI/Button/NavButton';
import { SCREEN_STATE_VIEW, SCREEN_STATE_ACTION } from './Division/constants';

export const DetailsViewWrapper = styled.div`
  background: ${props => props.theme.backgroundColour};
  transition: all 500ms;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;

  ${props =>
    props.withDrawer &&
    `
    padding-bottom: 160px;

    @media ${props.theme.sizes.md.breakpoint} {
      padding-bottom: 200px;
    }
  `};
`;

export const DetailsViewHeader = styled.div`
  background-color: ${props => props.theme.colours.offWhite};
  color: ${props => props.theme.textColour};
  padding: ${({ theme }) =>
    `${theme.padding.medium}px 80px 0 ${theme.padding.medium}px`};
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colours.clickableGrey};
  transition: all 500ms;
  min-height: 74px;
  flex: 0;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding: ${({ theme }) => `36px ${theme.padding.xlarge}px`};
  }

  a {
    float: right;
    color: white;
    text-decoration: none;
    line-height: 2em;
  }

  ${Heading3},
  ${Heading2} {
    width: calc(100% - 96px);
    margin-left: ${props => (props.state === SCREEN_STATE_VIEW ? '0' : '56px')};
    transition: margin-left 0.5s;
  }
`;

export const Statement = styled.div`
  padding: ${props => props.theme.padding.small}px;
  background: ${props => props.theme.colours.offWhite};
  border-radius: 4px;
  z-index: 100;
  position: relative;

  p {
    margin-top: 0;
  }
`;

export const Owner = styled(Label)`
  color: ${props => props.theme.colours.darkGrey};
  margin-bottom: 8px;
`;

export const MessagesBody = styled.div`
  flex: 1;
  overflow: auto;
  position: relative;
  background-color: ${defaultTheme.colours.white};
`;

export const MessagesPositionWrap = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
`;

export const BackButton = styled(NavButton)`
  position: absolute;
  left: 16px;
  top: 16px;
  opacity: ${props => (props.state === SCREEN_STATE_ACTION ? '1' : '0')};
  transform: ${props =>
    props.state === SCREEN_STATE_ACTION
      ? 'translateX(0)'
      : 'translateX(-100%)'};
  transition: all 0.5s;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    top: 32px;
  }
`;

export const StyledNavButton = styled(NavButton)`
  position: absolute;
  top: 20px;
  right: 24px;
  display: ${props => (props.state === SCREEN_STATE_VIEW ? 'block' : 'none')};
  transition: all 0.5s;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    right: 40px;
  }

  ${props =>
    props.type === 'done' &&
    css`
      background-color: ${props.theme.colours.purple};
      color: ${props.theme.colours.white};
      padding-left: ${props.theme.padding.medium}px;
      padding-right: ${props.theme.padding.medium}px;
    `}
`;
