import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import React from 'react';

const SuggestedDivisionBanner = () => (
  <>
    <Heading2MarginTopNone>
      amica one has come up with a suggested division of your assets including
      money and property.
    </Heading2MarginTopNone>
    <Paragraph>
      Our suggested division of assets is an estimate only, based on the
      information you’ve provided.
    </Paragraph>
  </>
);

export default SuggestedDivisionBanner;
