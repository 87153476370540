import React from 'react';

import isNumeric from 'Common/Utils/isNumeric';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import {
  getUpdatedItemValues,
  getHighlightStatementValuesFunction,
  getWasUpdatedFunction,
  getRedactFunction,
  getSingleUserFullName,
  getFullName,
} from 'Common/Utils/statementHelpers';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import HeadingLinkText from 'Common/UI/Text/HeadingLinkText';
import { formatDate } from 'Common/Data/App/helpers';
import isDate from '../../../../Utils/isDate';
import formatCurrency from '../../../../Utils/formatCurrency';
import capitaliseText from '../../../../Utils/capitaliseText';
import Matter from '../../../Types/matter';

export const aboutYouQuestionsB: QuestionAny[] = [
  {
    name: 'intro',
    heading: (matter: Matter) => (
      <H1Conversational>
        Hi {getSingleUserFullName(matter)}, we’re going to ask you about your
        former partner&apos;s age, income and health.
      </H1Conversational>
    ),
    content: () => (
      <Paragraph>
        These questions are important because we will base our suggestions for a
        fair division of your money and property on the information you give us.
      </Paragraph>
    ),
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'birthday',
    label: "What is your former partner's date of birth?",
    hint: '',
    type: 'date',
    defaultValue: '',
    validate: value =>
      isDate(value)
        ? [true]
        : [false, "Please enter in your former partner's date of birth"],
    index: 1,
    formAttributes: { max: new Date() },
    sensitive: true,
  },
  {
    name: 'b1',
    label: (
      <>
        Sorry. Because your former partner is under 18, you’re not able to use
        amica. Please visit the{' '}
        <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
          <HeadingLinkText>Help and Support</HeadingLinkText>
        </ReferralLink>{' '}
        page for other services that may be able to assist you.
      </>
    ),
    hint: '',
    type: 'blocker',
    defaultValue: '',
    index: 2,
    visible: answers => !isDate(answers.birthday, { atLeast: 18 }),
  },
  {
    name: 'earnings',
    label:
      "What was your former partner's total taxable income for the last financial year (a financial year is 1 July to 30 June)?",
    hint: '',
    type: 'currency',
    defaultValue: '',
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            This refers to your former partner&apos;s total taxable or
            &apos;gross&apos; income from paid work in the most recent year. It
            can be from more than one job, or source. For example it would
            include income from an investment property, but not any Centrelink
            benefits (we will cover these in a separate question).
          </Paragraph>
        </>
      ),
    },
    index: 3,
    validate: value =>
      isNumeric(value)
        ? [true]
        : [false, 'Please enter your earnings for the last financial year'],
  },
  {
    name: 'healthissues',
    label:
      'Does your former partner have any health issues that affect their ability to work?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            This might be a physical disability, disease, chronic pain, an
            injury or a mental health issue. Work means both paid work outside
            the home and unpaid work to keep the home going, such as household
            duties or looking after the children. This is important because
            these factors can affect your future. amica takes your future needs
            into account when helping you and your former partner to divide your
            money and property.
          </Paragraph>
        </>
      ),
    },
    index: 4,
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 5,
  },
];

export const aboutYouStatementB = ({
  matter,
  data,
  card,
  withUpdateLink = false,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'birthday',
    'earnings',
    'healthissues',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(aboutYouQuestionsB, data, hideSensitive);
  const party = data.CardIndex || matter.self.party;

  const name = capitaliseText(getFullName(matter.partyB, party, hideSensitive));

  const birthday = highlight`${name} was born ${updated(
    'birthday',
    redact('birthday', formatDate(data.birthday))
  )}.`;

  let earnings;

  if (data.earnings) {
    earnings = highlight`They made ${updated(
      ['earnings'],
      redact('earnings', formatCurrency(data.earnings))
    )} last year before tax.`;
  }

  let health;

  if (data.healthissues) {
    health = highlight`They ${updated(
      'healthissues',
      'do'
    )} have health issues that affect their ability to work.`;
  } else {
    health = highlight`They ${updated(
      'healthissues',
      'do not'
    )} have any health issues that affect their ability to work.`;
  }

  if (plainText) {
    return `${birthday} ${earnings} ${health}`;
  }

  return (
    <>
      {birthday} {earnings} {health}
    </>
  );
};
