// given a list of integers, return the lowest number greater or
// equal to 1 that does *not* exist in the list
export const getNextAvailableIndex = (list: number[]) => {
  let index = 1;

  while (list.indexOf(index) > -1) {
    index += 1;
  }

  return index;
};
