import React from 'react';
import CardQuestions from 'App/UI/Questions/CardQuestionsFlow';
import MatterGuard from './Guard/MatterGuard';

type Props = {
  props: any;
};

const ODRSYourFinancesShared: React.FC<Props> = props => (
  <MatterGuard>
    <CardQuestions baseID="sharedfinances" {...props} />
  </MatterGuard>
);

export default ODRSYourFinancesShared;
