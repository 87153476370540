import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Dropdown from 'react-dropdown';
import FormLabel from 'Common/UI/Form/FormLabel';
import arrowDownSVGPath from 'Common/Assets/images/icon-arrow-down.svg';
import useMobileDetect from '../../Utils/Hooks/useDeviceDetect';

const StyledSelect = styled.select`
  width: 100%;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-color: ${({ theme }) => theme.colours.offWhite};
  border: 1px solid ${({ theme }) => theme.colours.clickableGrey};
  color: ${({ theme }) => theme.colours.black};
`;

const ReactDropdownStylingContainer = styled.div`
  .Dropdown-root {
    position: relative;
  }

  .Dropdown-control {
    position: relative;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colours.offWhite};
    border: 1px solid ${({ theme }) => theme.colours.clickableGrey};
    color: ${({ theme }) => theme.colours.black};
    border-radius: 2px;
    box-sizing: border-box;
    cursor: default;
    outline: none;
    padding: 14px 52px 14px 10px;
    transition: all 200ms ease;
  }

  .Dropdown-control:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }

  .Dropdown-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    height: 0;
    margin-top: -ceil(2.5);
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0;
  }

  .is-open .Dropdown-arrow {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }

  .Dropdown-menu,
  ${StyledSelect} {
    background-color: ${({ theme }) => theme.colours.offWhite};
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
  }

  .Dropdown-menu {
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }

  .Dropdown-menu .Dropdown-group > .Dropdown-title,
  ${StyledSelect} {
    padding: 16px 14px;
  }

  .Dropdown-menu .Dropdown-group > .Dropdown-title {
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    text-transform: capitalize;
  }

  .Dropdown-option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
  }

  .Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .Dropdown-option:hover {
    background-color: ${({ theme }) => theme.colours.clickableGrey};
    color: #333;
  }

  .Dropdown-option.is-selected {
    background-color: ${({ theme }) => theme.colours.clickableGrey};
    color: #333;
  }

  .Dropdown-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px;
  }
`;

const Label = styled.label``;

const StyledIcon = styled.img`
  .is-open & {
    transform: rotate(180deg);
    transform-origin: 50% 43%;
  }
`;

const SelectDropdownContainer = styled.div`
  position: relative;
  ${StyledIcon} {
    position: absolute;
    right: 12px;
    bottom: 22px;
  }
`;

const selectDropdDownPlaceholder = 'Please select';

const iconArrowDown = <StyledIcon src={arrowDownSVGPath} alt="" />;

const mustBeAnOption = (value, options) =>
  options.map(option => option.value).includes(value);

const SelectDropdown = ({
  label,
  name,
  value,
  options,
  type,
  disabled,
  onChange,
  placeholder,
}) => {
  const { isMobile } = useMobileDetect();
  const isMobileDevice = isMobile();
  const [selectValue, setSelectValue] = useState(undefined);

  useEffect(() => {
    if (mustBeAnOption(value, options)) {
      setSelectValue(value);
    } else {
      setSelectValue(undefined);
    }
  }, [options, value]);

  return (
    <SelectDropdownContainer>
      <ReactDropdownStylingContainer>
        {isMobileDevice ? (
          <Label htmlFor={name}>
            <FormLabel label={label} type={type} />
            <StyledSelect
              onChange={event => onChange(event.target.value)}
              value={selectValue}
              defaultValue="DEFAULT"
            >
              <option disabled value="DEFAULT">
                {selectDropdDownPlaceholder}
              </option>
              {options.map(o => (
                <option key={o.value} value={o.value}>
                  {o.label}
                </option>
              ))}
            </StyledSelect>
            {iconArrowDown}
          </Label>
        ) : (
          <Dropdown
            options={options}
            onChange={select => onChange(select.value)}
            value={selectValue}
            disabled={disabled}
            // Note you need both arrowOpen and arrowClosed
            // for react-dropdown the package
            arrowOpen={iconArrowDown}
            arrowClosed={iconArrowDown}
            placeholder={placeholder}
          />
        )}
      </ReactDropdownStylingContainer>
    </SelectDropdownContainer>
  );
};

SelectDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  type: PropTypes.string,
};

SelectDropdown.defaultProps = {
  placeholder: selectDropdDownPlaceholder,
  value: '',
  type: '',
  disabled: false,
  options: [],
  label: '',
};

export default SelectDropdown;
