import React from 'react';
import styled, { css } from 'styled-components';
import { Theme } from 'Common/Utils/theme';
import ResponsiveButtonContainer from 'Common/UI/Layout/ResponsiveButtonContainer';

type Props = {
  transition?: boolean;
  direction?: string;
  animated?: boolean;
  state?: string;
  noPadding?: boolean;
  children: React.ReactNode;
  theme: Theme;
};

const StyledActions = styled(ResponsiveButtonContainer)<Props>`
  flex-grow: 0;
  display: flex;
  flex-direction: ${props =>
    props.direction === 'vertical' ? 'column' : 'row'};
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.5s;
  ${props =>
    !props.noPadding &&
    css`
      padding: ${props.theme.padding.medium}px;
    `}

  button {
    width: 100%;
    margin-top: 12px;

    ${props =>
      props.direction === 'horizontal' &&
      css`
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      `};
  }
`;

const Actions: React.FC<Partial<Props>> = ({
  children,
  direction = 'vertical',
  state,
  animated,
  ...rest
}) => (
  <StyledActions direction={direction} state={state} {...rest}>
    {children}
  </StyledActions>
);

const AnimatedActions = styled(Actions)`
  transition: all 0.5s;

  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'flex')};

  ${({ state }) =>
    state === 'exiting' &&
    css`
      position: absolute;
      left: 32px;
      right: 32px;
      bottom: 32px;
    `}
`;

export { AnimatedActions };

export default Actions;
