import { Theme } from 'Common/Utils/theme';
import styled from 'styled-components';
import spacer, { SpacerProps } from '../Layout/spacer';
// import theme from '../../Utils/theme';

const Paragraph = styled.p<
  {
    theme: Theme;
    large?: boolean;
    fontWeight?: number;
    marginTop?: string;
    marginBottom?: string;
  } & SpacerProps
>`
  ${props => !props.large && props.theme.typography.text.paragraph};
  ${props => props.large && props.theme.typography.text.paragraphLarge};
  font-weight: ${props =>
    props.fontWeight && `font-weight: ${props.fontWeight}`};
  color: ${props => props.color || props.theme.textColour};
  margin-top: ${props => props.marginTop || 0};
  margin-bottom: ${props => props.marginBottom || '16px'};
  margin-left: 0;
  margin-right: 0;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    ${props => !props.large && props.theme.typography.text.paragraph.md};
    ${props => props.large && props.theme.typography.text.paragraphLarge.md};
  }

  @media ${({ theme }) => theme.sizes.xl.breakpoint} {
    ${props => !props.large && props.theme.typography.text.paragraph.xl};
    ${props => props.large && props.theme.typography.text.paragraphLarge.xl};
  }

  a {
    color: ${props => props.color || props.theme.textColour};
  }

  ${spacer}
`;

export const HighlightedParagraph = styled(Paragraph)(props => ({
  ...props.theme.typography.text.highlighted,
}));

export const Callout = styled.p`
  ${({ theme }) => theme.typography.text.callout};
  color: ${props => props.color || props.theme.textColour};
  margin-top: 0;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    ${({ theme }) => theme.typography.text.callout.md};
  }

  @media ${({ theme }) => theme.sizes.xl.breakpoint} {
    ${({ theme }) => theme.typography.text.callout.xl};
  }
`;

export const ParagraphDiv = styled(Paragraph).attrs({
  as: 'div',
})``;

export const ParagraphNoMarginBottom = styled(Callout)`
  margin-bottom: 0;
`;

export default Paragraph;

export const FormalInformalText = styled(Paragraph).attrs(() => ({
  large: true,
}))`
  font-weight: bold;
  color: ${({ theme }) => theme.colours.darkGrey};
  margin-top: 0;
`;

export const SmallParagraph = styled(Paragraph)`
  font-size: 15px;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    ${({ theme }) => theme.typography.text.paragraph.md};
  }

  @media ${({ theme }) => theme.sizes.xl.breakpoint} {
    ${({ theme }) => theme.typography.text.paragraph.xl};
  }
`;
