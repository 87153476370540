/* eslint-disable quotes */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

import Paragraph from 'Common/UI/Text/Paragraph';
import capitaliseText from 'Common/Utils/capitaliseText';
import {
  AssetContentType,
  AssetDefaultContentType,
} from 'Common/Data/Types/assets';
import formatCurrency from 'Common/Utils/formatCurrency';

export const assetContent: ({
  type: string;
} & AssetContentType)[] = [
  {
    type: 'property',
    jointToOwing: {
      description: ({ assetName, owingName }) =>
        `If you choose this option, you both will transfer the ownership of ${assetName} to ${owingName}.  It will go from joint ownership to being solely owned by ${owingName}.`,
      info: ({ assetName, owingName }) => (
        <>
          <Paragraph large>
            If you choose this option, you both will transfer the ownership of{' '}
            {assetName} to {owingName}. You both will take the necessary steps
            to transfer title in the property from joint ownership to{' '}
            {owingName}.
          </Paragraph>
          <Paragraph>
            Important: If you choose this option you may have to allocate other
            money or property in your asset pool to {owingName} to achieve the
            agreed percentages. For example, this might involve a payment or the
            transfer of other assets.
          </Paragraph>
        </>
      ),
      selectedDescription: ({ owingName }) =>
        `You have chosen to transfer the ownership of this property to ${owingName} It will go from joint ownership to being solely owned by ${owingName}`,
    },
    jointToOwed: {
      description: ({ assetName, owedName }) =>
        `If you choose this option, you both will transfer the ownership of ${assetName} to ${owedName}.  It will go from joint ownership to being solely owned by ${owedName}.`,
      info: ({ assetName, owedName }) => (
        <>
          <Paragraph large>
            If you choose this option, you both will transfer the ownership of{' '}
            {assetName} to {owedName}. You both will take the necessary steps to
            transfer title in the property from joint ownership to {owedName}.
          </Paragraph>
          <Paragraph>
            Important: If you choose this option you may have to allocate other
            money or property in your asset pool to {owedName} to achieve the
            agreed percentages. For example, this might involve a payment or the
            transfer of other assets.
          </Paragraph>
        </>
      ),
      selectedDescription: ({ owedName }) =>
        `You have chosen to transfer the ownership of this property to ${owedName} It will go from joint ownership to being solely owned by ${owedName}`,
    },
    soleToOwing: {
      info: ({ owingName, owedName }) =>
        `If you choose this option, ${owedName} will transfer the ownership of this property to ${owingName}. ${owedName} will take the necessary steps to transfer title in the property from ${owedName} to ${owingName}`,
    },
    sell: {
      description: ({ assetName }) =>
        `If you choose this option, you will sell ${assetName} and divide the net proceeds between you in an agreed percentage.`,
      info: ({ isJointlyOwned }) => (
        <>
          <Paragraph large>
            If you choose to sell the property and divide the net proceeds
            between you, you will each receive an amount of the sale proceeds
            necessary to achieve the overall percentage division that you have
            agreed (the ‘agreed percentages’).
          </Paragraph>
          {isJointlyOwned && (
            <Paragraph>
              If the property sells for an amount that is different from the
              value you have indicated, you may need to make an adjustment in
              order for each of you to achieve the agreed percentages. For
              example, you can distribute the net proceeds in a way that
              achieves the overall agreed percentage division, or one of you
              could make a payment to the other to achieve the same result.
            </Paragraph>
          )}
        </>
      ),
      selectedDescription: () =>
        'You have chosen to sell the property and divide the net proceeds between you based on the percentages that you have agreed.',
    },
  },
  {
    type: 'vehicle',
    jointToOwing: {
      statement: ({ assetName }) => (
        <>
          We will <strong data-isaction="true">transfer the ownership</strong>{' '}
          of the <strong>vehicle (registration {assetName})</strong> to you.
        </>
      ),
      statementOwed: ({ assetName, owingName }) => (
        <>
          We will <strong data-isaction="true">transfer the ownership</strong>{' '}
          of the <strong>vehicle (registration {assetName})</strong> to{' '}
          {owingName}.
        </>
      ),
    },
    jointToOwed: {
      statement: ({ assetName, owedName }) => (
        <>
          We will <strong data-isaction="true">transfer the ownership</strong>{' '}
          of the <strong>vehicle (registration {assetName})</strong> to{' '}
          {owedName}.
        </>
      ),
      statementOwed: ({ assetName }) => (
        <>
          We will <strong data-isaction="true">transfer the ownership</strong>{' '}
          of the <strong>vehicle (registration {assetName})</strong> to you.
        </>
      ),
    },
    soleToOwing: {
      statement: ({ assetName, owedName }) => (
        <>
          {capitaliseText(owedName)} will{' '}
          <strong data-isaction="true">transfer the ownership</strong> of the{' '}
          <strong>vehicle (registration {assetName})</strong> to you.
        </>
      ),
      statementOwed: ({ assetName, owingName }) => (
        <>
          You will <strong data-isaction="true">transfer the ownership</strong>{' '}
          of the <strong>vehicle (registration {assetName})</strong> to{' '}
          {owingName}.
        </>
      ),
    },
    soleToOwed: {
      statement: ({ assetName, owedName }) => (
        <>
          You will <strong data-isaction="true">transfer the ownership</strong>{' '}
          of the <strong>vehicle (registration {assetName})</strong> to{' '}
          {owedName}.
        </>
      ),
      statementOwed: ({ assetName, owingName }) => (
        <>
          {capitaliseText(owingName)} will{' '}
          <strong data-isaction="true">transfer the ownership</strong> of the{' '}
          <strong>vehicle (registration {assetName})</strong> to you.
        </>
      ),
    },
    sell: {
      description: ({ assetFriendlyType }) =>
        `If you choose this option, you will sell the ${assetFriendlyType} and use the funds to pay out your former partner. You’ll be able to keep any remainder left over from the sale.`,
      info: ({ assetFriendlyType }) => (
        <>
          <Paragraph>
            If you choose this option, you will sell the {assetFriendlyType} and
            use the funds to pay out your former partner. You’ll be able to keep
            any remainder left over from the sale.
          </Paragraph>
          <Paragraph>
            The re-sale value for vehicles can fluctuate. You can find a guide
            to re-sale values for vehicles in the Red Book.
          </Paragraph>
        </>
      ),
      selectedDescription: ({ assetFriendlyType }) =>
        `You have chosen to sell the ${assetFriendlyType} and use the funds to pay out your former partner. You’ll be able to keep any remainder left over from the sale.`,
      statement: ({ assetName }) => (
        <>
          You will <strong data-isaction="true">sell</strong> the{' '}
          <strong>vehicle (registration {assetName})</strong>.
        </>
      ),
      statementOwed: ({ assetName }) => (
        <>
          You will <strong data-isaction="true">sell</strong> the{' '}
          <strong>vehicle (registration {assetName})</strong>.
        </>
      ),
    },
  },
  {
    type: 'shares',
    soleToOwed: {
      description: ({ assetFriendlyType, owedName, owingName }) =>
        `If you choose this option, ${owingName} will transfer the ownership of the ${assetFriendlyType} shares to ${owedName}. It will go from sole ownership by ${owingName} to being solely owned by ${owedName}.`,
      info: ({ assetFriendlyType, owingName, owedName }) =>
        `If you choose this option, ${owingName} will transfer the ownership of the ${assetFriendlyType} shares to ${owedName}. ${owingName} will take the necessary steps to transfer title in the shares from being solely owned by ${owingName} to being solely owned by ${owedName}. Depending on your personal circumstances, you may need to get financial advice before transferring any shares.`,
      statement: ({ assetName, owedName }) => (
        <>
          You will <strong data-isaction="true">transfer the ownership</strong>{' '}
          of the <strong>{assetName}</strong> shares to {owedName}.
        </>
      ),
      statementOwed: ({ assetName, owingName }) => (
        <>
          {capitaliseText(owingName)} will{' '}
          <strong data-isaction="true">transfer the ownership</strong> of the{' '}
          <strong>{assetName}</strong> shares to you.
        </>
      ),
    },
    soleToOwing: {
      description: ({ assetFriendlyType, owedName, owingName }) =>
        `If you choose this option, ${owedName} will transfer the ownership of the ${assetFriendlyType} shares to ${owingName}. It will go from sole ownership by ${owedName} to being solely owned by ${owingName}.`,
      info: ({ assetFriendlyType, owedName, owingName }) =>
        `If you choose this option,${owedName} will transfer the ownership of the ${assetFriendlyType} shares to ${owingName}. ${owedName} will take the necessary steps to transfer title in the shares from from being solely owned by ${owedName} to being solely owned by ${owingName}. Depending on your personal circumstances, you may need to get financial advice before transferring any shares.`,
      statement: ({ assetName, owedName }) => (
        <>
          {capitaliseText(owedName)} will{' '}
          <strong data-isaction="true">transfer the ownership</strong> of the{' '}
          <strong>{assetName}</strong> shares to you.
        </>
      ),
      statementOwed: ({ assetName, owingName }) => (
        <>
          You will <strong data-isaction="true">transfer the ownership</strong>{' '}
          of the <strong>{assetName}</strong> shares to {owingName}.
        </>
      ),
    },
  },
  {
    type: 'superannuation',
    soleToOwed: {
      title: () => 'Transferring superannuation',
      selectedDescription: ({ owedName, owingName, isOwnedByMe }) => (
        <>
          Select highlighted number to edit
          <br />
          <br />
          {isOwnedByMe ? (
            <>
              You have chosen to transfer an amount of your superannuation to{' '}
              {owedName}.
            </>
          ) : (
            <>
              You have chosen to transfer an amount of {owingName}'s
              superannuation to you. Receiving superannuation from {owingName}{' '}
              is not the same as receiving cash.
            </>
          )}
        </>
      ),
      statement: ({ assetName, owedName, value }) => (
        <>
          You will{' '}
          <strong data-isaction="true">transfer {formatCurrency(value)}</strong>{' '}
          of your <strong>{assetName} superannuation</strong> to {owedName}.
        </>
      ),
      statementOwed: ({ assetName, owingName, value }) => (
        <>
          {capitaliseText(owingName)} will{' '}
          <strong data-isaction="true">transfer {formatCurrency(value)}</strong>{' '}
          of their <strong>{assetName} superannuation</strong> to you.
        </>
      ),
      info: ({ owingName, owedName, isOwnedByMe }) =>
        isOwnedByMe
          ? `What is the dollar value of your superannuation you propose to transfer to ${owedName}?`
          : `What is the dollar value of superannuation you propose to request from ${owingName}?`,
      description: ({ owingName, owedName, isOwnedByMe }) =>
        isOwnedByMe
          ? `If you choose this option, you agree to transfer a chosen amount of your superannuation to ${owedName}. Transferring superannuation is not the same as transferring cash.`
          : `If you choose this option, you agree to receive a chosen amount of superannuation from ${owingName}. Transferring superannuation is not the same as transferring cash.`,
    },
  },
];

export const defaultContent: AssetDefaultContentType = {
  jointToOwing: {
    title: ({ assetFriendlyType, owingName }) =>
      `Transfer from joint ownership in the ${assetFriendlyType} to sole ownership by ${owingName}`,
    description: ({ assetFriendlyType, owingName }) =>
      `If you choose this option, you will transfer the ownership of the ${assetFriendlyType} to ${owingName}. It will go from joint ownership to being solely owned by ${owingName}.`,
    info: ({ assetFriendlyType, owingName }) =>
      `If you choose this option, you will transfer the ownership of the ${assetFriendlyType} to ${owingName}. It will go from joint ownership to being solely owned by ${owingName}.`,
    selectedDescription: ({ assetFriendlyType, owingName }) =>
      `You have chosen to transfer the ownership of the ${assetFriendlyType} to ${owingName}. It will go from joint ownership to being solely owned by ${owingName}. `,
    statement: ({ assetName }) => (
      <>
        We will <strong data-isaction="true">transfer the ownership</strong> of{' '}
        <strong>{assetName}</strong> to you.
      </>
    ),
    statementOwed: ({ assetName, owingName }) => (
      <>
        We will <strong data-isaction="true">transfer the ownership</strong> of{' '}
        <strong>{assetName}</strong> to {owingName}.
      </>
    ),
  },
  jointToOwed: {
    title: ({ assetFriendlyType, owedName }) =>
      `Transfer from joint ownership in the ${assetFriendlyType} to sole ownership by ${owedName}`,
    description: ({ assetFriendlyType, owedName }) =>
      `If you choose this option, you agree that ownership of the ${assetFriendlyType} will be transferred to ${owedName}. It will be solely owned by ${owedName}.`,
    info: ({ assetFriendlyType, owedName }) =>
      `If you choose this option, you agree that ownership of the ${assetFriendlyType} will be transferred to ${owedName}. It will be solely owned by ${owedName}.`,
    selectedDescription: ({ assetFriendlyType, owedName }) =>
      `You have chosen to transfer the ownership of the ${assetFriendlyType} to ${owedName}. It will go from being jointly owned to being solely owned by ${owedName}.`,
    statement: ({ assetName, owedName }) => (
      <>
        We will <strong data-isaction="true">transfer the ownership</strong> of{' '}
        <strong>{assetName}</strong> to {owedName}.
      </>
    ),
    statementOwed: ({ assetName }) => (
      <>
        We will <strong data-isaction="true">transfer the ownership</strong> of{' '}
        <strong>{assetName}</strong> to you.
      </>
    ),
  },
  soleToOwing: {
    title: ({ assetFriendlyType, owedName, owingName }) =>
      `Transfer the ${assetFriendlyType} from ${owedName} to ${owingName}`,
    description: ({ assetFriendlyType, owedName, owingName }) =>
      `If you choose this option, you agree that ownership of the ${assetFriendlyType} will be transferred from ${owedName} to ${owingName}. It will be solely owned by ${owingName}.`,
    info: ({ assetFriendlyType, owedName, owingName }) =>
      `If you choose this option, you agree that ownership of the ${assetFriendlyType} will be transferred from ${owedName} to ${owingName}. It will be solely owned by ${owingName}.`,
    selectedDescription: ({ assetFriendlyType, owingName }) =>
      `You have chosen to transfer the ownership of the ${assetFriendlyType} to ${owingName}. It will go from being solely owned by you to being solely owned by ${owingName}.`,
    statement: ({ assetName, owedName }) => (
      <>
        {capitaliseText(owedName)} will{' '}
        <strong data-isaction="true">transfer the ownership</strong> of{' '}
        <strong>{assetName}</strong> to you.
      </>
    ),
    statementOwed: ({ assetName, owingName }) => (
      <>
        You will <strong data-isaction="true">transfer the ownership</strong> of{' '}
        <strong>{assetName}</strong> to {owingName}.
      </>
    ),
  },
  soleToOwed: {
    title: ({ assetFriendlyType, owedName, owingName }) =>
      `Transfer the ${assetFriendlyType} from ${owingName} to ${owedName}`,
    description: ({ assetFriendlyType, owedName, owingName }) =>
      `If you choose this option, you agree that ownership of the ${assetFriendlyType} will be transferred from ${owingName} to ${owedName}. It will be solely owned by ${owedName}.`,
    info: ({ assetFriendlyType, owedName, owingName }) =>
      `If you choose this option, you agree that ownership of the ${assetFriendlyType} will be transferred from ${owingName} to ${owedName}. It will be solely owned by ${owedName}.`,
    selectedDescription: ({ assetFriendlyType, owedName, owingName }) =>
      `You have chosen to transfer the ownership of the ${assetFriendlyType} to you. It will go from being solely owned by ${owingName} to being solely owned by ${owedName}.`,
    statement: ({ assetName, owedName }) => (
      <>
        You will <strong data-isaction="true">transfer the ownership</strong> of{' '}
        <strong>{assetName}</strong> to {owedName}.
      </>
    ),
    statementOwed: ({ assetName, owingName }) => (
      <>
        {capitaliseText(owingName)} will{' '}
        <strong data-isaction="true">transfer the ownership</strong> of{' '}
        <strong>{assetName}</strong> to you.
      </>
    ),
  },
  sell: {
    title: ({ assetFriendlyType }) => `Sell ${assetFriendlyType}`,
    description: ({ assetFriendlyType }) =>
      `If you choose this option, you will sell the ${assetFriendlyType} and divide the net proceeds between you in an agreed percentage.`,
    info: ({ assetFriendlyType }) =>
      `If you choose this option, you will sell the ${assetFriendlyType} and divide the net proceeds between you in an agreed percentage.`,
    selectedDescription: ({ assetFriendlyType }) =>
      `You have chosen to sell the ${assetFriendlyType} and divide the net proceeds between you based on the percentages that you have agreed.`,
    statement: ({ assetName }) => (
      <>
        We will <strong data-isaction="true">sell</strong>{' '}
        <strong>{assetName}</strong>.
      </>
    ),
    statementOwed: ({ assetName }) => (
      <>
        We will <strong>sell</strong> <strong>{assetName}</strong>.
      </>
    ),
  },
  splitEvenly: {
    title: () => `Split evenly`,
    description: ({ assetFriendlyType }) =>
      `If you choose this option, you will split the ${assetFriendlyType} evenly. It will go from joint ownership to being split evenly between you and your former partner.`,
    info: ({ assetFriendlyType }) =>
      `If you choose this option, you will split the ${assetFriendlyType} evenly. It will go from joint ownership to being split evenly between you and your former partner. You will take the necessary steps to split the ${assetFriendlyType}.`,
    selectedDescription: ({ assetFriendlyType }) =>
      `You have chosen to split the ${assetFriendlyType} evenly. It will go from joint ownership to being split evenly between you and your former partner.`,
    statement: ({ assetName }) => (
      <>
        We will <strong data-isaction="true">evenly split</strong> the{' '}
        <strong>{assetName}</strong>.
      </>
    ),
    statementOwed: ({ assetName }) => (
      <>
        We will <strong data-isaction="true">evenly split</strong> the{' '}
        <strong>{assetName}</strong>.
      </>
    ),
  },
  discharge: {
    title: () => `Discharge`,
    description: ({ assetFriendlyType }) =>
      `If you choose this option, you will discharge the ${assetFriendlyType}.`,
    info: ({ assetFriendlyType }) =>
      `If you choose this option, you will discharge the ${assetFriendlyType}. You will both take the necessary steps to discharge the ${assetFriendlyType} evenly.`,
    selectedDescription: ({ assetFriendlyType }) =>
      `You have chosen to discharge the ${assetFriendlyType}.`,
    statement: ({ assetName }) => (
      <>
        We will <strong data-isaction="true">discharge</strong> the{' '}
        <strong>{assetName}</strong>.
      </>
    ),
    statementOwed: ({ assetName }) => (
      <>
        We will <strong data-isaction="true">discharge</strong> the{' '}
        <strong>{assetName}</strong>.
      </>
    ),
  },
};
