import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Heading3, HeadingSpacedContainer } from 'Common/UI/Text/Headings';
import theme from 'Common/Utils/theme';
import CardContext from '../context';

const StyledHeading3 = styled(Heading3)`
  margin-top: 0;
  font-weight: ${props => (props.toAction === 'true' ? 'bold' : '500')};
`;

const StyledHeadingSpacedContainer = styled(HeadingSpacedContainer)`
  align-items: center;
`;

const HeadingWithIcon = ({ children, toAction, icon }) => (
  <CardContext.Consumer>
    {() => (
      <StyledHeadingSpacedContainer marginBottom={`${theme.padding.xsmall}px`}>
        <StyledHeading3 toAction={toAction}>{children}</StyledHeading3>

        {icon}
      </StyledHeadingSpacedContainer>
    )}
  </CardContext.Consumer>
);

export default HeadingWithIcon;

HeadingWithIcon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  toAction: PropTypes.string,
  isApproved: PropTypes.bool,
};

HeadingWithIcon.defaultProps = {
  children: ['</>'],
  toAction: 'true',
  isApproved: false,
};
