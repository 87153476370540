import React from 'react';

import { SECTION_PROPERTY } from 'Common/constants';
import { ODRS_DASH_PROPERTY_ROUTE } from 'Common/routes';
import { darkerNoBackground } from 'Common/Utils/theme';
import DiviRoute from 'Common/UI/DiviRoute';
import MatterGuard from './Guard/MatterGuard';
import DashboardProperty from '../Pages/DashboardProperty';

const ODRSDashboardProperty = () => (
  <MatterGuard>
    <DiviRoute
      path={ODRS_DASH_PROPERTY_ROUTE}
      component={DashboardProperty}
      theme={darkerNoBackground}
      tab={SECTION_PROPERTY}
    />
  </MatterGuard>
);
export default ODRSDashboardProperty;
