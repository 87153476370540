import { Theme } from 'Common/Utils/theme';
import styled, { css } from 'styled-components';
import SpacedBox from './SpacedBox';

const ResponsiveContainer = styled(SpacedBox)<{
  theme: Theme;
  withPadding?: boolean;
  fullWidth?: boolean;
}>`
  width: 100%;

  ${({ withPadding, theme }) =>
    withPadding &&
    css`
      padding-left: ${theme.padding.medium}px;
      padding-right: ${theme.padding.medium}px;
    `}
  ${({ fullWidth, theme }) =>
    !fullWidth &&
    css`
      max-width: ${theme.sizes.lg.container};
    `}
`;

export default ResponsiveContainer;
