/* eslint-disable react/jsx-no-useless-fragment */
import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import { useAppDispatch } from 'App/State/Store';
import { Theme } from 'Common/Utils/theme';
import {
  openOdie as openOdieAction,
  updateOdie as updateOdieAction,
} from 'App/State/OdieReducer';
import GenericButton, { ButtonVariantProps } from './GenericButton';

type Props = {
  popUpContent: ReactNode;
  theme?: Theme;
  children?: ReactNode;
  className?: string;
  ui?: ReactNode;
  popupButtonText?: string;
} & Partial<ButtonVariantProps>;

export type OdieProps = {
  message: JSX.Element;
  popupButtonText?: string;
};

/**
 * This button is used to open up the side drawer Odie
 * @param ui - You can specify a different button to render
 * @param theme - theme for the button
 */
const LearnMoreButton: React.FC<Props> = ({
  popUpContent: Content,
  popupButtonText = undefined,
  children = 'I need more information',
  ui = undefined,
  className = '',
  theme = undefined,
  ...rest
}) => {
  if (!Content) {
    return null;
  }

  const dispatch = useAppDispatch();

  const updateOdie = (data: OdieProps) => dispatch(updateOdieAction(data));
  const openOdie = () => dispatch(openOdieAction());

  const showOdie = (e: any) => {
    e.preventDefault();
    updateOdie({
      message: <>{Content}</>,
      popupButtonText,
    });
    openOdie();
  };

  // @TODO work out what the correct type should be here
  const Button: any = ui || GenericButton;

  const ButtonMarkup = (
    <Button className={className} onClick={showOdie} {...rest}>
      {children}
    </Button>
  );

  if (theme) return <ThemeProvider theme={theme}>{ButtonMarkup}</ThemeProvider>;

  return <>{ButtonMarkup}</>;
};

export default LearnMoreButton;
