import React, { useState } from 'react';
import styled from 'styled-components';
import NukaCarousel from 'nuka-carousel';
import PropTypes from 'prop-types';

import MobileCenter from 'Common/UI/Layout/MobileCenter';
import RoundButton from 'Common/UI/Button/RoundButton';
import CloseButton from 'Common/UI/Button/CloseButton';
import PreviousButton from 'Common/UI/Button/PreviousButton';

export const CarouselNextButton = styled(RoundButton)`
  padding-left: 12px;
  padding-right: 12px;
  min-width: 105px;
  border-radius: 4px;
`;

const CarouselNavContainer = styled.div`
  display: flex;
`;

export const CarouselContainer = styled.div`
  top: 0;
  left: 0;
  /* transition: all 0.5s ease-out; */
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colours.white};
  position: absolute;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    height: calc(100% - 60px);
    background-color: transparent;
  }

  .slider {
    height: 100% !important;
  }

  & .slide {
    h2,
    p {
      text-align: left;
    }
  }

  & .slider-control-bottomright,
  & .slider-control-bottomleft {
    bottom: -70px !important;
    padding: ${({ theme }) => `0 ${theme.padding.medium}px`};

    @media ${({ theme }) => theme.sizes.md.breakpoint} {
      bottom: -70px !important; /* override plugin inline styles */
      padding: 0;
    }
  }

  .slider-frame {
    @media ${({ theme }) => theme.sizes.md.breakpoint} {
      border-radius: 16px;
    }
  }

  .slider-control-bottomcenter {
    bottom: -75px !important;
    padding-bottom: ${({ theme }) => theme.padding.medium}px;

    @media ${({ theme }) => theme.sizes.md.breakpoint} {
      bottom: 0 !important;
    }

    .paging-item {
      button {
        padding-left: 4px;
        padding-right: 4px;

        .paging-dot {
          background: white !important;
          width: 8px !important;
          height: 8px !important;
        }
      }
    }
  }

  .phone {
    background-image: url('./Phone.svg');
    width: 236px;
    height: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    img {
      display: block;
    }
  }
`;

export const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 2;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    top: ${({ theme }) => theme.spacing[16]};
    right: ${({ theme }) => theme.spacing[16]};
  }
`;

const CarouselNavBullet = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 4px;
  background-color: ${({ theme, active }) =>
    active ? theme.colours.midLightPurple : theme.colours.clickableGrey};
  transition: opacity 0.3s;
`;

const Carousel = ({ children, onClose, LastSlideControls }) => {
  const [slideIndex, updateSlideIndex] = useState(0);
  const slideHeight = window.innerHeight - 50; // @TODO make this less of a magic number

  const renderBottomLeftControls = ({ previousSlide, currentSlide }) => {
    if (currentSlide !== 0) {
      return (
        <PreviousButton
          onClick={e => {
            e.currentTarget.focus();
            previousSlide();
          }}
        >
          Previous
        </PreviousButton>
      );
    }
    return <PreviousButton onClick={onClose}>Previous</PreviousButton>;
  };

  const renderBottomCenterControls = ({
    currentSlide,
    goToSlide,
    slideCount,
  }) => (
    <CarouselNavContainer>
      {Array.from(Array(slideCount).keys()).map(index => (
        <CarouselNavBullet
          key={index}
          active={currentSlide === index}
          onClick={() => goToSlide(index)}
        />
      ))}
    </CarouselNavContainer>
  );

  const renderBottomRightControls = ({
    nextSlide,
    currentSlide,
    slideCount,
  }) => {
    if (currentSlide !== slideCount - 1) {
      return (
        <CarouselNextButton
          onClick={e => {
            e.currentTarget.focus();
            nextSlide();
          }}
          data-cy="carousel-next-button"
        >
          Next
        </CarouselNextButton>
      );
    }
    return LastSlideControls ? <LastSlideControls /> : null;
  };

  return (
    <CarouselContainer>
      {onClose && (
        <StyledCloseButton onClick={onClose}>
          <span>Close</span>
        </StyledCloseButton>
      )}

      <MobileCenter noPadding withBottomControls>
        <NukaCarousel
          initialSlideHeight={slideHeight}
          renderCenterLeftControls={() => null}
          renderCenterRightControls={() => null}
          slideIndex={slideIndex}
          afterSlide={slide => updateSlideIndex(slide)}
          renderBottomLeftControls={renderBottomLeftControls}
          renderBottomRightControls={renderBottomRightControls}
          renderBottomCenterControls={renderBottomCenterControls}
        >
          {children}
        </NukaCarousel>
      </MobileCenter>
    </CarouselContainer>
  );
};

Carousel.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  LastSlideControls: PropTypes.func,
};

Carousel.defaultProps = {
  LastSlideControls: null,
  onClose: null,
};

export default Carousel;
