// Get Blob Url

export default image =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
