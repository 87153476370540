import React from 'react';
import styled from 'styled-components';

import TextButton from 'Common/UI/Button/TextButton';
import GenericButton from 'Common/UI/Button/GenericButton';
import IconPlus from 'Common/Assets/images/icon-plus.svg';
import { SpacerProps } from 'Common/UI/Layout/spacer';
import CardContext from '../context';

const CardButton = styled(GenericButton)<{
  size: string;
}>`
  min-width: ${({ size }) =>
    ({
      fullWidth: '100%',
      small: 'auto',
      normal: '160px',
    }[size as 'fullWidth' | 'small' | 'normal'])};
  padding: 10px 16px 9px;
`;

const AddItemButton = styled(GenericButton)`
  width: 104px;
  min-height: 40px;
  text-align: left;
  padding-top: 0;
  padding-bottom: 0;
  background-image: url('${IconPlus}');
  background-size: 16px 16px;
  background-position: 80% 43%;
  background-repeat: no-repeat;
`;

type Props = {
  size?: string;
  onClick: (e: any) => void;
  subtle?: boolean;
  dataCy?: string;
  type?: 'text' | 'addButton';
  children: React.ReactNode;
};

const ButtonSection: React.FC<Props & SpacerProps> = ({
  children,
  size = 'normal',
  type,
  onClick,
  subtle = false,
  dataCy = 'card-button',
  ...props
}) => (
  <CardContext.Consumer>
    {({ active }) => {
      if (type === 'addButton') {
        return (
          <AddItemButton
            onClick={onClick}
            disabled={!active}
            subtle={subtle}
            data-cy={dataCy}
            {...props}
          >
            {children}
          </AddItemButton>
        );
      }

      if (type === 'text') {
        return (
          <TextButton
            onClick={onClick}
            disabled={!active}
            subtle={subtle}
            data-cy={dataCy}
            {...props}
          >
            {children}
          </TextButton>
        );
      }

      return (
        <CardButton
          size={size}
          onClick={onClick}
          disabled={!active}
          subtle={subtle}
          data-cy={dataCy}
          {...props}
        >
          {children}
        </CardButton>
      );
    }}
  </CardContext.Consumer>
);

export default ButtonSection;
