// this is the hook from https://github.com/haldarmahesh/use-mobile-detect-hook
// it was giving an error in IE11 so I made a local copy and updated it a bit

const getMobileDetect = userAgent => {
  const isAndroid = () => Boolean(userAgent.match(/Android/i));
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i));

  const isMobile = () =>
    Boolean(isAndroid() || isIos() || isOpera() || isWindows());
  const isDesktop = () => !isMobile();
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
  };
};
const useMobileDetect = () => getMobileDetect(navigator.userAgent);

export default useMobileDetect;
