import React from 'react';

import { SECTION_RELATIONSHIP, PAGE_RELATIONSHIP_MAIN } from 'Common/constants';
import { ODRS_DASH_FINANCES_ROUTE } from 'Common/routes';
import Dashboard from '../UI/Dashboard/Dashboard';
import Main from './DashboardRelationship/Main';

const DashboardRelationship: React.FC = () => (
  <Dashboard
    tab={PAGE_RELATIONSHIP_MAIN}
    section={SECTION_RELATIONSHIP}
    pages={{
      [PAGE_RELATIONSHIP_MAIN]: Main,
    }}
    nextPage={ODRS_DASH_FINANCES_ROUTE}
  />
);

export default DashboardRelationship;
