import React from 'react';
import styled from 'styled-components';
import { HeadingSpacedContainer } from 'Common/UI/Text/Headings';
import { HighlightedLabel } from 'Common/UI/Text/Label';
import IconTickSelf from 'Common/Assets/images/icon-tick-self.svg';
import IconTickOther from 'Common/Assets/images/icon-tick-other.svg';
import CardContext from '../context';

const ApprovedWrap = styled(HeadingSpacedContainer)`
  display: flex;
  padding: 13px 0;
  position: relative;
  top: 15px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid transparent;
  margin-bottom: 0;
  &::after {
    position: absolute;
    content: '';
    display: block;
    height: 1px;
    top: 0;
    left: -16px;
    right: -16px;
    background-color: ${props => props.theme.colours.clickableGrey};
  }
`;

const ApprovedTicksWrap = styled.span`
  display: flex;
  justify-content: space-between;
  width: 56px;
`;

const TickSelf = <img src={IconTickSelf} alt="Tick icon" />;
const TickOther = <img src={IconTickOther} alt="Tick icon" />;

// Note this can be expanded later if needed
const getApprovedHeading = (otherName, you, them) => {
  if (you && them) {
    return `You and ${otherName} agreed`;
  }
  if (!you && them) {
    return `${otherName} agreed`;
  }
  if (!you && !them) {
    return `You and ${otherName} have not agreed`;
  }
  return 'Something went wrong';
};

const ApprovedFooter = () => (
  <CardContext.Consumer>
    {({ otherName, you = true, them = true }) => (
      <ApprovedWrap>
        <HighlightedLabel>
          {getApprovedHeading(otherName, you, them)}
        </HighlightedLabel>
        <ApprovedTicksWrap>
          {TickSelf}
          {TickOther}
        </ApprovedTicksWrap>
      </ApprovedWrap>
    )}
  </CardContext.Consumer>
);

export default ApprovedFooter;
