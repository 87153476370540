import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RoundButton from './RoundButton';

import IconPencilBox from 'Common/Assets/images/icon-pencil-box.svg';

const StyleEditButton = styled(RoundButton)`
  background: ${props => props.theme.colours.clickableGrey};
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  min-width: 180px;
  color: ${props => props.theme.colours.black};
`;

const Label = styled.div`
  text-align: left;
  flex: 1;
`;

const Icon = styled.img`
  padding-right: 6px;
`;

const EditButton = ({ children = 'Edit', ...props }) => (
  <StyleEditButton type="button" {...props}>
    <Icon src={IconPencilBox} />
    <Label>{children}</Label>
  </StyleEditButton>
);

EditButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EditButton;
