import { reduxStore } from 'App/State/Store';
import { updateConsent } from 'App/State/AppReducer';

// Updates the store with the consent for a field
const updateConsentToStore = async (fieldName: string, consent: boolean) => {
  const { dispatch } = reduxStore;
  await dispatch(updateConsent({ [fieldName]: consent }));
};

export default updateConsentToStore;
