import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import GenericButton from './GenericButton';
import IconBack from 'Common/Assets/images/icon-arrow-left.svg';
import IconClose from 'Common/Assets/images/icon-close-no-background.svg';

const StyledButton = styled(GenericButton)`
  border-radius: 60px;
  background: ${props => props.theme.colours.clickableGrey};
  color: ${props => props.theme.colours.black};
`;

const CircleButton = styled(GenericButton).attrs(props => ({
  ...props,
  subtle: true,
}))`
  padding: 5px;
  line-height: 1;
  font-size: 0;
  min-width: 0;
  border-radius: 50%;
  width: ${props => props.size};
  height: ${props => props.size};
  color: ${props => props.theme.colours.black};
`;

const NavButton = ({ type, onClick, className, label, ...rest }) => {
  switch (type) {
    case 'done':
      return (
        <StyledButton small onClick={onClick} className={className} {...rest}>
          {label || 'Done'}
        </StyledButton>
      );

    case 'back':
      return (
        <CircleButton
          onClick={onClick}
          className={className}
          type="button"
          size="40px"
          {...rest}
        >
          <img alt="Go back" src={IconBack} />
        </CircleButton>
      );

    case 'close':
    default:
      return (
        <CircleButton
          onClick={onClick}
          className={className}
          type="button"
          size="40px"
          {...rest}
        >
          <img alt="Close" src={IconClose} />
        </CircleButton>
        // <RoundButton onClick={onClick} className={className}><img src={IconClose} alt="Go back" /></RoundButton>
      );
  }
};

NavButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};

NavButton.defaultProps = {
  type: 'done',
  label: 'Done',
  className: undefined,
};

export default NavButton;
