import React from 'react';
import formatCurrency from 'Common/Utils/formatCurrency';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getSingleUserFullName,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import { Statement, QuestionAny } from 'Common/Data/Types/appSections';
import Matter from 'Common/Data/Types/matter';
import { PARTY_B } from 'Common/constants';
import capitaliseText from '../../../../Utils/capitaliseText';

export const yourFinancesBQuestions: QuestionAny[] = [
  {
    name: 'intro',
    heading: (matter: Matter) => (
      <H1Conversational>
        Hi {getSingleUserFullName(matter)}, we&apos;re going to ask you about
        your former partner&apos;s finances and personal belongings. These
        questions are just about your former partner. We&apos;ll ask you about
        your shared finances in another section.
      </H1Conversational>
    ),
    content: () => (
      <Paragraph>
        These questions are important because we will base our suggestions for a
        fair division on the information you give.
      </Paragraph>
    ),
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'intro2',
    label: 'Before you get started.',
    content: (
      <Paragraph large>
        All the amounts you enter should be your former partner&apos;s most
        current values.
      </Paragraph>
    ),
    hint: '',
    type: 'info',
    defaultValue: '',
    index: 1,
    submitButtonLabel: 'I understand',
  },
  {
    name: 'personalSavings',
    label:
      "What is the total amount of savings held in your former partner's bank accounts?",
    hint: '',
    type: 'currency',
    defaultValue: '',
    index: 2,
    optional: true,
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>

          <Paragraph large>
            This should be the total amount your former partner has in any bank,
            building society, credit union or other financial institution as at
            today&apos;s date.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'cash',
    label: 'How much does your former partner have in cash?',
    hint: '',
    type: 'currency',
    defaultValue: '',
    index: 3,
  },
  {
    name: 'haveAnyShares',
    label: 'Do they have any shares?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 4,
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>

          <Paragraph large>
            You can own multiple shares in different companies and you can trade
            or sell your shares to other people in the sharemarket.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'shares',
    label: 'What is the total value of shares your former partner owns?',
    hint: '',
    type: 'currency',
    defaultValue: undefined,
    visible: answer => !!answer.haveAnyShares,
    optional: true,
    index: 5,
  },
  {
    name: 'creditCards',
    label:
      "What is the total amount owing on your former partner's credit cards?",
    hint: '',
    type: 'currency',
    defaultValue: '',
    optional: true,
    index: 6,
  },
  {
    name: 'otherDebts',
    label:
      "What is the total amount of other debts your former partner had when you separated that you haven't already included (not including mortgages)?",
    hint: '',
    type: 'currency',
    defaultValue: '',
    optional: true,
    index: 7,
    help: {
      label: 'What should I include?',
      content: (
        <>
          <H1Conversational>What should I include?</H1Conversational>
          <Paragraph large>
            You should include any debts that you haven&apos;t already listed.
          </Paragraph>
          <Paragraph large>For example:</Paragraph>
          <ul>
            <li>any hire-purchase agreements,</li>
            <li>
              any income tax that your former partner owes for the current
              financial year, and
            </li>
            <li>any unpaid amounts from previous financial years.</li>
          </ul>
        </>
      ),
    },
  },
  {
    name: 'otherAssets',
    label:
      "What is the total amount of other major money or property assets your former partner had when you separated that you haven't included already?",
    hint: '',
    type: 'currency',
    defaultValue: undefined,
    optional: true,
    index: 8,
    help: {
      label: 'What should I include?',
      content: (
        <>
          <H1Conversational>What should I include?</H1Conversational>
          <Paragraph large>
            Please include any other major money or property assets your former
            partner had when you separated that you haven&apos;t listed already.
          </Paragraph>
          <Paragraph large>
            You don&apos;t need to list any shared vehicles here. You can add
            those later in the Vehicles section.
          </Paragraph>
          <Paragraph large>
            You should list any other major money or property assets that you
            haven&apos;t already listed. For example:
          </Paragraph>
          <ul>
            <li>
              you should include any life insurance policies that have a cash
              surrender value,
            </li>
            <li>
              any interest in any other property, including leased property,
            </li>
            <li>
              or any other financial resource, such as a personal injury claim
              or court case or any property from an inheritance when someone
              passes away.
            </li>
          </ul>
        </>
      ),
    },
  },
  {
    name: 'householdItemsValue',
    label:
      'What is the total secondhand resale value of the household and personal items that your former partner owns?',
    hint: '',
    type: 'currency',
    defaultValue: '',
    index: 9,
    help: {
      label: 'What is a household item?',
      content: (
        <>
          <H1Conversational>What is a household item?</H1Conversational>
          <Paragraph large>A household item includes:</Paragraph>

          <ul>
            <li>pieces of furniture</li>
            <li>whitegoods</li>
            <li>kitchen appliances</li>
            <li>artwork</li>
          </ul>
        </>
      ),
    },
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 10,
  },
];

export const yourFinancesBStatement = ({
  matter,
  data,
  card,
  plainText = false,
  withUpdateLink = false,
  hideSensitive = false,
}: Statement) => {
  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'cash',
    'personalSavings',
    'haveAnyShares',
    'shares',
    'creditCards',
    'otherDebts',
    'otherAssets',
    'householdItemsValue',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(yourFinancesBQuestions, data, hideSensitive);
  const partyBName = capitaliseText(
    getFirstName(matter.partyB, PARTY_B, hideSensitive)
  );

  const personalSavings =
    (() => {
      const cashAmount = updated(
        'cash',
        redact('cash', formatCurrency(parseFloat(data.cash as string)))
      );
      if (!data.personalSavings || data.personalSavings === '0') {
        return highlight`${partyBName} has ${cashAmount} in cash and ${updated(
          'personalSavings',
          redact('personalSavings', 'do not')
        )} have any savings in the bank.`;
      }
      return highlight`${partyBName} has ${cashAmount} in cash 
      and ${updated(
        'personalSavings',
        redact(
          'personalSavings',
          formatCurrency(parseFloat(data.personalSavings as string))
        )
      )} savings in the bank.`;
    })() || '';

  const shares =
    (() => {
      if (!data.shares || data.shares === '0') {
        return highlight`They ${updated(
          'haveAnyShares',
          redact('haveAnyShares', 'do not')
        )} have any shares.`;
      }

      return highlight`Their total value of shares at separation is ${updated(
        'shares',
        redact('shares', formatCurrency(parseFloat(data.shares as string)))
      )}.`;
    })() || '';

  const creditCards =
    (() => {
      if (!data.creditCards || data.creditCards === '0') {
        return highlight`They ${updated(
          'creditCards',
          redact('creditCards', 'do not')
        )} have any credit cards.`;
      }

      return highlight`The total amount owing on their credit cards at separation is ${updated(
        'creditCards',
        redact(
          'creditCards',
          formatCurrency(parseFloat(data.creditCards as string))
        )
      )}.`;
    })() || '';

  const otherDebts =
    (() => {
      if (!data.otherDebts || data.otherDebts === '0') {
        return highlight`${partyBName} ${updated(
          'otherDebts',
          redact('otherDebts', 'does not')
        )} have any other debts.`;
      }

      return highlight`The total of other debts your former partner has is ${updated(
        'otherDebts',
        redact(
          'otherDebts',
          formatCurrency(parseFloat(data.otherDebts as string))
        )
      )}.`;
    })() || '';

  const otherAssets =
    (() => {
      if (!data.otherAssets || data.otherAssets === '0') {
        return highlight`They ${updated(
          'otherAssets',
          redact('otherAssets', 'do not')
        )} have any other major assets.`;
      }

      return highlight`The total of other major assets your former partner has is ${updated(
        'otherAssets',
        redact(
          'otherAssets',
          formatCurrency(parseFloat(data.otherAssets as string))
        )
      )}.`;
    })() || '';

  const personalItems = highlight`They have household and personal items in their possession that 
    have a total resale value of ${updated(
      'householdItemsValue',
      redact('householdItemsValue', formatCurrency(data.householdItemsValue))
    )}.`;

  if (plainText) {
    return `${personalSavings} ${shares} ${creditCards} ${otherDebts} ${otherAssets} ${personalItems}`;
  }

  return (
    <>
      {personalSavings} {shares} {creditCards} {otherDebts} {otherAssets}{' '}
      {personalItems}
    </>
  );
};
