import styled from 'styled-components';
import { Heading3 } from 'Common/UI/Text/Headings';
import RoundButton from 'Common/UI/Button/RoundButton';
import theme, { sizes } from 'Common/Utils/theme';

export const SlideInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @media ${sizes.md.breakpoint} {
    height: ${theme.carouselHeight};
  }
`;

export const AboveNavigation = styled.div`
  position: absolute;
  bottom: 30px;
  width: 100%;
  left: 0;
  padding: 0 ${theme.padding.medium}px;

  @media ${sizes.md.breakpoint} {
    bottom: 100px;
  }
`;

export const SlideIcon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 200px;
  margin-bottom: ${theme.padding.medium}px;
  background-color: transparent;

  @media ${sizes.md.breakpoint} {
    min-height: 232px;
  }

  img {
    width: 100%;
    margin: 0;
  }

  ${({ backgroundImage }) =>
    backgroundImage &&
    `

    background-image: url(${backgroundImage});
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;

    img {
      display: none;
    }
  `}
`;

export const SlideText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  padding: 0 ${theme.spacing[6]} ${theme.spacing[8]};
  overflow-y: auto;

  @media ${sizes.md.breakpoint} {
    max-height: 360px;
    padding: 0 ${theme.spacing[8]} ${theme.spacing[4]};
  }

  ${props =>
    props.paddingTop &&
    `
    padding-top: 168px;
    
    @media ${sizes.md.breakpoint} {
      max-height: 100%;
      padding-top: 128px;
    }
  `}

  ${props =>
    props.withButton &&
    `
    padding-bottom: ${theme.spacing[16]};
    
    @media ${sizes.md.breakpoint} {
      padding-bottom: ${theme.spacing[8]};
    }
  `}
`;

export const CarouselButton = styled(RoundButton)`
  padding-left: 16px;
  padding-right: 16px;
  min-width: 105px;
  border-radius: 4px;

  ${props =>
    props.desktopOnly &&
    `
    display: none;
    @media ${props.theme.sizes.md.breakpoint} {
      display: block;
    }
  `};
`;

export const SlideCaption = styled(Heading3)`
  margin-top: 0;
  margin-bottom: ${theme.padding.xxsmall}px;

  @media ${sizes.md.breakpoint} {
    margin-bottom: ${theme.padding.small}px;
  }
`;
