import React from 'react';
import styled from 'styled-components';

import { useAppDispatch } from 'App/State/Store';
import CloseIcon from 'Common/Assets/images/icon-close-white.svg';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import { clearAppStore, setIsLoading } from 'App/State/AppReducer';
import { clearMatterStore } from 'App/State/MatterReducer';

const QuickExitContainer = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  margin-left: auto;
  z-index: 1000;
  width: fit-content;
  padding-top: ${({ theme }) => theme.spacing[8]};
  padding-right: ${({ theme }) => theme.spacing[4]};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-right: ${({ theme }) => theme.spacing[12]};
  }

  @media ${({ theme }) => theme.sizes.lg.breakpoint} {
    padding-right: ${({ theme }) => theme.spacing[16]};
  }
`;

const StyledCloseIcon = styled.img`
  margin-right: 4px;
`;

const StyledQuickExit = styled.button`
  background-color: ${({ theme }) => theme.colours.purple};
  border-radius: 200px;
  text-decoration: none;
  color: white;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  border: none;
`;

const QuickExitLink = () => {
  const dispatch = useAppDispatch();
  const { withLoading } = usePromiseWithLoading();

  return (
    <QuickExitContainer>
      <StyledQuickExit
        onClick={async () => {
          withLoading(async () => {
            // isLoading is used to prevent page from redirecting back to home page before
            // redirecting to the bom website
            await dispatch(setIsLoading(true));
            await dispatch(clearMatterStore());
            await dispatch(clearAppStore());
            window.location.assign('http://www.bom.gov.au/');
          });
        }}
      >
        <StyledCloseIcon src={CloseIcon} />
        Quick exit
      </StyledQuickExit>
    </QuickExitContainer>
  );
};

export default QuickExitLink;
