import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import { Transition } from 'react-transition-group';
import { Theme } from 'Common/Utils/theme';
import { Heading1 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';

const LoadingOverlay = styled.div<{
  theme: Theme;
  transitionState?: string;
  opacity?: number;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  background: rgba(0, 0, 0, ${props => props.opacity});

  opacity: 0;
  transition: all 0.5s;

  ${props =>
    (props.transitionState === 'entering' ||
      props.transitionState === 'entered') &&
    css`
      opacity: 1;
    `}
`;

export const SpinnerBall = styled.div.attrs((props: any) => ({
  size: props.size || '18px',
}))<{
  theme: Theme;
  size?: string;
  colour?: string;
}>`
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 100%;
  background-color: ${props => props.colour || props.theme.colours.white};
  animation: bouncedelay 1.4s infinite ease-in-out both;
`;

export const Spinner = styled.div`
  display: flex;
  > ${SpinnerBall} {
    margin: 4px;
  }

  @keyframes bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    40% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
`;

const Fixed = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 80%;
  max-width: 260px;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Title = styled(Heading1)<{
  theme: Theme;
}>`
  color: ${props => props.theme.colours.white};
  margin: ${({ theme }) => theme.spacing[12]} 0
    ${props => props.theme.spacing[8]};
`;

const Subtitle = styled(Paragraph).attrs(() => ({ large: true }))<{
  theme: Theme;
}>`
  color: ${props => props.theme.colours.white};
`;

const loaderPortal = document.getElementById('loader')!;

type Props = {
  floating?: boolean;
  opacity?: number;
  title?: string;
  subtitle?: string;
  colour?: string;
};

const Loader: React.FC<Props> = ({
  floating,
  opacity,
  title,
  subtitle,
  colour,
}) => {
  const [visible, updateVisible] = useState(false);
  useEffect(() => updateVisible(true), []);

  const spinner = (
    <Fixed>
      <Spinner data-testid="loader" data-cy="loader">
        {[-0.32, -0.16, 0].map(i => (
          <SpinnerBall
            key={i}
            colour={colour}
            style={{
              animationDelay: `${i}s`,
            }}
          />
        ))}
      </Spinner>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Fixed>
  );

  if (floating) {
    return createPortal(
      <Transition in={visible} timeout={1000}>
        {(state: any) => (
          <LoadingOverlay transitionState={state} opacity={opacity}>
            {spinner}
          </LoadingOverlay>
        )}
      </Transition>,
      loaderPortal
    );
  }

  return spinner;
};

Loader.propTypes = {
  floating: PropTypes.bool,
  opacity: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  colour: PropTypes.string,
};

Loader.defaultProps = {
  floating: false,
  opacity: 0.6,
  title: undefined,
  subtitle: undefined,
  colour: undefined,
};

export default Loader;
