import React from 'react';
import MatterGuard from './Guard/MatterGuard';
import DivisionIntroCarousel from '../UI/IntroCarousel/DivisionIntroCarousel';

const ODRSDivisionIntro = () => (
  <MatterGuard>
    <DivisionIntroCarousel />
  </MatterGuard>
);

export default ODRSDivisionIntro;
