const changeCase = (
  [s, ...tring] = '',
  { capitalise = true, lowercase = false } = {}
) => {
  if (s === undefined) {
    return tring.join('');
  }

  if (lowercase) {
    return [s.toLowerCase(), ...tring].join('');
  }

  if (capitalise) {
    return [s.toUpperCase(), ...tring].join('');
  }

  return [s, ...tring].join('');
};

export default changeCase;
