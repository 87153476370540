import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GenericButton from './GenericButton';
import PlusIcon from 'Common/Assets/images/icon-plus.svg';

const StyleAddAnotherButton = styled(GenericButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  min-width: 160px;
`;

const Label = styled.div`
  text-align: left;
  flex: 1;
`;

const Icon = styled.img`
  padding-right: 6px;
`;

const AddAnotherButton = ({ children = 'Add another', ...props }) => (
  <StyleAddAnotherButton subtle type="button" {...props}>
    <Label>{children}</Label>
    <Icon src={PlusIcon} />
  </StyleAddAnotherButton>
);

AddAnotherButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AddAnotherButton;
