export const HOME_ROUTE = '/';
export const INTRO_ROUTE = '/intro';
const ODRS_BASE = '/odrs';

export const MARKETING_SITE = 'https://amica.gov.au';
export const AMICA_ONE = 'https://one.amica.gov.au';

export const ODRS_BASE_ROUTE = ODRS_BASE;
export const ODRS_START_ROUTE = `${ODRS_BASE}/signup`;
export const ODRS_INVITE_PARTNER_ROUTE = `${ODRS_BASE}/signup/invite`;
export const ODRS_INVITE_PARTNER_SENT_ROUTE = `${ODRS_BASE}/signup/invite/sent`;
export const ODRS_RE_SEND_INVITE_ROUTE = `${ODRS_BASE}/signup/re-send-invite`;
export const ODRS_INVITE_PARTNER_BLOCK_ROUTE = `${ODRS_BASE}/signup/invite-block`;
export const ODRS_TERMS_ROUTE = `${ODRS_BASE}/terms`;
export const ODRS_PRIVACY_ROUTE = `${ODRS_BASE}/privacy`;
export const ODRS_LOGIN_ROUTE = `${ODRS_BASE}/login`;
export const ODRS_PASSWORDRESET_ROUTE = `${ODRS_BASE}/password-reset`;
export const ODRS_INVITE_ROUTE = `${ODRS_BASE}/invite`;
export const ODRS_JOIN_ROUTE = `${ODRS_BASE}/invite/join`;
export const ODRS_ABANDON_ROUTE = `${ODRS_BASE}/invite/not-interested`;

export const ODRS_NOTIFICATIONS_ROUTE = `${ODRS_BASE}/notifications`;

export const ODRS_DOCUMENTS_ROUTE = `${ODRS_BASE}/documents`;

/**
 * Dashboard
 * */
export const ODRS_DASH_HOME_ROUTE = `${ODRS_BASE}/dashboard`;
export const ODRS_DASH_PARENTING_ROUTE = `${ODRS_DASH_HOME_ROUTE}/parenting`;
export const ODRS_DASH_PARENTING_PLAN_INTRO_ROUTE = `${ODRS_DASH_PARENTING_ROUTE}/intro`;
export const ODRS_DASH_PROPERTY_ROUTE = `${ODRS_DASH_HOME_ROUTE}/property`;
export const ODRS_DASH_RELATIONSHIP_ROUTE = `${ODRS_DASH_HOME_ROUTE}/relationship`;

// Property dashboard
export const ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE = `${ODRS_DASH_PROPERTY_ROUTE}/money-and-property`;
export const ODRS_PROPERTY_CONTRIBUTIONS_ROUTE = `${ODRS_DASH_PROPERTY_ROUTE}/contributions`;
export const ODRS_PROPERTY_ASSET_DIVISION_ROUTE = `${ODRS_DASH_PROPERTY_ROUTE}/asset-division`;
export const ODRS_PROPERTY_COMPLETE_ROUTE = `${ODRS_DASH_PROPERTY_ROUTE}/complete`;

// Parenting dashboard
export const ODRS_PARENTING_ESSENTIALS_ROUTE = `${ODRS_DASH_PARENTING_ROUTE}/essentials`;
export const ODRS_PARENTING_SCHEDULE_ROUTE = `${ODRS_DASH_PARENTING_ROUTE}/schedule`;
export const ODRS_PARENTING_ADDITIONS_ROUTE = `${ODRS_DASH_PARENTING_ROUTE}/additions`;
export const ODRS_PARENTING_COMPLETE_ROUTE = `${ODRS_DASH_PARENTING_ROUTE}/complete`;
export const ODRS_PARENTING_DOCUMENT_TYPE_ROUTE = `${ODRS_DASH_PARENTING_ROUTE}/document-type`;

// Consent orders
export const ODRS_CONSENT_ORDERS_ROUTE = `${ODRS_BASE}/consent-orders`;
export const ODRS_CONSENT_ORDERS_REQUIREMENTS_ROUTE = `${ODRS_BASE}/requirements`;
export const ODRS_CONSENT_ORDERS_JURISDICTION_ROUTE = `${ODRS_BASE}/jurisdiction`;
export const ODRS_CONSENT_ORDERS_GET_STARTED_ROUTE = `${ODRS_CONSENT_ORDERS_ROUTE}/get-started`;
export const ODRS_CONSENT_ORDERS_INTRO_SLIDER_ROUTE = `${ODRS_CONSENT_ORDERS_GET_STARTED_ROUTE}/intro`;
export const ODRS_CONSENT_ORDERS_RELATIONSHIP_ROUTE = `${ODRS_CONSENT_ORDERS_ROUTE}/relationship`;
export const ODRS_CONSENT_ORDERS_ASSET_ROUTE = `${ODRS_CONSENT_ORDERS_ROUTE}/asset`;
export const ODRS_CONSENT_ORDERS_FINANCES_ROUTE = `${ODRS_CONSENT_ORDERS_ROUTE}/finances`;
export const ODRS_CONSENT_ORDERS_COMPLETE_ROUTE = `${ODRS_CONSENT_ORDERS_ROUTE}/complete`;

export const ODRS_AGREEMENTS_ROUTE = `${ODRS_DASH_HOME_ROUTE}/agreements`;

export const ODRS_NOCHILDREN_ROUTE = `${ODRS_BASE}/no-children`;
export const ODRS_CHILDREN_ROUTE = `${ODRS_BASE}/children`;
export const ODRS_CHILDREN_CONTINUE_ROUTE = `${ODRS_BASE}/children-continue`;
export const ODRS_ABOUTYOU_ROUTE = `${ODRS_BASE}/about`;
export const ODRS_ABOUTYOU_CONTINUE_ROUTE = `${ODRS_BASE}/about-continue`;
export const ODRS_RELATIONSHIP_ROUTE = `${ODRS_BASE}/relationship`;
export const ODRS_RELATIONSHIP_CONTINUE_ROUTE = `${ODRS_BASE}/relationship-continue`;
export const ODRS_VEHICLES_ROUTE = `${ODRS_BASE}/vehicles`;
export const ODRS_SCHEDULE_ROUTE = `${ODRS_BASE}/schedule`;
export const ODRS_PROPERTIES_ROUTE = `${ODRS_BASE}/properties`;
export const ODRS_YOURFINANCES_ROUTE = `${ODRS_BASE}/your-finances`;
export const ODRS_SHAREDFINANCES_ROUTE = `${ODRS_BASE}/shared-finances`;
export const ODRS_CONTRIBUTIONS_ROUTE = `${ODRS_BASE}/contributions`;
export const ODRS_PROFILE_ROUTE = `${ODRS_BASE}/profile`;
export const ODRS_PROFILE_UPDATE_ROUTE = `${ODRS_PROFILE_ROUTE}/update`;
export const ODRS_PROFILE_SIGNOUT_ROUTE = `${ODRS_PROFILE_ROUTE}/signout`;
export const ODRS_STATEMENT_DETAILS = `${ODRS_BASE}/view`;
export const ODRS_YOURSUPERANNUATION_CONTINUE_ROUTE = `${ODRS_BASE}/your-superannuation-continue`;
export const ODRS_RETURN_SPECIFIC_ITEMS_ROUTE = `${ODRS_BASE}/return-specific-items`;
export const ODRS_ASSET_TABLE = `${ODRS_BASE}/asset-table`;
export const ODRS_PROPERTIES_CONTINUE_ROUTE = `${ODRS_BASE}/properties-continue`;
export const ODRS_YOUR_CONTRIBUTIONS_ROUTE = `${ODRS_BASE}/your-contributions`;
export const ODRS_YOURINCOME_ROUTE = `${ODRS_BASE}/your-income`;
export const ODRS_BANKACCOUNT_ROUTE = `${ODRS_BASE}/bank-account`;
export const ODRS_ACQUIRED_SOLD_ITEMS_ROUTE = `${ODRS_BASE}/acquired-and-sold-items`;

export const ODRS_SUPERANNUATION_FUND_CLAUSES_ROUTE = `${ODRS_BASE}/superannuation-fund-clauses`;
export const ODRS_SUPERANNUATION_FUND_CLAUSES_SENT_PFL_SUCCESS_ROUTE = 
  `${ODRS_SUPERANNUATION_FUND_CLAUSES_ROUTE}/letter-sent`;
export const ODRS_SUPERANNUATION_FUND_CLAUSES_NO_CHANGES_SUCCESS_ROUTE = 
  `${ODRS_SUPERANNUATION_FUND_CLAUSES_ROUTE}/no-changes`;

export const ODRS_NOSUPERANNUATION_ROUTE = `${ODRS_BASE}/no-superannuation`;
export const ODRS_YOURSUPERANNUATION_ROUTE = `${ODRS_BASE}/your-superannuation`;
export const ODRS_YOURSUPERANNUATION_CONFIRM_REQUEST_SUPERANNUATION_BALANCE_ROUTE = 
  `${ODRS_YOURSUPERANNUATION_ROUTE}/confirm-request-superannuation-balance`;
export const ODRS_YOURSUPERANNUATION_LETTER_RECEIVED_ROUTE = `${ODRS_YOURSUPERANNUATION_ROUTE}/letter-received`;
export const ODRS_YOURSUPERANNUATION_UPLOAD_LETTER_ROUTE = `${ODRS_YOURSUPERANNUATION_ROUTE}/upload-letter`;
export const ODRS_YOURSUPERANNUATION_LETTER_UPLOAD_SUCCESS_ROUTE = `${ODRS_YOURSUPERANNUATION_UPLOAD_LETTER_ROUTE}/thanks`;

export const ODRS_EDIT_STATEMENT_ROUTE = `${ODRS_BASE}/edit-statement`;
/**
 * DIVISION
 * */
export const ODRS_DIVISION_ROUTE = `${ODRS_BASE}/division`;
export const ODRS_DIVISION_VIEW_SUGGESTED = `${ODRS_BASE}/division/suggested`;
export const ODRS_DIVISION_SPLIT_INTRO_ROUTE = `${ODRS_BASE}/division/split/start`;
export const ODRS_DIVISION_SPLIT_VIEW_ROUTE = `${ODRS_BASE}/division/split/view`;
export const ODRS_DIVISION_AGREE_ROUTE = `${ODRS_BASE}/division/agree`;
export const ODRS_DIVISION_MESSAGE_ROUTE = `${ODRS_BASE}/division/message`;
export const ODRS_DIVISION_MESSAGE_SENT_ROUTE = `${ODRS_BASE}/division/sent`;
export const ODRS_DIVISION_SPLIT_NEGOTIATE_ROUTE = `${ODRS_BASE}/division/split/negotiate`;
export const ODRS_DIVISION_AGREE_WITH_OTHER_ROUTE = `${ODRS_BASE}/division/agreement`;

export const ODRS_DIVISION_ASSETS_INFO_INTRO = `${ODRS_BASE}/division/assets/start`;
export const ODRS_DIVISION_ASSETS_INFO = `${ODRS_BASE}/division/assets`;
export const ODRS_DIVISION_ASSETS_VIEW_OFFER = `${ODRS_BASE}/division/assets/offer`;
export const ODRS_DIVISION_ASSETS_CONFIRM = `${ODRS_BASE}/division/assets/confirm`;
export const ODRS_DIVISION_ASSETS_TRANSFER_JOINT = `${ODRS_BASE}/division/assets/transfer/joint`;
export const ODRS_DIVISION_ASSETS_TRANSFER_JOINT_PROPERTY = `${ODRS_BASE}/division/assets/transfer/joint/property`;
export const ODRS_DIVISION_ASSETS_TRANSFER_SELF = `${ODRS_BASE}/division/assets/transfer/yours`;
export const ODRS_DIVISION_ASSETS_TRANSFER_OTHER = `${ODRS_BASE}/division/assets/transfer/theirs`;
export const ODRS_DIVISION_ASSETS_MESSAGING = `${ODRS_BASE}/division/assets/message`;
export const ODRS_DIVISION_ASSETS_SENT = `${ODRS_BASE}/division/assets/sent`;
export const ODRS_DIVISION_ASSETS_AGREED = `${ODRS_BASE}/division/assets/agreed`;
export const ODRS_DIVISION_ASSETS_ALREADY_STARTED = `${ODRS_BASE}/division/assets/already-started`;

/**
 * PARENTING PLAN
 * */

export const ODRS_PARENTING_PLAN_SCHEDULE = `${ODRS_BASE}/schedule`;
export const ODRS_PARENTING_PLAN_HANDOVERS = `${ODRS_BASE}/handovers`;
export const ODRS_PARENTING_PLAN_BIRTHDAYS = `${ODRS_BASE}/birthdays`;
export const ODRS_PARENTING_PLAN_MOTHERS_FATHERS_DAY = `${ODRS_BASE}/mothers-fathers-day`;
export const ODRS_PARENTING_PLAN_CHRISTMAS = `${ODRS_BASE}/christmas`;
export const ODRS_PARENTING_PLAN_EASTER = `${ODRS_BASE}/easter`;
export const ODRS_PARENTING_PLAN_SCHOOL_HOLIDAY_SHORT = `${ODRS_BASE}/school-holiday-short`;
export const ODRS_PARENTING_PLAN_SCHOOL_HOLIDAY_LONG = `${ODRS_BASE}/school-holiday-long`;
export const ODRS_PARENTING_PLAN_EXTRACURRICULAR = `${ODRS_BASE}/extracurricular`;
export const ODRS_PARENTING_PLAN_PARENT_TEACHER_INTERVIEWS = `${ODRS_BASE}/parent-teacher-interviews`;
export const ODRS_PARENTING_PLAN_PICK_UPS_AND_DROP_OFFS = `${ODRS_BASE}/pick-ups-and-drop-offs`;
export const ODRS_PARENTING_PLAN_DOCTORS_APPOINTMENTS = `${ODRS_BASE}/doctors-appointments`;
export const ODRS_PARENTING_PLAN_MEDICATION = `${ODRS_BASE}/medication`;
export const ODRS_PARENTING_PLAN_TRAVELLING = `${ODRS_BASE}/day-to-day-expenses`;
export const ODRS_PARENTING_PLAN_EXPENSES = `${ODRS_BASE}/expenses`;
export const ODRS_PARENTING_PLAN_COMMUNICATION = `${ODRS_BASE}/communication`;
export const ODRS_PARENTING_PLAN_RELIGION_AND_CULTURE = `${ODRS_BASE}/religion-and-culture`;
export const ODRS_PARENTING_PLAN_COMMUNICATION_METHOD = `${ODRS_BASE}/communication-method`;
export const ODRS_PARENTING_PLAN_COOPERATING = `${ODRS_BASE}/cooperating`;
export const ODRS_PARENTING_PLAN_CONTACT_DETAILS = `${ODRS_BASE}/contact-details`;

/**
 * PAYMENT
 */

export const ODRS_PAYMENT_ROUTE = `${ODRS_BASE}/payment`;
export const ODRS_PAYMENT_REVIEW_ORDER_ROUTE = `${ODRS_PAYMENT_ROUTE}/review`;
export const ODRS_PAYMENT_PAY_FORM_ROUTE = `${ODRS_PAYMENT_ROUTE}/pay`;
export const ODRS_PAYMENT_COMPLETE_ROUTE = `${ODRS_PAYMENT_ROUTE}/complete`;
export const ODRS_PAYMENT_PAST_PAYMENTS_ROUTE = `${ODRS_PAYMENT_ROUTE}/past-payments`;
export const ODRS_PAYMENT_VIEW_PRICING_ROUTE = `${ODRS_PAYMENT_ROUTE}/view-pricing`;

/**
 * Single User Routes
 */

export const ODRS_DASH_FINANCES_ROUTE = `${ODRS_DASH_HOME_ROUTE}/finances`;
export const ODRS_SUA_ABOUTYOU_SELF_ROUTE = `${ODRS_BASE}/about-self`;
export const ODRS_SUA_ABOUTYOU_OTHER_ROUTE = `${ODRS_BASE}/about-other`;

export const ODRS_SUA_YOURDETAILS = `${ODRS_BASE}/your-details`;

export const ODRS_SUA_YOURFINANCES_SELF_ROUTE = `${ODRS_BASE}/your-finances-self`;
export const ODRS_SUA_YOURFINANCES_OTHER_ROUTE = `${ODRS_BASE}/your-finances-other`;

export const ODRS_SUA_YOURSUPERANNUATION_SELF_ROUTE = `${ODRS_BASE}/your-superannuation-self`;
export const ODRS_SUA_YOURSUPERANNUATION_OTHER_ROUTE = `${ODRS_BASE}/your-superannuation-other`;

export const ODRS_SUA_YOURCONTRIBUTIONS_SELF_ROUTE = `${ODRS_BASE}/your-contributions-self`;
export const ODRS_SUA_YOURCONTRIBUTIONS_OTHER_ROUTE = `${ODRS_BASE}/your-contributions-other`;

export const ODRS_DIVISION_INTRO = `${ODRS_BASE}/division/intro`;

// this is used to render some routes in the overlaid drawer on top of other routes
// to make a route do this, in addition to the normal route setup you also need to:
// - add it to this array
// - grab the `useEffect` code that handles it in the component from one of the existing components
// - add it to the `Odie` at the bottom of `ODRS/*/VERSIONED_Index.jsx`
// - make sure that anywhere you are routing users to the route, you use our internal `push` function and pass through the current location object from `useLocation` in the state
export const drawerRoutes = [
  `${ODRS_STATEMENT_DETAILS}/:section/:index?`,
  ODRS_NOTIFICATIONS_ROUTE,
];

export const normalTransitionRoutes = [ODRS_DASH_HOME_ROUTE];
export const verticalTransitionRoutes = [
  ODRS_DIVISION_SPLIT_VIEW_ROUTE,
  ODRS_ABOUTYOU_ROUTE,
  ODRS_CHILDREN_ROUTE,
  ODRS_CHILDREN_CONTINUE_ROUTE,
  ODRS_RELATIONSHIP_ROUTE,
  ODRS_VEHICLES_ROUTE,
  ODRS_SCHEDULE_ROUTE,
  ODRS_PROPERTIES_ROUTE,
  ODRS_YOURSUPERANNUATION_ROUTE,
  ODRS_YOURFINANCES_ROUTE,
  ODRS_SHAREDFINANCES_ROUTE,
  ODRS_CONTRIBUTIONS_ROUTE,
  ODRS_PROFILE_UPDATE_ROUTE,
  ODRS_EDIT_STATEMENT_ROUTE,
];
