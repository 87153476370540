import styled from 'styled-components';

import TextButton from './TextButton';

const PreviousButton = styled(TextButton)`
  color: ${props => props.theme.backButtonColour};
  font-weight: 600;
  padding-left: 0;
  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-left: ${({ theme }) => `${theme.padding.small}px`};
  }
`;

export default PreviousButton;