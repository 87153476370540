import React from 'react';
import styled from 'styled-components';

import { Heading1, Heading2, SubsectionHeading } from 'Common/UI/Text/Headings';
import IconLockLocked from 'Common/Assets/images/icon-lock-locked.svg';
import Paragraph from 'Common/UI/Text/Paragraph';
import NavLinkIcon from 'Common/UI/NavLinkIcon';
import {
  SUGGESTED_DIVISION_LOCKED,
  SUGGESTED_DIVISION_START,
  RELATIONSHIP_DASHBOARD_COMPLETED,
  SUGGESTED_DIVISION_CANT_CONTINUE,
  BANNER_GENERIC_CARDS_UNLOCKED,
  DIALOG_RESTART_SUGGESTED_DIVISION,
} from 'Common/constants';
import useOdie from 'Common/Utils/Hooks/useOdie';
import { Row } from 'Common/UI/Layout/Flex';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import { Theme } from 'Common/Utils/theme';
import {
  DataPage,
  DataSection,
  EphemeralCard,
  EphemeralStateCard,
  QuestionCard,
  QuestionStateCard,
} from 'Common/Data/Types/appSections';
import GenericButton from 'Common/UI/Button/GenericButton';
import { isInResetSuggestedDivisionFlow } from 'Common/Utils/resetSuggestedDivisionHelpers';
import GroupSubHeading from 'Common/UI/Text/GroupSubHeading';
import IconArrowRightWhite from 'Common/Assets/images/icon-arrow-right-white-small.svg';
import IconArrowRightBlack from 'Common/Assets/images/icon-arrow-right-black-small.svg';
import { getPageStatus } from 'App/Utils/sectionHelpers';
import { useAppDispatch } from 'App/State/Store';
import { showDialog } from 'App/State/AppReducer';

const StyledHeading2 = styled(Heading2)`
  flex: 1;
  margin: 0;
`;

const StyledGroupHeading = styled.div`
  flex: 1;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

type GroupHeadingProps = {
  disabled?: boolean;
  infoContent?: () => JSX.Element | null;
  children: React.ReactNode;
};

export const GroupHeading: React.FC<GroupHeadingProps> = ({
  children,
  disabled = false,
  infoContent: InfoContent,
}) => {
  const { HelpIcon } = useOdie({
    message: InfoContent && <InfoContent />,
  });

  return (
    <StyledGroupHeading>
      <StyledHeading2>{children}</StyledHeading2>
      {disabled && (
        <div>
          <img src={IconLockLocked} alt="" />
        </div>
      )}
      {InfoContent && <HelpIcon />}
    </StyledGroupHeading>
  );
};

const StyledHeading1 = styled(Heading1)<{
  theme: Theme;
}>`
  flex: 1;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const StyledPageDescription = styled.div<{
  theme: Theme;
}>`
  flex: 1;
  position: relative;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`;

const StyledGenericButton = styled(GenericButton)`
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

export const PageDescription: React.FC<Partial<DataPage>> = ({
  subTitle,
  description,
  infoContent: InfoContent,
  getPageButtonProps,
}) => {
  const dispatch = useAppDispatch();

  const { HelpIcon } = useOdie({
    message: InfoContent && <InfoContent />,
  });

  const { showButton = false, buttonText = '' } = getPageButtonProps
    ? getPageButtonProps()
    : {};

  return (
    <StyledPageDescription>
      <ResponsiveTextContainer>
        <Row alignItems="center">
          {subTitle && <StyledHeading1>{subTitle}</StyledHeading1>}
          {showButton && (
            <StyledGenericButton
              small
              subtle
              disabled={isInResetSuggestedDivisionFlow()}
              onClick={() =>
                dispatch(
                  showDialog({ dialog: DIALOG_RESTART_SUGGESTED_DIVISION })
                )
              }
            >
              {buttonText}
            </StyledGenericButton>
          )}
          {InfoContent && <HelpIcon />}
        </Row>
        {description && <Paragraph large>{description}</Paragraph>}
      </ResponsiveTextContainer>
    </StyledPageDescription>
  );
};

export const GroupDescription = styled(Paragraph)<{
  theme: Theme;
}>`
  margin-bottom: ${props => props.theme.padding.medium2}px;
`;

export const GroupWrap = styled.div<{
  theme: Theme;
  noMinHeight?: boolean;
  slim?: boolean;
}>`
  margin-top: ${props => (props.slim ? '0' : props.theme.padding.medium2)}px;
  margin-bottom: ${props => (props.slim ? '0' : props.theme.padding.xlarge)}px;
  // Need a minimum height for when show/hiding EmptyChildrenCard
  ${props =>
    !props.noMinHeight &&
    !props.slim &&
    `min-height: ${props.theme.tokens.groupWrapMinHeight}px;`}
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 70px;
  margin-bottom: 40px;
`;

const StyledNextButton = styled(NavLinkIcon)`
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 48px;
  padding: 6px 12px;
  font-size: ${({ theme }) => theme.typography.clickable.button.fontSize};
  border-color: transparent;
  background-color: ${({ theme }) => theme.colours.clickableGrey};
  color: ${({ theme }) => theme.colours.black};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    font-size: ${({ theme }) => theme.typography.clickable.button.md.fontSize};
  }
  > img {
    margin-left: ${({ theme }) => theme.padding.xxsmall}px;
  }
`;

const StyledCompleteNextButton = styled(StyledNextButton)`
  background-color: ${({ theme }) => theme.buttonBackgroundPrimary};
  color: ${({ theme }) => theme.buttonTextPrimary};
`;

export const getNextPage = (section: DataSection, page: string) => {
  const pageIndex = section.children.findIndex(_page => _page.id === page);
  const nextPage = section.children[pageIndex + 1]
    ? section.children[pageIndex + 1]
    : undefined;

  if (!nextPage) {
    return null;
  }

  return nextPage;
};

type NextPageButtonProps = {
  section: DataSection;
  page: string;
};

export const NextPageButton: React.FC<NextPageButtonProps> = ({
  section,
  page,
}) => {
  const nextPage = getNextPage(section, page);

  if (!nextPage) {
    return null;
  }

  const { title = '', route } = nextPage;

  const { completed } = getPageStatus({
    section: section.id,
    page,
  });

  const NextButton = completed ? StyledCompleteNextButton : StyledNextButton;
  const IconArrowRight = completed ? IconArrowRightWhite : IconArrowRightBlack;

  return (
    <ButtonContainer>
      <NextButton to={route}>
        {`Next up: ${title.replace(/^\d. /, '')}`}
        <img src={IconArrowRight} alt="" />
      </NextButton>
    </ButtonContainer>
  );
};

export const addNewCardsAppState = [
  SUGGESTED_DIVISION_LOCKED,
  SUGGESTED_DIVISION_START,
  RELATIONSHIP_DASHBOARD_COMPLETED,
  BANNER_GENERIC_CARDS_UNLOCKED,
];

export const canAddNewCardsOnAppState = (appState: string) =>
  addNewCardsAppState.includes(appState);

const reopenCardsAppState = [SUGGESTED_DIVISION_CANT_CONTINUE];

export const canReopenCardsOnAppState = (appState: string) =>
  reopenCardsAppState.includes(appState);

export const DrawerContent = styled.main`
  padding: 1px 0 ${({ theme }) => theme.padding.xlarge}px;
`;

export const HeaderContainer = styled.div`
  flex-grow: 0;
`;

const StyledSectionHeading = styled(SubsectionHeading)`
  margin-bottom: 0;
  margin-top: ${({ theme }) => theme.spacing[10]};
`;

export const generateSubHeading = (
  data: QuestionStateCard<QuestionCard> | EphemeralStateCard<EphemeralCard>
) => {
  if ('id' in data && 'title' in data) {
    return (
      <GroupSubHeading key={data.id}>
        <StyledSectionHeading>{data.title}</StyledSectionHeading>
      </GroupSubHeading>
    );
  }

  return null;
};
