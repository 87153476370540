import React from 'react';

import { DataGroupRepeatable } from 'Common/Data/Types/appSections';
import { useAppSelector } from 'App/State/Store';
import capitaliseText from 'Common/Utils/capitaliseText';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import {
  InitialRepeatablePromptCard,
  NoneRepeatablePromptCard,
} from './RepeatablePromptCards';

export const EmptyOptionsCard = ({
  group,
  active,
}: {
  group: DataGroupRepeatable;
  active: boolean;
}) => {
  const { id, promptCardData } = group;

  const { self } = useAppSelector(state => state.matter);

  let content;

  let flagName;

  if (group.isIndividual) {
    const { self: selfParty } = getPartyKeys();

    flagName = `hasNoItems${capitaliseText(id)}${selfParty}`;
  } else {
    flagName = `hasNoItems${capitaliseText(id)}`;
  }

  if (self[flagName] && promptCardData.hasNoneState) {
    content = (
      <NoneRepeatablePromptCard
        active={active}
        cardData={promptCardData.none}
        dataCy={`repeatable-prompt-none-${id}`}
      />
    );
  } else {
    content = (
      <InitialRepeatablePromptCard
        active={active}
        cardData={promptCardData.initial}
        dataCy={`repeatable-prompt-initial-${id}`}
      />
    );
  }

  return <>{content}</>;
};
