import React from 'react';
import { useAppSelector } from 'App/State/Store';
import { ThemeProvider } from 'styled-components';
import theme from 'Common/Utils/theme';
import Loader from './Loader';

const AppLoader: React.FC = () => {
  const isLoading = useAppSelector(state => state.app.isLoading);

  return isLoading ? (
    <ThemeProvider theme={theme}>
      <Loader floating />
    </ThemeProvider>
  ) : null;
};

export default AppLoader;
