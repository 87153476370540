import React from 'react';

import formatCurrency from 'Common/Utils/formatCurrency';
import isNumeric from 'Common/Utils/isNumeric';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getSingleUserFullName,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import { Statement, QuestionAny } from 'Common/Data/Types/appSections';
import Matter from 'Common/Data/Types/matter';
import { PARTY_A } from 'Common/constants';

export const contributionsLabel = 'Contributions';

export const contributionsQuestionsA: QuestionAny[] = [
  {
    name: 'intro',
    heading: (matter: Matter) => (
      <H1Conversational>
        Hi {getSingleUserFullName(matter)}, we&apos;re going to ask you about
        the contributions you made during the relationship.
      </H1Conversational>
    ),
    content: () => (
      <Paragraph>
        These questions are important because we will base our suggestions for a
        fair division on the information you give.
      </Paragraph>
    ),
    help: {
      label: 'Why is this important?',
      content: (
        <>
          <H1Conversational>Why is this important?</H1Conversational>

          <Paragraph large>
            Because the contributions that you made throughout the relationship
            can affect how to divide the money and property.
          </Paragraph>
          <Paragraph large>Contributions can include:</Paragraph>
          <ul>
            <li>
              Financial contributions made directly or indirectly such as your
              income or any gift of money (for example, from your parents).
            </li>
            <li>
              Non-financial contributions made directly or indirectly by you,
              which can include care that you provided for the children and
              looking after the family home.
            </li>
          </ul>
        </>
      ),
    },
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'initialPersonalAssetsValue',
    label: () =>
      `What was the value of your assets including money and property when you started living with your former partner?`,
    hint: '',
    type: 'currency',
    defaultValue: '',
    index: 1,
    validate: value =>
      isNumeric(value)
        ? [true]
        : [
            false,
            'Please tell us what your personal money and property assets were worth when you and your partner got together',
          ],
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            This means the total of your own money and property at the start of
            the relationship. It includes how much you had in savings and the
            value of any property you owned.
          </Paragraph>
          <Paragraph large>
            Tell us how much this was worth when your relationship started, not
            how much it is worth now. It will not necessarily mean you will be
            able to keep the things that you owned when you got together but
            they will be considered as part of your contributions by amica when
            assessing the division of property.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'otherContributionsReceived',
    label:
      "Did you receive a lump sum during the relationship? (This doesn't mean your usual income).",
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 2,
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>A lump sum could include:</Paragraph>
          <ul>
            <li>inheritance</li>
            <li>financial gift</li>
            <li>work bonus</li>
            <li>redundancy payout</li>
            <li>workcover payout</li>
            <li>other lump sum</li>
            <li>
              anything that reduced the debts of the relationship or your family
              expenses.
            </li>
          </ul>
        </>
      ),
    },
  },
  {
    name: 'otherContributionsReceivedBenefit',
    label:
      'Did you contribute some or all of the lump sum towards the relationship, in a way that benefitted you and your former partner?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 3,
    visible: answers => !!answers.otherContributionsReceived,
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            For example, to increase the assets of the relationship, reduce your
            mortgage or other debts of the relationship, to renovate your home
            or take a trip together.
          </Paragraph>
          <Paragraph large>
            This doesn&apos;t include amounts that only benefitted you, like
            pursuing your own hobbies, or a trip that didn&apos;t include your
            former partner or your children.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'otherContributionsValue',
    label: 'How much did you contribute from this lump sum?',
    hint: '',
    type: 'select',
    defaultValue: '',
    index: 4,
    visible: answers =>
      !!answers.otherContributionsReceived &&
      !!answers.otherContributionsReceivedBenefit,
    options: () => [
      // TODO: figure out what this means for suggested division. Previously this was an exact value currency field.
      { label: 'Less than $100,000', value: 'less than $100,000' },
      { label: '$100,001 to $400,000', value: '$100,001 to $400,000' },
      { label: '$400,001 to $700,000', value: '$400,001 to $700,000' },
      { label: '$700,001 to $1,000,000', value: '$700,001 to $1,000,000' },
      { label: 'More than $1,000,000', value: 'more than $1,000,000' },
    ],
  },
  {
    name: 'otherContributionWhenReceive',
    label: 'When did you make this contribution?',
    hint: '',
    type: 'select',
    defaultValue: '',
    index: 5,
    visible: answers =>
      !!answers.otherContributionsReceived &&
      !!answers.otherContributionsReceivedBenefit,
    options: () => [
      // TODO: figure out what this means for suggested division. Previously this was an exact value date field.
      { label: 'Less than 2 years ago', value: 'less than 2 years ago' },
      { label: '2 to 5 years ago', value: '2 to 5 years ago' },
      { label: '5 to 10 years ago', value: '5 to 10 years ago' },
      { label: '10 to 15 years ago', value: '10 to 15 years ago' },
      { label: 'More than 15 years ago', value: 'more than 15 years ago' },
    ],
  },
  {
    name: 'otherContributionsRegular',
    label:
      'Did you make regular or occasional financial contributions towards the relationship, in a way that benefitted you and your former partner, or your family?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 6,
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            Don&apos;t include amounts that only benefitted you, like pursuing
            your own hobbies, or a trip that didn&apos;t include your former
            partner or your children
          </Paragraph>
          <Paragraph large>You should include:</Paragraph>
          <ul>
            <li>Bills or loans (including mortgage payments)</li>
            <li>Anything else that contributed to running your household</li>
            <li>Home maintenance or improvements</li>
            <li>Holidays</li>
            <li>School fees, or children’s activities</li>
            <li>
              Anything else that contributed to the assets of the relationship
              or your family expenses.
            </li>
            <li>
              Anything else that reduced the debts of the relationship or your
              family expenses.
            </li>
          </ul>
        </>
      ),
    },
  },
  {
    name: 'otherContributionsRegularValue',
    label: 'How much did you contribute during the relationship?',
    hint: '',
    type: 'select',
    defaultValue: '',
    index: 7,
    visible: answers => !!answers.otherContributionsRegular,
    options: () => [
      // TODO: figure out what this means for suggested division. Previously this was an exact value currency field.
      { label: 'Less than $100,000', value: 'less than $100,000' },
      { label: '$100,001 to $400,000', value: '$100,001 to $400,000' },
      { label: '$400,001 to $700,000', value: '$400,001 to $700,000' },
      { label: '$700,001 to $1,000,000', value: '$700,001 to $1,000,000' },
      { label: 'More than $1,000,000', value: 'more than $1,000,000' },
    ],
  },
  {
    name: 'otherContributionsRegularWhen',
    label: 'When did you make these contributions?',
    hint: '',
    type: 'select',
    defaultValue: '',
    index: 8,
    visible: answers => !!answers.otherContributionsRegular,
    options: () => [
      // TODO: figure out what this means for suggested division. Previously this was an exact value date field.
      {
        label: 'Regularly throughout the relationship',
        value: 'Regularly throughout the relationship',
      },
      {
        label: 'Occasionally at different times during the relationship',
        value: 'Occasionally at different times during the relationship',
      },
      { label: 'Less than 2 years ago', value: 'less than 2 years ago' },
      { label: '2 to 5 years ago', value: '2 to 5 years ago' },
      { label: 'More than 5 years ago', value: 'more than 5 years ago' },
    ],
  },
  {
    name: 'primaryCarer',
    label:
      'Were you the main carer for any children you had in the relationship?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 9,
    help: {
      label: 'Why is this important?',
      content: (
        <>
          <H1Conversational>Why is this important?</H1Conversational>
          <Paragraph large>
            The contributions made as a home maker and looking after the
            children are taken into consideration when dividing money and
            property after separation.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'primaryCarerLength',
    label: 'How long were you the primary carer?',
    hint: '',
    type: 'select',
    options: () => [
      { label: 'Less than 12 months', value: 'less than 12 months' },
      { label: '1 to 2 years', value: '1 to 2 years' },
      { label: '3 to 5 years', value: '3 to 5 years' },
      { label: 'More than 5 years', value: 'more than 5 years' },
    ],
    defaultValue: '',
    index: 10,
    visible: answers => !!answers.primaryCarer,
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 11,
  },
];

export const contributionsStatementA = ({
  matter,
  data,
  card,
  plainText = false,
  withUpdateLink = false,
  hideSensitive = false,
}: Statement) => {
  const { itemHistory } = matter;
  const itemDiff = getUpdatedItemValues(data, itemHistory, [
    'initialPersonalAssetsValue',
    'otherContributionsReceived',
    'otherContributionsReceivedBenefit',
    'otherContributionsValue',
    'otherContributionWhenReceive',
    'otherContributionsRegular',
    'otherContributionsRegularValue',
    'otherContributionsRegularWhen',
    'primaryCarer',
    'primaryCarerLength',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(
    contributionsQuestionsA,
    data,
    hideSensitive
  );

  const yourName = getFirstName(matter.partyA, PARTY_A, hideSensitive);

  const assets = highlight`Your money and property assets were worth 
    ${updated(
      'initialPersonalAssetsValue',
      redact(
        'initialPersonalAssetsValue',
        formatCurrency(data.initialPersonalAssetsValue)
      )
    )} when the relationship began.`;

  let otherContributions;

  if (
    data.otherContributionsReceived &&
    data.otherContributionsReceivedBenefit
  ) {
    otherContributions = highlight`${yourName} contributed a lump sum to the relationship in the range of 
      ${updated(
        ['otherContributionsValue', 'otherContributionsValue'],
        redact('otherContributionsValue', data.otherContributionsValue)
      )} 
      ${updated(
        ['otherContributionWhenReceive', 'otherContributionWhenReceive'],
        redact(
          'otherContributionWhenReceive',
          data.otherContributionWhenReceive
        )
      )}.`;
  } else {
    otherContributions = highlight`${yourName} ${updated(
      'otherContributionsReceived',
      'did not'
    )} 
      receive any inheritance, redundancy payouts, or large gifts during the relationship.`;
  }

  let regularContributions;

  if (data.otherContributionsRegular) {
    regularContributions = highlight`${yourName} made regular financial contributions to the relationship in the range of  ${updated(
      ['otherContributionsRegularValue', 'otherContributionsRegular'],
      redact('paidOthersDebtValue', data.otherContributionsRegularValue)
    )} regularly throughout the relationship.`;
  } else {
    regularContributions = highlight`${yourName} ${updated(
      'otherContributionsRegular',
      'have not'
    )} made financial contributions to the relationship.`;
  }

  let children;

  if (data.primaryCarer) {
    children = highlight`${yourName} were the main primary carer for the children for ${updated(
      ['primaryCarer', 'primaryCarerLength'],
      redact('primaryCarerLength', data.primaryCarerLength as string)
    )}.`;
  } else {
    children = highlight`${yourName} ${updated(
      'primaryCarer',
      'did not'
    )} take time off to look after the children / do not have any children.`;
  }

  if (plainText) {
    return `${assets} ${otherContributions} ${regularContributions} ${children}`;
  }

  return (
    <>
      {assets} {otherContributions} {regularContributions} {children}
    </>
  );
};
