import TagManager from 'react-gtm-module';

const initGTM = trackingId => TagManager.initialize({
  gtmId: trackingId,
});

const dataLayer = async (dataLayerArgs, dataLayerName = 'dataLayer') => {
  TagManager.dataLayer({
    dataLayer: dataLayerArgs,
    dataLayerName,
  });
};

const trackEventGTM = async (
  { eventName, ...rest },
  dataLayerName = 'dataLayer'
) => {
  TagManager.dataLayer({
    dataLayer: { event: eventName, ...rest },
    dataLayerName,
  });
};

export default {
  initGTM,
  trackEventGTM,
  dataLayer,
};
