import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';

const StepTransitionContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: auto;
  overflow: auto;
  padding: 0 0 24px 0;

  ${({ theme }) => theme.tokens.questionsHorizontalSpacing};

  ${({ noLeftPadding }) =>
    noLeftPadding &&
    css`
      margin-left: 0;
      padding-left: 0;

      @media ${({ theme }) => theme.sizes.md.breakpoint} {
        padding-left: 0;
      }
    `}

  &:first-child {
    margin-top: 0;
  }

  .step-transition-fade-up,
  .step-transition-fade-down,
  .step-transition-fade-left,
  .step-transition-fade-right {
    opacity: 1;
    transition: all ${props => props.transitionTime}ms
      cubic-bezier(0.27, 0.82, 0.42, 0.75) ${props => props.transitionTime}ms;
    transition-property: transform, opacity;
  }

  &.step-transition-enter,
  &.step-transition-appear {
    .step-transition-fade-up {
      opacity: 0;
      transform: translateY(-20px);
    }

    .step-transition-fade-down {
      opacity: 0;
      transform: translateY(20px);
    }

    .step-transition-fade-left {
      opacity: 0;
      transform: translateX(-20px);
    }

    .step-transition-fade-right {
      opacity: 0;
      transform: translateX(20px);
    }
  }

  &.step-transition-enter-active,
  &.step-transition-enter-done,
  &.step-transition-appear-active,
  &.step-transition-appear-done {
    .step-transition-fade-up {
      opacity: 1;
      transform: translateY(0);
    }

    .step-transition-fade-down {
      opacity: 1;
      transform: translateY(0);
    }

    .step-transition-fade-left {
      opacity: 1;
      transform: translateX(0);
    }

    .step-transition-fade-right {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &.step-transition-exit,
  &.step-transition-exit-active,
  &.step-transition-exit-done {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;

    .step-transition-fade-up,
    .step-transition-fade-down,
    .step-transition-fade-left,
    .transition-fade-right {
      transition: all ${props => props.transitionTime}ms
        cubic-bezier(0.27, 0.82, 0.42, 0.75);
      transition-property: transform, opacity;
    }

    .step-transition-fade-up {
      opacity: 0;
      transform: translateY(-20px);
    }

    .step-transition-fade-down {
      opacity: 0;
      transform: translateY(20px);
    }

    .step-transition-fade-left {
      opacity: 0;
      transform: translateX(-20px);
    }

    .step-transition-fade-right {
      opacity: 0;
      transform: translateX(20px);
    }
  }
`;

const StepTransition = ({
  transitionTime,
  pageKey,
  children,
  appear = false,
  noLeftPadding,
}) => (
  <TransitionGroup component={null}>
    <CSSTransition
      timeout={transitionTime}
      classNames="step-transition"
      key={pageKey}
    >
      {state => (
        <StepTransitionContainer
          transitionTime={transitionTime}
          transitionState={state}
          noLeftPadding={noLeftPadding}
        >
          {children}
        </StepTransitionContainer>
      )}
    </CSSTransition>
  </TransitionGroup>
);

StepTransition.propTypes = {
  children: PropTypes.node,
  noLeftPadding: PropTypes.bool,
};

StepTransition.defaultProps = {
  children: null,
};

export default StepTransition;
