import { Party } from 'Common/constants';
import { CardAny } from 'Common/Data/Types/appSections';
import MatterPropsJS from 'Common/Utils/MatterProps';

// Filter
export const createdByYou = (creator: Party) =>
  creator === MatterPropsJS('self.party');
export const filterPrivateCards = (card: CardAny) => {
  if (!('hideFromOtherParty' in card)) {
    return true;
  }

  const { hideFromOtherParty, index } = card;

  return !(hideFromOtherParty && index === MatterPropsJS('other.party'));
};
