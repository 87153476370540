import PropTypes from 'prop-types';
import styled from 'styled-components';

import GenericButton from './GenericButton';

const ImageButton = styled(GenericButton)`
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 0;
  line-height: 1;

  &:hover {
    background: none;
    border-color: transparent;
  }
`;

ImageButton.propTypes = {
  className: PropTypes.string,
  invert: PropTypes.bool,
  animated: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

ImageButton.defaultProps = {
  className: '',
  invert: false,
  animated: false,
  fullWidth: false,
};

export default ImageButton;
