import moment from 'moment';
import {
  DATE_FIELD_FORMAT,
  DATE_FORMAT_SHORT,
  TIME_HOUR_MINUTE_FORMAT,
  TIME_HOUR_MINUTE_AM_PM_FORMAT,
} from 'Common/constants';

const getDate = date => {
  const momentDate = date ? moment(date) : moment();
  return {
    dateFieldFormat: () => momentDate.format(DATE_FIELD_FORMAT),
    timeHourMinuteFormat: () => momentDate.format(TIME_HOUR_MINUTE_FORMAT),
    shortFormat: () => momentDate.format(DATE_FORMAT_SHORT),
  };
};

export const formatTimeHhMmToAmPm = value =>
  moment(value, TIME_HOUR_MINUTE_FORMAT).format(TIME_HOUR_MINUTE_AM_PM_FORMAT);

export const formatTimeHhMmToDate = value =>
  moment(value, TIME_HOUR_MINUTE_FORMAT).toDate();

export default getDate;
