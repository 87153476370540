import styled from 'styled-components';

import { Theme } from 'Common/Utils/theme';
import spacer, { SpacerProps } from './spacer';

export const Column = styled.div<
  {
    height?: string;
    alignItems?: string;
    justifyContent?: string;
    margin?: string;
    gap?: number;
    theme: Theme;
  } & SpacerProps
>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ height }) => height || 'auto'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  margin: ${({ margin }) => margin || '0'};
  ${({ gap, theme }) => gap && `gap: ${theme.spacing[gap]};`}

  ${spacer}
`;

export const Row = styled.div<
  {
    alignItems?: string;
    justifyContent?: string;
    gap?: number;
    theme: Theme;
  } & SpacerProps
>`
  display: flex;
  flex-direction: row;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  ${({ gap, theme }) => gap && `gap: ${theme.spacing[gap]};`}

  ${spacer}
`;
