import React from 'react';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import { INTRO_ROUTE, MARKETING_SITE } from 'Common/routes';
import PageContent from 'Common/UI/Layout/PageContent';
import Actions from 'Common/UI/Actions';
import GenericButton from 'Common/UI/Button/GenericButton';
import FloatingCloseButton from 'Common/UI/Button/FloatingCloseButton';
import { Callout } from 'Common/UI/Text/Paragraph';
import MobileCenter from 'Common/UI/Layout/MobileCenter';

import BackgroundShapesTopRight from 'Common/UI/Icons/Background_shapes-2.svg';
import BackgroundShapesMobile from 'Common/UI/Icons/Background-signup-su-shapes.svg';
import BackgroundShapesBottomLeft from 'Common/UI/Icons/Background-signup-su-shapes-2.svg';
import AppLogo from 'App/UI/AppLogo';
import { useAppDispatch } from 'App/State/Store';
import { Title } from 'Common/UI/Text/Headings';
import PageFooter from 'Common/UI/Layout/PageFooter';
import OnboardingContent from './Onboarding.content';

const StyledPageContent = styled(PageContent)`
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledTitle = styled(Title)`
  margin: 80px 0 21px;
  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    margin-top: 20px;
  }
`;

const Superscript = styled.span`
  font-family: 'Founders Grotesk';
  vertical-align: super;
  font-size: 16px;
  font-weight: 500;
  margin-left: -4px;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    margin-left: -8px;
    font-size: 22px;
  }
`;

const StyledPageFooter = styled(PageFooter)``;

const Onboarding: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = (to: string) => dispatch(push(to));

  return (
    <>
      <AppLogo />
      <FloatingCloseButton as="a" href={MARKETING_SITE}>
        Back to home
      </FloatingCloseButton>
      <MobileCenter
        bottomLeftDesktopImage={BackgroundShapesBottomLeft}
        topRightDesktopImage={BackgroundShapesTopRight}
        topRightMobileImage={BackgroundShapesMobile}
      >
        <>
          <StyledPageContent>
            <StyledTitle data-cy="landing-heading">
              {OnboardingContent.title}{' '}
              <Superscript>{OnboardingContent.currentName}</Superscript>
            </StyledTitle>
            <Callout>{OnboardingContent.text}</Callout>
          </StyledPageContent>
          <StyledPageFooter className="transition-fade-down">
            <Actions>
              <GenericButton
                data-cy="signup-button"
                onClick={() => navigate(INTRO_ROUTE)}
              >
                {OnboardingContent.buttonText}
              </GenericButton>
            </Actions>
          </StyledPageFooter>
        </>
      </MobileCenter>
    </>
  );
};

export default Onboarding;
