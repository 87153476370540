import styled, { css } from 'styled-components';
import { Theme } from 'Common/Utils/theme';
import IconDollarLight from 'Common/UI/Icons/Dollar_light.svg';
import IconDollarDark from 'Common/UI/Icons/Dollar_dark.svg';
import IconPercentageDark from 'Common/Assets/images/icon-percentage-dark.svg';
import IconPercentageLight from 'Common/Assets/images/icon-percentage-light.svg';

const Input = styled.input<{
  theme: Theme;
  currency?: boolean;
  percentage?: boolean;
  number?: string;
}>`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colours.lightGrey : theme.inputBackground};
  font-size: ${props => props.theme.typography.forms.input.fontSize};
  line-height: ${props => props.theme.typography.forms.input.lineHeight};
  font-weight: ${props => props.theme.typography.forms.input.fontWeight};
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? 'transparent' : theme.colours.clickableGrey};
  border-radius: 4px;
  padding: 13px 16px;
  -webkit-appearance: none;

  &:focus {
    outline: none;
    border: 2px solid ${props => props.theme.colours.midLighterPurple};
  }

  ::placeholder {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colours.darkGrey : theme.colours.mediumGrey};
    font-weight: normal;
  }

  ${props =>
    props.currency &&
    css`
      background: ${props.theme.inputBackground} url('${IconDollarDark}');
      background-repeat: no-repeat;
      background-position: 16px center;
      padding-left: ${props.theme.padding.medium2}px;
      &:focus {
        background: ${props.theme.colours.offWhite} url('${IconDollarLight}');
        background-repeat: no-repeat;
        background-position: 16px center;
        padding-left: ${props.theme.padding.medium2}px;
      }
    `}

  ${props =>
    props.percentage &&
    css`
      background: ${props.theme.inputBackground} url('${IconPercentageDark}');
      background-repeat: no-repeat;
      background-position: 16px center;
      padding-left: ${props.theme.spacing[12]};
      &:focus {
        background: ${props.theme.colours.offWhite}
          url('${IconPercentageLight}');
        background-repeat: no-repeat;
        background-position: 16px center;
        padding-left: ${props.theme.spacing[12]};
      }
    `}

  ${props =>
    props.number &&
    css`
      //Note: # is %23 in data image
      background: ${props.theme.inputBackground}
        url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="18px"><text x="14" y="14" font-size="15px" fill="%237B798E" font-family="SF UI Display, Arial">${props.number}</text></svg>');
      background-repeat: no-repeat;
      background-position: 2px center;
      padding-left: ${props.theme.padding.medium2}px;
      &:focus {
        background: ${props.theme.colours.offWhite}
          url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="18px"><text x="14" y="14" font-size="15px" fill="%237B798E" font-family="SF UI Display, Arial">${props.number}</text></svg>');
        background-repeat: no-repeat;
        background-position: 2px center;
      }
    `}
`;

export default Input;
