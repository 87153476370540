import { createSlice } from '@reduxjs/toolkit';

type UserState = {
  userAttributes: {
    'custom:notification'?: string;
    email?: string;
    email_verified?: string;
    family_name?: string;
    given_name?: string;
    phone_number?: string;
    phone_number_verified?: string;
    sub?: string;
  };
};

const initialState: UserState = {
  userAttributes: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserAttributesSuccess(state, action) {
      return { ...state, userAttributes: action.payload };
    },
    clearUserStore() {
      return initialState;
    },
  },
});

export const { getUserAttributesSuccess, clearUserStore } = userSlice.actions;

export default userSlice.reducer;
