import { STATUS_APPROVED, STATUS_REMOVED } from 'Common/constants';
import { ChildrenData, Item } from 'Common/Data/Types/matter';
import capitaliseText from './capitaliseText';

const defaultChildName = 'child';
const defaultChildrenName = 'the children';

const generateChildrenString = (
  children: Item[],
  hideSensitive: boolean,
  separator = ', ',
  andOr = 'and'
) => {
  if (hideSensitive) {
    return `[redacted] (${children.length} ${
      children.length > 1 ? 'children' : 'child'
    })`;
  }

  if (children.length > 2) {
    const childrenSeparated = children
      .slice(0, children.length - 1)
      .map(child => child.childName)
      .join(separator);
    return `${childrenSeparated} ${andOr} ${children.slice(-1)[0].childName}`;
  }
  if (children.length > 0) {
    return children.map(child => child.childName).join(` ${andOr}  `);
  }
  return defaultChildName;
};

export const getChildrenFromItems = (items: Item[]) =>
  items
    ? items.filter(
        item =>
          item.BaseSection === 'children' && item.status !== STATUS_REMOVED
      )
    : [];

const hasChildrenBeenConfirmed = (children: Item[]) =>
  children
    .filter(child => child.status !== STATUS_REMOVED)
    .every(child => child.status === STATUS_APPROVED);

export const replaceTextWithChildName = (
  text: string,
  childrenData: ChildrenData,
  hideSensitive: boolean,
  andOr: string
) => {
  const { raw: children = [], moreThanOneChild } = childrenData;

  const childrenString =
    children.length > 0
      ? generateChildrenString(children, hideSensitive, ', ', andOr)
      : defaultChildrenName;

  // Replace [child]
  let modifiedText = text.replace(
    /^\[child\]/g,
    capitaliseText(childrenString)
  );
  modifiedText = modifiedText.replace(/\[child\]/g, childrenString);

  // Replace [has] and [have]
  modifiedText = moreThanOneChild
    ? modifiedText.replace(/\[has\]/g, 'have')
    : modifiedText.replace(/\[has\]/g, 'has');

  return modifiedText;
};

const getChildrenData = (items: Item[]): ChildrenData => {
  const children = getChildrenFromItems(items);
  if (!children.length) {
    return {
      raw: undefined,
      haveBeenConfirmed: false,
      namesArray: undefined,
      moreThanOneChild: false,
    };
  }
  return {
    raw: children,
    haveBeenConfirmed: hasChildrenBeenConfirmed(children),
    namesArray: children.map(child => child.childName),
    moreThanOneChild: children.length > 1,
  };
};

export default getChildrenData;
