import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { push } from 'connected-react-router';
import { TransitionGroup, Transition } from 'react-transition-group';

import { DATE_FORMAT_SHORT } from 'Common/constants';
import { getCard } from 'Common/Data/SingleUser/appSections';
import { ODRS_AGREEMENTS_ROUTE } from 'Common/routes';
import MatterPropsJS from 'Common/Utils/MatterProps';
import capitaliseText from 'Common/Utils/capitaliseText';
import defaultTheme from 'Common/Utils/theme';

import { Heading3 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import GenericButton from 'Common/UI/Button/GenericButton';
import { sendMessageAction } from 'App/State/MatterActions';
import getYouOrThem from 'Common/Utils/getYouOrThem';
import Message from 'Common/UI/Messaging/Message';
import ToneWarning from 'Common/UI/Messaging/ToneWarning';

import MessageTextBox from 'Common/UI/Messaging/MessageTextBox';

export const generateMessage = message =>
  message.message && (
    <Message
      owner={MatterPropsJS('self.party') === message.owner ? 'self' : 'other'}
      ownerName={capitaliseText(getYouOrThem(message.owner))}
      date=""
      className=""
      message={message.message}
      withMargin={false}
      flip
    />
  );

const StyledMessagesHeader = styled.div`
  background: ${props => props.theme.colours.black};
  color: ${props => props.theme.colours.white};
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a {
    float: right;
    color: white;
    text-decoration: none;
    line-height: 2em;
  }

  ${Heading3} {
    float: left;
    line-height: 2em;
    margin: 0;
    color: ${props => props.theme.colours.white};
  }
`;

const MessagesBody = styled.div`
  flex: 1;
  overflow: auto;
  position: relative;
  background-color: ${defaultTheme.colours.clickableGrey};
`;

const MessagesPositionWrap = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
`;

const MessagesFooter = styled.div`
  flex: 0;
`;

const CancelDoneButton = styled(GenericButton)`
  border-radius: 60px;
  padding-left: 32px;
  padding-right: 32px;
  ${({ isCancel, theme }) =>
    isCancel ? `background-color:${theme.colours.darkGrey};` : ''}
`;

const Statement = styled.div`
  margin: ${props => props.theme.padding.small}px;
  padding: ${props => props.theme.padding.small}px;
  background: ${props => props.theme.colours.lightAqua};
  border-radius: 4px;
  z-index: 100;
  position: relative;

  p {
    margin: 0;
  }
`;

class Messages extends React.Component {
  constructor(props) {
    super(props);

    const { match, messageIdentifier, matter } = this.props;

    const { section, index } = messageIdentifier || match.params;

    const cardID = `${section}${index || ''}`;

    let adjustedIndex;

    if (index === 'A' || index === 'B') {
      adjustedIndex = index;
    } else {
      adjustedIndex = Number(index);
    }

    const card = getCard(cardID);

    this.state = {
      cardID,
      section,
      index: adjustedIndex,
      messageInput: '',
      transitionDuration: 500,
      transitionDelay: 100,
      hasSentMessage: false,
      hasDismissedToneWarning: false,
      statement: card
        ? card.statement({ matter, data: card.data, card })
        : false,
    };

    this.onMessageInput = this.onMessageInput.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
  }

  componentDidMount() {
    this.scrollInterval = setInterval(() => {
      const nscroll = this.ref.scrollHeight - this.ref.clientHeight;
      if (this.ref.scrollTop < nscroll) {
        this.ref.scrollTop += 10;
      } else {
        clearInterval(this.scrollInterval);
      }
    }, 10);
  }

  onMessageInput(messageInput) {
    this.setState({ messageInput });
  }

  onMessageSubmit(event) {
    event.preventDefault();

    const { sendMessage, onMessage } = this.props;
    const { section, index, messageInput, hasDismissedToneWarning, cardID } =
      this.state;

    const data = {
      section: cardID,
      owner: MatterPropsJS('self.party'),
      message: messageInput,
      checkTone: !hasDismissedToneWarning,
    };

    sendMessage(data)
      .then(() => {
        this.ref.scrollTop = this.ref.scrollHeight - this.ref.clientHeight;
        this.setState({ messageInput: '', hasSentMessage: true });
      })
      .then(() => onMessage())
      .catch(() => {
        this.setState({ toneWarning: true });
        throw new Error();
      });
  }

  render() {
    const {
      messageInput,
      section,
      index,
      transitionDelay,
      transitionDuration,
      hasSentMessage,
      toneWarning,
      statement,
    } = this.state;

    const { matter, prompt, pageTitle, onDone, onCancel, navigate, disabled } =
      this.props;

    const messages = matter.messages.filter(
      message => message.MessageID.split('|')[0] === `${section}${index || ''}`
    );

    const buildMessages = () => {
      if (messages.length === 0) {
        return null;
      }

      return messages.map((message, messageIndex) => (
        <Transition timeout={transitionDuration} key={message.MessageID}>
          {state => (
            <Message
              owner={
                MatterPropsJS('self.party') === message.owner ? 'self' : 'other'
              }
              ownerName={MatterPropsJS(`party${message.owner}.firstname`)}
              date={moment(message.date).format(DATE_FORMAT_SHORT)}
              message={message.message}
              transitionState={state}
              transitionDuration={transitionDuration}
              transitionDelay={transitionDelay * messageIndex + 500}
            />
          )}
        </Transition>
      ));
    };

    return (
      <>
        <StyledMessagesHeader className="transition-fade-up">
          <Heading3>{pageTitle}</Heading3>
          <CancelDoneButton
            isCancel={!hasSentMessage}
            onClick={() => {
              if (hasSentMessage) {
                // Done
                if (onDone) {
                  onDone();
                } else {
                  navigate(ODRS_AGREEMENTS_ROUTE);
                }
              } else if (onCancel) {
                onCancel();
              } else {
                navigate(ODRS_AGREEMENTS_ROUTE);
              }
            }}
          >
            {hasSentMessage ? 'Done' : 'Cancel'}
          </CancelDoneButton>
        </StyledMessagesHeader>
        <MessagesBody className="transition-fade">
          {statement && (
            <Statement>
              <Paragraph>{statement}</Paragraph>
            </Statement>
          )}
          <MessagesPositionWrap ref={ref => (this.ref = ref)}>
            <TransitionGroup component={null} appear mountOnEnter>
              {buildMessages()}
            </TransitionGroup>
          </MessagesPositionWrap>
          {toneWarning && (
            <ToneWarning
              onClick={() =>
                this.setState({
                  hasDismissedToneWarning: true,
                  toneWarning: false,
                })
              }
            />
          )}
        </MessagesBody>

        <MessagesFooter className="transition-fade-down">
          <MessageTextBox
            onMessageSubmit={this.onMessageSubmit}
            onMessageInput={this.onMessageInput}
            messageInput={messageInput}
            prompt={prompt}
            disabled={disabled || toneWarning}
          />
        </MessagesFooter>
      </>
    );
  }
}

Messages.propTypes = {
  matter: PropTypes.object, // eslint-disable-line
  match: PropTypes.object, // eslint-disable-line
  sendMessage: PropTypes.func.isRequired,
  onMessage: PropTypes.func,
  messageIdentifier: PropTypes.shape({
    section: PropTypes.string.isRequired,
    index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }),
  prompt: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  pageTitle: PropTypes.string,
  onDone: PropTypes.func,
  onCancel: PropTypes.func,
  disabled: PropTypes.bool,
  navigate: PropTypes.func.isRequired,
};

Messages.defaultProps = {
  onMessage: () => {},
  messageIdentifier: null,
  prompt: false,
  pageTitle: 'Messages',
  onDone: null,
  onCancel: null,
  disabled: false,
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  sendMessage: data => dispatch(sendMessageAction(data)),
  navigate: to => dispatch(push(to)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Messages));
