import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FullScreenModal from 'Common/UI/Layout/FullScreenModal';
import GenericButton from 'Common/UI/Button/GenericButton';

const StyledPageContent = styled.div`
  width: 100%;
  padding: ${props => props.theme.padding.small}px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.colours.darkGrey};
  background-color: ${props => props.theme.colours.white};
  color: ${props => props.theme.colours.black};
`;

const StyledButton = styled(GenericButton)`
  display: block;
  margin-top: ${({ theme }) => theme.padding.xsmall}px;
`;

const modalPortal = document.getElementById('modal');

const StatementPopUp = ({ buttonText, content: Content, popUpTitle }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  const portal = createPortal(
    <FullScreenModal
      show={isModalVisible}
      onClose={hideModal}
      title={popUpTitle}
    >
      <StyledPageContent>
        <Content />
      </StyledPageContent>
    </FullScreenModal>,
    modalPortal
  );

  return (
    <>
      <StyledButton subtle small onClick={showModal}>
        {buttonText}
      </StyledButton>
      {portal}
    </>
  );
};

StatementPopUp.propTypes = {
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  buttonText: PropTypes.string,
  popUpTitle: PropTypes.string,
};

StatementPopUp.defaultProps = {
  buttonText: 'view',
  popUpTitle: 'List',
};

export default StatementPopUp;
