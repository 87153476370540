import React from 'react';
import PropTypes from 'prop-types';

import Paragraph from 'Common/UI/Text/Paragraph';
import BubbleNotification from '../BubbleNotification';

const ToneWarning = ({ onClick }) => (
  <BubbleNotification
    onClick={onClick}
    buttonLabel="Got it"
    buttonStyle="rounded"
  >
    <Paragraph>
      It helps to not get emotional or use rude language when you’re
      negotiating. Please rewrite your response.
    </Paragraph>
  </BubbleNotification>
);

ToneWarning.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ToneWarning;
