import {
  CARD_NORMAL,
  CARD_AGREEMENT,
  CARD_STATEMENT,
  DATA_SECTION,
  DATA_GROUP,
  DATA_PAGE,
  Sections,
} from 'Common/constants';

import {
  CardAny,
  DataGroup,
  DataPage,
  DataSection,
} from 'Common/Data/Types/appSections';
import { Item } from 'Common/Data/Types/matter';
import { ODRS_DASH_HOME_ROUTE } from 'Common/routes';
import { getMatter } from 'Common/Utils/MatterProps';
import generateRelationship from './Sections/relationship';
import generateFinances from './Sections/finances';
import generateProperty from './Sections/property';
import generateSuggestedDivision from './Sections/suggestedDivision';

// return an array of existing children cards, property cards, and vehicle cards respectively
const getExistingRepeatableCards = (items: Item[]) => [
  items.filter(item => item.BaseSection === 'children'),
  items.filter(item => item.BaseSection === 'properties'),
  items.filter(item => item.BaseSection === 'vehicles'),
];

export const getAppSections = (): DataSection[] => {
  const { items } = getMatter();

  const [existingChildren, existingProperties, existingVehicles] =
    getExistingRepeatableCards(items);

  return [
    generateRelationship({ existingChildren }),
    generateFinances(),
    generateProperty({
      existingProperties,
      existingVehicles,
    }),
    generateSuggestedDivision(),
  ];
};

const getIDAsArray = (id: string) =>
  Array.isArray(id) ? `${id[0]}${id[1] || ''}` : id;

const sectionsFlattened = (sections: DataSection[]): [] => {
  const reducer = (array: any, el: any) => {
    const { children } = el;
    // eslint-disable-next-line no-param-reassign
    delete el.children;
    array.push(el);

    if (children) {
      return children.reduce(reducer, array);
    }

    return array;
  };

  return sections.reduce(reducer, []);
};

export const getSection = (sectionID: Sections) =>
  getAppSections().find((section: DataSection) => section.id === sectionID);

export const getPage = (sectionID: Sections, pageID: string) => {
  try {
    return getSection(sectionID)?.children.find(
      (page: DataPage) => page.id === pageID
    );
  } catch (error) {
    throw Error(`Page does not exist (${sectionID} / ${pageID})`);
  }
};

export const getOptionalCardIDsInSection = (sectionID: Sections) => {
  const section = getSection(sectionID);

  if (section) {
    return sectionsFlattened([])
      .filter(
        (item: Item) =>
          [CARD_AGREEMENT, CARD_STATEMENT, CARD_NORMAL].includes(item.type) &&
          item.optional
      )
      .map((item: Item) => item.cardID);
  }

  return [];
};

export const getCard = (id: string) => {
  const cardID = getIDAsArray(id);

  const sections = getAppSections();
  const sectionsFlat = sectionsFlattened(sections);

  return sectionsFlat.find((el: any) => el.cardID === cardID);
};

/** Gets a card and returns it as the provided card type
 *
 * @throws if the card is undefined
 *
 * @param cardID - card id
 * @returns returns the card as the provided type
 *
 * @example
 *
 * // card is now definitely defined and of type CardYourSuperannuation
 * const card = definitelyGetCard<CardYourSuperannuation>('yoursuperannuation1A');
 */
export const definitelyGetCard = <T extends CardAny>(cardID: string) => {
  const cardMaybe = getCard(cardID);

  if (cardMaybe === undefined) {
    throw Error(`Card is undefined (${cardID})`);
  }

  return cardMaybe as T;
};

export const getGroupContainingCard = (id: string): DataGroup => {
  const cardID = getIDAsArray(id);

  const sections = getAppSections();
  const sectionsFlat = sectionsFlattened(sections);

  const index = sectionsFlat.findIndex((el: any) => el.cardID === cardID);

  const partialReversed = sectionsFlat.slice(0, index).reverse();

  const group = partialReversed.find((el: any) => el.type === DATA_GROUP);

  if (!group) {
    throw new Error(`Card '${id}' does not have a group`);
  }

  return group;
};

export const getCardDashboardRoute = (id: string) => {
  const cardID = getIDAsArray(id);

  const sections = getAppSections();
  const sectionsFlat = sectionsFlattened(sections);

  const index = sectionsFlat.findIndex((item: any) => item.cardID === cardID);

  const partialReversed = sectionsFlat.slice(0, index).reverse();

  return (
    (
      partialReversed.find((el: any) => el.type === DATA_PAGE) ||
      ({} as DataPage)
    ).route || ODRS_DASH_HOME_ROUTE
  );
};

export const getCardQuestionFlowMetadata = (id: string) => {
  const cardID = getIDAsArray(id);

  const sections = getAppSections();
  const sectionsFlat = sectionsFlattened(sections);

  const index = sectionsFlat.findIndex((item: any) => item.cardID === cardID);

  const partialReversed = sectionsFlat.slice(0, index).reverse();

  const group = (partialReversed.find((el: any) => el.type === DATA_GROUP) ||
    {}) as DataGroup;

  const section = (partialReversed.find(
    (el: any) => el.type === DATA_SECTION
  ) || {}) as DataSection;

  return {
    backgroundImage: section.questionFlowBackgroundImage,
    backgroundColour: section.questionFlowBackgroundColour,
    icon: group.icon || undefined,
  };
};
