import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colours.white};

  ${props =>
    props.withBottomControls &&
    `
    max-height: calc(100% - ${props.theme.carouselcontrolHeight});
    @media ${props.theme.sizes.md.breakpoint} {
      max-height: 100%;
    }
  `};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    background-color: initial;
  }

  &:before {
    content: '';
    position: absolute;
    background-image: ${props =>
      props.topRightMobileImage && `url(${props.topRightMobileImage})`};
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: top right;
    pointer-events: none;
    z-index: 0;

    ${props =>
      props.topRightDesktopImage &&
      `
      @media ${props.theme.sizes.md.breakpoint} {
        background-image: url(${props.topRightDesktopImage});
      }`}
  }

  ${props =>
    props.bottomLeftDesktopImage &&
    `
  &:after {
    @media ${props.theme.sizes.md.breakpoint} {
      content: '';
      position: absolute;
      background-image: url(${props.bottomLeftDesktopImage});
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      background-position: bottom left;
      left: ${props.theme.padding.xlarge}px;
      bottom: ${props.theme.padding.xlarge}px;
      pointer-events: none;
      z-index: 0;
    }
  }
  `};
`;

const MobileResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    background-color: ${({ theme }) => theme.colours.white};
    min-height: 500px;
    max-height: calc(100% - 50px);
    height: ${({ theme }) => theme.carouselHeight};

    max-width: 482px;
    border-radius: ${({ theme }) => theme.padding.small}px;
    padding: ${({ noPadding }) => !noPadding && '24px'};
  }
  @media ${({ theme }) => theme.sizes.xl.breakpoint} {
    max-height: ${({ theme }) => theme.carouselHeight};
  }
`;

const MobileCenter = ({
  children,
  noPadding,
  withBottomControls,
  topRightDesktopImage,
  bottomLeftDesktopImage,
  topRightMobileImage,
}) => (
  <Center
    topRightDesktopImage={topRightDesktopImage}
    bottomLeftDesktopImage={bottomLeftDesktopImage}
    topRightMobileImage={topRightMobileImage}
    withBottomControls={withBottomControls}
  >
    <MobileResponsiveContainer noPadding={noPadding}>
      {children}
    </MobileResponsiveContainer>
  </Center>
);

MobileCenter.propTypes = {
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
  withBottomControls: PropTypes.bool,
  topRightDesktopImage: PropTypes.string,
  bottomLeftDesktopImage: PropTypes.string,
  topRightMobileImage: PropTypes.string,
};

MobileCenter.defaultProps = {
  noPadding: false,
  withBottomControls: false,
  topRightDesktopImage: null,
  bottomLeftDesktopImage: null,
  topRightMobileImage: null,
};

export default MobileCenter;
