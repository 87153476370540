// Jurisdiction State Location map
export const jurisdictionStateLocationsMap: {
  [key: string]: string[];
} = {
  'Australian Capital Territory': ['Canberra'],
  'New South Wales': [
    'Albury',
    'Dubbo',
    'Lismore',
    'Newcastle',
    'Parramatta',
    'Sydney',
    'Wollongong',
  ],
  'Northern Territory': ['Darwin'],
  Queensland: ['Brisbane', 'Cairns', 'Rockhampton', 'Townsville'],
  'South Australia': ['Adelaide'],
  Tasmania: ['Hobart', 'Launceston'],
  Victoria: ['Dandenong', 'Melbourne'],
  'Western Australia': ['Perth'],
};

export const jurisdictionStateOptions = Object.keys(
  jurisdictionStateLocationsMap
);
export const getCourtLocationByState = (stateProp: string) =>
  Object.keys(jurisdictionStateLocationsMap)
    .filter(state => state === stateProp)
    .reduce<string[]>(
      (acc, state) => [...acc, ...jurisdictionStateLocationsMap[state]],
      []
    );
