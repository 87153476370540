import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import useMobileDetect from '../../Utils/Hooks/useDeviceDetect';
import getDate, { formatTimeHhMmToDate } from '../../Utils/getDate';

import 'react-datepicker/dist/react-datepicker.css';

import FormLabel from 'Common/UI/Form/FormLabel';
import Input from './Input';
import IconClock from 'Common/Assets/images/icon-clock.svg';

const StyledInput = styled(Input)`
  padding-left: 50px;
  min-width: 210px;
  // iPhone issue with input height if no time
  height: 48px;
`;

const IconWrap = styled.span`
  position: relative;

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box,
  .react-datepicker__time-container {
    width: 125px;
  }
`;

const Icon = styled.img`
  position: absolute;
  left: 14px;
  top: 14px;
  z-index: 1;
`;

const TimeInput = ({
  label,
  name,
  className,
  onChange,
  value,
  placeholder,
  disabled,
  onBlur,
  formAttributes,
}) => {
  const { max, min, ...restFormAttributes } = formAttributes || {};
  const { isMobile } = useMobileDetect();
  const isMobileDevice = isMobile();

  return (
    <div className={className}>
      <label htmlFor={name}>
        <FormLabel label={label} />
        <IconWrap>
          <Icon src={IconClock} alt="" />
          {isMobileDevice ? (
            <StyledInput
              type="time"
              id={name}
              value={value || null}
              // Saving as HH:mm
              onChange={event => onChange(event.currentTarget.value)}
              onBlur={onBlur}
              placeholder={placeholder || 'Enter time'}
              autoComplete="off"
              disabled={disabled}
              {...restFormAttributes}
            />
          ) : (
            <DatePicker
              // Need to convert time to date for DatePicker to work
              selected={value ? formatTimeHhMmToDate(value) : undefined}
              // Convert date to HH:mm when saving
              onChange={date => onChange(getDate(date).timeHourMinuteFormat())}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Select Time"
              dateFormat="h:mm aa"
              placeholderText={placeholder || 'Select Time'}
              id={name}
              autoComplete="off"
              customInput={
                <StyledInput type="text" id={name} disabled={disabled} />
              }
            />
          )}
        </IconWrap>
      </label>
    </div>
  );
};

TimeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  formAttributes: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

TimeInput.defaultProps = {
  placeholder: '',
  className: '',
  disabled: false,
  onBlur: () => {},
  formAttributes: {},
  value: '',
};

const StyledTime = styled(TimeInput)`
  label {
    display: flex;
    flex-direction: column;
  }
`;

export default StyledTime;
