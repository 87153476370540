import {
  PROPERTY_DASHBOARD_COMPLETED,
  NEW_PARENTING_PLAN_SELF_NOT_READY,
  PARENTING_PLAN_AGREED,
} from 'Common/constants';

export const sessionStorageSave = (key, obj) =>
  window.sessionStorage.setItem(key, JSON.stringify(obj));

export const sessionStorageRemove = key =>
  window.sessionStorage.removeItem(key);

export const sessionStorageLoad = key =>
  JSON.parse(window.sessionStorage.getItem(key));

export const clearAllDismissSessionStorageParenting = () => {
  sessionStorageRemove(`${NEW_PARENTING_PLAN_SELF_NOT_READY}Dismissed`);
  sessionStorageRemove(`${PARENTING_PLAN_AGREED}Dismissed`);
};

export const clearAllDismissSessionStorageProperty = () => {
  sessionStorageRemove(`${PROPERTY_DASHBOARD_COMPLETED}Dismissed`);
};
