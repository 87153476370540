import React from 'react';
import styled, { css } from 'styled-components';
import {
  MESSAGE_STATUS_MESSAGE,
  MESSAGE_STATUS_STATEMENT_CREATED,
  MESSAGE_STATUS_UPDATE_REQUESTED,
  MESSAGE_STATUS_UPDATE_DONE,
  MESSAGE_STATUS_APPROVED,
  MESSAGE_STATUS_REOPENED,
  MESSAGE_STATUS_REMOVED,
  PartyKeys,
  MessageStatus,
} from 'Common/constants';
import { Theme } from 'Common/Utils/theme';
import { Label } from 'Common/UI/Text/Label';
import Paragraph from 'Common/UI/Text/Paragraph';

const MessageHeader = styled.div<{
  theme: Theme;
}>`
  color: ${props => props.theme.colours.black};
  min-height: 18px;
`;

const MessageContent = styled.div`
  flex: 1;
`;

const SystemMessage = styled(Paragraph)<{
  theme: Theme;
  hasUserMessage?: boolean;
  owner: PartyKeys;
}>`
  color: ${props => props.theme.colours.mediumGrey};
  max-width: 200px;
  margin: 0 0
    ${props => (props.hasUserMessage ? props.theme.padding.small : '0')}px;
  text-align: ${props => (props.owner === 'self' ? 'right' : 'left')};
`;

const Date = styled(Label)`
  margin: 0;
  color: ${props => props.theme.colours.midLightGrey};
  font-weight: normal;
`;

const Owner = styled(Label)<{
  theme: Theme;
}>`
  margin: 0 0 8px;
  color: ${props => props.theme.colours.black};
  font-weight: bold;
`;

const MessageText = styled(Paragraph)<{
  theme: Theme;
}>`
  flex: 1;
  margin: 0;
  color: ${props => props.theme.colours.black};
`;

const MessageContainer = styled.div<{
  theme: Theme;
  owner: PartyKeys;
  transitionDuration: number;
  transitionDelay: number;
  transitionState: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.owner === 'self' ? 'flex-end' : 'flex-start')};
  margin: ${props => props.theme.padding.medium}px 16px;
  transition: all ${props => props.transitionDuration}ms
    ${props => props.transitionDelay}ms;

  ${props =>
    props.transitionState === 'exited' &&
    css`
      opacity: 0;
    `}

  ${props =>
    (props.transitionState === 'entering' ||
      props.transitionState === 'entered') &&
    css`
      opacity: 1;
    `};
`;

const StyledMessage = styled.div<{
  theme: Theme;
  owner: PartyKeys;
  fullWidth?: boolean;
}>`
  border-radius: ${props =>
    props.owner === 'self' ? '16px 2px 16px 16px' : '2px 16px 16px 16px'};
  background: ${props =>
    props.owner === 'self'
      ? props.theme.colours.pastelPurple
      : props.theme.colours.lightAqua};
  display: inline-flex;
  flex-direction: column;
  width: 70%;
  max-width: 300px;
  padding: 12px 16px;
  word-wrap: break-word;

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`;

type Props = {
  date: string;
  owner: PartyKeys;
  fullWidth?: boolean;
  message?: string;
  transitionState?: string;
  transitionDuration?: number;
  transitionDelay?: number;
  className?: string;
  status?: MessageStatus;
  youOrThem?: string;
  cardTypeName?: string;
  showLabel?: boolean;
  dataCy?: string;
};

const Message: React.FC<Props> = ({
  date,
  owner,
  fullWidth = false,
  message = '',
  transitionState = 'entered',
  transitionDuration = 0,
  transitionDelay = 0,
  className,
  status = MESSAGE_STATUS_MESSAGE,
  youOrThem = '',
  cardTypeName = 'statement',
  showLabel = false,
  dataCy,
}) => {
  let isSystemMessage = false;
  let systemMessage;

  if (status !== MESSAGE_STATUS_MESSAGE) {
    isSystemMessage = true;

    if (status === MESSAGE_STATUS_STATEMENT_CREATED) {
      systemMessage = (
        <>
          {youOrThem} <strong>created a {cardTypeName}</strong>
        </>
      );
    } else if (status === MESSAGE_STATUS_UPDATE_REQUESTED) {
      systemMessage = (
        <>
          {youOrThem} <strong>requested to change</strong> this {cardTypeName}
        </>
      );
    } else if (status === MESSAGE_STATUS_UPDATE_DONE) {
      systemMessage = (
        <>
          {youOrThem} <strong>made an edit to the {cardTypeName}</strong>
        </>
      );
    } else if (status === MESSAGE_STATUS_APPROVED) {
      systemMessage = (
        <>
          {youOrThem} <strong>approved the {cardTypeName}</strong>
        </>
      );
    } else if (status === MESSAGE_STATUS_REOPENED) {
      systemMessage = (
        <>
          {youOrThem} <strong>re-opened the {cardTypeName}</strong>
        </>
      );
    } else if (status === MESSAGE_STATUS_REMOVED) {
      systemMessage = (
        <>
          {youOrThem} <strong>removed the {cardTypeName}</strong>
        </>
      );
    }
  }

  return (
    <MessageContainer
      owner={owner}
      transitionState={transitionState}
      transitionDuration={transitionDuration}
      transitionDelay={transitionDelay}
      data-cy={dataCy}
    >
      <Date>{date}</Date>
      {isSystemMessage && (
        <SystemMessage owner={owner} hasUserMessage={!!message}>
          {systemMessage}
        </SystemMessage>
      )}
      {showLabel && (
        <Paragraph>{youOrThem} left the following message</Paragraph>
      )}
      {message && (
        <StyledMessage
          owner={owner}
          fullWidth={fullWidth}
          className={className}
        >
          <MessageHeader>
            <Owner>{youOrThem}</Owner>
          </MessageHeader>
          <MessageContent>
            <MessageText>{message}</MessageText>
          </MessageContent>
        </StyledMessage>
      )}
    </MessageContainer>
  );
};

export default Message;
