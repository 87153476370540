import styled from 'styled-components';
import GenericButton from './GenericButton';

const HelpButton = styled(GenericButton).attrs(() => ({
  help: true,
}))`
  width: ${({ width }) => width || 'auto'};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  min-height: 40px;
  padding-top: 0;
  padding-bottom: 0;
`;

export default HelpButton;
