import {
  MESSAGE_STATUS_REMOVED,
  MESSAGE_STATUS_REOPENED,
  MESSAGE_STATUS_MESSAGE,
  MESSAGE_STATUS_UPDATE_REQUESTED,
} from 'Common/constants';
import MatterPropsJS from 'Common/Utils/MatterProps';

const getMessages = (messages = [], cardID = null) => {
  const selectedMessages = cardID
    ? messages.filter(message => message.section === cardID)
    : messages;

  return {
    withMessage: () => selectedMessages.filter(message => message.message),
    withStatus: status =>
      selectedMessages.filter(message => message.status === status),
    lastMessage: () =>
      selectedMessages && selectedMessages.length > 0
        ? selectedMessages[selectedMessages.length - 1]
        : false,
  };
};

export const getLatestUserMessage = (messages, cardID) => {
  const userMessages = getMessages(messages, cardID).withMessage();
  return {
    latest: userMessages[userMessages.length - 1],
    count: userMessages.length,
  };
};

const getLastMessageOwner = (messages, cardID, status = null) => {
  const selectedMessages = status
    ? getMessages(messages, cardID).withStatus(status)
    : messages;
  return selectedMessages.length > 0
    ? selectedMessages[selectedMessages.length - 1].owner
    : false;
};

export const youRemovedPreviousMessage = (messages, cardID) => {
  const you = MatterPropsJS('self.party');
  const owner = getLastMessageOwner(messages, cardID, MESSAGE_STATUS_REMOVED);
  return owner === you;
};

export const youWereTheLastInDiscussion = (messages, cardID) => {
  const you = MatterPropsJS('self.party');
  const selectedMessages = messages.filter(
    message =>
      message.section === cardID &&
      (message.status === MESSAGE_STATUS_MESSAGE ||
        message.status === MESSAGE_STATUS_UPDATE_REQUESTED)
  );

  if (selectedMessages && selectedMessages.length > 0) {
    return selectedMessages[selectedMessages.length - 1].owner === you;
  }

  return false;
};

export const youReopenedPreviousMessage = (messages, cardID) => {
  const you = MatterPropsJS('self.party');
  const owner = getLastMessageOwner(messages, cardID, MESSAGE_STATUS_REOPENED);
  return owner === you;
};

export const getNumberOfBackAndForth = (messages, cardID) => {
  const cardMessages = getMessages(messages, cardID).withMessage();
  if (!cardMessages) return false;
  return cardMessages
    .map(message => message.owner)
    .reduce((count, message, index, messageArray) => {
      if (message !== messageArray[index - 1]) {
        return count + 1;
      }
      return count;
    }, 0);
};

export default getMessages;
