import React, { useState } from 'react';
import styled from 'styled-components';

import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import PreviousNextButtons from 'Common/UI/Questions/PreviousNextButtons';

import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import CheckboxField from 'Common/UI/Form/CheckboxField';
import { InnerContentMiddle } from 'Common/UI/Signup/Layout';
import Actions from 'Common/UI/Actions';
import SuggestedDivisionRangeContent from './SuggestedDivisionRange.content';

export const StyledCheckboxField = styled(CheckboxField)`
  margin: 0;
  align-self: flex-start;
`;

const StyledH1Conversational = styled(H1Conversational)`
  margin-top: ${({ theme }) => theme.spacing[8]};
  margin-bottom: ${({ theme }) => theme.spacing[12]};
`;

const StyledInnerContentMiddle = styled(InnerContentMiddle)`
  margin-bottom: ${({ theme }) => theme.spacing[16]};

  @media (max-height: 1000px) {
    display: block;
  }
`;

type Props = {
  goBack: () => void;
  goNext: () => void;
  onChange: (key: string, value: boolean) => void;
};

const SuggestedDivisionRange: React.FC<Props> = ({
  goBack,
  goNext,
  onChange,
}) => {
  const [confirmedRangeOnly, setConfirmedRangeOnly] = useState(false);
  const [isQuestionSubmitting, setIsQuestionSubmitting] = useState(false);

  const clickConfirmedRangeOnly = (value: boolean) => {
    setConfirmedRangeOnly(value);
    onChange('confirmedRangeOnly', value);
  };

  const onHandleNext = () => {
    setIsQuestionSubmitting(true);
    goNext();
  };

  return (
    <>
      <StyledInnerContentMiddle>
        <ResponsiveTextContainer className="step-transition-fade-up">
          <StyledH1Conversational>
            {SuggestedDivisionRangeContent.title}
          </StyledH1Conversational>

          <Actions noPadding>
            <StyledCheckboxField
              value={confirmedRangeOnly}
              name="confirmedRangeOnly"
              label={
                <Paragraph>
                  {SuggestedDivisionRangeContent.rangeCheckboxText}
                </Paragraph>
              }
              onChange={updateValue => clickConfirmedRangeOnly(updateValue)}
            />
          </Actions>
        </ResponsiveTextContainer>
      </StyledInnerContentMiddle>

      <div>
        <PreviousNextButtons
          hasPreviousQuestion
          onBack={goBack}
          onNext={onHandleNext}
          isInputValid={confirmedRangeOnly && !isQuestionSubmitting}
          nextButtonLabel="Next"
          data-cy="confirm-button"
          className="step-transition-fade-down"
        />
      </div>
    </>
  );
};

export default SuggestedDivisionRange;
