import React from 'react';
import PropTypes from 'prop-types';

import { useAppDispatch } from 'App/State/Store';
import ImageButton from 'Common/UI/Button/ImageButton';
import {
  openOdie as openOdieAction,
  updateOdie as updateOdieAction,
} from 'App/State/OdieReducer';
import IconMoreInformation from 'Common/Assets/images/icon-more-information.svg';

const HelpIcon = ({ onClick, className }) => (
  <ImageButton onClick={onClick} className={className}>
    <img src={IconMoreInformation} alt="More information" />
  </ImageButton>
);

HelpIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

HelpIcon.defaultProps = {
  className: '',
};

/**
 * useOdie hook
 * ************
 *
 * To use, pass in your content object and then use either handleOpenOdie
 * or HelpIcon, depending on your needs
 *
 * @param {object} content
 */
const useOdie = content => {
  const dispatch = useAppDispatch();

  const updateOdie = c => dispatch(updateOdieAction(c));
  const openOdie = () => dispatch(openOdieAction());

  const handleOpenOdie = () => {
    updateOdie(content);
    openOdie();
  };

  return {
    openOdie, // you shouldn't need this
    updateOdie, // you shouldn't need this
    handleOpenOdie, // the click handler if you want to use it in a custom way
    HelpIcon: () => <HelpIcon onClick={handleOpenOdie} />, // the generic question mark help icon with the click handler already attached
  };
};

export default useOdie;
