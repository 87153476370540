import Matter from 'Common/Data/Types/matter';

type Props = {
  state: Matter;
  countKey: string;
  flag: string;
  counter: -1 | 1;
};
/**
 * This state function gets the setup item in the matter and updates the count for a
 * multi-item such as children, vehicles or property.
 * Calculates the flag based off the updated count
 * Can increment or decrement
 */
const updateNumForMultiItem = ({ state, countKey, flag, counter }: Props) => {
  const newState = { ...state };

  const index = newState.items.findIndex(item => item.SectionID === 'setup');
  const setupItem = newState.items[index];

  const currentCount = setupItem[countKey] || 0;
  const updatedCount = currentCount + counter;

  const isFlagTrue = updatedCount >= 1;

  return {
    ...state,
    items: [
      ...state.items.slice(0, index),
      {
        ...setupItem,
        [countKey]: isFlagTrue ? updatedCount : 0,
        [flag]: isFlagTrue,
      },
      ...state.items.slice(index + 1),
    ],
  };
};

export default updateNumForMultiItem;
