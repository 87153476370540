import formatCurrency from './formatCurrency';

/**
 * Calculates and returns the various data from a MultiItems question
 *
 * @param {array} value - array of objects with keys text/number
 */
const calculateMultiItemsValue = value => {
  let string = '';

  if (Array.isArray(value) && value.length > 0) {
    if (value[0].number) {
      // items with values
      if (value.length === 1) {
        string = `${value[0].text} (${formatCurrency(value[0].number)})`;
      } else if (value.length > 1) {
        const firstItems = value.slice(0, value.length - 1);
        const lastItem = value.slice(-1)[0];

        string = `${firstItems
          .map(item => `${item.text} (${formatCurrency(item.number)})`)
          .join(', ')} 
          and ${lastItem.text} (${formatCurrency(lastItem.number)})`;
      }
    } else {
      // list only (no values)
      // eslint-disable-next-line no-lonely-if
      if (value.length === 1) {
        string = `${value[0].text}`;
      } else if (value.length > 1) {
        const firstItems = value.slice(0, value.length - 1);
        const lastItem = value.slice(-1)[0];

        string = `${firstItems.map(item => `${item.text}`).join(', ')} 
          and ${lastItem.text}`;
      }
    }

    return {
      hasItems: value.length > 0,
      total: value.reduce((acc, cur) => (acc += Number(cur.number)), 0),
      string,
      count: value.length,
      items: value,
    };
  }

  return {
    hasItems: false,
    total: 0,
    string,
    count: 0,
    items: [],
  };
};

export default calculateMultiItemsValue;
