import { Item } from 'Common/Data/Types/matter';
import moment from 'moment';

const setupChildItem: Item = {
  MatterID: 'c92a51b0-4918-11ed-9d8c-ed08f24365a6', // may need to change this
  ownVehicles: false,
  creator: 'A',
  status: 'STATUS_APPROVED',
  created: moment().toISOString(),
  BaseSection: 'setup',
  numChildren: 1,
  parentingPlan: true,
  SectionID: 'setup',
  haveChildren: true,
  ownProperties: false,
  modified: moment().toISOString(),
};

export default setupChildItem;
