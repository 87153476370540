import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Label, HighlightedLabel } from 'Common/UI/Text/Label';
import Paragraph from 'Common/UI/Text/Paragraph';

const MessageHeader = styled.div`
  color: ${props => props.theme.colours.black};
  min-height: 18px;
`;

const MessageContent = styled.div`
  flex: 1;
`;

const Date = styled(Label)`
  margin: 0;
  color: ${props => props.theme.colours.darkGrey};
  font-weight: normal;
`;

const Owner = styled(HighlightedLabel)`
  margin: 0;
  color: black;
`;

const MessageText = styled(Paragraph)`
  flex: 1;
  margin-bottom: 0;
  margin-top: 8px;
  color: black;
`;

const MessageContainer = styled.div`
  display: flex;
  justify-content: ${props =>
    props.owner === 'self' ? 'flex-end' : 'flex-start'};
  ${props =>
    props.withMargin &&
    css`
      margin-top: ${props.theme.padding.xsmall}px;
      margin-bottom: ${props.theme.padding.medium}px;
      margin-left: 16px;
      margin-right: 16px;
    `}
  transition: all ${props => props.transitionDuration}ms ${props =>
    props.transitionDelay}ms;

  ${props =>
    props.transitionState === 'exited' &&
    css`
      opacity: 0;
    `}

  ${props =>
    (props.transitionState === 'entering' ||
      props.transitionState === 'entered') &&
    css`
      opacity: 1;
    `};
`;

const StyledMessage = styled.div`
  background: ${props =>
    props.owner === 'self'
      ? props.theme.colours.pastelPurple
      : props.theme.colours.lightAqua};
  display: inline-flex;
  flex-direction: column;
  width: 70%;
  max-width: 300px;
  padding: ${props => props.theme.padding.xsmall}px
    ${props => props.theme.padding.small}px;
  overflow-wrap: break-word;
  word-break: break-all;

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`;

const StyledMessageNormal = styled(StyledMessage)`
  border-radius: ${props =>
    props.owner === 'self' ? '16px 16px 2px 16px' : '16px 16px 16px 2px'};
`;

const StyledMessageFlip = styled(StyledMessage)`
  border-radius: ${props =>
    props.owner === 'self' ? '16px 2px 16px 16px' : '2px 16px 16px 16px'};
`;

const Message = ({
  date,
  ownerName,
  owner,
  fullWidth,
  message,
  transitionState,
  transitionDuration,
  transitionDelay,
  className,
  withMargin = true,
  flip,
}) => {
  const StyledMessageContainer = flip ? StyledMessageFlip : StyledMessageNormal;
  return (
    <MessageContainer
      owner={owner}
      transitionState={transitionState}
      transitionDuration={transitionDuration}
      transitionDelay={transitionDelay}
      withMargin={withMargin}
      data-cy="message-bubble"
    >
      <StyledMessageContainer
        owner={owner}
        fullWidth={fullWidth}
        className={className}
      >
        <MessageHeader>
          <Owner>{ownerName}</Owner>
          <Date>{date}</Date>
        </MessageHeader>
        <MessageContent>
          <MessageText>{message}</MessageText>
        </MessageContent>
      </StyledMessageContainer>
    </MessageContainer>
  );
};

Message.propTypes = {
  owner: PropTypes.string.isRequired,
  ownerName: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  transitionState: PropTypes.string,
  transitionDuration: PropTypes.number,
  transitionDelay: PropTypes.number,
  className: PropTypes.string.isRequired,
  withMargin: PropTypes.bool,
  flip: PropTypes.bool,
};

Message.defaultProps = {
  fullWidth: false,
  transitionState: 'entered',
  transitionDuration: 0,
  transitionDelay: 0,
  withMargin: true,
  flip: false,
};

export default Message;
