import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import React from 'react';

const SuggestedDivisionReadyBanner = () => (
  <>
    <Heading2MarginTopNone>
      amica one has come up with a suggested division of money and property for
      you and your former partner.
    </Heading2MarginTopNone>
    <Paragraph>
      Once you view the suggested division in amica one, you won’t be able to
      edit your answers.
    </Paragraph>
  </>
);

export default SuggestedDivisionReadyBanner;
