import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Theme } from 'Common/Utils/theme';
import FormLabel from 'Common/UI/Form/FormLabel';
import IconTick from 'Common/Assets/images/icon-checkbox-tick-white.svg';

const CheckboxWrap = styled.div<{
  theme: Theme;
}>`
  position: relative;
  margin: ${props => props.theme.padding.medium}px 0 0;
`;

const CheckboxLabel = styled.label`
  display: flex;
  flex-direction: row;
  -webkit-tap-highlight-color: transparent;
`;

const CheckboxFormDescription = styled(FormLabel)<{
  theme: Theme;
  value?: boolean;
}>`
  margin: 0;

  &::before {
    content: '';
    height: 24px;
    width: 24px;
    border: 2px solid ${({ theme }) => theme.checkBoxTickOutline};
    border-radius: 4px;
    display: inline-block;
    position: absolute;
    left: 0px;
    ${({ value, theme }) =>
      value === true
        ? `
          background-color: ${theme.checkBoxTickBackground};
          background-image: url(${IconTick});
          background-repeat: no-repeat;
          background-position: center center;
          border-color: ${theme.checkBoxTickOutlineChecked};
        `
        : ''}
  }
`;

const CheckboxInput = styled.input`
  margin-top: 1em;
  margin-right: 1.5em;
  margin-left: 2px;
  font-size: 14px;
  height: 21px;
  opacity: 0;
  &:focus {
    + ${CheckboxFormDescription}::before {
      outline: rgb(59, 153, 252) auto 5px;
    }
  }
`;

type Props = {
  onChange: (checked: boolean, event: any) => void;
  name: string;
  value?: boolean;
  label: ReactNode;
  className?: string;
  disabled?: boolean;
};

const CheckboxField: React.FC<Props> = ({
  onChange,
  name,
  value = false,
  label,
  className,
  disabled = false,
  ...rest
}) => (
  <CheckboxWrap className={className}>
    <CheckboxLabel htmlFor={name}>
      <CheckboxInput
        value={value ? 'true' : 'false'}
        checked={value}
        id={name}
        type="checkbox"
        onChange={evt => onChange(evt.target.checked, evt)}
        disabled={disabled}
        {...rest}
      />
      <CheckboxFormDescription value={value} label={label} />
    </CheckboxLabel>
  </CheckboxWrap>
);

export default CheckboxField;
