import { Theme } from 'Common/Utils/theme';
import styled, { css } from 'styled-components';
// TODO:This will be refactored. Once I accumulate enough components to have a
// pattern in my desktop refactor
import GroupSubHeading from '../Text/GroupSubHeading';

const FlexGrid = styled.div<{
  align?: string;
  justifyContent?: string;
  theme: Theme;
  medium?: number;
  large?: number;
  offset?: number;
}>`
  display: block;
  ${props => props.align && `align-items: ${props.align}`};
  ${props =>
    props.justifyContent && `justify-content: ${props.justifyContent}`};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 -16px;
  }

  & > ${GroupSubHeading} {
    @media ${({ theme }) => theme.sizes.md.breakpoint} {
      flex: 1 0 100%;
      max-width: 100%;
    }
  }

  /* If medium or large is set, every child has equal width */
  & > * {
    margin-bottom: 16px;

    ${({ medium, theme }) =>
      medium &&
      css`
        @media ${theme.sizes.md.breakpoint} {
          margin: 0 0 16px 16px;
          flex: 1 0 calc(${(100 / medium).toFixed(2)}% - 16px);
          max-width: calc(${(100 / medium).toFixed(2)}% - 16px);
        }
      `}

    ${({ large, theme }) =>
      large &&
      css`
        @media ${theme.sizes.lg.breakpoint} {
          margin: 0 0 16px 16px;
          flex: 1 0 calc(${(100 / large).toFixed(2)}% - 16px);
          max-width: calc(${(100 / large).toFixed(2)}% - 16px);
        }
      `}
  }
`;
// Adds padding left and/or right to columns
export const OffsetColumn = styled.div<{
  theme: Theme;
  offset?: number;
}>`
  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-left: calc(100% / 12 * ${props => props.offset || 1});
  }
  @media ${({ theme }) => theme.sizes.lg.breakpoint} {
    padding-right: calc(100% / 12 * ${props => props.offset || 1});
  }
`;

export default FlexGrid;
