/**
 * THIS IS A VERSIONED FILE - see readme for more info
 */

import MatterProps, { getMatter } from 'Common/Utils/MatterProps';
import { Party, PARTY_A, PARTY_B } from 'Common/constants';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import DiviProps from 'Common/Data/App/diviProperties';
import {
  AssetSplitAsset,
  AssetSplitRound,
  AssetSplitShortfall,
  Division,
  Item,
  MatterFlags,
} from 'Common/Data/Types/matter';
import {
  AssetDataFull,
  AssetDataSimple,
  AssetEnhanced,
  AssetFull,
  AssetOnlyValue,
  AssetTypeProperty,
  PartysWithValues,
} from 'Common/Data/Types/assets';
import {
  assetCategories,
  debtCategories,
  getValueModifier,
  getSoleAssetValue,
  getJointAssetValue,
  getPropertyValues,
  getVehicleValues,
  getSharesValue,
  getSuperannuationValues,
  buildPartyValuesObject,
} from './VERSIONED_assetHelpers';

// calculates the value of all assets and debts for both parties
// returns an object with individual values plus totals
const getAssetData = ({
  onlyValues = false,
  parties = { owing: PARTY_A, owed: PARTY_B },
  isOwingParty = false,
  selectedAssets,
}: {
  onlyValues?: boolean;
  parties: { owing: Party; owed: Party };
  isOwingParty?: boolean;
  selectedAssets?: (AssetSplitAsset | AssetSplitShortfall)[];
}) => {
  const assets: (AssetFull | AssetTypeProperty | AssetOnlyValue)[] = [
    ...getPropertyValues({
      onlyValues,
      parties,
      isOwingParty,
      isTransferrable: true,
    }),
    ...getVehicleValues({
      onlyValues,
      parties,
      isOwingParty,
      isTransferrable: true,
    }),
    ...getSoleAssetValue({
      options: {
        onlyValues,
        parties,
        isOwingParty,
        isTransferrable: true,
      },
      groupName: 'Savings',
      type: 'savings',
      baseSection: 'yourfinances',
      dataKey: 'personalSavings',
    }),
    ...getSoleAssetValue({
      options: {
        onlyValues,
        parties,
        isOwingParty,
        isTransferrable: false,
        combineValues: true,
      },
      groupName: 'Cash',
      type: 'cash',
      baseSection: 'yourfinances',
      dataKey: 'cash',
    }),
    ...getSharesValue({
      onlyValues,
      parties,
      isOwingParty,
      isTransferrable: true,
    }),
    ...getSoleAssetValue({
      options: {
        onlyValues,
        parties,
        isOwingParty,
        isTransferrable: false,
      },
      groupName: 'Other assets',
      type: 'otherAssets',
      baseSection: 'yourfinances',
      dataKey: 'otherAssets',
    }),
    ...getSoleAssetValue({
      options: {
        onlyValues,
        parties,
        isOwingParty,
        isTransferrable: false,
        combineValues: true,
      },
      groupName: 'Household items',
      type: 'householdItems',
      baseSection: 'yourfinances',
      dataKey: 'householdItemsValue',
    }),
    // some superannuation accounts are transferrable, the logic for that is inside this function
    ...getSuperannuationValues({
      onlyValues,
      parties,
      isOwingParty,
      selectedAssets,
    }),
    ...getJointAssetValue({
      options: {
        onlyValues,
        parties,
        isOwingParty,
        isTransferrable: true,
      },
      groupName: 'Shared savings',
      type: 'sharedSavings',
      baseSection: 'sharedfinances',
      dataKey: 'sharedSavings',
    }),
    ...getJointAssetValue({
      options: {
        onlyValues,
        parties,
        isOwingParty,
        isTransferrable: true,
        canSplit: true,
      },
      groupName: 'Other shared assets',
      type: 'sharedAssets',
      baseSection: 'sharedfinances',
      dataKey: 'sharedAssets',
    }),
  ];

  const debts: (AssetFull | AssetTypeProperty | AssetOnlyValue)[] = [
    ...getSoleAssetValue({
      options: {
        onlyValues,
        parties,
        isOwingParty,
        isTransferrable: false,
        isLiability: true,
      },
      groupName: 'Credit cards',
      type: 'creditCards',
      baseSection: 'yourfinances',
      dataKey: 'creditCards',
    }),
    ...getSoleAssetValue({
      options: {
        onlyValues,
        parties,
        isOwingParty,
        isTransferrable: false,
        isLiability: true,
      },
      groupName: 'Other debts',
      type: 'otherDebts',
      baseSection: 'yourfinances',
      dataKey: 'otherDebts',
    }),
    ...getJointAssetValue({
      options: {
        onlyValues,
        parties,
        isOwingParty,
        isTransferrable: true,
        isLiability: true,
        canSplit: true,
      },
      groupName: 'Shared debts',
      type: 'sharedDebts',
      baseSection: 'sharedfinances',
      dataKey: 'sharedDebts',
    }),
  ];

  return { assets, debts };
};

const getSimpleAssetData = ({
  parties,
}: {
  parties: { owing: Party; owed: Party };
}): AssetDataSimple => {
  const { assets, debts } = getAssetData({
    onlyValues: true,
    parties,
  });

  const getGroupValue = (group: AssetOnlyValue[]): PartysWithValues =>
    group.reduce(
      (acc: PartysWithValues, item) =>
        buildPartyValuesObject({
          owingParty: parties.owing,
          owingValue: acc[parties.owing] + item.valueData[parties.owing],
          owedValue: acc[parties.owed] + item.valueData[parties.owed],
        }),
      { [PARTY_A]: 0, [PARTY_B]: 0 }
    );

  const totalAssets: PartysWithValues = getGroupValue(assets);
  const totalDebts: PartysWithValues = getGroupValue(debts);

  const data: AssetDataSimple = {
    assets: assets as AssetOnlyValue[],
    debts: debts as AssetOnlyValue[],
    // adds up the value of all of the assets and debts for both parties
    totalAssets,
    totalDebts,
    total: buildPartyValuesObject({
      owingParty: parties.owing,
      owingValue: totalAssets[parties.owing] - totalDebts[parties.owing],
      owedValue: totalAssets[parties.owed] - totalDebts[parties.owed],
    }),
  };

  return data;
};

const getFullAssetData = ({
  parties,
  isOwingParty,
  selectedAssets,
}: {
  parties: { owing: Party; owed: Party };
  isOwingParty: boolean;
  selectedAssets?: (AssetSplitAsset | AssetSplitShortfall)[];
}): AssetDataFull => {
  const { assets, debts } = getAssetData({
    onlyValues: false,
    parties,
    isOwingParty,
    selectedAssets,
  });

  const getGroupValue = (group: AssetOnlyValue[]): PartysWithValues =>
    group.reduce(
      (acc: PartysWithValues, item) =>
        buildPartyValuesObject({
          owingParty: parties.owing,
          owingValue: acc[parties.owing] + item.valueData[parties.owing],
          owedValue: acc[parties.owed] + item.valueData[parties.owed],
        }),
      { [PARTY_A]: 0, [PARTY_B]: 0 }
    );

  const totalAssets: PartysWithValues = getGroupValue(assets);
  const totalDebts: PartysWithValues = getGroupValue(debts);

  const data: AssetDataFull = {
    assets: assets as (AssetFull | AssetTypeProperty)[],
    debts: debts as (AssetFull | AssetTypeProperty)[],
    // adds up the value of all of the assets and debts for both parties
    totalAssets,
    totalDebts,
    total: buildPartyValuesObject({
      owingParty: parties.owing,
      owingValue: totalAssets[parties.owing] - totalDebts[parties.owing],
      owedValue: totalAssets[parties.owed] - totalDebts[parties.owed],
    }),
  };

  return data;
};

const AssetPropsFunc = () => {
  const divi: Division = MatterProps('divi', {});

  const {
    TAP,
    agreedSplit,
    agreedToDivision,
  }: { TAP: number; agreedSplit: number; agreedToDivision: boolean } =
    DiviProps();

  const party = getPartyKeys();

  const {
    confirmedAssetTransfer: isAssetSplitCompleted = false,
    assetSplitRounds: rounds = [],
    owingParty: owingPartyAPI,
    owedParty: owedPartyAPI,
    assets = [],
  } = divi;

  // get just the values of the asset pool so we can work out who is the owing and owed parties
  const assetPoolValues = getSimpleAssetData({
    parties: { owing: party.self, owed: party.other },
  });

  const selfAssetSplit = assetPoolValues.total[party.self] / TAP;
  const selfAssetSplitValue = TAP * selfAssetSplit;
  const selfStartAmountToTransfer = selfAssetSplitValue - TAP * agreedSplit;

  let owingParty: Party;
  let owedParty: Party;

  if (selfStartAmountToTransfer > 0) {
    owingParty = party.self;
    owedParty = party.other;
  } else if (selfStartAmountToTransfer === 0) {
    owingParty = PARTY_B;
    owedParty = PARTY_A;
  } else {
    owingParty = party.other;
    owedParty = party.self;
  }

  if (
    (owingPartyAPI && owingParty !== owingPartyAPI) ||
    (owedPartyAPI && owedParty !== owedPartyAPI)
  ) {
    throw new Error('Local and API owing/owed parties dont match');
  }

  const isOwingParty = owingParty === party.self;
  const isOwedParty = owedParty === party.self;

  let selectedAssets: (AssetSplitAsset | AssetSplitShortfall)[];

  if (isAssetSplitCompleted) {
    selectedAssets = assets;
  } else {
    selectedAssets = rounds.length > 0 ? rounds[rounds.length - 1].assets : [];
  }

  // now load the entire asset pool data, based on the owing party's perspective
  const data = getFullAssetData({
    parties: { owing: owingParty, owed: owedParty },
    isOwingParty,
    selectedAssets,
  });

  // remove shortfall if asset split isn't completed - it's only relevant for the API
  const shortfallData = selectedAssets.find(asset => asset.id === 'shortfall');
  selectedAssets = isAssetSplitCompleted
    ? selectedAssets
    : selectedAssets.filter(asset => asset.id !== 'shortfall');

  const owingAgreedSplit =
    owingParty === party.self ? agreedSplit : 1 - agreedSplit;

  const assetSplit = assetPoolValues.total[owingParty] / TAP;
  const assetSplitValue = TAP * assetSplit;

  const startAmountToTransfer = Math.round(
    assetSplitValue - TAP * owingAgreedSplit
  );
  const absoluteStartAmountToTransfer = Math.abs(startAmountToTransfer);
  const startPercentageToTransfer = Math.abs(assetSplit - owingAgreedSplit);

  const valueOfTransferringAssets = selectedAssets.reduce((acc, item) => {
    let isJointlyOwnedRealProperty = false;
    const asset = [...data.assets, ...data.debts].find(i => i.id === item.id);

    if (asset && asset.type === 'property' && asset.isJointlyOwned) {
      isJointlyOwnedRealProperty = true;
    }

    if ('isLiability' in item && 'option' in item) {
      return (
        acc +
        item.value *
          getValueModifier(
            item.isLiability,
            item.option,
            isJointlyOwnedRealProperty
          )
      );
    }

    return acc + item.value;
  }, 0);
  const remainingAmountToTransfer = Math.round(
    assetSplitValue - valueOfTransferringAssets - TAP * owingAgreedSplit
  );
  const absoluteRemainingAmountToTransfer = Math.abs(remainingAmountToTransfer);
  const remainingPercentageToTransfer = remainingAmountToTransfer / TAP;
  const absoluteRemainingPercentageToTransfer = Math.abs(
    remainingPercentageToTransfer
  );
  const isTAPNegative =
    assetPoolValues.total[party.self] < 0 ||
    assetPoolValues.total[party.other] < 0;

  let shortfallAmount;
  let shortfallPayParty;

  if (isAssetSplitCompleted) {
    shortfallAmount = shortfallData?.value ?? 0;
    shortfallPayParty = shortfallAmount > 0 ? owingParty : owedParty;
  } else {
    shortfallAmount = remainingAmountToTransfer;
    shortfallPayParty = remainingAmountToTransfer > 0 ? owingParty : owedParty;
  }

  const lastOffer = rounds.length > 0 ? rounds[rounds.length - 1] : undefined;

  const hasAnyOfferBeenStarted = rounds.length > 0;
  const hasAnyOfferBeenMade = rounds.filter(round => !!round.sent).length > 0;
  const offers = rounds;
  const numberOfOffers = rounds.length;
  const lastSentOfferBy = hasAnyOfferBeenMade
    ? [...rounds].reverse().find(round => round.sent)?.owner
    : undefined;
  const hasMadeOffer = {
    [PARTY_A]: rounds.some(round => round.sent && round.owner === PARTY_A),
    [PARTY_B]: rounds.some(round => round.sent && round.owner === PARTY_B),
  };

  const getTransferrableAssets = (offer?: AssetSplitRound) => {
    const transferrableAssets: AssetEnhanced[] = [
      ...data.assets
        .filter(asset => asset.isTransferrable)
        .map(asset => {
          // the ternary is to make sure we get a value for assets owned by the other party
          const returnData = {
            ...asset,
            value:
              asset.valueData[owingParty] > 0
                ? asset.valueData[owingParty]
                : asset.valueData[owedParty],
          };

          return returnData;
        }),
    ].map(asset => {
      const selected = offer
        ? offer.assets.find(item => item.id === asset.id)
        : selectedAssets.find(item => item.id === asset.id);

      return {
        ...asset,
        isSelected: !!selected,
        selectedOption:
          selected && 'option' in selected ? selected?.option : undefined,
        selectedValue:
          selected && 'value' in selected ? selected?.value : undefined,
      };
    });

    return transferrableAssets;
  };

  const getTransferrableDebts = (offer?: AssetSplitRound) => {
    const transferrableDebts: AssetEnhanced[] = [
      ...data.debts
        .filter(debt => debt.isTransferrable && debt.valueData[owingParty] > 0)
        .map(debt => ({ ...debt, value: debt.valueData[owingParty] })),
    ].map(debt => {
      const selected = offer
        ? offer.assets.find(item => item.id === debt.id)
        : selectedAssets.find(item => item.id === debt.id);

      return {
        ...debt,
        isSelected: !!selected,
        selectedOption:
          selected && 'option' in selected ? selected?.option : undefined,
      };
    });

    return transferrableDebts;
  };

  const getSelectedItems = (offer: AssetSplitRound) => {
    if (offer) {
      return offer.assets;
    }

    if (lastOffer) {
      return lastOffer.assets;
    }

    return [];
  };

  const transferrableAssets = getTransferrableAssets(lastOffer);
  const transferrableDebts = getTransferrableDebts(lastOffer);

  const haveAllJointAssetsBeenAssigned = [
    ...transferrableAssets,
    ...transferrableDebts,
  ]
    .filter(item => item.isJointlyOwned)
    .every(item => selectedAssets.map(i => i.id).includes(item.id));

  const haveAllJointAssetsExceptPropertiesBeenAssigned = [
    ...transferrableAssets,
    ...transferrableDebts,
  ]
    .filter(item => item.isJointlyOwned && item.type !== 'property')
    .every(item => selectedAssets.map(i => i.id).includes(item.id));

  let isOfferInProgress = false;
  let canStartOffer = false;
  let canWorkOnOffer = false;
  let canStartOrWorkOnOffer = false;
  let canSubmitOffer = false;

  // make sure these variables can only ever be true if the user has completed suggested division
  if (agreedToDivision) {
    isOfferInProgress = !!lastOffer && !lastOffer.sent;

    canStartOffer =
      !isAssetSplitCompleted &&
      (!hasAnyOfferBeenStarted ||
        (!isOfferInProgress && lastOffer?.owner === party.other));

    canWorkOnOffer =
      !isAssetSplitCompleted &&
      isOfferInProgress &&
      lastOffer?.owner === party.self;

    canStartOrWorkOnOffer = canStartOffer || canWorkOnOffer;

    canSubmitOffer =
      canWorkOnOffer ||
      (!isOfferInProgress && canStartOffer && haveAllJointAssetsBeenAssigned);
  }

  const otherPartyIsMakingAnOffer = isOfferInProgress && !canWorkOnOffer;
  const isExactlyOneOfferAndMadeByOtherParty =
    rounds.length === 1 &&
    !isOfferInProgress &&
    lastSentOfferBy === party.other;
  const hasOtherPartyMadeCounterOffer =
    rounds.length > 1 && !isOfferInProgress && lastSentOfferBy === party.other;
  const isWaitingForOtherPartyToViewOffer =
    hasAnyOfferBeenMade && lastSentOfferBy === party.self && !isOfferInProgress;
  const lastCompletedOffer = hasAnyOfferBeenMade
    ? [...rounds].reverse().find(round => round.sent)
    : undefined;
  const shouldShowViewProposalDialog =
    !isAssetSplitCompleted &&
    lastSentOfferBy === party.other &&
    !isOfferInProgress;

  const superannuationAsset = selectedAssets.find(item =>
    item.id.includes('yoursuperannuation')
  );

  const isSuperIncludedInOffer = !!superannuationAsset;
  let superannuationContinueItem: Item | undefined;

  if (superannuationAsset) {
    const { items } = getMatter();

    const superannuationItem = items.find(
      item => item.SectionID === superannuationAsset.id
    );

    superannuationContinueItem = items.find(
      item =>
        item.SectionID ===
        `yoursuperannuationcontinue${superannuationItem?.CardIndex}`
    );
  }

  const hasOwedPartyViewedIntroCarousel: boolean = MatterProps(
    `party${owedParty}.hasSeenAssetTransferIntro`,
    false
  );

  const matterFlags: MatterFlags = MatterProps('flags', {});

  const { superSplitting: superSplittingFlags = {} } = matterFlags;
  // console.log('Owing agreed split', owingAgreedSplit);
  // console.log('Asset split', assetSplit);
  // console.log('Asset split value', assetSplitValue);
  // console.log('Start $ to transfer', startAmountToTransfer);
  // console.log('ABS start $ to transfer', absoluteStartAmountToTransfer);
  // console.log('Remaining $ to transfer', remainingAmountToTransfer);
  // console.log('TAP', TAP);
  // console.log('Remaining % to transfer', remainingPercentageToTransfer);
  // console.log('Owing party', owingParty);
  // console.log('Owed party', owedParty);

  return {
    getAssetData: getFullAssetData,
    assetSplit, // current split of assets (0-1) - always returns the split for the *owing* party
    selfAssetSplit, // current split of assets (0-1) - always returns the split for the current party
    // assetSplitValue, // value of the current party's assets
    startAmountToTransfer, // the amount the current party needs to transfer to equal the agreed split
    absoluteStartAmountToTransfer, // the absolute value of the amount the current party needs to transfer to equal the agreed split
    startPercentageToTransfer, // percentage (0-1) that the current party needs to transfer
    remainingAmountToTransfer, // the amount that the owing party still needs to transfer (negative numbers mean the owed party needs to transfer)
    absoluteRemainingAmountToTransfer, // the absolute value of the amount that the one of the parties still needs to transfer (could be either party)
    remainingPercentageToTransfer, // percentage (-0 to 1) that the owing party still needs to transfer (negative numbers mean the owed party needs to transfer)
    absoluteRemainingPercentageToTransfer, // the absolute value of the percentage (0-1) that one of the parties still needs to transfer (could be either party)
    TAP,
    owingParty, // the party (A/B) who owes assets
    owedParty, // the party (A/B) who is owed assets (and does not take part in the asset split section)
    isOwingParty, // bool - if the current party is the owing party
    isOwedParty, // bool - if the current party is the owed party
    isAssetSplitCompleted, // has asset split been fully completed and submitted by the owing party
    transferrableAssets, // an array of all transferrable assets
    transferrableDebts, // an array of all transferrable debts
    getTransferrableAssets, // func - load transferrable assets from a specific offer
    getTransferrableDebts, // func - load transferrable debts from a specific offer
    haveAllJointAssetsBeenAssigned, // bool - have all joint assets been dealt with (i.e. can the user submit their asset transfer)
    haveAllJointAssetsExceptPropertiesBeenAssigned, // bool - have all joint assets *except properties* been dealt with
    assetCategories, // array - list of all asset categories
    debtCategories, // array - list of all debt categories
    shortfallAmount, // number - shortfall amount owed, always from the perspective of the owing party (i.e. negative numbers mean the owed party owes money)
    shortfallPayParty, // the party (A/B) who needs to pay the shortfall
    hasOwedPartyViewedIntroCarousel,
    isTAPNegative,
    offers, // array of offers (both in progress and completed)
    lastOffer,
    /** Is there currently an offer from either party in progress (at least one asset selected for transfer but offer not sent to other party yet) */
    isOfferInProgress,
    /** The party who made the last completed offer (or undefined if no offers have been sent yet) */
    lastSentOfferBy,
    /** Has at least one offer been started (doesn't need to have been completed and sent) by either party */
    hasAnyOfferBeenStarted,
    /** Has at least one offer been made (completed and sent to the other party) yet by either party */
    hasAnyOfferBeenMade,
    hasMadeOffer, // an object that indicates whether each party has made an offer yet
    /** Can the current party work on an existing offer in progress. This will only be true when there is currently an offer in progress by the current party (started but not sent to the other party yet).
     *
     * @see `canStartOffer` if you want to know if the current party can start a new offer
     * @see `canStartOrWorkOnOffer` if you want to know if the current party can start a new offer or work on an existing offer
     */
    canWorkOnOffer,
    /** Can the current party start an offer. This will only be true if no offers have been made by anyone, or the other party has completed an offer and the current party has not responded to it yet.
     *
     * @see `canWorkOnOffer` if you want to know if the current party can work on an existing offer
     * @see `canStartOrWorkOnOffer` if you want to know if the current party can start a new offer or work on an existing offer
     */
    canStartOffer,
    /** Can the current party start a new offer OR work on an existing offer in progress
     *
     * @see `canWorkOnOffer` if you want to know if the current party can work on an existing offer
     * @see `canStartOffer` if you want to know if the current party can start a new offer
     */
    canStartOrWorkOnOffer,
    /** Can the current user submit their offer at the moment.
     *
     * This will be true when the current party has an offer in progress and they have selected all of their joint assets for transfer, but it can also be true if there are no joint assets for the current matter and the current party can start an offer, as they need to be able to submit an empty offer in this case. */
    canSubmitOffer,
    /** Is the other party currently making an offer (offer has been started but not sent yet) */
    otherPartyIsMakingAnOffer,
    isExactlyOneOfferAndMadeByOtherParty, // bool - is there exactly one offer and it has been made by the other party, and there is no offer in progress by anyone
    hasOtherPartyMadeCounterOffer, // bool - is the last offer a counter offer from the other party and there is no offer in progress by anyone
    isWaitingForOtherPartyToViewOffer, // bool - current party has made an offer, but the other party hasn't viewed/responded to it yet
    numberOfOffers,
    lastCompletedOffer,
    shouldShowViewProposalDialog, // bool - should we show the view proposal dialog
    getSelectedItems, // func - get a list of raw selected items from the database
    agreedAssetsToSplit: isAssetSplitCompleted ? selectedAssets : [], // array - list of selected assets that both parties have agreed to split

    // super splitting
    isPayer: isOwingParty, // alias for isOwingParty - we're using payer/payee terminology for super splitting so it makes sense to have variables named this way
    isPayee: isOwedParty, // alias for isOwedParty - we're using payer/payee terminology for super splitting so it makes sense to have variables named this way
    isSuperIncludedInOffer, // bool - is superannuation included in the current offer
    superSplittingFlags, // object - flags for super splitting
    /** The superannuation asset that is being split, if any */
    superannuationAsset,
    /** The superannuation item that is being split, if any */
    superannuationContinueItem,
  };
};

// used to memoize the output
const lastRefreshed: Record<string, number> = {};
const output: Record<string, ReturnType<typeof AssetPropsFunc>> = {};

const AssetProps = () => {
  const matterID = MatterProps('MatterID');
  const matterLastRefreshed = MatterProps('lastRefreshed');
  if (!output[matterID] || lastRefreshed[matterID] !== matterLastRefreshed) {
    lastRefreshed[matterID] = matterLastRefreshed;
    output[matterID] = AssetPropsFunc();
  }

  return output[matterID];
};

export default AssetProps;
