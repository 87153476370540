import React from 'react';
import styled from 'styled-components';

import ImageButton from './ImageButton';
import BinBlackIcon from '../Icons/BinBlack.svg';

const DeleteIconButton = styled(ImageButton)`
  border-radius: 50%;
  color: black;
`;

export default props => (
  <DeleteIconButton {...props}>
    <img alt="" aria-hidden="true" src={BinBlackIcon} />
  </DeleteIconButton>
);
