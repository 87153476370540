import styled from 'styled-components';

const PageContent = styled.div`
  flex: 1;
  padding: 20px ${props => props.theme.padding.small}px;
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
  z-index: 1;
  position: relative;
`;

export default PageContent;
