import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SwipeNav, { NavItem } from 'Common/UI/Layout/SwipeNav';
import NavLinkIcon from 'App/UI/NavLinkIcon';

const StyledNavItem = styled(NavItem)`
  font-size: 15px;

  &.${props => props.activeClassName} {
    color: ${props => props.theme.colours.white};

    img {
      opacity: 1;
    }
  }
`;

const StyledNavDiv = styled.div`
  display: flex;
  align-items: center;
  margin: ${props =>
    `${props.theme.padding.xsmall}px 10px ${props.theme.padding.xsmall}px 0`};
  color: ${props => props.theme.colours.midLightGrey};
  text-decoration: none;
  position: relative;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
  }

  &.${props => props.activeClassName} {
    color: ${props => props.theme.colours.white};
  }
`;

const propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string,
      status: PropTypes.oneOf([
        'completed-locked',
        'completed',
        'locked',
        'updated',
        'not-completed',
      ]),
      testId: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
      active: PropTypes.bool,
    })
  ).isRequired,
  dataCyId: PropTypes.string,
  className: PropTypes.string,
};

const Navigation = ({ tabs, dataCyId, className }) => {
  const onClickWithTooltipCheck = (onClick, e) => {
    // prevent link navigation if the user has clicked on an element that has a `data-prevent-nav` attribute (usually used for tooltips)
    if (e.target.getAttribute('data-prevent-nav')) {
      e.preventDefault();
      return false;
    }

    if (onClick) {
      return onClick();
    }
  };

  return (
    <SwipeNav data-cy="nav-swipe" data-cy-id={dataCyId} className={className}>
      {tabs.map(tab => {
        const {
          label,
          to,
          status,
          testId,
          onClick,
          active,
          lockedTooltip,
        } = tab;
        return (
          <NavLinkIcon
            key={label}
            data-cy={testId}
            as={to ? StyledNavItem : StyledNavDiv}
            to={to}
            isTicked={status === 'completed'}
            isLocked={status === 'locked' || status === 'completed-locked'}
            isUpdated={status === 'updated'}
            isNotCompleted={status === 'not-completed'}
            disabled={status === 'locked' || status === 'completed-locked'}
            onClick={e => onClickWithTooltipCheck(onClick, e)}
            active={active}
            lockedTooltip={lockedTooltip}
          >
            {label}
          </NavLinkIcon>
        );
      })}
    </SwipeNav>
  );
};

Navigation.propTypes = propTypes;
Navigation.defaultProps = {
  dataCyId: undefined,
  className: '',
};

export default Navigation;
