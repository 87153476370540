import React, { ReactNode, useContext } from 'react';

import Matter from 'Common/Data/Types/matter';
import {
  EphemeralStateCard,
  QuestionStateCard,
  CardNormal,
  CardComponent,
} from 'Common/Data/Types/appSections';
import { NormalCard } from 'App/UI/Dashboard/common/cards';
import { DashboardContext } from 'Common/UI/DashboardContext';
import isPageContentsUnlocked from 'App/AppState/isPageContentsUnlocked';

// Group
export const generateNormalCard = (
  data: QuestionStateCard<CardNormal>,
  matter: Matter
): ReactNode => {
  const { cardID } = data;

  const { section, tab } = useContext(DashboardContext);

  const isActive = isPageContentsUnlocked(matter, section, tab);

  const cardHeadingText = data.label;

  return (
    <NormalCard
      key={cardID}
      data={data}
      isActive={isActive}
      matter={matter}
      cardHeadingText={cardHeadingText}
    />
  );
};

export const generateComponentCard = (
  data: EphemeralStateCard<CardComponent>
): ReactNode => {
  const { cardID } = data;

  if ('ComponentClass' in data) {
    const { ComponentClass } = data;

    return <ComponentClass key={cardID} />;
  }

  return null;
};
