import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FormLabel from 'Common/UI/Form/FormLabel';
import Input from './Input';

const FormGroup = styled.div``;

const FormGroupContainer = styled.div`
  ${FormGroup}:first-child {
    margin-bottom: ${props => props.theme.padding.medium}px;
  }
`;

const CountryCity = ({ onChange, value, disabled, name }) => {
  const selectedCountry = value.country || '';
  const selectedCity = value.city || '';

  const onCountryChange = event => {
    onChange({ ...value, ...{ country: event.target.value } });
  };

  const onCityChange = event => {
    onChange({ ...value, ...{ city: event.target.value } });
  };

  return (
    <FormGroupContainer>
      <FormGroup>
        <FormLabel label="Country" />
        <Input
          placeholder="Enter country"
          value={selectedCountry}
          onChange={onCountryChange}
          onBlur={onCountryChange}
          disabled={disabled}
          id={`${name}_country`}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel label="City" />
        <Input
          placeholder="Enter city"
          value={selectedCity}
          onChange={onCityChange}
          onBlur={onCityChange}
          disabled={disabled}
          id={`${name}_city`}
        />
      </FormGroup>
    </FormGroupContainer>
  );
};

CountryCity.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({
    country: PropTypes.string,
    city: PropTypes.string,
  }),
};

CountryCity.defaultProps = {
  disabled: false,
  value: {},
};

export default CountryCity;
