import styled from 'styled-components';

const PageContainer = styled.div`
  height: 100vh;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  ${props =>
    props.backgroundColor && `background-color: ${props.backgroundColor}`}
`;

export default PageContainer;
