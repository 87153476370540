import React, { KeyboardEvent } from 'react';
import styled from 'styled-components';

import { Theme } from 'Common/Utils/theme';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Label } from 'Common/UI/Text/Label';
import StyledTextfield from 'Common/UI/Form/Textfield';
import SendButton from './SendButton';
import { SCREEN_STATE_ACTION } from '../Division/constants';

const MessageInputForm = styled.form`
  ${Paragraph} {
    margin-top: 0;
    color: black;
  }
`;

const Prompt = styled(Label)<{
  visible: boolean;
}>`
  font-weight: bold;
  margin-bottom: 10px;
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 0.5s;
`;

const MessageInput = styled(StyledTextfield)<{
  theme: Theme;
  state: string;
  onKeyDown: (event: KeyboardEvent) => void;
}>`
  flex: 1;

  textarea {
    border-radius: 24px;
    line-height: 22px;
    margin-top: 0;
    border: 1px solid ${props => props.theme.colours.clickableGrey};
    background: ${props => props.theme.colours.white};
    padding: 9px 16px;
    height: ${props => (props.state === SCREEN_STATE_ACTION ? '68px' : '40px')};
    transition: height 0.5s;
  }
`;

// this is needed so we can reference it in the `ControlsWrap` styled component below
const StyledSendButton = styled(SendButton)``;

const ControlsWrap = styled.div<{
  showSend?: boolean;
}>`
  display: flex;
  flex-direction: row;
  position: relative;

  ${MessageInput} {
    flex-grow: 1;
    margin-right: ${props => (props.showSend ? '50px' : 0)};
    transition: margin-right 0.5s;
  }
  ${StyledSendButton} {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: ${props => (props.showSend ? 1 : 0)};
    transition: opacity 0.5s;
  }
`;

type Props = {
  onMessageSubmit: (event: any) => void;
  onMessageInput: (value: unknown, event: any) => void;
  messageInput?: string;
  prompt: string;
  disabled?: boolean;
  placeholder: string;
  showPrompt: boolean;
  state: string;
  onFocus: () => void;
  isSubmitting: boolean;
};

const MessageTextBox: React.FC<Props> = ({
  onMessageSubmit,
  onMessageInput,
  messageInput = '',
  prompt,
  disabled = false,
  placeholder,
  onFocus,
  state,
  showPrompt,
  isSubmitting,
}) => {
  const keyboardSubmit = (event: KeyboardEvent) => {
    if (event.keyCode === 13 && (event.metaKey || event.ctrlKey)) {
      onMessageSubmit(event);
    }
  };

  const hasInput = messageInput.trim().length !== 0;

  return (
    <MessageInputForm onSubmit={onMessageSubmit}>
      {prompt && <Prompt visible={showPrompt}>{prompt}</Prompt>}
      <ControlsWrap showSend={hasInput}>
        <MessageInput
          state={state}
          onChange={onMessageInput}
          label=""
          name="message"
          value={messageInput}
          placeholder={placeholder}
          rows={1}
          onFocus={onFocus}
          onBlur={() => {}}
          onKeyDown={keyboardSubmit}
          disabled={isSubmitting}
        />
        <StyledSendButton
          disabled={disabled || messageInput.trim().length === 0}
          onClick={onMessageSubmit}
          isSubmitting={isSubmitting}
          data-cy="send-message-button"
        />
      </ControlsWrap>
    </MessageInputForm>
  );
};

export default MessageTextBox;
