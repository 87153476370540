import { Location } from 'history';

import { push as rrPush, replace as rrReplace } from 'connected-react-router';
import { matchPath } from 'react-router';
import { drawerRoutes } from 'Common/routes';
import { reduxStore } from 'App/State/Store';

/* This function checks if a route is in a group of routes using react router matchPath.
 * For simplicity, just returns a boolean and not the route object
 */
export const checkRouteInGroup = (route: string, group: string[]) =>
  group.some(groupRoute => matchPath(route, { path: groupRoute }) !== null);

// custom push function that adds the previous route to the state
// useful for contextual back/cancel buttons
const push = (
  route: string,
  state: { location?: Location } = {},
  replace = false
) => {
  if (replace) {
    return rrReplace(route, {
      previous: reduxStore.getState().router.location.pathname,
      ...state,
    });
  }
  // check if the route should be rendered in the drawer component
  const inDrawer = checkRouteInGroup(route, drawerRoutes);

  return rrPush(route, {
    previous: reduxStore.getState().router.location.pathname,
    // set the background route
    background: inDrawer ? state?.location : undefined,
    ...state,
  });
};

export default push;
