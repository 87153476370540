import React, { ReactNode, useContext } from 'react';
import { Theme, offWhiteTheme } from 'Common/Utils/theme';
import styled, { ThemeProvider } from 'styled-components';

import getNames from 'Common/Utils/getNames';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import { QuestionCard, QuestionAny } from 'Common/Data/Types/appSections';
import IconSelf from 'Common/Assets/images/icon-users-self.svg';
import { Row as FlexRow } from 'Common/UI/Layout/Flex';
import PageContent from 'Common/UI/Layout/PageContent';
import { Heading1 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import FlexDrawer from 'Common/UI/Layout/FlexDrawer';
import GenericButton from 'Common/UI/Button/GenericButton';
import PageFooter from 'Common/UI/Layout/PageFooter';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';
import ProgressBar from '../ProgressBar';
import { CardQuestionFlowContext } from './CardQuestionsFlowContext';

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > * {
    width: 100%;
  }

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    flex-direction: row;

    > * {
      width: 45%;
      max-width: 400px;
    }
  }
`;

export const StyledPageContent = styled(PageContent)`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-left: 0;
  padding-right: 0;
`;

export const QuestionFlowHeader = styled.div<{
  theme: Theme;
}>`
  padding-top: ${({ theme }) => theme.spacing[4]};
  padding-bottom: ${({ theme }) => theme.spacing[4]};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-top: ${({ theme }) => theme.spacing[8]};
    padding-bottom: ${({ theme }) => theme.spacing[16]};
  }
`;

export const TitleIconImage = styled.img<{
  theme: Theme;
}>`
  margin-right: ${({ theme }) => theme.spacing[4]};
`;

export const TitleHeadingWrapper = styled(Heading1)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledQuestionsFlowWrapper = styled.form<{
  theme: Theme;
}>`
  display: flex;
  flex-direction: column;
  max-width: 660px;
  flex: 1;

  ${({ theme }) => theme.tokens.questionsHorizontalSpacing}
`;

const StatementWrapper = styled.div`
  background: ${({ theme }) => theme.colours.offWhite};
  padding: ${({ theme }) => theme.spacing[6]};
`;

export const StatementParagraph = styled(Paragraph)`
  line-height: 1.8;
  max-height: 50vh;
  overflow-y: auto;
`;

const StatementFooter = styled(FlexRow)`
  margin-top: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colours.midDarkGrey};
`;

export const EditableStatement: React.FC<{
  statement: ReactNode;
  showEditedByYou?: boolean;
}> = ({ statement, showEditedByYou = false }) => (
  <>
    <Paragraph>Select highlighted words to edit</Paragraph>

    <StatementWrapper data-cy="card-statement">
      <StatementParagraph>{statement}</StatementParagraph>

      {showEditedByYou && (
        <StatementFooter>
          <img src={IconSelf} alt="" />
          &nbsp;&nbsp;&nbsp; Edited by you
        </StatementFooter>
      )}
    </StatementWrapper>
  </>
);

type StatementSubmitDrawerProps = {
  onSubmit: (answers: any) => Promise<unknown>;
  isUpdating: boolean;
};

export const StatementSubmitDrawer: React.FC<StatementSubmitDrawerProps> = ({
  onSubmit,
  isUpdating,
}) => {
  const { activeQuestion } = useContext(CardQuestionFlowContext);

  const { withLoading } = usePromiseWithLoading();

  const names = getNames();

  return (
    <ThemeProvider theme={offWhiteTheme}>
      <FlexDrawer
        isVisible={activeQuestion && activeQuestion.type === 'statement'}
      >
        <ResponsiveContainer>
          <Row>
            <Paragraph>
              {names.other} will review{' '}
              {isUpdating ? 'your updates' : 'this statement'} and if they
              approve, it will move to your agreement.
            </Paragraph>
            <div>
              <GenericButton
                fullWidth
                onClick={() => withLoading(onSubmit)}
                data-cy="send-statement-button"
              >
                Send to {names.other}
              </GenericButton>
            </div>
          </Row>
        </ResponsiveContainer>
      </FlexDrawer>
    </ThemeProvider>
  );
};

type CardQuestionsFlowFooterProps = {
  card: QuestionCard;
  questions: QuestionAny[];
};

export const CardQuestionsFlowFooter: React.FC<CardQuestionsFlowFooterProps> =
  ({ card, questions }) => {
    const { showProgressBar } = useContext(CardQuestionFlowContext);

    return showProgressBar ? (
      <PageFooter>
        <ProgressBar
          label={card.title}
          steps={questions.length}
          currentStep={0}
        />
      </PageFooter>
    ) : null;
  };
