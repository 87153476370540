import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';

import { darkerTheme, Theme } from 'Common/Utils/theme';
import PageContent from 'Common/UI/Layout/PageContent';
import GenericButton from 'Common/UI/Button/GenericButton';

import BackButton from 'Common/UI/Button/BackButton';
import StepTransition from 'App/UI/Transitions/StepTransition';

export const StyledPageContent = styled(PageContent)`
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
`;

const UnderstandButton = styled(GenericButton)<{
  theme: Theme;
}>`
  margin-top: ${({ theme }) => theme.spacing[4]};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    margin-top: 0;
  }
`;

export const ButtonContainer = styled.div<{
  theme: Theme;
}>`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing[4]};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    margin-bottom: ${({ theme }) => theme.spacing[32]};
    width: 266px;
    display: inline-block;
  }
`;

const InnerContent = styled.div`
  flex: 1;
  display: inline-flex;
  flex-direction: column;
`;

const InnerContentMiddle = styled(InnerContent)`
  justify-content: center;
`;

const StyledBackButton = styled(BackButton)<{
  theme: Theme;
}>`
  position: absolute;
  top: ${({ theme }) => theme.spacing[8]};
  z-index: 100;

  ${({ theme }) => theme.tokens.closeButtonPosition}
`;

const Wrapper = styled.div<{
  theme: Theme;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  display: flex;

  background: ${({ theme }) => theme.backgroundColour};
`;

type Props = {
  goBack?: (e?: any) => void;
  goNext: (e?: any) => void;
  heading?: JSX.Element;
  content?: JSX.Element;
  help?: JSX.Element;
};

const Intro: React.FC<Props> = ({ goBack, goNext, heading, content, help }) => (
  <ThemeProvider theme={darkerTheme}>
    <Wrapper>
      <StyledPageContent>
        {goBack && <StyledBackButton onClick={goBack}>Back</StyledBackButton>}
        <StepTransition
          noLeftPadding={false}
          pageKey="1"
          transitionTime={500}
          appear
        >
          <InnerContentMiddle className="step-transition-fade-up">
            <ResponsiveTextContainer data-cy="welcome-info">
              {heading}
              {content}
              {help}
            </ResponsiveTextContainer>
          </InnerContentMiddle>
          <ButtonContainer className="step-transition-fade-down">
            <UnderstandButton
              fullWidth
              onClick={goNext}
              data-cy="question-intro-next"
            >
              I understand
            </UnderstandButton>
          </ButtonContainer>
        </StepTransition>
      </StyledPageContent>
    </Wrapper>
  </ThemeProvider>
);

export default Intro;
