import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Label } from 'Common/UI/Text/Label';
import FormLabel from 'Common/UI/Form/FormLabel';
import theme from 'Common/Utils/theme';
import Input from './Input';
import IconFlagPlus61 from '../Icons/Flag_plus_61.svg';

const MobileField = props => {
  const { onChange, label, name, value, hint, placeholder, disabled, onBlur } =
    props;

  return (
    <div>
      <label htmlFor={label}>
        <FormLabel label={hint} />
        <div>
          <StyledInput
            value={value}
            id={name}
            name={name}
            type="text"
            onChange={evt => onChange(evt.target.value, evt)}
            placeholder={placeholder}
            disabled={disabled}
            onBlur={() => onBlur(name)}
          />
        </div>
        <StyledHelperText>Please enter an Australian number</StyledHelperText>
      </label>
    </div>
  );
};

MobileField.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  hint: PropTypes.node,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
};

MobileField.defaultProps = {
  value: '',
  hint: '',
  placeholder: '',
  disabled: false,
  onBlur: () => {},
};

const StyledInput = styled(Input)`
  padding-left: 70px;
  width: 100%;
  background: ${theme.inputBackground} url('${IconFlagPlus61}');
  background-repeat: no-repeat;

  &:focus {
    background: ${theme.inputBackground} url('${IconFlagPlus61}');
    background-repeat: no-repeat;
  }
`;

const StyledHelperText = styled(Label)`
  padding-top: 12px;
`;

export default MobileField;
