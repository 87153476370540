import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FormLabel from 'Common/UI/Form/FormLabel';
import Input from './Input';

const FormGroup = styled.div`
  margin-bottom: 10px;
  ${Input} {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
`;

const FormContainer = styled.div`
  ${FormGroup}:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.padding.medium}px;
  }
`;

type Props = {
  onChange: any;
  currency?: boolean;
  mobile?: boolean;
  value?: {
    firstname: string;
    lastname: string;
  };
  className?: string;
  onBlur: any;
  placeholder?: string;
  label?: string | JSX.Element;
  onFocus: any;
  rows?: number | undefined;
  name: string;
  type?: any;
  disabled?: boolean;
  inputmode?: string;
};

const FirstLastNameField = forwardRef<HTMLInputElement, Props>(
  (
    {
      onChange,
      label,
      name,
      value = { firstname: '', lastname: '' },
      className = '',
      disabled = false,
      onBlur = () => {},
      onFocus = () => {},
      ...rest
    },
    ref
  ) => {
    const onChangeAction = (evt: any) => {
      let targetValue = evt.target.value;
      if (typeof targetValue === 'string') {
        targetValue = targetValue.trimStart();
      }

      return targetValue;
    };

    const onChangeMerge = (newInputValue: any) =>
      onChange({ ...value, ...newInputValue });

    return (
      <FormContainer className={className}>
        <label htmlFor={name}>
          <FormGroup>
            <FormLabel label="First Name" />
            <Input
              value={value.firstname}
              id="Firstname"
              type="text"
              placeholder="Enter first name"
              onChange={evt =>
                onChangeMerge({ firstname: onChangeAction(evt) })
              }
              disabled={disabled}
              onBlur={() => onBlur(name)}
              onFocus={() => onFocus(name)}
              ref={ref}
              {...rest}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel label="Last Name" />
            <Input
              value={value.lastname}
              id="Lastname"
              type="text"
              onChange={evt => onChangeMerge({ lastname: onChangeAction(evt) })}
              placeholder="Enter last name"
              disabled={disabled}
              onBlur={() => onBlur(name)}
              onFocus={() => onFocus(name)}
              ref={ref}
              {...rest}
            />
          </FormGroup>
        </label>
      </FormContainer>
    );
  }
);

FirstLastNameField.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
  }),
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  rows: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FirstLastNameField;
