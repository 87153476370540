import {
  FLAG_MATTER_OPTED_IN,
  FLAG_MATTER_OPTED_IN_DEFAULT,
  SECTION_STATUS_COMPLETED,
  SECTION_STATUS_IN_PROGRESS,
  SECTION_STATUS_LOCKED,
  SECTION_STATUS_NOT_STARTED,
  SECTION_STATUS_OPT_IN,
  Sections,
} from 'Common/constants';
import { getSectionCardStats } from 'Common/Utils/cardHelpers';
import { getAppSections } from 'Common/Data/SingleUser/appSections';
import MatterProps from 'Common/Utils/MatterProps';

// to make this backwards compatible, we can't rely only on DB flags because old accounts wont have them
// so we need to also check if any cards have been started
export const hasOptedInToSection = (section: string) => {
  const optedIn = MatterProps(
    `flags.${FLAG_MATTER_OPTED_IN}`,
    FLAG_MATTER_OPTED_IN_DEFAULT
  );

  if (optedIn[section]) {
    return true;
  }

  const { stats } = getSectionCardStats({
    appSections: getAppSections(),
    section,
  });

  return stats.inProgress > 0 || stats.completed > 0;
};

// the `customChecks` props lets us pass in custom checks for some combinations of cards and status checks
// this is needed because some cards have different checks, e.g. property needs to check if asset split and
// suggested division have been completed as well as all of the cards
export const getSectionStatus = ({
  section,
  customChecks = {},
  checkBothParties,
}: {
  section: string;
  customChecks?: any;
  checkBothParties?: boolean;
}) => {
  const { completed, stats } = getSectionCardStats({
    appSections: getAppSections(),
    section,
    checkBothParties,
  });

  const progress = (stats.completed / (stats.total - stats.removed)) * 100;

  if (
    !hasOptedInToSection(section) &&
    (customChecks.optIn ? customChecks.optIn() : true)
  ) {
    return {
      status: SECTION_STATUS_OPT_IN,
      stats,
    };
  }

  if (completed && (customChecks.completed ? customChecks.completed() : true)) {
    return {
      status: SECTION_STATUS_COMPLETED,
      stats,
      progress,
    };
  }

  if (customChecks.locked && customChecks.locked()) {
    return {
      status: SECTION_STATUS_LOCKED,
      stats,
      progress,
    };
  }

  if (stats.inProgress > 0 || stats.completed > 0) {
    return {
      status: SECTION_STATUS_IN_PROGRESS,
      stats,
      progress,
    };
  }

  return {
    status: SECTION_STATUS_NOT_STARTED,
    stats,
    progress,
  };
};

// checks if a section (dashboard) has been started (including if it's been completed)
export const hasSectionBeenStarted = ({
  section,
  customChecks = {},
  checkBothParties,
}: {
  section: string;
  customChecks?: any;
  checkBothParties?: boolean;
}): boolean => {
  const sectionStatus = getSectionStatus({
    section,
    customChecks,
    checkBothParties,
  });

  return (
    sectionStatus.status === SECTION_STATUS_COMPLETED ||
    sectionStatus.status === SECTION_STATUS_IN_PROGRESS
  );
};

export const hasSectionBeenCompleted = ({
  section,
  customChecks,
  checkBothParties = true,
}: {
  section: string;
  customChecks?: any;
  checkBothParties?: boolean;
}): boolean => {
  const sectionStatus = getSectionStatus({
    section,
    customChecks,
    checkBothParties,
  });

  return sectionStatus.status === SECTION_STATUS_COMPLETED;
};

export const getPageStatus = ({
  section,
  page,
}: {
  section: Sections;
  page: string;
}) =>
  getSectionCardStats({
    appSections: getAppSections(),
    section,
    page,
  });
