import React from 'react';
import PropTypes from 'prop-types';

import GenericButton from 'Common/UI/Button/GenericButton';
import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  RELATIONSHIP_DASHBOARD_COMPLETED,
  RELATIONSHIP_DASHBOARD_COMPLETED_AND_LOCKED,
  SECTION_PARENTING,
  SECTION_PROPERTY,
  SECTION_RELATIONSHIP,
  SECTION_STATUS_COMPLETED,
} from 'Common/constants';
import { ODRS_DASH_HOME_ROUTE } from 'Common/routes';
import {
  getSectionStatus,
  hasSectionBeenStarted,
} from 'App/Utils/sectionHelpers';

export const relationshipDashboardCompletedFlags = {
  id: 'relationshipDashboardCompleted',
  description: 'Relationship dashboard is complete',
  check: () => {
    const relationshipStatus = getSectionStatus({
      section: SECTION_RELATIONSHIP,
    });

    return relationshipStatus.status === SECTION_STATUS_COMPLETED;
  },
  flags: {
    appState: RELATIONSHIP_DASHBOARD_COMPLETED,
  },
};

export const relationshipDashboardCompletedAndLockedFlags = {
  id: 'relationshipDashboardCompletedAndLocked',
  description: "Relationship dashboard is complete and can't be edited",
  check: () =>
    hasSectionBeenStarted({
      section: SECTION_PROPERTY,
      checkBothParties: true,
    }) ||
    hasSectionBeenStarted({
      section: SECTION_PARENTING,
      checkBothParties: true,
    }),
  flags: {
    appState: RELATIONSHIP_DASHBOARD_COMPLETED_AND_LOCKED,
  },
};

type Props = {
  navigate: (route: string) => void;
};

const RelationshipDashboardCompleted: React.FC<Props> = ({ navigate }) => (
  <>
    <Heading2MarginTopNone>
      Congratulations! You have finished the about you and your relationship
      section
    </Heading2MarginTopNone>
    <Paragraph>
      You can now start creating property and money agreements and parenting
      arrangements.
    </Paragraph>
    <GenericButton
      fullWidth
      onClick={() => navigate(ODRS_DASH_HOME_ROUTE)}
      small
    >
      Back to your dashboard
    </GenericButton>
  </>
);

RelationshipDashboardCompleted.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default RelationshipDashboardCompleted;
