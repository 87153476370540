import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RoundButton from 'Common/UI/Button/RoundButton';
import FormLabel from 'Common/UI/Form/FormLabel';
import IconMinus from 'Common/Assets/images/icon-minus.svg';
import IconPlus from 'Common/Assets/images/icon-plus.svg';

const NumberStepperContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NumberStepperButton = styled(RoundButton)`
  padding: 13px;
  line-height: 1;
  font-size: 0;
  min-width: 0;
  border-radius: 50%;
`;

const NumberStepperValue = styled.span`
  background: ${props => props.theme.colours.lightestGrey};
  line-height: 1;
  padding: 15px;
  border-radius: 60px;
  display: inline-block;
  margin: 0 16px;
  min-width: 72px;
  text-align: center;
`;

const NumberStepper = ({ onChange, label, name, value, min, max }) => (
  <div>
    <label htmlFor={name}>
      <FormLabel label={label} />
      <NumberStepperContainer data-cy="numberStepper">
        <NumberStepperButton
          selected={value === false}
          onClick={() => {
            onChange(value - 1 >= min ? value - 1 : value);
          }}
          type="button"
          disabled={value <= min}
          reverse="true"
        >
          <img src={IconMinus} alt="" />
        </NumberStepperButton>
        <NumberStepperValue>{value}</NumberStepperValue>
        <NumberStepperButton
          selected={value === true}
          onClick={() => {
            onChange(value + 1 <= max ? value + 1 : value);
          }}
          type="button"
          disabled={value >= max}
          reverse="true"
        >
          <img src={IconPlus} alt="" />
        </NumberStepperButton>
      </NumberStepperContainer>
    </label>
  </div>
);

NumberStepper.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

NumberStepper.defaultProps = {
  value: 0,
  label: '',
};

export default NumberStepper;
