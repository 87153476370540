import React from 'react';
import CardQuestions from 'App/UI/Questions/CardQuestionsFlow';
import MatterGuard from './Guard/MatterGuard';

const ODRSRelationship = (props: any) => (
  <MatterGuard>
    <CardQuestions baseID="relationship" {...props} />
  </MatterGuard>
);

export default ODRSRelationship;
