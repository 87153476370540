import React from 'react';

import { push } from 'connected-react-router';

import { ODRS_START_ROUTE, MARKETING_SITE } from 'Common/routes';
import Carousel from 'Common/UI/Carousel';

import { CarouselButton } from 'Common/UI/Carousel/ImageSlideWrapper';
import {
  WelcomeSlide01,
  WelcomeSlide02,
} from './IntroCarouselSlides';
import { useAppDispatch } from '../../State/Store';

type StateProps = {
  state: {
    next?: string;
    back?: string;
  };
};

const WelcomeCarousel = ({ state }: StateProps) => {
  const dispatch = useAppDispatch();
  const navigate = (to: string, navigateState?: StateProps['state']) =>
    dispatch(push(to, navigateState));

  const LastSlideControls = () => (
    <CarouselButton
      onClick={() =>
        navigate(state && state.next ? state.next : ODRS_START_ROUTE, state)
      }
      data-cy="carousel-finish-button"
    >
      Next
    </CarouselButton>
  );

  const handleClose = () => {
    window.location.href = MARKETING_SITE;
  };

  return (
    <Carousel onClose={handleClose} LastSlideControls={LastSlideControls}>
      <WelcomeSlide01 />
      <WelcomeSlide02 />
    </Carousel>
  );
};

export default WelcomeCarousel;
