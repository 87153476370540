import styled, { css } from 'styled-components';
import { Theme } from 'Common/Utils/theme';
import SpacedBox from './SpacedBox';

const ResponsiveTextContainer = styled(SpacedBox)<{
  theme: Theme;
  fullWidth?: boolean;
}>`
  width: 100%;

  ${({ fullWidth, theme }) =>
    !fullWidth &&
    css`
      @media ${theme.sizes.sm.breakpoint} {
        max-width: ${theme.sizes.sm.text};
      }

      @media ${theme.sizes.md.breakpoint} {
        max-width: ${theme.sizes.md.text};
      }

      @media ${theme.sizes.lg.breakpoint} {
        max-width: ${theme.sizes.lg.text};
      }

      @media ${theme.sizes.xl.breakpoint} {
        max-width: ${theme.sizes.xl.text};
      }

      @media ${theme.sizes.xxl.breakpoint} {
        max-width: ${theme.sizes.xxl.text};
      }
    `}
`;

export default ResponsiveTextContainer;
