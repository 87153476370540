import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import GenericButton from './GenericButton';

const RoundButton = styled(GenericButton)`
  border-radius: 60px;
  padding: 8px 24px;

  ${props =>
    props.inverted &&
    css`
      background-color: white;
      color: green;
    `}

  transition: 0.5s;
  ${({ animated }) =>
    animated &&
    css`
      opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
    `}

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding: 10px 32px;
  }
`;

RoundButton.propTypes = {
  className: PropTypes.string,
  invert: PropTypes.bool,
  animated: PropTypes.bool,
  fullWidth: PropTypes.bool,
  subtle: PropTypes.bool,
};

RoundButton.defaultProps = {
  className: '',
  invert: false,
  animated: false,
  fullWidth: false,
  subtle: false,
};

export default RoundButton;
