import isPageContentsUnlocked from 'App/AppState/isPageContentsUnlocked';
import {
  DashboardPages,
  Sections,
  STATUS_NEW_BOTH_PARTIES,
  STATUS_NEW_ONLY_SELF,
  STATUS_NEW_ONLY_THEM,
} from 'Common/constants';
import { CardAny } from 'Common/Data/Types/appSections';
import Matter from 'Common/Data/Types/matter';
import generateOptionsForCard from 'App/UI/Dashboard/common/generateOptionsForCard';

const generateCardOptionsData = (
  card: CardAny,
  matter: Matter,
  section: Sections,
  tab: DashboardPages,
  showOptionsDrawer: (card: CardAny, options: string[]) => void
) => {
  let options: string[];
  let hasOptions = false;

  // Allow removing empty cards even if the page is locked
  const isEmptyCard = [
    STATUS_NEW_ONLY_SELF,
    STATUS_NEW_BOTH_PARTIES,
    STATUS_NEW_ONLY_THEM,
  ].includes(card.status ?? '');
  if (isPageContentsUnlocked(matter, section, tab) || isEmptyCard) {
    [options, hasOptions] = generateOptionsForCard(card);
  }

  return {
    showOptionsDrawer: () => options && showOptionsDrawer(card, options),
    hasOptions,
  };
};

export default generateCardOptionsData;
