import React from 'react';
import styled from 'styled-components';

import MatterPropsJS from 'Common/Utils/MatterProps';
import FlexDrawer from 'Common/UI/Layout/FlexDrawer';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import { QuestionCard } from 'Common/Data/Types/appSections';
import { Theme } from 'Common/Utils/theme';
import Paragraph from 'Common/UI/Text/Paragraph';
import { SubHeadingBold } from 'Common/UI/Text/Headings';
import { TwoButtonRow } from 'Common/UI/Button/ButtonGroup';
import GenericButton from 'Common/UI/Button/GenericButton';
import getYouOrThem from 'Common/Utils/getYouOrThem';

import { REOPEN_STATEMENT, REMOVE_STATEMENT } from '../constants';

const StyledParagraph = styled(Paragraph)<{
  theme: Theme;
}>`
  margin-bottom: ${props => props.theme.padding.medium}px;
  margin-top: ${props => props.theme.padding.xxsmall}px;
`;

const StyledSubHeading = styled(SubHeadingBold)<{
  theme: Theme;
}>`
  margin-bottom: ${props => props.theme.padding.xxsmall}px;
`;

type Props = {
  visible: boolean;
  optionItems?: string[];
  onCancel: () => void;
  onAccept: () => void;
  selectedOption?: 'reopen-statement' | 'remove-statement';
  card: QuestionCard;
};

const OptionsConfirmationScreen: React.FC<Props> = ({
  visible = false,
  onCancel,
  onAccept,
  selectedOption,
  card,
}) => {
  if (!selectedOption) {
    return null;
  }

  const data = {
    [REOPEN_STATEMENT]: {
      headingText: 'Are you sure you want to reopen this statement?',
      paragraph: `Any changes you want to make will still need to be reviewed by ${getYouOrThem(
        MatterPropsJS('other.party')
      )}`,
      buttonText: 'Re-open',
    },
    [REMOVE_STATEMENT]: {
      headingText:
        card.removeCardConfirmText ||
        'Are you sure you want to remove this statement',
      paragraph: 'This will clear the conversation history for this statement ',
      buttonText: 'Remove',
    },
  };

  if (!Object.keys(data).includes(selectedOption)) {
    return <div>Option does not exist</div>;
  }

  const { headingText, paragraph, buttonText } = data[selectedOption];

  return (
    <FlexDrawer isVisible={visible}>
      <ResponsiveTextContainer>
        <StyledSubHeading>{headingText}</StyledSubHeading>
        <StyledParagraph>{paragraph}</StyledParagraph>
        <TwoButtonRow>
          <GenericButton reverse onClick={onCancel} small>
            Cancel
          </GenericButton>
          <GenericButton onClick={onAccept} small>
            {buttonText}
          </GenericButton>
        </TwoButtonRow>
      </ResponsiveTextContainer>
    </FlexDrawer>
  );
};

export default OptionsConfirmationScreen;
