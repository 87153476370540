import React, { useContext } from 'react';
import styled from 'styled-components';

import { ProgressBarContext } from 'Common/Utils/ProgressBarContext';
import { Heading4 } from 'Common/UI/Text/Headings';
import { Theme } from 'Common/Utils/theme';
import { Label } from 'Common/UI/Text/Label';

const StyledProgressBar = styled.div<{
  theme: Theme;
  hidden: boolean;
}>`
  position: relative;
  transition: margin-bottom 1s;
  margin-bottom: ${props => (props.hidden ? '-64px' : '0')};
`;

const ProgressContent = styled.div<{
  theme: Theme;
}>`
  margin: 0;
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing[6]};
  ${props => props.theme.spacing[8]};
`;

const FlowName = styled(Heading4)<{
  theme: Theme;
}>`
  display: inline-block;
  margin: 0 ${({ theme }) => theme.spacing[8]} 0 0;
`;

const FlowProgressPercentage = styled(Label)`
  display: inline-block;
`;

const ProgressVisualBar = styled.div<{
  theme: Theme;
  distance: number;
}>`
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 12px 12px 0 0;
  overflow: hidden;

  background: ${props => props.theme.progressBarBackgroundColour};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: ${props => props.distance * 100}%;
    background: ${props => props.theme.progressBarColour};
    transition: width 0.3s;
    border-radius: 0 12px 12px 0;
  }
`;

type Props = {
  className?: string;
  label: string;
  steps: number;
  currentStep?: number;
  minWidth?: number;
  onClick?: () => void;
  hidden?: boolean;
};

const ProgressBar: React.FC<Props> = ({
  className = '',
  label,
  steps,
  currentStep = 0,
  minWidth = 0,
  onClick,
  hidden = false,
}) => {
  const { step, totalSteps } = useContext(ProgressBarContext);

  const percentageCompleted = Math.min(
    (step || currentStep) / (totalSteps || steps),
    1
  );

  const distance = Math.min(Math.max(percentageCompleted, minWidth), 1);

  return (
    <StyledProgressBar className={className} onClick={onClick} hidden={hidden}>
      <ProgressVisualBar distance={distance} />
      <ProgressContent>
        <FlowName>{label}</FlowName>
        <FlowProgressPercentage>
          {Math.round(percentageCompleted * 100)}%
        </FlowProgressPercentage>
      </ProgressContent>
    </StyledProgressBar>
  );
};

export default ProgressBar;
