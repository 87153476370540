import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router';

import Odie from 'Common/UI/Odie/Odie';
import AppLogo from 'App/UI/AppLogo';
import IntroCarousel from '../UI/Intro/IntroCarousel';

const LogoContainer = styled.div`
  display: none;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    display: block;
  }
`;

const Intro = ({ location }) => (
  <>
    <LogoContainer>
      <AppLogo />
    </LogoContainer>
    <IntroCarousel state={location.state} />
    <Odie />
  </>
);

Intro.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
};

export default withRouter(Intro);
