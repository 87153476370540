import React, { ReactNode } from 'react';
import styled from 'styled-components';

const ExternalIconWrapper = styled.span`
  padding-left: 8px;
  display: inline-block;
`;

const IconExternalLink = ({ colour }: { colour: string }) => (
  <ExternalIconWrapper>
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 11.3333V16.3333C15 16.7754 14.8244 17.1993 14.5118 17.5118C14.1993 17.8244 13.7754 18 13.3333 18H4.16667C3.72464 18 3.30072 17.8244 2.98816 17.5118C2.67559 17.1993 2.5 16.7754 2.5 16.3333V7.16667C2.5 6.72464 2.67559 6.30072 2.98816 5.98816C3.30072 5.67559 3.72464 5.5 4.16667 5.5H9.16667"
        stroke={colour}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 3H17.5V8"
        stroke={colour}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33334 12.1667L17.5 3"
        stroke={colour}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ExternalIconWrapper>
);

const StyledLink = styled.a<{
  fullWidth?: boolean;
}>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  cursor: pointer;
`;

type Props = {
  href?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  fullWidth?: boolean;
  showExternalIcon?: boolean;
  className?: string;
  onClick?: () => void;
  externalLinkIconColour?: string;
  children: ReactNode;
};

const ReferralLink: React.FC<Props> = ({
  href,
  target = '_blank',
  children,
  fullWidth = false,
  showExternalIcon = false,
  className = undefined,
  onClick = undefined,
  externalLinkIconColour = 'white',
}) => (
  <StyledLink
    onClick={() => {
      if (onClick) {
        onClick();
      }
    }}
    href={href}
    role="link"
    target={target}
    rel="noopener noreferrer"
    fullWidth={fullWidth}
    className={className}
  >
    <>
      {children}
      {showExternalIcon && <IconExternalLink colour={externalLinkIconColour} />}
    </>
  </StyledLink>
);

export default ReferralLink;
