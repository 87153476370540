import { Theme } from 'Common/Utils/theme';
import React from 'react';
import styled, { css } from 'styled-components';

import RoundButton from 'Common/UI/Button/RoundButton';
import FormLabel from 'Common/UI/Form/FormLabel';

const SelectButton = styled(RoundButton)<{
  theme: Theme;
}>`
  ${props =>
    !props.selected &&
    css`
      background: ${props.theme.colours.lightestGrey};
      color: ${props.theme.colours.black};
      font-weight: 400;
    `}
  margin-bottom: 16px;
  min-width: auto;
  display: inline-block;
  text-align: left;
  padding-top: 9px;
  padding-bottom: 9px;
`;

const Label = styled.label``;

const ButtonsRadioSelectContainer = styled.div`
  // Adjust buttons on phone resolution
  @media (max-width: 400px) {
    ${SelectButton} {
      height: auto;
      margin-bottom: 12px;
    }
  }
`;

type Option = {
  label: string;
  value: string;
};

type Props = {
  onChange: (value?: string) => void;
  options: Option[];
  disabled?: boolean;
  value: string;
  name: string;
  label?: JSX.Element | string;
  type?: string;
};

const ButtonsRadioSelect: React.FC<Props> = ({
  options = [],
  type = '',
  onChange,
  disabled = false,
  label,
  name,
  value,
}) => {
  const onInputChange = (e: any, value: string) => {
    e.preventDefault();
    e.stopPropagation();

    if (disabled || !options || options.length === 0) {
      return false;
    }

    const selectedValue = value;
    const selectedOption = options
      .filter((o: Option) => o.value === selectedValue)
      .pop();

    return onChange(selectedOption?.value);
  };

  return (
    <ButtonsRadioSelectContainer>
      <Label htmlFor={name}>
        <FormLabel label={label} type={type} />
        {options.map((o: Option) => (
          <div key={o.value}>
            <SelectButton
              as={disabled ? 'span' : undefined}
              onClick={(e: any) => onInputChange(e, o.value)}
              selected={value === o.value}
              subtle
            >
              {o.label}
            </SelectButton>
          </div>
        ))}
      </Label>
    </ButtonsRadioSelectContainer>
  );
};

export default ButtonsRadioSelect;
