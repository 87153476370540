import React from 'react';

import { SECTION_RELATIONSHIP } from 'Common/constants';
import { ODRS_DASH_RELATIONSHIP_ROUTE } from 'Common/routes';
import { darkerNoBackground } from 'Common/Utils/theme';
import DashboardRelationship from 'App/Pages/DashboardRelationship';
import DiviRoute from 'Common/UI/DiviRoute';
import MatterGuard from './Guard/MatterGuard';

const ODRSDashboardRelationship = () => (
  <MatterGuard>
    <DiviRoute
      path={ODRS_DASH_RELATIONSHIP_ROUTE}
      component={DashboardRelationship}
      theme={darkerNoBackground}
      tab={SECTION_RELATIONSHIP}
    />
  </MatterGuard>
);
export default ODRSDashboardRelationship;
