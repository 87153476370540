import { Theme } from 'Common/Utils/theme';
import React, { useEffect, useContext } from 'react';
import { Route } from 'react-router-dom';
import { RouteContext } from 'App/UI/Transitions/RouteContext';

const renderMergedProps = (component: any, ...rest: any[]) => {
  const props = Object.assign({}, ...rest);
  const { history, location, match, computedMatch, ...finalProps } = props;

  return React.createElement(component, finalProps);
};

const PropsRoute: React.FC<{ component: React.Component }> = ({
  component,
  ...rest
}) => (
  <Route
    {...rest}
    render={routeProps => renderMergedProps(component, routeProps, rest)}
  />
);

const DiviRoute: React.FC<{
  component: any;
  theme?: Theme;
  exact?: boolean;
  path: string;
  tab?: string;
}> = ({ theme = undefined, component, ...rest }) => {
  const { setTheme } = useContext(RouteContext);

  useEffect(() => {
    if (theme) {
      setTheme(theme);
    }
  }, []);

  return <PropsRoute component={component} {...rest} />;
};

export default DiviRoute;
