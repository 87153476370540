import React, { Ref } from 'react';
import styled from 'styled-components';
import { Theme } from 'Common/Utils/theme';

type SpacedProps = {
  marginTop?: string | number;
  marginBottom?: string | number;
  marginTopAuto?: boolean;
  className?: string;
  ref?: Ref<HTMLDivElement>;
  children?: React.ReactNode;
};

// uses the old theme `padding` (strings)
const SpacedBoxOld = styled.div<
  {
    theme: Theme;
  } & SpacedProps
>`
  margin-top: ${({ theme, marginTop }) =>
    marginTop ? `${theme.padding[marginTop]}px` : '0'};
  margin-bottom: ${({ theme, marginBottom }) =>
    marginBottom ? `${theme.padding[marginBottom]}px` : '0'};
  ${({ marginTopAuto }) => marginTopAuto && 'margin-top: auto;'};
`;

// uses the new theme `spacing` (integers)
const SpacedBoxNew = styled.div<
  {
    theme: Theme;
  } & SpacedProps
>`
  margin-top: ${({ theme, marginTop }) =>
    marginTop ? theme.spacing[marginTop] : '0'};
  margin-bottom: ${({ theme, marginBottom }) =>
    marginBottom ? theme.spacing[marginBottom] : '0'};
  ${({ marginTopAuto }) => marginTopAuto && 'margin-top: auto;'};
`;

const SpacedBox: React.FC<SpacedProps> = React.forwardRef<
  HTMLDivElement,
  SpacedProps
>(
  (
    { marginTop, marginBottom, marginTopAuto = false, children, ...props },
    ref
  ) => {
    if (typeof marginTop === 'string' || typeof marginBottom === 'string') {
      return (
        <SpacedBoxOld
          ref={ref}
          marginTop={marginTop}
          marginBottom={marginBottom}
          marginTopAuto={marginTopAuto}
          {...props}
        >
          {children}
        </SpacedBoxOld>
      );
    }

    return (
      <SpacedBoxNew
        ref={ref}
        marginTop={marginTop}
        marginBottom={marginBottom}
        {...props}
      >
        {children}
      </SpacedBoxNew>
    );
  }
);

export const withSpacedBox = <P extends Record<string, unknown>>(
  Component: React.ComponentType<P>
): React.FC<P & { spaced?: SpacedProps }> =>
  function ({ spaced, ...props }) {
    return spaced ? (
      <SpacedBox {...spaced}>
        <Component {...(props as P)} />
      </SpacedBox>
    ) : (
      <Component {...(props as P)} />
    );
  };

export default SpacedBox;
