import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HighlightedLabel } from 'Common/UI/Text/Label';
import { Callout } from 'Common/UI/Text/Paragraph';

const StyledLabel = styled(Callout)`
  margin: 0px;
  padding-bottom: 28px;
`;

const FormLabel = ({ label, type, ...props }) => {
  if (typeof label === 'string') {
    return type === 'select' && label ? (
      <StyledLabel {...props}>{label}</StyledLabel>
    ) : (
      <HighlightedLabel {...props}>{label}</HighlightedLabel>
    );
  }
  if (React.isValidElement(label)) {
    return React.cloneElement(label, props);
  }
  return null;
};

const StyledFormLabel = styled(FormLabel)`
  margin-bottom: 4px;
`;

FormLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  type: PropTypes.string,
};

FormLabel.defaultProps = {
  label: null,
  type: '',
};

export default StyledFormLabel;
