import { Theme } from 'Common/Utils/theme';
import React from 'react';
import styled from 'styled-components';

import SendButtonIcon from './send-button.svg';

const ButtonWrapper = styled.button<{
  theme: Theme;
}>`
  border: 0;
  background: ${props => props.theme.colours.purple};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;

  * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const Image = styled.img<{
  visible: boolean;
}>`
  z-index: 10;
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 0.5s;
`;

const Spinner = () => (
  <svg
    version="1.1"
    id="L9"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 0 0"
    xmlSpace="preserve"
  >
    <path
      fill="#fff"
      d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="1s"
        from="0 50 50"
        to="360 50 50"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);

type Props = {
  isSubmitting: boolean;
  disabled: boolean;
  onClick: (event: any) => void;
};

const SendButton: React.FC<Props> = ({ isSubmitting, ...rest }) => (
  <ButtonWrapper {...rest}>
    <Image alt="Send" src={SendButtonIcon} visible={!isSubmitting} />
    <Spinner />
  </ButtonWrapper>
);

export default SendButton;
