import { css } from 'styled-components';
import { Theme } from '../../Utils/theme';

export type SpacerProps = {
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
};

/** A styled-components mixin to allow easy adding of spacing to a component
 *
 * Parameters are numbers based on the spacing system in the theme
 * @see common/Utils/theme.ts
 *
 * @example
 * ```
 * import styled from 'styled-components';
 * import spacer, { SpacerProps } from 'common/UI/Layout/spacer';
 *
 * const CustomComponent = styled.div<SpacerProps>`
 *  ... your custom styles here
 *  ${spacer}
 * `;
 *
 * ...
 *
 * In your component:
 *
 * <CustomComponent mt={2} mb={8} />
 * ```
 *
 * ---
 *
 * @param {number} mt - margin-top
 * @param {number} mr - margin-right
 * @param {number} mb - margin-bottom
 * @param {number} ml - margin-left
 * @param {number} pt - padding-top
 * @param {number} pr - padding-right
 * @param {number} pb - padding-bottom
 * @param {number} pl - padding-left
 * @returns {string} - css string
 */
const spacer = css<{ theme: Theme } & SpacerProps>`
  ${props =>
    Number.isInteger(props.mt) &&
    `margin-top: ${props.theme.spacing[Number(props.mt)]};`}
  ${props =>
    Number.isInteger(props.mr) &&
    `margin-right: ${props.theme.spacing[Number(props.mr)]};`}
  ${props =>
    Number.isInteger(props.mb) &&
    `margin-bottom: ${props.theme.spacing[Number(props.mb)]};`}
  ${props =>
    Number.isInteger(props.ml) &&
    `margin-left: ${props.theme.spacing[Number(props.ml)]};`}
  ${props =>
    Number.isInteger(props.pt) &&
    `padding-top: ${props.theme.spacing[Number(props.pt)]};`}
  ${props =>
    Number.isInteger(props.pr) &&
    `padding-right: ${props.theme.spacing[Number(props.pr)]};`}
  ${props =>
    Number.isInteger(props.pb) &&
    `padding-bottom: ${props.theme.spacing[Number(props.pb)]};`}
  ${props =>
    Number.isInteger(props.pl) &&
    `padding-left: ${props.theme.spacing[Number(props.pl)]};`}
`;

export default spacer;
