import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Paragraph from 'Common/UI/Text/Paragraph';
import RoundButton from 'Common/UI/Button/RoundButton';
import FormLabel from 'Common/UI/Form/FormLabel';

const SelectButton = styled(RoundButton)`
  margin: 0 ${({ theme }) => theme.padding.xxsmall}px
    ${({ theme }) => theme.padding.xxsmall}px 0;
`;

const Label = styled.label``;

const MultiSelectContainer = styled.div`
  overflow: auto;

  @media (max-width: 700px) {
    max-height: 400px;
  }

  @media (max-width: 400px) {
    max-height: 260px;
    ${SelectButton} {
      height: auto;
      margin-bottom: 12px;
    }
  }
`;

const MultiSelect = ({
  label,
  name,
  value: selected,
  onChange,
  options,
  type,
}) => {
  const onButtonClicked = (e, value) => {
    onChange({ ...selected, [value]: !selected[value] });
    e.preventDefault();
  };

  return (
    <>
      <Paragraph large>You can select multiple</Paragraph>
      <MultiSelectContainer>
        <Label htmlFor={name}>
          <FormLabel label={label} type={type} />
          {options.map(o => (
            <SelectButton
              key={o.value}
              onClick={e => onButtonClicked(e, o.value)}
              selected={selected[o.value] === true}
              reverse={selected[o.value] !== true}
            >
              {o.label}
            </SelectButton>
          ))}
        </Label>
      </MultiSelectContainer>
    </>
  );
};

MultiSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  type: PropTypes.string,
};

MultiSelect.defaultProps = {
  value: {},
  type: '',
  options: [],
  label: '',
};

export default MultiSelect;
