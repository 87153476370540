import type { DashboardTabs } from './generateDashboardTabs'

// check if all dashboards that need to be completed are completed
// this excludes the Suggested division dashboard
const areAllDashboardsComplete = (dashboardTabs: DashboardTabs): boolean => {
  let allComplete = true;

  dashboardTabs.forEach((tab) => {
    // don't check the suggested division dashboard for completion
    if (tab.label === 'Suggested Division') return;
    // if allComplete gets set to false, don't set it back to true
    if (allComplete === false) return;

    if (tab.status === 'completed') {
      allComplete = true;
    }
    if (tab.status !== 'completed') {
      allComplete = false;
    }
  });

  return allComplete;
}

export default areAllDashboardsComplete;