import React from 'react';
import CardQuestions from 'App/UI/Questions/CardQuestionsFlow';
import { reduxStore } from 'App/State/Store';
import { updateFlag } from 'App/State/AppReducer';
import MatterGuard from './Guard/MatterGuard';

type Props = {
  props: any;
};

const onQuestionsSubmit = async () => {
  // Updates the store with the consent for a field
  const { dispatch } = reduxStore;
  await dispatch(updateFlag({ isYourDetailsSetupComplete: true }));
};

const ODRSYourDetails: React.FC<Props> = props => (
  <MatterGuard>
    <CardQuestions
      baseID="yourdetails"
      index="self"
      onSubmit={onQuestionsSubmit}
      {...props}
    />
  </MatterGuard>
);

export default ODRSYourDetails;
