import styled from 'styled-components';
import { Theme } from 'Common/Utils/theme';
import spacer, { SpacerProps } from '../Layout/spacer';

export const Label = styled.p<
  {
    theme: Theme;
    bold?: boolean;
  } & SpacerProps
>`
  ${({ theme }) => theme.typography.text.label};
  color: ${({ theme }) => theme.textColour};
  ${({ bold }) => bold && 'font-weight: 600;'}
  margin: 0;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    ${({ theme }) => theme.typography.text.label.md};
  }

  @media ${({ theme }) => theme.sizes.xl.breakpoint} {
    ${({ theme }) => theme.typography.text.label.xl};
  }

  ${spacer}
`;
export const HighlightedLabel = styled(Label)<{
  theme: Theme;
}>`
  ${({ theme }) => theme.typography.text.highlighted};
`;
