import BackgroundQuestionFlowRelationship from 'Common/Assets/images/demo-header.svg';
import { reduxStore } from 'App/State/Store';
import {
  DATA_PAGE,
  DATA_SECTION,
  PAGE_SUGGESTED_DIVISION_MAIN,
  SECTION_SUGGESTED_DIVISION,
} from '../../../constants';
import { ODRS_DIVISION_VIEW_SUGGESTED } from '../../../routes';
import { getNavItemStatus } from '../../../UI/Navigation';
import colours from '../../../Utils/colours';
import { DataSection } from '../../Types/appSections';

const generateSuggestedDivision = (): DataSection => {
  const generateStatus = () => {
    const { app } = reduxStore.getState();

    return getNavItemStatus({
      isCompleted: false,
      isLocked: !app.flags.isDemoComplete,
    });
  };

  return {
    type: DATA_SECTION,
    id: SECTION_SUGGESTED_DIVISION,
    title: 'Suggested Division',
    route: ODRS_DIVISION_VIEW_SUGGESTED,
    questionFlowBackgroundImage: BackgroundQuestionFlowRelationship,
    questionFlowBackgroundColour: colours.offWhite,
    children: [
      {
        type: DATA_PAGE,
        id: PAGE_SUGGESTED_DIVISION_MAIN,
        title: 'Suggested Division',
        route: ODRS_DIVISION_VIEW_SUGGESTED,
        generateStatus,
        children: [],
      },
    ],
  };
};

export default generateSuggestedDivision;
