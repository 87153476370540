import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BANNER_GENERIC,
  BANNER_HIDE,
  CONTENT_HIDE,
  DialogCompleteActions,
  Dialogs,
} from 'Common/constants';
import { GeneratedAppState } from 'Common/Data/Types/appState';
import theme from 'Common/Utils/theme';
import { DefaultTheme } from 'styled-components';

export type AppState = {
  theme: DefaultTheme;
  isLoading: boolean;
  isModalShowing: boolean;
  flags: {
    confirmedRangeOnly: boolean;
    isYourDetailsSetupComplete: boolean;
    isDemoComplete: boolean;
  };
  consent?: {
    email?: boolean;
  };
  appState: GeneratedAppState;
  selectedDialogCompleteAction?: DialogCompleteActions;
  isDialogShowing?: boolean;
};

const initialState: AppState = {
  theme,
  isLoading: false,
  isModalShowing: false,
  selectedDialogCompleteAction: undefined,
  isDialogShowing: undefined,
  flags: {
    confirmedRangeOnly: false,
    isYourDetailsSetupComplete: false,
    isDemoComplete: false,
  },
  consent: {},
  appState: {
    appState: BANNER_GENERIC,
    bannerTopState: BANNER_HIDE,
    bannerBottomState: BANNER_HIDE,
    contentState: CONTENT_HIDE,
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsModalShowing(state, action) {
      return { ...state, isModalShowing: action.payload };
    },
    setIsLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
    updateFlag(state, action: PayloadAction<Record<string, unknown>>) {
      return {
        ...state,
        flags: {
          ...state.flags,
          ...action.payload,
        },
      };
    },
    updateConsent(state, action: PayloadAction<Record<string, unknown>>) {
      return {
        ...state,
        consent: {
          ...state.consent,
          ...action.payload,
        },
      };
    },
    updateAppState(state, action: PayloadAction<GeneratedAppState>) {
      return { ...state, appState: action.payload };
    },
    clearAppStore() {
      return initialState;
    },

    showDialog(
      state,
      action: PayloadAction<{ dialog: Dialogs; data?: unknown }>
    ) {
      return {
        ...state,
        isDialogShowing: true,
        dialog: action.payload.dialog,
        dialogData: action.payload.data ?? null,
      };
    },

    hideDialog(state) {
      return { ...state, isDialogShowing: false, dialog: undefined };
    },

    // this is used by a dialog to broadcast which action was selected in the dialog
    // it is needed if the dialog needs to trigger local code that it doesn't have access to in the component that triggered the dialog
    setDialogCompleteAction(
      state,
      action: PayloadAction<DialogCompleteActions>
    ) {
      return { ...state, selectedDialogCompleteAction: action.payload };
    },

    clearDialogCompleteAction(state) {
      return { ...state, selectedDialogCompleteAction: undefined };
    },
  },
});

export const {
  setIsModalShowing,
  setIsLoading,
  updateFlag,
  updateConsent,
  updateAppState,
  clearAppStore,
  showDialog,
  hideDialog,
  setDialogCompleteAction,
  clearDialogCompleteAction,
} = appSlice.actions;

export default appSlice.reducer;
