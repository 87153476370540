import React, { useEffect } from 'react';
import styled from 'styled-components';
import Div100vh from 'react-div-100vh';
import { useTransition, animated } from 'react-spring';

import { Heading2 } from 'Common/UI/Text/Headings';
import ImageButton from 'Common/UI/Button/ImageButton';
import TextButton from 'Common/UI/Button/TextButton';
import RoundButton from 'Common/UI/Button/RoundButton';
import IconClose from 'Common/Assets/images/icon-close-lightgrey.svg';
import { setIsModalShowing as setIsModalShowingAction } from 'App/State/AppReducer';
import { useAppDispatch } from 'App/State/Store';
import PageContainer from './PageContainer';

const ModalWrapper = styled(PageContainer)`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 500;
  color: ${props => props.theme.textColour};
`;

const InnerWrapper = styled.div`
  height: 100%;
  width: 100vw;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colours.white};
`;

const Header = styled.div`
  background: ${props => props.theme.colours.offWhite};
  min-height: 82px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${props => props.theme.spacing[6]};
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
`;

const Title = styled(Heading2)`
  margin: 0;
`;

const StyledTextButton = styled(TextButton)`
  padding: 0;
`;

const Content = styled.div`
  flex: 1;
  display: inline-flex;
  flex-direction: column;
`;

const Animated = styled(animated.div)`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const IconCloseButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <ImageButton onClick={onClick}>
    <img src={IconClose} alt="Close" />
  </ImageButton>
);

type Props = {
  show: boolean;
  title: React.ReactNode;
  children: React.ReactNode;
  onClose: () => void;
  closeButtonType: 'text' | 'icon' | 'done';
};

const FullScreenModal: React.FC<Props> = ({
  show,
  title = '',
  children,
  onClose,
  closeButtonType = 'text',
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const setIsModalShowing = (value: boolean) =>
    dispatch(setIsModalShowingAction(value));

  const transitions = useTransition(show, null, {
    from: { transform: 'translateY(100%)' },
    enter: { transform: 'translateY(0)' },
    leave: { transform: 'translateY(100%)' },
  });

  useEffect(() => {
    setIsModalShowing(show);
    return () => {
      setIsModalShowing(false);
    };
  }, [show]);

  let button: React.ReactNode;

  if (closeButtonType === 'icon') {
    button = (
      <IconCloseButton data-cy="full-screen-modal-button" onClick={onClose} />
    );
  } else if (closeButtonType === 'done') {
    button = (
      <RoundButton data-cy="full-screen-modal-button" subtle onClick={onClose}>
        Done
      </RoundButton>
    );
  } else {
    button = (
      <StyledTextButton data-cy="full-screen-modal-button" onClick={onClose}>
        Close
      </StyledTextButton>
    );
  }

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <Div100vh data-cy="full-screen-modal" key={key}>
              <ModalWrapper {...rest}>
                <Animated style={props}>
                  <InnerWrapper>
                    <Header>
                      <Title>{title}</Title>
                      {button}
                    </Header>
                    <Content>{children}</Content>
                  </InnerWrapper>
                </Animated>
              </ModalWrapper>
            </Div100vh>
          )
      )}
    </>
  );
};

export default FullScreenModal;
