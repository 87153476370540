import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css, ThemeProvider } from 'styled-components';

import { orangeTheme } from 'Common/Utils/theme';
import { Callout } from 'Common/UI/Text/Paragraph';
import { Heading2 } from 'Common/UI/Text/Headings';
import BackgroundOverlay from 'Common/UI/Layout/BackgroundOverlay';
import GenericButton from 'Common/UI/Button/GenericButton';
import {
  ODIE_STATE_OPEN,
  openOdie as openOdieAction,
  closeOdie as closeOdieAction,
} from 'App/State/OdieReducer';

const LearnMoreContentContainer = styled.div`
  padding-top: 28px;

  @media ${props => props.theme.sizes.md.breakpoint} {
    padding: 48px;
  }

  ${Heading2} {
    margin-top: 0;
    margin-bottom: ${props => props.theme.padding.medium}px;
  }
  ${Callout} {
    margin-bottom: ${props => props.theme.padding.medium2}px;
  }
`;

export const OdieBody = styled.div`
  background-color: ${props =>
    props.theme.odieBackground || props.theme.colours.white};
  color: ${props => props.theme.colours.black};
  display: flex;
  flex-direction: column;
  right: 0;
  position: fixed;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
  z-index: 10;
  width: 100%;
  height: 100%;

  @media ${props => props.theme.sizes.md.breakpoint} {
    width: 60%;
  }
`;

export const OdieContent = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  padding: ${({ noPadding, theme }) =>
    !noPadding &&
    `${theme.padding.medium}px ${theme.padding.medium}px ${theme.padding.medium}px`};

  ${({ noPadding, theme }) =>
    !noPadding &&
    css`
      @media ${theme.sizes.md.breakpoint} {
        padding: 30px 40px;
      }
    `};
`;

const OdieFooter = styled.div`
  flex: 0;
  text-align: right;
  padding: ${props =>
    `0 ${props.theme.padding.medium}px ${props.theme.padding.medium}px`};

  @media ${props => props.theme.sizes.md.breakpoint} {
    padding: 40px 40px 20px;
  }
`;

const StyledOdie = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 600;
  transition: all 0.5s;

  ${BackgroundOverlay} {
    transform: translateX(100%);
    opacity: 0;
    transition: opacity 0.5s;
  }

  ${props =>
    !props.isVisible &&
    `
    pointer-events: none;
    visibility: hidden;
    button {
      background: transparent;
      span.label {
        transform: translateX(-200px);
      }
    }
    .label {
      opacity: 0;
    }
  `};

  ${props =>
    props.isVisible &&
    `
    ${OdieBody} {
      transform: translateX(0);
    }

    ${BackgroundOverlay} {
      transform: translateX(0);
      opacity: 1;
    }
  `};
`;

const CloseButton = styled(GenericButton)`
  color: ${props => props.theme.colours.black};
  padding-left: 48px;
  padding-right: 48px;
`;

const Odie = ({ odieState, closeOdie, children }) => {
  const handleOnClose = () => {
    if (odieState.onClose) odieState.onClose();
    closeOdie();
  };

  return (
    <ThemeProvider theme={odieState.theme}>
      <StyledOdie isVisible={odieState.state === ODIE_STATE_OPEN}>
        <BackgroundOverlay onClick={handleOnClose} />
        <OdieBody>
          <OdieContent noPadding={odieState.noPadding}>
            {odieState.message && (
              <LearnMoreContentContainer>
                {odieState.message}
              </LearnMoreContentContainer>
            )}
            {children}
          </OdieContent>
          {!odieState.hideFooter && (
            <OdieFooter>
              <ThemeProvider theme={orangeTheme}>
                <CloseButton
                  onClick={handleOnClose}
                  disabled={odieState.state !== ODIE_STATE_OPEN}
                >
                  {odieState.popupButtonText || 'Got it'}
                </CloseButton>
              </ThemeProvider>
            </OdieFooter>
          )}
        </OdieBody>
      </StyledOdie>
    </ThemeProvider>
  );
};

Odie.propTypes = {
  odieState: PropTypes.shape({
    popupButtonText: PropTypes.string,
    message: PropTypes.element,
    footer: PropTypes.element,
    theme: PropTypes.shape({}),
    hideFooter: PropTypes.bool,
    prompt: PropTypes.string,
    state: PropTypes.string.isRequired,
    noPadding: PropTypes.bool,
  }).isRequired,
  closeOdie: PropTypes.func.isRequired,
  children: PropTypes.node,
};

Odie.defaultProps = {
  children: null,
};

const mapDispatchToProps = dispatch => ({
  openOdie: () => dispatch(openOdieAction()),
  closeOdie: () => dispatch(closeOdieAction()),
});

const mapStateToProps = state => ({
  odieState: state.odie,
});

export default connect(mapStateToProps, mapDispatchToProps)(Odie);
