import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Paragraph from 'Common/UI/Text/Paragraph';
import StyledTextfield from 'Common/UI/Form/Textfield';
import SendButton from './SendButton';

const MessageInputForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;
  ${Paragraph} {
    margin-top: 0;
    color: black;
  }
  background-color: white;
`;

const MessageInput = styled(StyledTextfield)`
  flex: 1;

  textarea {
    border-radius: 16px;
    line-height: 144%;
    margin-top: 0;
  }
`;

const ControlsWrap = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  ${MessageInput} {
    flex-grow: 1;
    margin-right: ${props => (props.showSend ? '50px' : 0)};
    transition: margin-right 0.5s;
  }
  ${SendButton} {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: ${props => (props.showSend ? 1 : 0)};
    transition: opacity 0.5s;
  }
`;

const MessageTextBox = ({
  onMessageSubmit,
  onMessageInput,
  messageInput,
  prompt,
  disabled,
}) => {
  const hasInput = messageInput.trim().length !== 0;

  return (
    <MessageInputForm onSubmit={onMessageSubmit}>
      {prompt && <Paragraph>{prompt}</Paragraph>}
      <ControlsWrap showSend={hasInput}>
        <MessageInput
          onChange={onMessageInput}
          label=""
          name="message"
          value={messageInput}
          placeholder=""
          rows={3}
          disabled={disabled}
        />
        <SendButton
          disabled={disabled || messageInput.trim().length === 0}
          onClick={onMessageSubmit}
        />
      </ControlsWrap>
    </MessageInputForm>
  );
};

MessageTextBox.propTypes = {
  onMessageSubmit: PropTypes.func.isRequired,
  onMessageInput: PropTypes.func.isRequired,
  messageInput: PropTypes.string,
  prompt: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
};

MessageTextBox.defaultProps = {
  prompt: false,
  messageInput: '',
  disabled: false,
};

export default MessageTextBox;
