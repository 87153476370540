import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { useSpring, animated } from 'react-spring/web.cjs';

import RoundButton from 'Common/UI/Button/RoundButton';
import { orangeTheme } from 'Common/Utils/theme';

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.align};
  margin: ${props => props.theme.padding.xsmall}px 0;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    justify-content: flex-start;
  }
`;

export const BubbleWrap = styled.div`
  padding: 0
    ${props =>
      props.padding ? props.padding : `${props.theme.padding.small}px`};
  ${props => props.fullWidth && 'width: 100%;'}

  > div {
    background-color: ${props => props.theme.primary};
    border-radius: ${props =>
      props.borderRadius
        ? `${props.borderRadius}px`
        : `${props.theme.padding.small}px`};
    padding: ${props => props.theme.padding.medium}px;
    max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : '100%')};

    @media ${({ theme }) => theme.sizes.md.breakpoint} {
      max-width: 416px;
    }
  }

  & p {
    margin: 0;
    color: ${props => props.theme.textPrimary};
  }
`;

export const BubbleButton = styled(RoundButton)`
  background-color: ${props => props.theme.buttonBackgroundPrimary};
  color: ${props => props.theme.textPrimary};
  min-width: 60px;
  padding: 9px 16px;
  border-radius: ${props => (props.buttonStyle === 'rounded' ? '20px' : '4px')};
  margin: 16px 0 0;
  line-height: 140%;
`;

const BubbleNotification = ({
  onClick,
  buttonLabel,
  buttonStyle,
  children,
  dataCy,
  align,
  padding,
}) => {
  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <FlexWrapper align={align}>
      <ThemeProvider theme={orangeTheme}>
        <BubbleWrap data-cy={dataCy} maxWidth={280} padding={padding}>
          <animated.div style={props}>
            {children}
            <BubbleButton
              data-cy={`${dataCy}-got-it-button`}
              onClick={onClick}
              buttonStyle={buttonStyle}
            >
              {buttonLabel}
            </BubbleButton>
          </animated.div>
        </BubbleWrap>
      </ThemeProvider>
    </FlexWrapper>
  );
};

BubbleNotification.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
  buttonStyle: PropTypes.string,
  children: PropTypes.element.isRequired,
  dataCy: PropTypes.string,
  align: PropTypes.string,
  padding: PropTypes.string,
};

BubbleNotification.defaultProps = {
  buttonStyle: '',
  buttonLabel: 'Got it',
  dataCy: undefined,
  align: 'flex-end',
  padding: undefined,
};

export default BubbleNotification;
