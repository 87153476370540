import React from 'react';
import { PARTY_A, PARTY_B } from 'Common/constants';
import formatCurrency from 'Common/Utils/formatCurrency';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getFirstName,
  getRedactFunction,
  getSingleUserFullName,
} from 'Common/Utils/statementHelpers';
import DocumentList from 'Common/UI/UploadedDocuments/DocumentList';
import isFileObject from 'Common/Utils/isFileObject';
import { Statement, QuestionAny } from 'Common/Data/Types/appSections';
import Matter from 'Common/Data/Types/matter';
import capitaliseText from '../../../../Utils/capitaliseText';

export const propertiesLabel = 'Properties';

export const propertiesQuestions: QuestionAny[] = [
  {
    name: 'intro',
    heading: (matter: Matter) => (
      <H1Conversational>
        Hi {getSingleUserFullName(matter)}, we’re going to ask you about the
        real estate that you owned or shared with your former partner.
      </H1Conversational>
    ),
    content: () => (
      <Paragraph>
        These questions are important because we will base our suggestions for a
        fair division on the information you give.
      </Paragraph>
    ),
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'propertyAddress',
    label: 'What suburb is this property located in?',
    hint: '',
    type: 'googleSuburb',
    sensitive: true,
    defaultValue: '',
    index: 1,
  },
  {
    name: 'propertyTitle',
    label: 'Who owns this property?',
    hint: '',
    type: 'select',
    options: MatterPropsJS => [
      { label: 'Myself', value: MatterPropsJS('self.party') },
      {
        label: `Both myself and my former partner`,
        value: 'both',
      },
      {
        label: `My former partner`,
        value: MatterPropsJS('other.party', PARTY_A),
      },
    ],
    defaultValue: '',
    index: 2,
  },
  {
    name: 'propertyValue',
    label: 'How much is this property worth?',
    hint: '',
    type: 'currency',
    defaultValue: '',
    index: 3,
    help: {
      label: 'How do I figure this out?',
      content: (
        <>
          <H1Conversational>How do I figure this out?</H1Conversational>
          <Paragraph large>
            {`This is how much the property is worth now, not when you bought 
            it or when you got together. If you don't know what it is currently 
            worth, you can use informal valuations, have someone formally estimate 
            the value of all property, or use your council rates valuation as a guide.`}
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'propertyHasMortgage',
    label: 'Thanks. Do you have a mortgage on this property?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 4,
  },
  {
    name: 'propertyMortgage',
    label: 'Okay, how much do you owe on your mortgage?',
    hint: '',
    type: 'currency',
    defaultValue: '',
    index: 5,
    visible: answers => !!answers.propertyHasMortgage,
    help: {
      label: 'How do I figure this out?',
      content: (
        <>
          <H1Conversational>How do I figure this out?</H1Conversational>
          <Paragraph large>
            This is the remaining balance owed on the home loan. You can find
            this out from your bank, or your most recent home loan bank
            statement.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'propertyWhoseName',
    label: 'Whose name is on the mortgage? ',
    hint: '',
    type: 'select',
    defaultValue: '',
    options: _MatterPropsJS => [
      { label: 'My name', value: _MatterPropsJS('self.party') },
      { label: 'Their name', value: _MatterPropsJS('other.party', PARTY_A) },
      { label: 'Both our names', value: 'both' },
    ],
    index: 6,
    visible: answers => !!answers.propertyHasMortgage,
    sensitive: true,
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 9,
  },
];

export const propertiesStatement = ({
  matter,
  data,
  card,
  plainText = false,
  withUpdateLink = false,
  hideSensitive = false,
}: Statement) => {
  const { itemHistory } = matter;

  const itemDiff = getUpdatedItemValues(data, itemHistory, [
    'propertyAddress',
    'propertyValue',
    'propertyMortgage',
    'propertyHasMortgage',
    'propertyTitle',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(propertiesQuestions, data, hideSensitive);

  const value = highlight`The property in ${updated(
    'propertyAddress',
    redact('propertyAddress')
  )} is worth ${updated(
    'propertyValue',
    redact('propertyValue', formatCurrency(data.propertyValue))
  )}.`;

  let mortgage;

  if (data.propertyHasMortgage) {
    mortgage = highlight`There is ${updated(
      'propertyMortgage',
      redact('propertyMortgage', formatCurrency(data.propertyMortgage))
    )} owing on the mortgage.`;
  } else {
    mortgage = highlight`There is ${updated(
      'propertyHasMortgage',
      'no mortgage'
    )} on the property.`;
  }

  let title;

  if (data.propertyTitle === 'both') {
    title = highlight`The property is ${updated(
      'propertyTitle',
      'owned jointly'
    )}.`;
  } else {
    let propertyOwner;

    if (data.propertyTitle === PARTY_A) {
      propertyOwner = getFirstName(matter.partyA, PARTY_A, hideSensitive);
      title = highlight`${updated(
        'propertyTitle',
        propertyOwner
      )} own the property.`;
    } else {
      propertyOwner = capitaliseText(
        getFirstName(matter.partyB, PARTY_B, hideSensitive)
      );
      title = highlight`${updated(
        'propertyTitle',
        propertyOwner
      )} owns the property.`;
    }
  }

  if (plainText) {
    return `${value} ${mortgage} ${title}`;
  }

  let files = null;

  // Create a document list if they have been uploaded
  if (
    data.propertyProof &&
    data.propertyProof.length &&
    !isFileObject(data.propertyProof)
  ) {
    files = <DocumentList documents={data.propertyProof} />;
  }

  return (
    <>
      {value} {mortgage} {title} {files}
    </>
  );
};
