import React from 'react';

import formatCurrency from 'Common/Utils/formatCurrency';

import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getSingleUserFullName,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import Matter from 'Common/Data/Types/matter';
import { PARTY_A } from 'Common/constants';

export const sharedFinancesLabel = 'Shared Finances';

export const sharedFinancesQuestions: QuestionAny[] = [
  {
    name: 'intro',
    heading: (matter: Matter) => (
      <H1Conversational>
        Hi {getSingleUserFullName(matter)}, we’re going to ask you about the
        money and property you shared with your former partner.
      </H1Conversational>
    ),
    content: () => (
      <Paragraph>
        These questions are important because we will base our suggestions for a
        fair division on the information you give.
      </Paragraph>
    ),
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'intro2',
    label: 'Before you get started',
    content: (
      <Paragraph large>
        All the amounts you enter should use your most current values.
      </Paragraph>
    ),
    hint: '',
    type: 'info',
    defaultValue: '',
    index: 1,
    submitButtonLabel: 'I understand',
  },
  {
    name: 'sharedSavings',
    label: () => 'What is the total balance of your joint  bank accounts?',
    hint: '',
    type: 'currency',
    defaultValue: '',
    index: 2,
    optional: true,
  },

  // Shared Asset
  {
    name: 'sharedAssets',
    label:
      "What is the total amount of any other major money or property assets (such as shares, cash and household items) that you own jointly and haven't already included in the 'Your Finances' section.",
    hint: '',
    type: 'currency',
    defaultValue: undefined,
    optional: true,
    index: 3,
    help: {
      label: 'What should I include?',
      content: (
        <>
          <H1Conversational>What should I include?</H1Conversational>
          <Paragraph large>
            Please include any other major money or property assets you had when
            you separated that you haven&apos;t listed already.
          </Paragraph>
          <Paragraph large>
            You don&apos;t need to include any shared vehicles here. You can add
            those later in the Vehicles section.
          </Paragraph>
          <Paragraph large>
            You should include any other major money or property assets that you
            haven&apos;t already listed. For example:
          </Paragraph>
          <ul>
            <li>
              you should include any life insurance policies that have a cash
              surrender value,
            </li>
            <li>
              any interest in any other property, including leased property,
            </li>
            <li>
              or any other financial resource, such as a personal injury claim
              or court case or any property from an inheritance when someone
              passes away.
            </li>
          </ul>
        </>
      ),
    },
  },
  // Shared Debt
  {
    name: 'sharedDebts',
    label:
      "What is the total amount of any other debts you owe jointly (not including mortgages) that you haven't already included in the 'Your Finances' section?",
    hint: '',
    type: 'currency',
    defaultValue: undefined,
    optional: true,
    index: 4,
    help: {
      label: 'What should I include?',
      content: (
        <>
          <H1Conversational>What should I include?</H1Conversational>
          <Paragraph large>
            You should include any debts that you haven&apos;t already listed.
            For example: the total amount of any hire-purchase agreements.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 5,
  },
];

export const sharedFinancesStatement = ({
  matter,
  data,
  card,
  plainText = false,
  withUpdateLink = false,
  hideSensitive = false,
}: Statement) => {
  const { itemHistory } = matter;

  const itemDiff = getUpdatedItemValues(data, itemHistory, [
    'sharedSavings',
    'sharedAssets',
    'sharedDebts',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(
    sharedFinancesQuestions,
    data,
    hideSensitive
  );

  const names = `${getFirstName(
    matter.partyA,
    PARTY_A,
    hideSensitive
  )} and your former partner`;

  // "Name and Name have amount in their joint account, including name (number). The have amount in cash, shares, and household items that they haven’t listed previously including name (number). They have amount in debts they haven’t listed previously including name (number)."

  // Joint savings
  const sharedSavings =
    (() => {
      if (!data.sharedSavings || data.sharedSavings === '0') {
        return highlight`${updated(
          'sharedSavings',
          redact('sharedSavings', 'do not')
        )} have any joint savings.`;
      }

      return highlight`have a total balance in your joint bank accounts of ${updated(
        'sharedSavings',
        redact(
          'sharedSavings',
          formatCurrency(parseFloat(data.sharedSavings as string))
        )
      )}.`;
    })() || '';

  // Assets
  const sharedAssets =
    (() => {
      if (!data.sharedAssets || data.sharedAssets === '0') {
        return highlight`You ${updated(
          'sharedAssets',
          redact('sharedAssets', 'do not')
        )} have any cash, shares, or household items that they haven't listed previously.`;
      }

      return highlight`The total amount of other shared assets (cash, shares, and household items) that haven't been listed previously is ${updated(
        'sharedAssets',
        redact(
          'sharedAssets',
          formatCurrency(parseFloat(data.sharedAssets as string))
        )
      )}.`;
    })() || '';

  // sharedDebts
  const sharedDebts =
    (() => {
      if (!data.sharedDebts || data.sharedDebts === '0') {
        return highlight`They ${updated(
          'sharedDebts',
          redact('sharedDebts', 'do not')
        )} have debts they haven't listed previously.`;
      }

      return highlight`The total amount of other debts that haven't been listed previously is ${updated(
        'sharedDebts',
        redact(
          'sharedDebts',
          formatCurrency(parseFloat(data.sharedDebts as string))
        )
      )}.`;
    })() || '';

  if (plainText) {
    return `${names} ${sharedSavings} ${sharedAssets} ${sharedDebts}`;
  }

  return (
    <>
      {names} {sharedSavings} {sharedAssets} {sharedDebts}
    </>
  );
};
