import React from 'react';

import { H2Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  SlideInner,
  SlideText,
  SlideIcon,
} from 'Common/UI/Carousel/ImageSlideWrapper';

import DivisionImage01 from 'Common/Assets/images/divi-background-circles-and-message-box.png';

const DivisionSlide1 = () => (
  <SlideInner>
    <SlideIcon className="slide-icon" backgroundImage={DivisionImage01}>
      <img alt="" src={DivisionImage01} srcSet={`${DivisionImage01} 2x`} />
    </SlideIcon>
    <SlideText data-cy="suggested-division-slide-1">
      <H2Conversational>
        amica one&apos;s suggested division should be used as a guide only.
      </H2Conversational>

      <Paragraph>
        It&apos;s important to remember that the suggested division we provide
        is a guide only; it is not a substitute for legal advice from a properly
        qualified legal practitioner.
      </Paragraph>
      <Paragraph>
        <strong>
          Once you view the suggested division, you won’t be
          able to edit your answers.
        </strong>
      </Paragraph>
    </SlideText>
  </SlideInner>
);
export default DivisionSlide1;
