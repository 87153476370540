import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { darkTheme } from 'Common/Utils/theme';

const StyledDrawer = styled.div`
  border-radius: 24px 24px 0 0;
  width: 100%;
  border: 2px solid #f1f1f8;
  padding-top: ${({ theme }) => theme.spacing[6]};
  padding-bottom: ${({ theme }) => theme.spacing[6]};
  ${({ noPadding, theme }) => !noPadding && theme.tokens.pageSpacingHorizontal}

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-top: ${({ theme }) => theme.spacing[8]};
    padding-bottom: ${({ theme }) => theme.spacing[8]};
  }
`;

const Drawer = props => <StyledDrawer {...props} />;

export const TopMessageDrawerComponent = styled(Drawer)`
  background: ${({ theme }) => theme.topMessageColour};
  border: none;
  color: ${({ theme }) => theme.colours.white};
  flex: ${({ hasFlex }) => (hasFlex ? '1' : '0')};
  max-height: ${({ hasFlex, open }) => {
    if (open && !hasFlex) return '300px';
    if (open && hasFlex) return 'initial';
    return '0';
  }};
  ${({ open }) => !open && 'padding: 0;'}
  transition: all 1s;
  position: relative;
  z-index: ${({ zindex }) => zindex || '-1'};

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 100px;
    transition: all 1s;
    background: ${({ open, theme }) =>
      open ? theme.backgroundColour : 'transparent'};
  }
`;

export const TopMessageDrawer = ({ theme, children, ...props }) => (
  <ThemeProvider theme={theme}>
    <TopMessageDrawerComponent {...props}>{children}</TopMessageDrawerComponent>
  </ThemeProvider>
);

TopMessageDrawer.propTypes = {
  theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

TopMessageDrawer.defaultProps = {
  theme: darkTheme,
  children: null,
};

export default Drawer;
