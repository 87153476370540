import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import defaultTheme, { Theme } from "Common/Utils/theme";

type Props = {
  setTheme: (theme: Theme) => void;
  theme: Theme;
};

export const RouteContext = createContext<Props>({
  setTheme: () => {},
  theme: defaultTheme,
});

export const RouteProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme] = useState(defaultTheme);

  return (
    <RouteContext.Provider
      value={{
        setTheme,
        theme,
      }}
    >
      {children}
    </RouteContext.Provider>
  );
};

RouteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
