import moment from 'moment';

const isDate = (date, options = {}) => {
  const NOW = moment();

  if (!moment(date).isValid()) {
    return false;
  }

  if (options.atLeast) {
    return NOW.diff(date, 'years') >= options.atLeast;
  }

  if (options.atLeastMonths) {
    return NOW.diff(date, 'months') >= options.atLeastMonths;
  }

  if (options.inPast) {
    return NOW <= moment(date);
  }

  if (options.after) {
    return moment(date).isAfter(options.after);
  }

  return true;
};

export default isDate;
