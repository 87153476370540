import MatterProps from 'Common/Utils/MatterProps';
import { Party, PARTY_A, PARTY_B } from 'Common/constants';

const getPartyKeys = (): {
  self: Party;
  other: Party;
} => ({
  self: MatterProps('self.party', PARTY_A),
  other: MatterProps('other.party', PARTY_B),
});

export default getPartyKeys;
