import React from 'react';
import styled, { ThemeProvider, css } from 'styled-components';
import { Link } from 'react-router-dom';

import { ODRS_DASH_HOME_ROUTE } from 'Common/routes';

import { darkerTheme, Theme } from 'Common/Utils/theme';
import AmicaLogo from 'App/UI/AmicaLogoLight';

import { NavHeading3 } from 'Common/UI/Layout/SwipeNav';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { Title } from 'Common/UI/Text/Headings';
import Navigation from 'App/UI/Navigation';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const LogoWrapper = styled.div`
  display: flex;
`;

const LogoBackWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

const StyledAppHeader = styled.header<{
  theme: Theme;
  isMobileMenuOpen?: boolean;
  logoColour?: string;
  small?: boolean;
}>`
  transform: translate3d(0, 0, 0);
  background-color: ${props => props.theme.backgroundColour};
  position: relative;
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
  padding-top: ${({ theme }) => theme.spacing[8]};
  padding-bottom: 1px;
  transition: all 0.3s;
  margin-top: -66px; // used to offset the quick exit button

  svg {
    height: auto;
    width: 130px;
    transition: color 0.2s 0.1s;

    color: ${props => props.logoColour || props.theme.logoColour};

    @media ${({ theme }) => theme.sizes.md.breakpoint} {
      width: 160px;
    }
  }

  ${NavHeading3} {
    transition: font-size 0.5s;
    margin-bottom: 0;
  }

  ${props =>
    props.small &&
    css`
      padding: ${props.theme.padding.medium}px ${props.theme.padding.medium}px
        ${props.theme.padding.small}px;

      img {
        opacity: 0;
        transition: all 0.3s;
      }
    `}

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-top: ${({ theme }) => theme.spacing[8]};
  }
`;

const StyledTopHeader = styled.div<{
  theme: Theme;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing[4]};
  position: relative;

  @media ${({ theme }) => theme.sizes.sm.breakpoint} {
    padding-bottom: ${({ theme }) => theme.spacing[12]};
  }

  @media ${({ theme }) => theme.sizes.lg.breakpoint} {
    padding-bottom: ${({ theme }) => theme.spacing[16]};
  }
`;

type Tabs = {
  withNav: true;
  tabs: {
    label: string;
    to: string;
    status:
      | 'completed-locked'
      | 'completed'
      | 'locked'
      | 'updated'
      | 'not-completed';
    testId: string;
  }[];
};

type NoTabs = {
  withNav?: false;
  tabs?: undefined;
};

type AppHeaderProps = {
  small?: boolean;
  className?: string;
  logoColour?: string;
  theme?: Theme;
  children?: React.ReactNode;
  userName: string;
} & (Tabs | NoTabs);

const AppHeader: React.FC<AppHeaderProps> = ({
  withNav = false,
  small = false,
  className,
  logoColour,
  tabs,
  theme = darkerTheme,
  children,
  userName,
}) => (
  <ResponsiveContainer fullWidth>
    <ThemeProvider theme={theme}>
      <StyledAppHeader
        small={small}
        className={className}
        logoColour={logoColour}
      >
        <StyledTopHeader>
          <LogoBackWrapper>
            <StyledLink to={ODRS_DASH_HOME_ROUTE}>
              <LogoWrapper>
                <AmicaLogo />
              </LogoWrapper>
            </StyledLink>
          </LogoBackWrapper>
        </StyledTopHeader>

        <SpacedBox marginBottom="small" className="mobile-hider">
          <Title>Hi {userName}</Title>
          {withNav && tabs && <Navigation tabs={tabs} />}
        </SpacedBox>
        {children}
      </StyledAppHeader>
    </ThemeProvider>
  </ResponsiveContainer>
);

export default AppHeader;
