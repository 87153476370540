import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring/web.cjs';
import Measure from 'react-measure';
import BackgroundOverlay from './BackgroundOverlay';

const Outer = styled.div`
  position: relative;
  z-index: 20;
  ${props => props.fullWidth && 'width: 100%'};
`;

// to prevent margin collapse on inner components and measure the height of the components correctly
const InnerWrap = styled.div`
  display: inline-block;
  width: 100%;
`;

const Appears = ({
  children,
  on,
  className,
  onUpdateHeight,
  withBackgroundOverlay,
  bgOverlayOnClick,
  fullWidth,
}) => {
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (onUpdateHeight) {
      onUpdateHeight(contentHeight);
    }
  }, [contentHeight]);

  const props = useSpring({
    from: {
      overflow: 'hidden',
      opacity: 0,
      height: 0,
    },
    to: {
      overflow: 'hidden',
      opacity: 1,
      height: on ? contentHeight : 0,
    },
  });

  return (
    <Outer fullWidth={fullWidth}>
      <div className={className}>
        <animated.div style={props}>
          <Measure
            bounds
            margin
            onResize={contentRect => {
              setContentHeight(
                contentRect.bounds.height +
                  contentRect.margin.top +
                  contentRect.margin.bottom
              );
            }}
          >
            {({ measureRef }) => (
              <InnerWrap ref={measureRef}>{children}</InnerWrap>
            )}
          </Measure>
        </animated.div>
      </div>
      {withBackgroundOverlay && on && (
        <BackgroundOverlay onClick={bgOverlayOnClick} />
      )}
    </Outer>
  );
};

Appears.propTypes = {
  className: PropTypes.string,
  on: PropTypes.bool,
  onUpdateHeight: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  withBackgroundOverlay: PropTypes.bool,
  bgOverlayOnClick: PropTypes.func,
  fullWidth: PropTypes.bool,
};

Appears.defaultProps = {
  on: false,
  className: null,
  onUpdateHeight: null,
  withBackgroundOverlay: false,
  bgOverlayOnClick: () => {},
  fullWidth: false,
};

export default Appears;
