import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import useMobileDetect from '../../Utils/Hooks/useDeviceDetect';
import getDate from '../../Utils/getDate';

import 'react-datepicker/dist/react-datepicker.css';

import FormLabel from 'Common/UI/Form/FormLabel';
import Input from './Input';
import IconCalendar from 'Common/Assets/images/icon-calendar.svg';

const StyledInput = styled(Input)`
  padding-left: 50px;
  min-width: 210px;
  // iPhone issue with input height if no date
  height: 48px;
`;

const IconWrap = styled.span`
  position: relative;

  .react-datepicker__year-read-view--selected-year {
    line-height: 2;
  }
`;

const Icon = styled.img`
  position: absolute;
  left: 14px;
  top: 14px;
  z-index: 1;
`;

const DateInput = ({
  label,
  name,
  className,
  onChange,
  value,
  placeholder,
  onBlur,
  disabled,
  formAttributes,
}) => {
  const { max, min, ...restFormAttributes } = formAttributes || {};
  const { isMobile } = useMobileDetect();
  const isMobileDevice = isMobile();

  return (
    <div className={className}>
      <label htmlFor={name}>
        <FormLabel label={label} />
        <IconWrap>
          <Icon src={IconCalendar} alt="" />
          {isMobileDevice ? (
            <StyledInput
              type="date"
              id={name}
              value={value ? getDate(value).dateFieldFormat() : ''}
              onChange={event =>
                onChange(moment(event.currentTarget.value).toISOString(true))
              }
              onBlur={onBlur}
              min={min ? getDate(min).dateFieldFormat() : undefined}
              max={max ? getDate(max).dateFieldFormat() : undefined}
              placeholder={placeholder || 'Select Date'}
              autoComplete="off"
              disabled={disabled}
              {...restFormAttributes}
            />
          ) : (
            <DatePicker
              onChange={isoDateStr =>
                onChange(moment(isoDateStr).toISOString(true))
              }
              onBlur={onBlur}
              selected={moment(value).isValid() ? moment(value).toDate() : null}
              dateFormat="dd/MM/yyyy"
              showYearDropdown
              yearDropdownItemNumber={50}
              scrollableYearDropdown
              minDate={min}
              maxDate={max}
              placeholderText={placeholder || 'Select Date'}
              autoComplete="off"
              disabled={disabled}
              id={name}
              customInput={
                <StyledInput type="text" id={name} disabled={disabled} />
              }
              {...restFormAttributes}
            />
          )}
        </IconWrap>
      </label>
    </div>
  );
};

DateInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  formAttributes: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

DateInput.defaultProps = {
  placeholder: '',
  className: '',
  min: undefined,
  max: undefined,
  disabled: false,
  onBlur: () => {},
  formAttributes: {},
  value: '',
};

const StyledDate = styled(DateInput)`
  label {
    display: flex;
    flex-direction: column;
  }
`;

export default StyledDate;
