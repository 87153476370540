import {
  BANNER_HIDE,
  BANNER_SHOW,
  CONTENT_SHOW,
  BANNER_GENERIC,
  DashboardPages,
  Sections,
  SECTIONS,
  BANNER_GENERIC_CARDS_UNLOCKED,
  SECTION_PROPERTY,
  SECTION_RELATIONSHIP,
  SECTION_FINANCES,
} from 'Common/constants';

import Matter from 'Common/Data/Types/matter';
import { FullAppState, GeneratedAppState } from 'Common/Data/Types/appState';
import { hasSectionBeenCompleted } from '../Utils/sectionHelpers';
import relationship from './Sections/relationship';

/**
 * Generate App State
 * -----
 *  The function generates an appState depending on what matter data is present
 *  for a user. The appState controles
 *  - Banner State - Whether visible at the top / bottom and what content to show
 *  - Content  - Whether visible
 *  - Card
 *      - Whether cards are active on a given page
 *      - Whether you can add optional cards in a given page
 *
 *  There are three scopes to set app state for - all of a page, all of a section, or global
 *  If multiple things are matched, the first match will take priority e.g. if there is
 *  a matching section and page state, only the page state will be used
 * */

export const appStatesMap: FullAppState = {
  allSectionsDefault: [
    // If any states that are common between the pages
  ],
  ...relationship,
  // ...property,
  genericCardsUnlocked: {
    id: 'bannerGenericCardsUnlocked',
    description:
      'Generic banner - doesnt render anything but allows cards to be edited/added',
    check: (_matter: Matter, section?: string) => {
      // fixes bug in https://portableaustralia.atlassian.net/browse/DIVI-1782 which occurs when users have
      // finished asset split and can re-open property cards
      // this is caused because we were having the generic catch-all app state allowing users to edit cards so if
      // we had a gap in our app state it would fall back to the generic one and allow cards to be edited
      // now, the generic one does not allow editing and we have to specifically allow editing in this state

      switch (section) {
        case SECTION_RELATIONSHIP:
          // first dashboard, always unlocked
          return true;
        case SECTION_FINANCES:
          // check relationship dashboard finished
          return hasSectionBeenCompleted({ section: SECTION_RELATIONSHIP });
        case SECTION_PROPERTY:
          // return (
          //   hasSectionBeenCompleted({ section: SECTION_RELATIONSHIP }) &&
          //   hasSectionBeenCompleted({ section: SECTION_FINANCES })
          // );
          return true;
        default:
          return false;
      }
    },
    flags: {
      appState: BANNER_GENERIC_CARDS_UNLOCKED,
      bannerTopState: BANNER_HIDE,
    },
  },
  generic: {
    id: 'bannerGeneric',
    description: 'Generic banner - doesnt render anything',
    check: () => true,
    flags: {
      appState: BANNER_GENERIC,
      bannerTopState: BANNER_HIDE,
    },
  },
};

export const generatePageAppStateMap = (
  section: Sections,
  page?: DashboardPages
) => {
  if (!SECTIONS.includes(section)) {
    return appStatesMap.allSectionsDefault;
  }

  const { pageBlock: sectionPageBlock = [], state: sectionState = [] } =
    appStatesMap[section] || {};

  if (!page) {
    return [
      ...sectionPageBlock,
      ...sectionState,
      ...appStatesMap.allSectionsDefault,
      appStatesMap.genericCardsUnlocked,
      appStatesMap.generic,
    ];
  }

  const { pageBlock: pagePageBlock = [], state: pageState = [] } =
    appStatesMap[page] || {};

  return [
    ...pagePageBlock,
    ...sectionPageBlock,
    ...pageState,
    ...sectionState,
    ...appStatesMap.allSectionsDefault,
    appStatesMap.genericCardsUnlocked,
    appStatesMap.generic,
  ];
};

export const defaultFlags: GeneratedAppState = {
  appState: BANNER_GENERIC,
  bannerTopState: BANNER_SHOW,
  bannerBottomState: BANNER_HIDE,
  contentState: CONTENT_SHOW,
};
