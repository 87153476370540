import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import BackgroundOverlay from 'Common/UI/Layout/BackgroundOverlay';

const StyledOptionsDrawer = styled.div`
  display: inline-flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

type Props = {
  isOpen: boolean;
  onBackgroundClick: () => void;
  children: React.ReactNode;
};

const el = document.getElementById('modal');

const OptionsDrawerPortal: React.FC<Props> = ({
  children,
  isOpen,
  onBackgroundClick,
}) =>
  !isOpen || !el
    ? null
    : createPortal(
        <StyledOptionsDrawer>
          <BackgroundOverlay onClick={onBackgroundClick} />
          {children}
        </StyledOptionsDrawer>,
        el
      );

export default OptionsDrawerPortal;
