import { youWereTheLastInDiscussion } from 'Common/Utils/getMessages';
import {
  STATUS_APPROVED,
  STATUS_REMOVED,
  CARD_NORMAL,
  STATUS_DISCUSSION,
  STATUS_UPDATE_REQUESTED,
  CARD_AGREEMENT,
  CARD_STATEMENT,
  CARD_JURISDICTION,
  CARD_CONTINUE,
  CardType,
  STATUS_REOPENED,
  STATUS_CREATED,
  STATUS_UPDATE_DONE,
  SECTION_PROPERTY,
  SECTION_RELATIONSHIP,
  SECTION_PARENTING,
  SECTION_CONSENT_ORDERS,
  STATUS_NEW_ONLY_THEM,
  CARD_YOUR_SUPERANNUATION,
  CARD_CUSTOM,
} from 'Common/constants';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import Matter from 'Common/Data/Types/matter';
import { QuestionCard } from 'Common/Data/Types/appSections';
import { Notification } from 'Common/Data/Types/appState';
import { createdByYou } from './dashboardHelpers';
import MatterProps from './MatterProps';
import getNames from './getNames';

// can the current party approve the card
// any time a card is updated, the `owner` field is set to the other party
// therefore, if the owner field equals the current party (and the card is in the appropriate state), the card can be approved
export const canApproveCard = (
  { status, data: { owner } }: QuestionCard,
  { self: { party: selfParty } }: Matter
): boolean =>
  [
    STATUS_DISCUSSION,
    STATUS_REOPENED,
    STATUS_CREATED,
    STATUS_UPDATE_REQUESTED,
    STATUS_UPDATE_DONE,
  ].includes(status) && owner === selfParty;

/**
 * Utils
 * */

function flattenItemChildren(arr: any) {
  return arr
    ? arr.reduce(
        (result: [], item: any) => [
          ...result,
          item,
          ...(item.children ? flattenItemChildren(item.children) : []),
        ],
        []
      )
    : [];
}

/**
 * Get Flattened Sections
 * - Flathten and filter down to get a specific section from appSections
 * */

type AppSectionsProps = {
  appSections: any;
  section: string;
  page?: string;
  group?: string;
  party?: string;
};

export const getFlattenedSections = ({
  appSections,
  section,
  page,
  group,
  party,
}: AppSectionsProps) => {
  let newSections = flattenItemChildren(appSections);

  if (section) {
    newSections = flattenItemChildren(
      newSections.filter((_section: any) => _section.id === section)
    );
  }

  if (page) {
    newSections = flattenItemChildren(
      newSections.filter((_section: any) => _section.id === page)
    );
  }

  if (group) {
    newSections = flattenItemChildren(
      newSections.filter((_section: any) => _section.id === group)
    );
  }

  if (party) {
    newSections = flattenItemChildren(
      newSections.filter((_section: any) => _section.index === party)
    );
  }

  return newSections;
};

export type SectionCardTally = {
  total: number;
  completed: number;
  notStarted: number;
  notStartedOptional: number;
  removed: number;
  inProgress: number;
  waitingOther: number;
  waitingSelf: number;
};

/**
 * Get Tally
 * - Get overall tally of approved, removed and inreview
 * */

export const getSectionCardStatsRaw = ({
  appSections,
  section,
  page,
  group,
  party,
  filter = [
    CARD_NORMAL,
    CARD_AGREEMENT,
    CARD_STATEMENT,
    CARD_JURISDICTION,
    CARD_CONTINUE,
    CARD_YOUR_SUPERANNUATION,
    CARD_CUSTOM,
  ],
  checkBothParties = false,
}: AppSectionsProps & {
  filter?: CardType[];
  checkBothParties?: boolean;
}): SectionCardTally => {
  const sections =
    getFlattenedSections({
      appSections,
      section,
      page,
      group,
      party,
    }) || [];

  const { self } = getPartyKeys();

  let sectionsFiltered;

  if (checkBothParties) {
    sectionsFiltered = sections.filter((item: QuestionCard) =>
      filter && Array.isArray(filter) ? filter.includes(item.type) : item
    );
  } else {
    sectionsFiltered = sections
      .filter((item: QuestionCard) =>
        filter && Array.isArray(filter) ? filter.includes(item.type) : item
      )
      // remove cards that are visible to this party but can only be filled out by the other party
      // this is only relevant if the cards are not marked as single user approved though
      .filter(
        (item: QuestionCard) =>
          item.singleUserApproved || item.status !== STATUS_NEW_ONLY_THEM
      )
      // remove cards that are hidden from this party
      .filter(
        (item: QuestionCard) =>
          !item.hideFromOtherParty ||
          (item.hideFromOtherParty && item.index === self)
      );
  }

  return sectionsFiltered.reduce(
    (acc: any, item: QuestionCard) => {
      const { data } = item;

      acc.total += 1;

      if (!data.status) {
        if (item.optional) {
          acc.notStartedOptional += 1;
        } else {
          acc.notStarted += 1;
        }

        return acc;
      }

      if (data.status === STATUS_APPROVED) {
        acc.completed += 1;
        return acc;
      }

      if (data.status === STATUS_REMOVED) {
        acc.removed += 1;
        return acc;
      }

      acc.inProgress += 1;

      if (
        data.status === STATUS_DISCUSSION ||
        data.status === STATUS_UPDATE_REQUESTED
      ) {
        if (
          youWereTheLastInDiscussion(MatterProps('messages', []), item.cardID)
        ) {
          acc.waitingOther += 1;
        } else {
          acc.waitingSelf += 1;
        }
      } else if (createdByYou(item?.data?.creator)) {
        acc.waitingOther += 1;
      } else {
        acc.waitingSelf += 1;
      }

      return acc;
    },
    {
      total: 0,
      completed: 0,
      notStarted: 0,
      notStartedOptional: 0,
      removed: 0,
      inProgress: 0,
      waitingSelf: 0,
      waitingOther: 0,
    }
  );
};

export const getCardNotificationData = ({
  appSections,
  matter,
  filter = [
    CARD_NORMAL,
    CARD_AGREEMENT,
    CARD_STATEMENT,
    CARD_JURISDICTION,
    CARD_CONTINUE,
    CARD_YOUR_SUPERANNUATION,
    CARD_CUSTOM,
  ],
}: {
  appSections: any;
  filter?: CardType[];
  matter: Matter;
}): Notification[] => {
  const sections = [
    ...getFlattenedSections({
      appSections,
      section: SECTION_RELATIONSHIP,
    }),
    ...getFlattenedSections({
      appSections,
      section: SECTION_PROPERTY,
    }),
    ...getFlattenedSections({
      appSections,
      section: SECTION_PARENTING,
    }),
    ...getFlattenedSections({
      appSections,
      section: SECTION_CONSENT_ORDERS,
    }),
  ];

  const { messages = [] } = matter;

  const { self } = getPartyKeys();
  const names = getNames();

  const sectionsFiltered = sections
    .filter((item: any) =>
      filter && Array.isArray(filter) ? filter.includes(item.type) : item
    )
    .filter(
      (item: any) =>
        !item.hideFromOtherParty ||
        (item.hideFromOtherParty && item.index === self)
    );

  return sectionsFiltered.reduce((acc: any, item: QuestionCard) => {
    const { data } = item;

    const canApprove = canApproveCard(item, matter);

    if (item.status === STATUS_CREATED && canApprove) {
      return [
        ...acc,
        {
          text: `Review the '${item.title}' statement that ${names.other} has created`,
          route: item.viewRoute,
        },
      ];
    }

    if (item.status === STATUS_UPDATE_DONE && canApprove) {
      return [
        ...acc,
        {
          text: `View ${names.other}'s update to the '${item.title}' statement`,
          route: item.viewRoute,
        },
      ];
    }

    if (item.status === STATUS_REOPENED && canApprove) {
      return [
        ...acc,
        {
          text: `${names.other} has reopened the '${item.title}' statement`,
          route: item.viewRoute,
        },
      ];
    }

    if (
      data.status === STATUS_DISCUSSION ||
      data.status === STATUS_UPDATE_REQUESTED
    ) {
      if (!youWereTheLastInDiscussion(messages, item.cardID)) {
        return [
          ...acc,
          {
            text: `View ${names.other}'s response to the '${item.title}' statement`,
            route: item.viewRoute,
          },
        ];
      }
    }

    return acc;
  }, []);
};

type CompletionStatus = {
  completed?: boolean;
  completedIncludingOptional?: boolean;
};

/**
 * Boolean
 * */

export const checkTotalMatch = (stats: SectionCardTally) => {
  if (!stats) {
    return { completed: false, completedIncludingOptional: false };
  }

  const { total, notStartedOptional, removed, completed } = stats;

  return {
    completed: total - notStartedOptional - removed === completed,
    completedIncludingOptional: total - removed === completed,
  };
};

export const areSectionCardsCompleted = ({
  appSections,
  canStart = true,
  section,
  page,
  group,
  party,
  filter,
  checkBothParties,
}: AppSectionsProps & {
  canStart?: boolean;
  filter?: CardType[];
  returnStats?: boolean;
  checkBothParties?: boolean;
}): boolean => {
  if (!canStart) return false;

  const tally = getSectionCardStatsRaw({
    appSections,
    section,
    page,
    group,
    party,
    filter,
    checkBothParties,
  });

  if (!tally) return false;

  return checkTotalMatch(tally).completed;
};

export const getSectionCardStats = ({
  appSections,
  section,
  page,
  group,
  party,
  filter,
  checkBothParties,
}: AppSectionsProps & {
  filter?: CardType[];
  checkBothParties?: boolean;
}): CompletionStatus & { stats: SectionCardTally } => {
  const tally = getSectionCardStatsRaw({
    appSections,
    section,
    page,
    group,
    party,
    filter,
    checkBothParties,
  });

  return {
    ...checkTotalMatch(tally),
    stats: tally,
  };
};
