import {
  PAGE_FINANCES_MAIN,
  SUA_SUGGESTED_DIVISION_BANNER_DISMISSED,
} from 'Common/constants';
import { getAppSections } from 'Common/Data/SingleUser/appSections';
import { HeaderContainer } from 'Common/UI/Dashboard/common';
import { TopMessageDrawer } from 'Common/UI/Layout/Drawer';
import MainDrawer from 'Common/UI/Layout/MainDrawer';
import PageContainer from 'Common/UI/Layout/PageContainer';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';
import ScrollerContainer from 'Common/UI/Layout/ScrollerContainer';
import Theme, { orangeTheme } from 'Common/Utils/theme';
import React, { useEffect, useState } from 'react';
import AppHeader from 'App/UI/AppHeader';
import AppFooter from 'App/UI/AppFooter/AppFooter';
import { sessionStorageLoad } from 'Common/Utils/sessionStorage';
import { H1Conversational, Title } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import GenericButton from 'Common/UI/Button/GenericButton';
import styled from 'styled-components';
import Loader from 'Common/UI/Loader/Loader';
import { Redirect } from 'react-router';
import { ODRS_DASH_RELATIONSHIP_ROUTE } from 'Common/routes';
import { useAppDispatch, useAppSelector } from '../../State/Store';
import DemoBanner from '../../UI/Dashboard/common/DemoBanner';
import generateDashboardTabs from '../../UI/Dashboard/common/generateDashboardTabs';
import SuggestedDivisionBanner from '../../UI/Banners/SuggestedDivisionBanner';
import QuickExitLink from '../../UI/QuickExitLink';
import SuggestedDivisionContent from './SuggestedDivision.content';
import DivisionSlider from '../../UI/DivisionSlider';
import { finalizeCaptureAction } from '../../State/MatterActions';

const StyledTitle = styled(Title)`
  margin-top: ${({ theme }) => theme.spacing[16]};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`;

const LoaderWrapper = styled(MainDrawer)`
  min-height: 50vh;
`;

const StyledButtonLink = styled(GenericButton)`
  display: inline-block;
  text-decoration: none;
  margin-right: ${({ theme }) => theme.spacing[4]};
`;

const ButtonsWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[12]};
`;

const SuggestedDivision = () => {
  const [showBanner, setShowBanner] = useState(true);
  const sections = getAppSections();
  const matter = useAppSelector(state => state.matter);
  const tabs = generateDashboardTabs({ sections, matter });
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();

  const onDismissBanner = () => {
    setShowBanner(false);
  };

  const { suggestedDivision } = matter;

  useEffect(() => {
    async function fetchSuggestedDivision() {
      const response = await dispatch(finalizeCaptureAction());
      if (response) {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000); // pretend like we're calculating
      }
    }
    fetchSuggestedDivision();
  }, []);

  const { isDemoComplete } = useAppSelector(state => state.app.flags);
  if (!isDemoComplete) {
    // dashboards not complete, redirecting
    return <Redirect to={ODRS_DASH_RELATIONSHIP_ROUTE} />;
  }

  const showDemoBanner =
    !sessionStorageLoad(SUA_SUGGESTED_DIVISION_BANNER_DISMISSED) && showBanner;

  return (
    <PageContainer id="scrollMemory" backgroundColor={Theme.colours.darkPurple}>
      <QuickExitLink />
      <HeaderContainer className="transition-fade-up">
        <AppHeader
          withNav
          tab={PAGE_FINANCES_MAIN}
          withProfile
          matter={matter}
          title="Suggested Division"
          tabs={tabs}
          withBackButton
          userName={matter?.items[0]?.name || 'guest'}
        />
      </HeaderContainer>
      {isLoading ? (
        <LoaderWrapper colour={Theme.colours.offWhite}>
          <Loader colour={Theme.colours.purple} />
        </LoaderWrapper>
      ) : (
        <>
          {showDemoBanner && (
            <TopMessageDrawer hasFlex open zindex="1" theme={orangeTheme}>
              <DemoBanner
                sessionId={SUA_SUGGESTED_DIVISION_BANNER_DISMISSED}
                showBanner={showBanner}
                onBannerButtonClick={onDismissBanner}
              >
                <SuggestedDivisionBanner />
              </DemoBanner>
            </TopMessageDrawer>
          )}
          <MainDrawer colour={Theme.colours.offWhite}>
            <ScrollerContainer>
              <H1Conversational>
                {SuggestedDivisionContent.title}
              </H1Conversational>

              <DivisionSlider {...suggestedDivision} />

              <ResponsiveContainer>
                <Paragraph>
                  {SuggestedDivisionContent.suggestionAccountText}
                </Paragraph>
                <StyledTitle>{SuggestedDivisionContent.subheading}</StyledTitle>
                <H1Conversational>
                  {SuggestedDivisionContent.signUpHeading}
                </H1Conversational>
                <Paragraph>{SuggestedDivisionContent.signupText}</Paragraph>
                <ButtonsWrapper>
                  <StyledButtonLink
                    onClick={() => {
                      window.open(SuggestedDivisionContent.amicaLink, '_blank');
                    }}
                  >
                    {SuggestedDivisionContent.amicaButton}
                  </StyledButtonLink>
                  <GenericButton
                    onClick={() => {
                      window.open(
                        SuggestedDivisionContent.pricingLink,
                        '_blank'
                      );
                    }}
                    reverse
                  >
                    {SuggestedDivisionContent.pricingButton}
                  </GenericButton>
                </ButtonsWrapper>
                <H1Conversational>
                  {SuggestedDivisionContent.helpHeading}
                </H1Conversational>
                <Paragraph>{SuggestedDivisionContent.helpText}</Paragraph>
                <Paragraph
                  color={Theme.colours.white}
                  marginBottom={Theme.spacing[6]}
                >
                  <StyledButtonLink
                    color={Theme.colours.white}
                    as="a"
                    href={SuggestedDivisionContent.helpLink}
                  >
                    {SuggestedDivisionContent.helpButton}
                  </StyledButtonLink>
                </Paragraph>
              </ResponsiveContainer>
            </ScrollerContainer>
          </MainDrawer>
        </>
      )}
      <AppFooter />
    </PageContainer>
  );
};

export default SuggestedDivision;
