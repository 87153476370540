import React from 'react';

import {
  SECTION_PROPERTY,
  PAGE_PROPERTY_MAIN,
  DashboardPages,
} from 'Common/constants';
import Dashboard from 'App/UI/Dashboard/Dashboard';
import { ODRS_DASH_FINANCES_ROUTE, ODRS_DIVISION_INTRO } from 'Common/routes';
import { getPage } from 'Common/Data/SingleUser/appSections';
import { useAppSelector } from 'App/State/Store';
import { Redirect } from 'react-router';
import Main from './DashboardProperty/Main';

type Props = {
  tab: DashboardPages;
};

const DashboardProperty: React.FC<Props> = () => {
  const pageData = getPage(SECTION_PROPERTY, PAGE_PROPERTY_MAIN);
  const pageStatus = pageData && pageData.generateStatus();
  const { isDemoComplete } = useAppSelector(state => state.app.flags);

  if (pageStatus === 'locked' && !isDemoComplete) {
    // this dashboard is locked and the demo is not complete - redirect to the finances dashboard
    return <Redirect to={ODRS_DASH_FINANCES_ROUTE} />;
  }

  return (
    <Dashboard
      tab={PAGE_PROPERTY_MAIN}
      section={SECTION_PROPERTY}
      pages={{
        [PAGE_PROPERTY_MAIN]: Main,
      }}
      nextPage={ODRS_DIVISION_INTRO}
    />
  );
};

export default DashboardProperty;
