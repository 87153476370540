import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Paragraph from 'Common/UI/Text/Paragraph';

const StyledParagraph = styled(Paragraph)`
  margin-top: 1em;
  color: ${({ theme }) => theme.colours.darkGrey};
`;

const FormDescription = ({ children }) => (
  <StyledParagraph>{children}</StyledParagraph>
);

FormDescription.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default FormDescription;
