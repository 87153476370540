import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import moment from 'moment';

import { MATTER_REFRESH_PERIOD } from 'Common/constants';
import {
  HOME_ROUTE,
  ODRS_DIVISION_VIEW_SUGGESTED,
  ODRS_LOGIN_ROUTE,
  ODRS_SUA_YOURDETAILS,
} from 'Common/routes';
import { useAppDispatch, useAppSelector, reduxStore } from 'App/State/Store';
import Loader from 'Common/UI/Loader/Loader';
import { loadMatterAction } from 'App/State/MatterActions';
import { Redirect } from 'react-router';

const STATE_UNCHECKED = 'STATE_UNCHECKED';
const STATE_CHECK_SUCCESS = 'STATE_CHECK_SUCCESS';
const STATE_CHECK_FAILURE = 'STATE_CHECK_FAILURE';

type BaseProps = {
  children: React.ReactNode;
};

type PropsMatterExpected = {
  destination?: string;
  expectNone?: false;
};

type PropsMatterNotExpected = {
  destination?: string;
  expectNone: true;
};

const MatterGuard: React.FC<
  BaseProps & (PropsMatterExpected | PropsMatterNotExpected)
> = ({ destination = ODRS_LOGIN_ROUTE, expectNone = false, children }) => {
  const [matterStatus, setMatterStatus] = useState(STATE_UNCHECKED);
  const dispatch = useAppDispatch();
  const loadMatter = () => dispatch(loadMatterAction());
  const navigate = (to: string) => dispatch(push(to));

  const { matter, app: appState } = useAppSelector(state => state);
  const {
    _persist: { rehydrated: appStateRehydrated },
  } = reduxStore.getState();

  const { MatterID, lastRefreshed } = matter;

  useEffect(() => {
    if (
      MatterID &&
      moment().diff(lastRefreshed, 'seconds') < MATTER_REFRESH_PERIOD
    ) {
      setMatterStatus(STATE_CHECK_SUCCESS);
    } else if (matterStatus === STATE_UNCHECKED) {
      loadMatter()
        .then(async () => {
          if (expectNone) {
            // we didn't want a matter so this is a failure state.
            setMatterStatus(STATE_CHECK_FAILURE);
            navigate(destination);
          } else {
            setMatterStatus(STATE_CHECK_SUCCESS);
          }
        })
        .catch(() => {
          if (expectNone) {
            // we didn't want a matter so this is a success state.
            setMatterStatus(STATE_CHECK_SUCCESS);
          } else {
            setMatterStatus(STATE_CHECK_FAILURE);
            navigate(destination);
          }
        });
    }
  }, []);

  // we want to wait until the app state is rehydrated before we render anything/redirect the user
  if (matterStatus === STATE_CHECK_SUCCESS && appStateRehydrated) {
    const {
      flags: { confirmedRangeOnly, isYourDetailsSetupComplete, isDemoComplete },
    } = appState;
    const isOnboarded = confirmedRangeOnly;

    // redirect to onboarding
    if (!isOnboarded) return <Redirect to={HOME_ROUTE} />;

    // redirect to your details
    if (
      !isYourDetailsSetupComplete &&
      ![ODRS_SUA_YOURDETAILS, HOME_ROUTE].includes(window.location.pathname)
    )
      return <Redirect to={ODRS_SUA_YOURDETAILS} />;
    // redirect to suggested division
    if (isDemoComplete) return <Redirect to={ODRS_DIVISION_VIEW_SUGGESTED} />;

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return <Loader />;
};

export default MatterGuard;
