import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Paragraph from 'Common/UI/Text/Paragraph';
import EvaluatePasswordStrength from '../../Utils/EvaluatePasswordStrength';

const StyledIndicator = styled.li`
  list-style: none;
  margin: 0;
  padding: 4px 10px 0 0;
  flex-basis: 50%;
  flex-shrink: 0;
  flex-grow: 1;
  font-size: 14px;
  line-height: 140%;
  transition: all 0.5s;
  font-weight: ${({ pass }) => (pass ? 'bold' : 'normal')};
  color: ${({ pass, theme }) =>
    pass ? theme.textColour : theme.textColourDisabled};
  @media (max-width: 320px) {
    flex-basis: 100%;
  }
`;

const PasswordStrength = ({ password, className }) => {
  const { moreThanEight, oneUpperCase, oneLowerCase, oneNumber, oneSpecial } =
    EvaluatePasswordStrength(password);

  return (
    <div className={className}>
      <Paragraph>Your password must have:</Paragraph>
      <ul className="password-strength">
        <StyledIndicator pass={moreThanEight}>
          at least 8 characters
        </StyledIndicator>
        <StyledIndicator pass={oneNumber}>a number</StyledIndicator>
        <StyledIndicator pass={oneUpperCase && oneLowerCase}>
          a mix of upper and lowercase letters
        </StyledIndicator>
        <StyledIndicator pass={oneSpecial}>a special character</StyledIndicator>
      </ul>
    </div>
  );
};

PasswordStrength.propTypes = {
  password: PropTypes.string,
  className: PropTypes.string,
};

PasswordStrength.defaultProps = {
  password: '',
  className: '',
};

const StyledPasswordStrength = styled(PasswordStrength)`
  padding-top: 8px;
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (max-width: 400px) {
      width: 100%;
    }
  }

  p {
    flex-grow: 1;
    width: 100%;
    flex-shrink: 0;
    font-size: 14px;
    line-height: 140%;
    @media (max-width: 400px) {
      margin: 0;
    }
  }
`;

export default StyledPasswordStrength;
