import React from 'react';
import {
  INTRO_ROUTE,
  MARKETING_SITE,
  ODRS_SUA_YOURDETAILS,
} from 'Common/routes';
import StepTransition from 'App/UI/Transitions/StepTransition';
import { ProgressBarProvider } from 'Common/Utils/ProgressBarContext';

import { StyledPageContent } from 'Common/UI/Signup/Layout';

import CardQuestionFlowHeader from 'App/UI/Questions/QuestionFlowHeader';
import { useAppDispatch } from 'App/State/Store';
import push from 'Common/Utils/push';
import SuggestedDivisionRange from 'App/UI/Onboarding/SuggestedDivisionRange';
import HeaderBackground from 'Common/Assets/images/demo-header.svg';
import colours from 'Common/Utils/colours';
import { updateFlag } from 'App/State/AppReducer';

type Step = {
  step: string;
  theme?: any;
  backgroundShapes: boolean;
  noLeftPadding?: boolean;
  hideBackButton?: boolean;
};

const steps: Step[] = [
  {
    step: 'suggested-division-range',
    backgroundShapes: false,
  },
];

const Signup: React.FC = () => {
  const [step, setStep] = React.useState<Step>(steps[0]);
  const [signupQuestionsInput, setSignupQuestionsInput] = React.useState<any>();
  const [currentStep, setCurrentStep] = React.useState(0);

  const dispatch = useAppDispatch();
  const navigate = (to: string) => dispatch(push(to));

  const onInputChange = async (key: string, value: string | boolean) => {
    const newSignupQuestionsInput = {
      ...signupQuestionsInput, // eslint-disable-line
      [key]: value,
    };

    setSignupQuestionsInput(newSignupQuestionsInput);
  };

  const goNext = () => {
    const cur = steps.indexOf(step);

    // loop through the signupQuestionsInput and update the flags
    Object.keys(signupQuestionsInput).forEach(key => {
      dispatch(updateFlag({ [key]: !!signupQuestionsInput[key] }));
    });
    if (steps[cur + 1]) {
      setStep(steps[cur + 1]);
      setCurrentStep(currentStep + 1);
    } else {
      navigate(ODRS_SUA_YOURDETAILS);
    }
  };

  const goBack = () => {
    const cur = steps.indexOf(step);

    if (steps[cur - 1]) {
      setStep(steps[cur - 1]);
      setCurrentStep(currentStep - 1);
    } else {
      setStep(steps[0]);
      navigate(INTRO_ROUTE);
    }
  };

  let stepComponent;

  switch (step.step) {
    case 'suggested-division-range':
    default:
      stepComponent = (
        <SuggestedDivisionRange
          goBack={goBack}
          goNext={goNext}
          onChange={onInputChange}
        />
      );
      break;
  }

  return (
    <ProgressBarProvider>
      <CardQuestionFlowHeader
        backgroundImage={HeaderBackground}
        backgroundColour={colours.offWhite}
        previousRoute={MARKETING_SITE}
        external
      />
      <StyledPageContent>
        <StepTransition
          noLeftPadding={step.noLeftPadding}
          pageKey={step.step}
          transitionTime={500}
          appear
        >
          {step.step ? stepComponent : null}
        </StepTransition>
      </StyledPageContent>
    </ProgressBarProvider>
  );
};

export default Signup;
