import styled from 'styled-components';
import { H2Conversational } from './Headings';

const HeadingLinkText = styled(H2Conversational)`
  color: ${({ theme }) => theme.colours.purple};
  display: inline;
  margin: 0;
`;

export default HeadingLinkText;
