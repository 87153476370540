import React from 'react';
import styled from 'styled-components';

import {
  PromptCardDataInitial,
  PromptCardDataNone,
} from 'Common/Data/Types/appSections';
import { Row } from 'Common/UI/Layout/Flex';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import Card from 'Common/UI/Card';

const StatementWrapper = styled.div`
  background: ${({ theme }) => theme.colours.offWhite};
  padding: ${({ theme }) => `0px ${theme.spacing[4]}`};
  margin-top: ${({ theme }) => theme.spacing[6]};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`;

type InitialRepeatablePromptCardProps = {
  cardData: PromptCardDataInitial;
  active: boolean;
  dataCy?: string;
};

export const InitialRepeatablePromptCard: React.FC<InitialRepeatablePromptCardProps> =
  ({
    cardData: { title, content, yesButton, noButton, onClickYes, onClickNo },
    active,
    dataCy,
  }) => {
    const { withLoading } = usePromiseWithLoading();

    return (
      <SpacedBox marginTop="small" data-cy={dataCy}>
        <Card active={active}>
          <Card.MainHeading>{title}</Card.MainHeading>
          <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
            {content}
          </Card.Paragraph>
          <Row justifyContent="space-between">
            <Card.Button
              size="small"
              dataCy="repeatable-prompt-initial-yes"
              onClick={() => withLoading(onClickYes)}
            >
              {yesButton}
            </Card.Button>
            <Card.Button
              size="small"
              type="text"
              dataCy="repeatable-prompt-initial-no"
              onClick={() => withLoading(onClickNo)}
            >
              {noButton}
            </Card.Button>
          </Row>
        </Card>
      </SpacedBox>
    );
  };

type NoneRepeatablePromptCardProps = {
  cardData: PromptCardDataNone;
  active: boolean;
  dataCy?: string;
};

export const NoneRepeatablePromptCard: React.FC<NoneRepeatablePromptCardProps> =
  ({ cardData, active }, dataCy) => {
    const { withLoading } = usePromiseWithLoading();
    const { title, content, onClickEdit } = cardData;

    return (
      <SpacedBox marginTop="small" data-cy={dataCy}>
        <Card active={active}>
          <Card.MainHeading>{title}</Card.MainHeading>
          <StatementWrapper>
            <Card.Paragraph>{content}</Card.Paragraph>
          </StatementWrapper>
          <Row justifyContent="space-between">
            <Card.Button
              size="small"
              subtle
              onClick={() => withLoading(onClickEdit)}
              dataCy="repeatable-prompt-none-edit"
            >
              Edit
            </Card.Button>
          </Row>
        </Card>
      </SpacedBox>
    );
  };
