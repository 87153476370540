import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Theme } from 'Common/Utils/theme';
import TextArea from 'Common/UI/Form/TextArea';
import FormLabel from 'Common/UI/Form/FormLabel';

const StyledTextArea = styled(TextArea)<{
  theme: Theme;
}>`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colours.clickableGrey};
  background-color: ${({ theme }) => theme.colours.white};
  width: 100%;
  resize: vertical;
`;

type Props = {
  onChange: (
    value: string,
    evt: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  currency?: boolean;
  mobile?: boolean;
  value?: string;
  className?: string;
  onBlur?: (field: string) => void;
  placeholder?: string;
  label?: string | JSX.Element;
  onFocus?: (field: string) => void;
  rows?: number | undefined;
  name: string;
  disabled?: boolean;
  inputmode?: string;
  autoFocus?: boolean;
};

const TextAreafield = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      onChange,
      label,
      name,
      value = '',
      className = '',
      placeholder = '',
      disabled = false,
      onBlur = () => {},
      onFocus = () => {},
      autoFocus,
      ...rest
    },
    ref
  ) => (
    <div className={className}>
      <label htmlFor={name}>
        {label && <FormLabel label={label} />}
        <StyledTextArea
          id={name}
          value={value}
          onChange={evt => onChange(evt.target.value, evt)}
          placeholder={placeholder}
          disabled={disabled}
          onBlur={() => onBlur(name)}
          onFocus={() => onFocus(name)}
          ref={ref}
          rows={5}
          autoFocus={autoFocus}
          {...rest}
        />
      </label>
    </div>
  )
);

export default TextAreafield;
