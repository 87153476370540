import React, { createContext, useState } from 'react';
import { QuestionAny } from 'Common/Data/Types/appSections';

type Context = {
  headerVisibility: boolean;
  setHeaderVisibility: (visible: boolean) => void;
  activeQuestion?: QuestionAny;
  setActiveQuestion: (question: QuestionAny) => void;
  showProgressBar: boolean;
  setShowProgressBar: (visible: boolean) => void;
};

export const CardQuestionFlowContext = createContext<Context>({
  headerVisibility: true,
  setHeaderVisibility: () => {},
  setActiveQuestion: () => {},
  showProgressBar: true,
  setShowProgressBar: () => {},
});

export const CardQuestionFlowProvider: React.FC<{ children: React.ReactNode }> =
  ({ children }) => {
    const [headerVisibility, setHeaderVisibility] = useState(true);
    const [activeQuestion, setActiveQuestion] = useState<QuestionAny>();
    const [showProgressBar, setShowProgressBar] = useState(true);

    return (
      <CardQuestionFlowContext.Provider
        value={{
          headerVisibility,
          setHeaderVisibility,
          activeQuestion,
          setActiveQuestion,
          showProgressBar,
          setShowProgressBar,
        }}
      >
        {children}
      </CardQuestionFlowContext.Provider>
    );
  };
