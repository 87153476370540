import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';

import { Heading3 } from 'Common/UI/Text/Headings';
import useMobileDetect from '../../Utils/Hooks/useDeviceDetect';

const StyledHeaderNavContainer = styled.div`
  overflow-x: scroll;
  display: block;

  margin: 0 -${props => props.theme.padding.small}px;
  padding: 0 ${props => props.theme.padding.small}px;

  // Hide Scrollbar on X axis
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // IE
  &::-webkit-scrollbar {
    // Webkit
    width: 0;
    height: 0;
  }
`;

const StyledHeaderNav = styled.div`
  display: flex;
  margin: 0;
`;

export const NavHeading3 = styled(Heading3)`
  font-size: 16px;
  padding: ${({ theme }) => theme.padding.xxxsmall}px
    ${({ theme }) => theme.padding.xsmall}px;
  border: 2px solid ${({ theme }) => theme.navBorderColour};
  border-radius: 16px;

  &.${props => props.activeClassName} {
    border-color: ${({ theme }) => theme.navHighlightBorderColour};
  }
`;

export const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  margin: ${props =>
    `${props.theme.padding.xsmall}px 10px ${props.theme.padding.xsmall}px 0`};
  color: ${props => props.theme.colours.midLightGrey};
  text-decoration: none;
  position: relative;
  white-space: nowrap;

  &.${props => props.activeClassName} {
    color: ${props => props.theme.colours.white};
  }

  ${props =>
    props.notifications > 0 &&
    css`
      &:after {
        content: '${props.notifications}';
        position: absolute;
        top: 0;
        right: -20px;
        font-family: ${props.theme.typography.fonts.default};
        color: ${props.theme.colours.black};
        border-radius: 50%;
        font-size: 11px;
        letter-spacing: 0.01em;
        background: ${props.theme.colours.red};
        width: 16px;
        height: 16px;
        padding: 2px 1px 2px 0;
        line-height: 1.2;
        text-align: center;
      }
    `}
`;

const getNavDataFromChildren = (location, children) => {
  const navs = [];
  children.forEach(child => {
    if (child.type === React.Fragment) {
      child.props.children.forEach(subChild => {
        navs.push(subChild);
      });
    } else {
      navs.push(child);
    }
  });
  return {
    numberOfLinks: navs.length,
    currentIndex:
      navs.findIndex(
        nav => nav.props && nav.props.to && nav.props.to === location.pathname
      ) || 0,
  };
};

const SwipeNav = ({ location, children, ...rest }) => {
  const containerEl = useRef(null);
  const { isMobile } = useMobileDetect();
  const isMobileDevice = isMobile();

  useEffect(() => {
    const { current } = containerEl;
    if (isMobileDevice) {
      const { currentIndex, numberOfLinks } = getNavDataFromChildren(
        location,
        children
      );
      current.scrollLeft =
        (currentIndex / numberOfLinks) * (current.clientWidth + 50);
    }
  }, []);

  return (
    <StyledHeaderNavContainer ref={containerEl} {...rest}>
      <StyledHeaderNav>{children}</StyledHeaderNav>
    </StyledHeaderNavContainer>
  );
};

SwipeNav.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default withRouter(SwipeNav);
