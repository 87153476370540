/* eslint-disable prefer-const */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-undef-init */
/* eslint-disable indent */
/* eslint-disable import/first */

/** !!! READ THIS !!!
 *
 * This file lets us share code between the API, admin app, and the main app by giving us a couple of functions to get matter data even though it is stored in different ways for each environment.
 *
 * It does this through conditional compilation using a webpack loader called `ifdef-loader`. This loader will remove code that is not needed for the current environment.
 * The way it works is that it looks for comments that start with `#code` and then removes them if the condition is not met. The condition is set in the `webpack.config.js` file.
 *
 * You will see below that the code for the `node` (API) and `admin` environments are all prefixed with `// #code`, but the code in the else (the main app) is not.
 * This is because it is essentially impossible to get jest tests to work with this loader, so we have to have the code that Jest needs to use as the base default uncommented code.
 *
 * If you make any changes to this file, please immediately test it by:
 *
 * - running the main app and checking for compilation or runtime errors / type errors
 * - running the admin app and checking for compilation or runtime errors / type errors
 * - packaging the API and checking for compilation or runtime errors / type errors
 * - running the jest tests and checking for compilation or runtime errors / type errors
 * - running the cypress tests and checking for compilation or runtime errors / type errors
 *
 * Also note that if you make any changes to the conditionally compiled code, you will need to restart the dev server for the changes to take effect.
 */

import get from 'lodash/get';

// eslint-disable-next-line import/order, @typescript-eslint/no-unused-vars
import { reduxStore } from 'App/State/Store';
import getChildrenData from './getChildrenData';

//////////////////////////
////////////////////////////////

///////////////////////////////////////////////////
//////////////////////////////
///////////

///////////////////////////////////////////
//////////////////////////////
///////////

/////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
///////////////////////////////

//////////////////
///////////////////////////////////////////////////////////////////
//////////////////////////
///////////////////////////////////////////////////////
//////////////

//////////////////////////////////
////////////
/////////////////////////
///////////
/////////////////////////////
////////////////////////////////

///////////////////////////////////////////////////
///////////////////////////////////////

///////////////////////////////////////////////////////

///////////////////////////////////////////////////
///////////

///////////////////////////////////////////
//////////////////////////////
///////////

///////////////////////////////////////////////
/////////

/** Get the current matter
 *
 * Warning: if you use this function you will not be subscribed to receive updates any time the matter object updates.
 * You should use `useAppSelector` where possible.
 */
export const getMatter = () => reduxStore.getState().matter;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const updateMatter = _newMatter => {};
//////////

/** Get the value stored in the matter via dot notation
 * @param {string} prop - the dot notation path to the value
 * @param {any} [defaultValue] - the default value to return if the value is not found
 * @returns {any} the value stored in the matter
 *
 * @deprecated - use `getMatter` instead as it is type safe
 */
const MatterProps = (prop, defaultValue) => {
  let propValue;

  // if these environment variables are set, then load the matter from there instead of from redux
  // this lets jest tests still load the app properly
  if (process.env.LOAD_MATTER_FROM_ENV && process.env.MATTER_DATA) {
    let data;

    try {
      data = JSON.parse(process.env.MATTER_DATA);
    } catch (e) {
      data = process.env.MATTER_DATA;
    }

    propValue = get(data, prop);
  } else {
    propValue = get(getMatter(), prop);
  }

  if (propValue) {
    return propValue;
  }

  return defaultValue !== undefined ? defaultValue : null;
};

export default MatterProps;
