import React from 'react';
import styled from 'styled-components';

import SendButtonIcon from './send-button.svg';

const ButtonWrapper = styled.button`
  border: 0;
  background: none;
  width: 40px;
  height: 40px;
  padding: 0;
  img {
    width: 100%;
    height: 100%;
  }
`;

const SendButton = props => (
  <ButtonWrapper {...props}>
    <img alt="Send" src={SendButtonIcon} />
  </ButtonWrapper>
);

export default styled(SendButton)``;
