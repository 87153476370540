import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import React from 'react';

const UsingDemoBanner = () => (
  <>
    <Heading2MarginTopNone>You&apos;re using amica one.</Heading2MarginTopNone>
    <Paragraph>
      amica one gives you a preview of what it&apos;s like to divide your money
      and property, before inviting your former partner.
    </Paragraph>
  </>
);

export default UsingDemoBanner;
