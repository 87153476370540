import React, { ReactNode } from 'react';
import { isEqual } from 'lodash';

import { Party, STATUS_APPROVED } from 'Common/constants';
import { reduxStore } from 'App/State/Store';
import Matter, {
  ItemHistory,
  CardRevision,
  PartyMatter,
} from 'Common/Data/Types/matter';
import { QuestionAny, QuestionCard } from 'Common/Data/Types/appSections';
import getPartyKeyFromParty from 'Common/Utils/getPartyKeyFromParty';
import { ODRS_DASH_HOME_ROUTE } from 'Common/routes';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import UpdatedText from 'Common/UI/Text/UpdatedText';
import Highlight from 'Common/UI/Text/Highlight';
import push from './push';

const highlightStatementValues = (plainText = false) =>
  function (strings: TemplateStringsArray, ...values: ReactNode[]) {
    if (plainText) {
      let output = '';
      strings.forEach((string, i) => {
        output += `${string} ${values[i] || ''}`;
      });

      return output;
    }

    return (
      <>
        {strings.map((string, i) => (
          <React.Fragment key={i}>
            {string} <Highlight>{values[i]}</Highlight>
          </React.Fragment>
        ))}
      </>
    );
  };

export const getHighlightStatementValuesFunction = (plainText = false) =>
  highlightStatementValues(plainText);

// given a diff object and the key to check, returns the valueToWrap either highlighted or as-is
const wasUpdated = (
  itemDiff: CardRevision,
  plainText: boolean,
  keyOrKeys: string | string[],
  valueToWrap: ReactNode,
  withUpdateLink: boolean,
  handleClick: () => void
) => {
  if (plainText) {
    return valueToWrap;
  }

  // if key is a string, mark it as updated if it is not undefined in the item diff
  if (!Array.isArray(keyOrKeys) && itemDiff[keyOrKeys] !== undefined) {
    return (
      <UpdatedText
        withUpdateLink={withUpdateLink}
        onClick={withUpdateLink ? handleClick : undefined}
        lastUpdatedBy={getPartyKeyFromParty(itemDiff[keyOrKeys].updatedBy)}
      >
        {valueToWrap}
      </UpdatedText>
    );
  }

  // if key is an array, mark it as not undefined if any of the values are not undefined in the item diff
  if (
    Array.isArray(keyOrKeys) &&
    keyOrKeys.find(k => itemDiff[k] !== undefined)
  ) {
    // find the key of the item that was updated most recently
    const diffs = keyOrKeys.map(key => itemDiff[key]);
    const lastUpdatedItem = diffs.reduce((prev, current) =>
      prev.revision > current.revision ? prev : current
    );

    return (
      <UpdatedText
        withUpdateLink={withUpdateLink}
        onClick={withUpdateLink ? handleClick : undefined}
        lastUpdatedBy={getPartyKeyFromParty(lastUpdatedItem.updatedBy)}
      >
        {valueToWrap}
      </UpdatedText>
    );
  }

  return `${valueToWrap}`;
};

export const getWasUpdatedFunction = (
  itemDiff: CardRevision,
  plainText = false,
  withUpdateLink = false,
  card: QuestionCard
) => {
  const { dispatch } = reduxStore;

  const navigate = (route: string, state?: any) => dispatch(push(route, state));

  const { questionsRoute = '', viewRoute = ODRS_DASH_HOME_ROUTE } = card;

  return (key: string | string[], valueToWrap: ReactNode) => {
    // skip highlighting if the card has been approved
    if (card.status === STATUS_APPROVED && !card.singleUserApproved) {
      return `${valueToWrap}`;
    }

    return wasUpdated(
      itemDiff,
      plainText,
      key,
      valueToWrap,
      withUpdateLink,
      () =>
        navigate(questionsRoute, {
          gotoQuestion: Array.isArray(key) ? key[0] : key,
          timestamp: Date.now(),
          previous: viewRoute,
          skipVerticalTransition: true,
        })
    );
  };
};

export const getUpdatedItemValues = (
  item: Record<string, unknown>,
  itemHistoryAll: ItemHistory[],
  keysToTrack: string[] = []
): CardRevision => {
  // get all item history for this item
  const itemHistory = itemHistoryAll.filter(
    (history: ItemHistory) => history.SectionID === item.SectionID
  );

  const selfParty = getPartyKeys().self;

  const revisionsReversed = [...itemHistory].reverse();

  const revision: CardRevision = keysToTrack.reduce((acc, key) => {
    const revisionContainingUpdatedItem = revisionsReversed.find(
      r => r[key] !== undefined
    );

    // check if a value has been updated now, before the data has been saved to the database
    // to do this, we check if the key exists in `item` and if either there is no revision where the value has
    // been updated or the value in `item` does not match the last revision item
    if (
      item[key] &&
      (!revisionContainingUpdatedItem ||
        (revisionContainingUpdatedItem &&
          !isEqual(item[key], revisionContainingUpdatedItem[key])))
    ) {
      return {
        ...acc,
        [key]: {
          updatedBy: selfParty,
          revision: -1,
        },
      };
    }

    return {
      ...acc,
      [key]: {
        updatedBy:
          revisionContainingUpdatedItem?.creator || item.creator || selfParty,
        revision: revisionContainingUpdatedItem?.revision || 0,
      },
    };
  }, {});

  return revision;
};

export const getQuestionValuesAtIndex = (
  questionsInput: Record<string, unknown>,
  index: number
) => {
  const valuesAtIndex: Record<string, unknown> = {};

  Object.entries(questionsInput).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      valuesAtIndex[key] = value[index];
    }
  });

  return valuesAtIndex;
};

export const getRedactFunction =
  (
    questions: QuestionAny[],
    data: Record<string, unknown>,
    hideSensitive: boolean
  ): ((arg1: string, arg2?: React.ReactNode) => ReactNode) =>
  (key: string, value?: React.ReactNode): ReactNode => {
    const question = questions.find(q => q.name === key);

    let actualValue: ReactNode;

    if (value) {
      actualValue = value;
    } else if (
      data[key] &&
      typeof data[key] !== 'object' &&
      !Array.isArray(data[key])
    ) {
      actualValue = data[key] as string;
    }

    if (hideSensitive && question && question.sensitive) {
      if (typeof question.sensitive === 'function') {
        return question.sensitive(actualValue);
      }

      return '[redacted]';
    }

    return actualValue || '';
  };

// redact sensitive data
// currently only used for when emailing LSC an export of agreed settlement
export const removeSensitiveData = (
  questions: QuestionAny[],
  data: Record<string, unknown>
) => {
  const redactedData: Record<string, unknown> = {};

  Object.entries(data).forEach(([key, value]) => {
    const question = questions.find(q => q.name === key);

    if (question && question.sensitive) {
      if (typeof question.sensitive === 'function') {
        redactedData[key] = question.sensitive(value);
      }

      redactedData[key] = '[redacted]';
    } else {
      redactedData[key] = value;
    }
  });

  return redactedData;
};

export const getFirstName = (
  partyData: PartyMatter,
  party: Party,
  hideSensitive: boolean
) => {
  if (!partyData || !party) {
    return '';
  }

  if (hideSensitive) {
    return `Party ${party}`;
  }

  return partyData.firstname;
};

export const getFullName = (
  partyData: PartyMatter,
  party: Party,
  hideSensitive: boolean
) => {
  if (hideSensitive) {
    return `Party ${party}`;
  }

  let name = '';

  if (partyData.firstname) {
    name = partyData.firstname;
  }

  if (partyData.middlename) {
    name += ` ${partyData.middlename}`;
  }

  if (partyData.lastname) {
    name += ` ${partyData.lastname}`;
  }

  return name;
};

export const checkStartsWithVowel = (answer: string): boolean =>
  !!answer?.charAt(0).match('[aieouAIEOU]');

export const getSingleUserFullName = (matter: Matter): string =>
  matter?.items[0]?.name || 'guest';
