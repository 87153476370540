import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import spacer, { SpacerProps } from '../Layout/spacer';

export type ButtonVariantProps = {
  disabled: boolean;
  subtle?: boolean;
  reverse?: boolean;
  help?: boolean;
  transparent?: boolean;
};

type Props = {
  theme?: any;
  selected?: boolean;
  fullWidth?: boolean;
  enabled?: boolean;
  small?: boolean;
  invert?: boolean;
  animated?: boolean;
  buttonStyle?: string;
  bumpDown?: boolean;
  desktopOnly?: boolean;
  wide?: boolean;
} & ButtonVariantProps;

const getButtonVariantProps = (props: Partial<Props>) => {
  const { selected, disabled, theme, subtle, reverse, help, transparent } =
    props;

  if (disabled) {
    return {
      backgroundColor: theme.buttonInactiveColour,
      color: theme.buttonTextInactive,
      border: '2px solid transparent',
    };
  }

  if (subtle) {
    const isSelected = selected
      ? { backgroundColor: theme.buttonActiveBackgroundSubtle }
      : {};

    return {
      backgroundColor: theme.buttonBackgroundSubtle,
      color: theme.buttonTextSubtle,
      border: '2px solid transparent',
      '&:hover': {
        backgroundColor: theme.buttonHoverBackgroundSubtle,
        borderColor: theme.buttonHoverBorderSubtle,
      },
      '&:active': {
        borderColor: 'transparent',
        backgroundColor: theme.buttonActiveBackgroundSubtle,
      },
      ...isSelected,
    };
  }

  if (reverse) {
    const isSelected = selected
      ? { backgroundColor: theme.buttonActiveBackgroundReverse }
      : {};

    return {
      backgroundColor: theme.buttonBackgroundReverse,
      color: theme.buttonTextReverse,
      border: '2px solid transparent',
      '&:hover': {
        backgroundColor: theme.buttonHoverBackgroundReverse,
        borderColor: theme.buttonHoverBorderReverse,
      },
      '&:active': {
        borderColor: 'transparent',
        backgroundColor: theme.buttonActiveBackgroundReverse,
      },
      ...isSelected,
    };
  }

  if (help) {
    const isSelected = selected
      ? { backgroundColor: theme.buttonActiveBackgroundHelp }
      : {};

    return {
      backgroundColor: theme.buttonBackgroundHelp,
      color: theme.buttonTextHelp,
      border: '2px solid transparent',
      '&:hover': {
        backgroundColor: theme.buttonHoverBackgroundHelp,
        borderColor: theme.buttonHoverBorderHelp,
      },
      '&:active': {
        borderColor: 'transparent',
        backgroundColor: theme.buttonActiveBackgroundHelp,
      },
      ...isSelected,
    };
  }

  if (transparent) {
    return {
      backgroundColor: 'transparent',
      color: theme.buttonTextTransparent,
      border: '2px solid transparent',
      '&:hover': {
        textDecoration: 'underline',
      },
    };
  }

  const isSelected = selected
    ? { backgroundColor: theme.buttonActiveBackgroundPrimary }
    : {};

  return {
    backgroundColor: theme.buttonBackgroundPrimary,
    color: theme.buttonTextPrimary,
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: theme.buttonHoverBackgroundPrimary,
      borderColor: theme.buttonHoverBorderPrimary,
    },
    '&:active': {
      borderColor: 'transparent',
      backgroundColor: theme.buttonActiveBackgroundPrimary,
    },
    ...isSelected,
  };
};

const GenericButton = styled.button.withConfig({
  shouldForwardProp: prop =>
    ![
      'subtle',
      'enabled',
      'small',
      'invert',
      'fullWidth',
      'animated',
      'reverse',
      'buttonStyle',
      'help',
      'transparent',
      'bumpDown',
      'desktopOnly',
      'wide',
      'iconDisabled',
    ].includes(prop),
})<Partial<Props> & SpacerProps>`
  ${({ theme }) => theme.typography.clickable.button};
  ${props => getButtonVariantProps(props)}
  ${props => props.color && `color: ${props.color};`};
  padding: ${props => (props.small ? '10px 16px 9px' : '14px 16px 13px')};
  ${({ wide, theme }) =>
    wide &&
    css`
      padding-left: ${theme.spacing[8]};
      padding-right: ${theme.spacing[8]};
    `}
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  border-radius: 4px;
  transition: all 200ms;

  ${({ theme, small }) => small && theme.typography.clickable.button2}

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    ${({ theme }) => theme.typography.clickable.button.md};
    ${({ theme, small }) => small && theme.typography.clickable.button2.md}
  }

  ${spacer}
`;

export const SubtleButton = styled(GenericButton)`
  background-color: ${({ theme, selected }) =>
    selected
      ? theme.buttonActiveBackgroundSubtle
      : theme.buttonBackgroundSubtle};
  color: ${({ theme }) => theme.buttonTextSubtle};
  border: '2px solid transparent';

  &:hover {
    background-color: ${({ theme }) => theme.buttonHoverBackgroundSubtle};
    border-color: ${({ theme }) => theme.buttonHoverBorderSubtle};
  }

  &:active {
    border-color: 'transparent';
    background-color: ${({ theme }) => theme.buttonActiveBackgroundSubtle};
  }
`;

export const MediumButton = styled(GenericButton)`
  max-width: 50%;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    min-width: 260px;
    max-width: 100%;
  }
`;

export const SmallButton = styled(GenericButton)`
  ${({ theme }) => theme.typography.clickable.button2};
  padding: 6px 16px;
  min-width: 0;
  text-decoration: none;
  border-radius: ${props => props.theme.padding.large}px;
  right: ${props => props.theme.padding.medium}px;
  bottom: ${props => props.theme.padding.medium}px;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    ${({ theme }) => theme.typography.clickable.button2.md};
  }
`;

GenericButton.propTypes = {
  className: PropTypes.string,
  enabled: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  subtle: PropTypes.bool,
  help: PropTypes.bool,
};

GenericButton.defaultProps = {
  enabled: true,
  selected: undefined,
  className: 'generic-button',
  fullWidth: false,
  subtle: false,
  help: false,
};

export default GenericButton;
