import React, { useContext } from 'react';
import styled from 'styled-components';
import { Theme } from 'Common/Utils/theme';
import { Link } from 'react-router-dom';
import CloseButton from 'Common/UI/Button/CloseButton';
import { CardQuestionFlowContext } from './CardQuestionsFlowContext';

export const QuestionFlowHeader = styled.div<{
  theme: Theme;
}>`
  padding-bottom: ${({ theme }) => theme.spacing[16]};
  position: relative;
  height: 82;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    height: 124px;
  }
`;

const StyledCardQuestionFlowHeader = styled.div<{
  theme: Theme;
  bgImage: string;
  bgColour: string;
}>`
  padding-bottom: ${({ theme }) => theme.spacing[16]};
  position: relative;
  background: ${props => props.bgColour} url('${props => props.bgImage}')
    no-repeat top left;
  background-size: contain;
  height: 82px;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    height: 124px;
  }
`;

const StyledCloseButton = styled(CloseButton)<{
  theme: Theme;
}>`
  position: absolute;
  top: 12px;
  z-index: 10;
  text-decoration: none;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    top: 24px;
  }

  ${({ theme }) => theme.tokens.closeButtonPosition}
`;

type Props = {
  backgroundImage: string;
  backgroundColour: string;
  previousRoute: string;
  textClose?: string;
};

const CardQuestionFlowHeader: React.FC<Props> = ({
  backgroundImage,
  backgroundColour,
  previousRoute,
  textClose = 'Close',
}) => {
  const { headerVisibility } = useContext(CardQuestionFlowContext);

  return headerVisibility ? (
    <StyledCardQuestionFlowHeader
      bgImage={backgroundImage}
      bgColour={backgroundColour}
    >
      <StyledCloseButton as={Link} to={previousRoute}>
        &nbsp;&nbsp;{textClose}
      </StyledCloseButton>
    </StyledCardQuestionFlowHeader>
  ) : null;
};

export default CardQuestionFlowHeader;
