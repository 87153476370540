import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import FlexDrawer from 'Common/UI/Layout/FlexDrawer';
import { Row } from 'Common/UI/Layout/Flex';
import Paragraph from 'Common/UI/Text/Paragraph';
import { SubHeadingBold } from 'Common/UI/Text/Headings';
import DeleteButton from 'Common/UI/Button/DeleteButton';
import GenericButton from 'Common/UI/Button/GenericButton';
import IconClose from 'Common/Assets/images/icon-close-darkgrey.svg';

const StyledDocumentViewer = styled.div`
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: ${props => props.theme.colours.black};
`;

const DocumentViewerHeader = styled.div``;

const DocumentViewerBody = styled.div`
  flex: 1;
  overflow: scroll;
`;

const DocumentViewerBodyInner = styled.div`
  height: 100%;
  max-width: 750px;
  margin: auto;
`;

const DocumentViewerFooter = styled.div`
  min-height: 40px;
  padding: ${({ theme }) => theme.padding.small}px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const CloseButton = styled.button`
  float: right;
  padding: 16px;
  background-color: #fff0;
  border: none;
`;

const Iframe = styled.iframe`
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;

const modalPortal = document.getElementById('modal');

const StyledButton = styled(GenericButton)`
  flex-basis: 45%;
`;

const DocumentDeleteConfirmation = ({ visible, onCancel, onAccept }) => (
  <FlexDrawer isVisible={visible}>
    <SubHeadingBold>Do you want to delete this file?</SubHeadingBold>
    <Paragraph>
      This image will be deleted from your statement. You will still be able to
      upload another image.
    </Paragraph>
    <Row justifyContent="space-between">
      <StyledButton reverse onClick={onCancel}>
        Cancel
      </StyledButton>
      <StyledButton onClick={onAccept}>Delete</StyledButton>
    </Row>
  </FlexDrawer>
);

const DocumentView = ({ documentURL }) => {
  if (documentURL.match(/\.pdf\?/)) {
    return <Iframe title={documentURL} src={documentURL} />;
  }
  return <Image src={documentURL} />;
};

DocumentView.propTypes = {
  documentURL: PropTypes.string.isRequired,
};

const SCREEN_STATE_VIEWING = 'SCREEN_STATE_VIEWING';
const SCREEN_STATE_CONFIRMING = 'SCREEN_STATE_CONFIRMING';

const DocumentViewer = ({
  isOpen,
  handleClose,
  documentURL,
  deletable,
  handleDelete,
}) => {
  const [screenState, setScreenState] = useState(SCREEN_STATE_VIEWING);
  return isOpen
    ? createPortal(
        <StyledDocumentViewer>
          <DocumentViewerHeader>
            <CloseButton
              onClick={() => {
                setScreenState(SCREEN_STATE_VIEWING);
                handleClose();
              }}
            >
              <img src={IconClose} alt="Close icon" />
            </CloseButton>
          </DocumentViewerHeader>
          <DocumentViewerBody>
            <DocumentViewerBodyInner>
              <DocumentView documentURL={documentURL} />
            </DocumentViewerBodyInner>
          </DocumentViewerBody>
          <DocumentViewerFooter>
            {deletable && (
              <DeleteButton
                onClick={() => {
                  setScreenState(SCREEN_STATE_CONFIRMING);
                }}
              />
            )}
          </DocumentViewerFooter>
          <DocumentDeleteConfirmation
            visible={screenState === SCREEN_STATE_CONFIRMING}
            onAccept={() => {
              setScreenState(SCREEN_STATE_VIEWING);
              handleDelete();
            }}
            onCancel={() => setScreenState(SCREEN_STATE_VIEWING)}
          />
        </StyledDocumentViewer>,
        modalPortal
      )
    : null;
};

DocumentViewer.propTypes = {
  sectionID: PropTypes.string,
  documentURL: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.oneOf([PropTypes.instanceOf(File), PropTypes.string])
  ),
};

DocumentViewer.defaultProps = {
  value: [],
  sectionID: '',
};

export default DocumentViewer;
