import { Theme } from 'Common/Utils/theme';
import styled from 'styled-components';
import spacer, { SpacerProps } from '../Layout/spacer';

export const Title = styled.h1<{
  theme: Theme;
}>`
  ${({ theme }) => theme.typography.headings.title};
  color: ${({ theme }) => theme.textColour};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    ${({ theme }) => theme.typography.headings.title.md};
  }
  @media ${({ theme }) => theme.sizes.xl.breakpoint} {
    ${({ theme }) => theme.typography.headings.title.xl};
  }
`;

export const Heading1 = styled.h1<
  {
    theme: Theme;
  } & SpacerProps
>`
  ${({ theme }) => theme.typography.headings.h1};
  color: ${({ theme }) => theme.textColour};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    ${({ theme }) => theme.typography.headings.h1.md};
  }
  @media ${({ theme }) => theme.sizes.xl.breakpoint} {
    ${({ theme }) => theme.typography.headings.h1.xl};
  }

  ${spacer}
`;

export const Heading2 = styled.h2<
  {
    theme: Theme;
  } & SpacerProps
>`
  ${({ theme }) => theme.typography.headings.h2};
  color: ${({ theme }) => theme.textColour};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    ${({ theme }) => theme.typography.headings.h2.md};
  }
  @media ${({ theme }) => theme.sizes.xl.breakpoint} {
    ${({ theme }) => theme.typography.headings.h2.xl};
  }

  ${spacer}
`;

export const Heading2MarginTopNone = styled(Heading2)`
  margin-top: 0;
`;

export const Heading3 = styled.h3<
  {
    theme: Theme;
  } & SpacerProps
>`
  ${({ theme }) => theme.typography.headings.h3};
  color: ${({ theme }) => theme.textColour};
  margin-top: 0;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    ${({ theme }) => theme.typography.headings.h3.md};
  }
  @media ${({ theme }) => theme.sizes.xl.breakpoint} {
    ${({ theme }) => theme.typography.headings.h3.xl};
  }

  ${spacer}
`;

export const SubHeadingHelp = styled(Heading3)(props => ({
  marginTop: `${props.theme.padding.medium2}px`,
  marginBottom: `${props.theme.padding.xxsmall}px`,
}));

export const Heading3Inactive = styled.h3<{
  theme: Theme;
}>`
  ${({ theme }) => theme.typography.headings.h3Inactive};
  color: ${({ theme }) => theme.textColour};
  margin-top: 0;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    ${({ theme }) => theme.typography.headings.h3Inactive.md};
  }
  @media ${({ theme }) => theme.sizes.xl.breakpoint} {
    ${({ theme }) => theme.typography.headings.h3Inactive.xl};
  }
`;

export const Heading4 = styled.h4<
  {
    theme: Theme;
  } & SpacerProps
>`
  ${({ theme }) => theme.typography.headings.h4};
  color: ${({ theme }) => theme.textColour};

  @media ${({ theme }) => theme.sizes.xl.breakpoint} {
    ${({ theme }) => theme.typography.headings.h4.xl};
  }

  ${spacer}
`;

export const SubHeadingBold = styled(Heading4)(props => ({
  ...props.theme.typography.headings.subHeadingBold,
  color: props.theme.textColour,
  margin: '0 0 12px',
}));

export const SectionHeading = styled(Heading2)(props => ({
  color: props.theme.colours.black,
}));

export const SubsectionHeading = styled(Heading3)(props => ({
  color: props.theme.colours.black,
}));

export const H1Conversational = styled(Heading1)`
  ${({ theme }) => theme.typography.headings.h1Conversational};
  color: ${({ theme }) => theme.textColour};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    ${({ theme }) => theme.typography.headings.h1Conversational.md};
  }
  @media ${({ theme }) => theme.sizes.xl.breakpoint} {
    ${({ theme }) => theme.typography.headings.h1Conversational.xl};
  }
`;

export const H2Conversational = styled(Heading2)<{
  theme: Theme;
}>`
  ${({ theme }) => theme.typography.headings.h2Conversational};
  color: ${({ theme }) => theme.textColour};
  margin: 0 0 ${({ theme }) => theme.padding.medium}px;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    ${({ theme }) => theme.typography.headings.h2Conversational.md};
  }
  @media ${({ theme }) => theme.sizes.xl.breakpoint} {
    ${({ theme }) => theme.typography.headings.h2Conversational.xl};
  }
`;

// export const H3Conversational = styled(Heading3)(props => ({
//   ...props.theme.typography.headings.h3Conversational,
//   color: props.theme.textColour,
//   margin: '0 0 15px',
// }));

export const BigNumber = styled(Heading1)(props => ({
  ...props.theme.typography.number.bigNumber,
  color: props.theme.textColour,
}));

export const RegularNumber = styled(Heading2)(props => ({
  ...props.theme.typography.number.regularNumber,
}));

export const HeadingSpacedContainer = styled.div<{
  theme: Theme;
  align?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  maxWidth?: string;
  width?: string;
}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: ${({ align }) => align || 'flex-start'};
  margin-top: ${props => (props.marginTop ? props.marginTop : 0)};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)};
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '')};
  width: ${props => (props.width ? props.width : '')};
  > ${SectionHeading}, ${Heading2} {
    margin-top: 0;
  }
  > * {
    margin-bottom: 0;
  }
  > ${SectionHeading} {
    margin-top: 0;
  }
`;
