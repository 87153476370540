import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PARTY_BOTH } from 'Common/constants';
import { Heading3, HeadingSpacedContainer } from 'Common/UI/Text/Headings';
import { HighlightedParagraph } from 'Common/UI/Text/Paragraph';
import MoreButton from 'Common/UI/Button/MoreButton';
import defaultTheme from 'Common/Utils/theme';

import IconBoth from 'Common/Assets/images/icon-users-both.svg';
import IconSelf from 'Common/Assets/images/icon-users-self.svg';
import IconOther from 'Common/Assets/images/icon-users-other.svg';
import IconSuperCardSplitting from 'Common/Assets/images/icon-super-card-splitting.svg';
import useOdie from 'Common/Utils/Hooks/useOdie';
import { Row } from 'Common/UI/Layout/Flex';
import AssetProperties from 'Common/Data/App/assetProperties';
import CardContext from '../context';

const StyledHeading3 = styled(Heading3)`
  margin-top: 0;
  font-weight: ${props => (props.toAction === 'true' ? 'bold' : '500')};
`;

const StyledHighlightedParagraph = styled(HighlightedParagraph)`
  color: ${props => props.theme.colours.mediumGrey};
  margin-top: 0;
`;

const StyledMoreButton = styled(MoreButton)`
  margin-left: ${({ theme }) => theme.spacing[4]};
`;

const HelpIconWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing[2]};
`;

const MainHeading = ({
  children,
  toAction,
  enableMoreButton,
  showHelpIcon,
  helpContent: HelpContent,
  customMoreIcon,
  cardForParty,
  isApproved,
}) => {
  const { HelpIcon } = useOdie({
    message: showHelpIcon && HelpContent,
  });

  const { extendID, hasOptions, showOptionsDrawer } = useContext(CardContext);

  const { isSuperIncludedInOffer, superannuationAsset } = AssetProperties();

  let isSuperannuationCardThatIsBeingSplit = false;

  if (
    isSuperIncludedInOffer &&
    superannuationAsset &&
    superannuationAsset.id === extendID
  ) {
    isSuperannuationCardThatIsBeingSplit = true;
  }

  return (
    <HeadingSpacedContainer marginBottom={`${defaultTheme.padding.xsmall}px`}>
      {isApproved ? (
        <StyledHighlightedParagraph>{children}</StyledHighlightedParagraph>
      ) : (
        <StyledHeading3 toAction={toAction}>{children}</StyledHeading3>
      )}
      <Row>
        {cardForParty === PARTY_BOTH && (
          <img
            src={IconBoth}
            alt="Both you and your former partner can edit this card"
            title="Both you and your former partner can edit this card"
          />
        )}
        {cardForParty === 'self' && !isSuperannuationCardThatIsBeingSplit && (
          <img
            src={IconSelf}
            alt="Only you can edit this card"
            title="Only you can edit this card"
          />
        )}
        {cardForParty === 'self' && isSuperannuationCardThatIsBeingSplit && (
          <img
            src={IconSuperCardSplitting}
            alt="Only you can edit this card"
            title="Only you can edit this card"
          />
        )}
        {cardForParty === 'other' && (
          <img
            src={IconOther}
            alt="Only your former partner can edit this card"
            title="Only your former partner can edit this card"
          />
        )}
        {showHelpIcon && HelpContent && (
          <HelpIconWrapper>
            <HelpIcon />
          </HelpIconWrapper>
        )}
        {enableMoreButton && hasOptions && (
          <StyledMoreButton
            onClick={showOptionsDrawer}
            customIcon={customMoreIcon}
          />
        )}
      </Row>
    </HeadingSpacedContainer>
  );
};

export default MainHeading;

MainHeading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  toAction: PropTypes.string,
  enableMoreButton: PropTypes.bool,
  showHelpIcon: PropTypes.bool,
  helpContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  cardForParty: PropTypes.string,
  isApproved: PropTypes.bool,
};

MainHeading.defaultProps = {
  toAction: 'true',
  enableMoreButton: false,
  showHelpIcon: false,
  helpContent: '',
  cardForParty: undefined,
  isApproved: false,
};
