import React from 'react';
import CardQuestions from 'App/UI/Questions/CardQuestionsFlow';
import MatterGuard from './Guard/MatterGuard';

type Props = {
  props: any;
};

const ODRSAboutYouB: React.FC<Props> = props => (
  <MatterGuard>
    <CardQuestions baseID="aboutyou" index="B" {...props} />
  </MatterGuard>
);

export default ODRSAboutYouB;
