import {
  STATUS_NEW_ONLY_SELF,
  STATUS_NEW_ONLY_THEM,
  STATUS_NEW_BOTH_PARTIES,
  DATA_SECTION,
  DATA_GROUP,
  CARD_NORMAL,
  DATA_PAGE,
  SECTION_FINANCES,
  PAGE_FINANCES_MAIN,
  SECTION_RELATIONSHIP,
} from 'Common/constants';
import MatterPropsJS from 'Common/Utils/MatterProps';
import {
  ODRS_SUA_YOURFINANCES_SELF_ROUTE,
  ODRS_SUA_YOURFINANCES_OTHER_ROUTE,
  ODRS_SHAREDFINANCES_ROUTE,
  ODRS_DASH_FINANCES_ROUTE,
} from 'Common/routes';
import { reduxStore } from 'App/State/Store';

import { getNavItemStatus } from 'Common/UI/Navigation';
import IconFinances from 'Common/Assets/images/icon-finances.svg';
import BackgroundQuestionFlowProperty from 'Common/Assets/images/demo-header.svg';
import colours from 'Common/Utils/colours';
import {
  getPageStatus,
  hasSectionBeenCompleted,
} from 'App/Utils/sectionHelpers';
import {
  yourFinancesAQuestions,
  yourFinancesBQuestions,
  yourFinancesAStatement,
  yourFinancesBStatement,
  sharedFinancesQuestions,
  sharedFinancesStatement,
} from 'Common/Data/SingleUser/Questions/Finances';

import { CardNormal, DataSection } from 'Common/Data/Types/appSections';
import { buildCard } from '../buildCard';

const generateFinances = (): DataSection => {
  const generateStatus = () => {
    const { completed } = getPageStatus({
      section: SECTION_FINANCES,
      page: PAGE_FINANCES_MAIN,
    });

    const { app } = reduxStore.getState();
    
    let isLocked = app.flags.isDemoComplete;
    if (!isLocked) {
      isLocked = !hasSectionBeenCompleted({ section: SECTION_RELATIONSHIP });
    }

    return getNavItemStatus({
      isCompleted: completed,
      isLocked,
    });
  }

  return ({
  type: DATA_SECTION,
  id: SECTION_FINANCES,
  title: 'Finances',
  route: ODRS_DASH_FINANCES_ROUTE,
  questionFlowBackgroundImage: BackgroundQuestionFlowProperty,
  questionFlowBackgroundColour: colours.offWhite,
  children: [
    {
      type: DATA_PAGE,
      id: PAGE_FINANCES_MAIN,
      title: 'Finances',
      route: ODRS_DASH_FINANCES_ROUTE,
      generateStatus,
      children: [
        {
          type: DATA_GROUP,
          id: 'finances',
          title: 'Finances',
          description:
            'We are going to ask a few questions about your finances and personal belongings.',
          icon: IconFinances,
          defaultRoute: ODRS_DASH_FINANCES_ROUTE,
          children: [
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'yourfinances',
              cardID: `yourfinances${MatterPropsJS('self.party', 'A')}`,
              title: 'Your finances',
              sensitiveTitle: `Party ${MatterPropsJS(
                'self.party',
                'A'
              )}'s Finances`,
              questions: yourFinancesAQuestions,
              statement: yourFinancesAStatement,
              index: MatterPropsJS('self.party', 'A'),
              questionsRoute: ODRS_SUA_YOURFINANCES_SELF_ROUTE,
              updateRoute: `${ODRS_SUA_YOURFINANCES_SELF_ROUTE}/update`,
              dashboardRoute: ODRS_DASH_FINANCES_ROUTE,
              status: STATUS_NEW_ONLY_SELF,
              autoApprove: true,
              singleUserApproved: true,
            }),
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'yourfinances',
              cardID: `yourfinances${MatterPropsJS('other.party', 'B')}`,
              title: `${MatterPropsJS('other.firstname', 'Them')}'s finances`,
              sensitiveTitle: `Party ${MatterPropsJS(
                'other.party',
                'B'
              )}'s finances`,
              questions: yourFinancesBQuestions,
              statement: yourFinancesBStatement,
              index: MatterPropsJS('other.party', 'B'),
              questionsRoute: ODRS_SUA_YOURFINANCES_OTHER_ROUTE,
              updateRoute: `${ODRS_SUA_YOURFINANCES_OTHER_ROUTE}/update`,
              dashboardRoute: ODRS_DASH_FINANCES_ROUTE,
              status: STATUS_NEW_ONLY_THEM,
              autoApprove: true,
              singleUserApproved: true,
            }),
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'sharedfinances',
              cardID: 'sharedfinances',
              title: 'Shared finances',
              questions: sharedFinancesQuestions,
              statement: sharedFinancesStatement,
              questionsRoute: ODRS_SHAREDFINANCES_ROUTE,
              updateRoute: `${ODRS_SHAREDFINANCES_ROUTE}/update`,
              dashboardRoute: ODRS_DASH_FINANCES_ROUTE,
              status: STATUS_NEW_BOTH_PARTIES,
              autoApprove: true,
              singleUserApproved: true,
            }),
          ],
        },
      ],
    },
  ],
})};

export default generateFinances;
