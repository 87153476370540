import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { useTransition } from 'react-spring/web.cjs';

import {
  verticalTransitionRoutes,
  ODRS_PROFILE_SIGNOUT_ROUTE,
} from 'Common/routes';
import { RouteProvider } from './RouteContext';
import RouteWrapper from './RouteWrapper';

// for each of the routes in the array, check if the given route starts with the array route
const isRouteInArray = (routeArray, route) =>
  routeArray.some(item => route.startsWith(item));

const RouteTransition = ({ children }) => {
  const location = useLocation();

  const skipVerticalTransition =
    location?.state?.skipVerticalTransition || false;

  const transitions = useTransition(location, loc => loc.pathname, {
    from: item => {
      // do a vertical transition if it's a vertical transition route UNLESS we've come from the edit statement screen
      if (
        isRouteInArray(verticalTransitionRoutes, item.pathname) &&
        !skipVerticalTransition
      ) {
        return {
          dummy: 0,
          opacity: 1,
          transform: 'translateY(100%)',
          pos: 'front',
          leaving: 0,
        };
      }

      return {
        dummy: 0,
        opacity: 1,
        transform: 'translateY(0%)',
        pos: 'back',
        leaving: 0,
      };
    },
    enter: item => {
      if (isRouteInArray(verticalTransitionRoutes, item.pathname)) {
        return {
          dummy: 0,
          opacity: 1,
          transform: 'translateY(0%)',
          pos: 'front',
          leaving: 0,
        };
      }

      return {
        dummy: 0,
        opacity: 1,
        transform: 'translateY(0%)',
        pos: 'front',
        leaving: 0,
      };
    },
    leave: item => {
      const leaveRoute = item.pathname;
      const enterRoute = location.pathname;

      if (
        !isRouteInArray(verticalTransitionRoutes, enterRoute) &&
        isRouteInArray(verticalTransitionRoutes, leaveRoute)
      ) {
        return {
          dummy: 1,
          opacity: 1,
          transform: 'translateY(100%)',
          pos: 'front',
          leaving: 1,
        };
      }
      if (
        !isRouteInArray(verticalTransitionRoutes, leaveRoute) &&
        isRouteInArray(verticalTransitionRoutes, enterRoute)
      ) {
        return {
          dummy: 1,
          opacity: 1,
          transform: 'translateY(0%)',
          pos: 'back',
          leaving: 1,
        };
      }

      return {
        dummy: 0,
        opacity: 0,
        transform: 'translateY(0%)',
        pos: 'back',
        leaving: 1,
      };
    },
    config: {
      // if we're transitioning to the signout route, we want to set the duration to 0 so it skips the transition
      // without this, the old route is still mounted when the user is signed out and the app throws an error due to the old
      // component trying to access information that no longer exists
      duration: location.pathname.startsWith(ODRS_PROFILE_SIGNOUT_ROUTE)
        ? 0
        : undefined,
    },
  });

  return (
    <>
      {transitions.map(({ item, props: { pos, leaving, ...styles }, key }) => (
        <RouteProvider key={key}>
          <RouteWrapper
            location={item}
            style={{ zIndex: pos.value === 'front' ? 1 : 0, ...styles }}
          >
            <Switch location={item}>{children}</Switch>
          </RouteWrapper>
        </RouteProvider>
      ))}
    </>
  );
};

export default RouteTransition;
