import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import * as Sentry from '@sentry/browser';

import { Label } from 'Common/UI/Text/Label';
import { getPresignedUrlForFile } from 'Common/Utils/presignedUrlMaker';
import useIsMounted from 'Common/Utils/Hooks/useIsMounted';
import DocumentViewer from './DocumentViewer';

const StyledDocumentList = styled.div`
  display: flex;
  flex-direction: column;
  height: ${props =>
    props.numDocuments * 42}px; // @TODO make this not a magic number
`;

const Document = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const Thumbnail = styled.div`
  background-color: #92d2d6;
  border-radius: 4px;
  color: #036268;
  cursor: pointer;
  display: block;
  height: 32px;
  position: relative;
  width: 32px;
  z-index: 2;
  ${props =>
    props.backgroundImage &&
    css`
      &::before {
        content: '';
        display: block;
        width: 100%;
        min-width: 32px;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(${props.backgroundImage});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    `}
  ${props =>
    props.fileType === 'pdf' &&
    css`
      &::after {
        content: 'PDF';
        position: absolute;
        font-weight: bold;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    `}
`;

const Name = styled(Label)`
  font-weight: bold;
  display: block;
  padding-left: 12px;
  min-width: 0;
  word-break: break-all;
`;

const DocumentList = ({ documents: documentsUnfiltered }) => {
  const isMounted = useIsMounted();
  const [presignedUrls, setPresignedUrls] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [documentURL, setDocumentURL] = useState('');

  // documents can be null sometimes for some reason, so make sure they are removed
  const documents = documentsUnfiltered.filter(document => document);

  useEffect(() => {
    if (!documents || (documents && documents.length === 0)) {
      return;
    }
    Promise.all(
      documents.map(file => {
        // `file` can be a string or an object
        if (typeof file === 'object' && file.status && file.imagePath) {
          return getPresignedUrlForFile(file.imagePath);
        }

        return getPresignedUrlForFile(file);
      })
    )
      .then(urls => {
        if (isMounted()) {
          setPresignedUrls(urls);
        }
      })
      .catch(error => {
        if (process.env.SENTRY_DSN) {
          Sentry.withScope(scope => {
            scope.setLevel('info');
            Sentry.captureException(error);
          });
        } else {
          console.log(error);
        }
      });
  }, []);

  const getFileName = fullFileName => {
    let fileName = fullFileName;
    if (fullFileName.length > 40) {
      const start = fullFileName.slice(0, 30);
      const end = fullFileName.slice(-10);
      fileName = `${start}...${end}`;
    }
    return fileName;
  };

  return (
    <StyledDocumentList
      numDocuments={documents.length}
      data-cy="documents-list"
    >
      {presignedUrls &&
        presignedUrls.map(file => (
          <Document key={file.filePath}>
            <Thumbnail
              onClick={() => {
                const { presignedUrl } = file;

                if (presignedUrl) {
                  setDocumentURL(presignedUrl);
                } else {
                  console.log('error');
                }
                setModalOpen(true);
              }}
              backgroundImage={file.presignedUrlThumb && file.presignedUrlThumb}
              fileType={file.name.split('.').pop()}
              alt=""
            />
            <Name>{getFileName(file.name)}</Name>
          </Document>
        ))}
      <DocumentViewer
        isOpen={isModalOpen}
        handleClose={() => setModalOpen(false)}
        handleDelete={() => {}}
        documentURL={documentURL}
      />
    </StyledDocumentList>
  );
};

const mapStateToProps = state => ({
  matter: state.matter,
});

export default connect(mapStateToProps)(DocumentList);
