import React from 'react';
import styled from 'styled-components';
import RoundButton from 'Common/UI/Button/RoundButton';
import ChevronRight from 'Common/Assets/images/icon-chevron-right-white.svg';
import ChevronRightGrey from 'Common/Assets/images/icon-chevron-right-grey.svg';
import { useAppDispatch } from 'App/State/Store';
import push from 'Common/Utils/push';

type Props = {
  route?: string;
  enabled?: boolean;
};

type ButtonProps = {
  iconDisabled: boolean;
};

const StyledRoundButton = styled(RoundButton)<ButtonProps>`
  min-width: 0;
  padding: 8px 16px;
  margin-top: 8px;
  background-image: ${({ iconDisabled }) =>
    `url('${iconDisabled ? ChevronRightGrey : ChevronRight}')`};

  background-size: 16px 16px;
  background-position: 75% center;
  background-repeat: no-repeat;
  padding-right: 42px;

  p {
    font-weight: 500;
  }
`;

const ButtonContainer = styled.div`
  text-align: right;
`;

/**
 * Shows the next button if its enabled
 * @param route: where you going?
 * @param enabled: can you go?
 * @returns a button
 */
const NextPageButton: React.FC<Props> = ({ route, enabled = false }) => {
  const dispatch = useAppDispatch();
  const navigate = (to: string) => dispatch(push(to));

  return route ? (
    <ButtonContainer>
      <StyledRoundButton
        iconDisabled={!enabled}
        onClick={() => navigate(route)}
        disabled={!enabled}
        data-cy="next-page-button"
      >
        Next{' '}
      </StyledRoundButton>
    </ButtonContainer>
  ) : null;
};

export default NextPageButton;
