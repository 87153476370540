import styled from 'styled-components';

const TextArea = styled.textarea`
  background-color: ${props => props.theme.inputBackground};

  font-size: 16px;
  line-height: 1.3;
  font-weight: 500;
  border: 0;
  font-weight: normal;
  border-radius: 60px;
  padding: 13px 16px;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${props => props.theme.colours.mediumGrey};
    font-weight: normal;
  }
`;

export default TextArea;
