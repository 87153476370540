import styled from 'styled-components';

import { Row } from 'Common/UI/Layout/Flex';

export const TwoButtonRow = styled(Row)`
  width: 100%;

  button {
    flex-grow: 1;
    flex-basis: 50%;
    &:first-child {
      margin-right: ${({ theme }) => theme.padding.small}px;
    }
  }
`;

export const TwoButtonResponsiveGroup = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    flex-direction: column;
  }

  button {
    flex-grow: 1;
    flex-basis: 50%;

    &:first-child {
      margin-right: ${({ theme }) => theme.padding.small}px;

      @media ${({ theme }) => theme.sizes.md.breakpoint} {
        margin-right: 0;
        margin-bottom: ${({ theme }) => theme.padding.small}px;
      }
    }
  }
`;
