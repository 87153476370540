import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Callout } from 'Common/UI/Text/Paragraph';
import RoundButton from 'Common/UI/Button/RoundButton';

const SelectButton = styled(RoundButton)`
  ${props =>
    !props.selected &&
    css`
      background: ${props.theme.colours.lightestGrey};
      color: ${props.theme.colours.black};
      font-weight: 400;
    `}
  margin-bottom: 16px;
  min-width: auto;
  display: block;
  text-align: left;
  padding-top: 9px;
  padding-bottom: 9px;
`;

const Label = styled.label``;

const StyledCallout = styled(Callout)`
  margin-top: 0px;
  margin-bottom: 32px;
`;

const IncludeStatementContainer = styled.div`
  overflow: auto;

  @media (max-width: 700px) {
    max-height: 400px;
  }

  @media (max-width: 400px) {
    max-height: 260px;
    ${SelectButton} {
      height: auto;
      margin-bottom: 12px;
    }
  }
`;

const IncludeStatement = ({ label, name, value, type, onChange }) => (
  <IncludeStatementContainer>
    <Label htmlFor={name}>
      <StyledCallout type={type}>{label}</StyledCallout>
      <SelectButton
        key="Yes"
        onClick={() => onChange('Yes')}
        selected={value === 'Yes'}
        subtle
      >
        Yes, include this statement
      </SelectButton>
      <SelectButton
        key=""
        onClick={() => onChange('No')}
        selected={false}
        subtle
      >
        No, don&apos;t include this statement
      </SelectButton>
    </Label>
  </IncludeStatementContainer>
);

IncludeStatement.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
};

IncludeStatement.defaultProps = {
  value: '',
  type: '',
  label: '',
};

export default IncludeStatement;
