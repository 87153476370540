import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import formatCurrency from 'Common/Utils/formatCurrency';
import TextArea from 'Common/UI/Form/TextArea';
import FormLabel from 'Common/UI/Form/FormLabel';
import Input from './Input';

type Props = {
  onChange: any;
  currency?: boolean;
  percentage?: boolean;
  mobile?: boolean;
  value?: string | number;
  className?: string;
  onBlur: any;
  placeholder?: string;
  label?: string | JSX.Element;
  onFocus: any;
  rows?: number | undefined;
  name: string;
  type?: any;
  disabled?: boolean;
  inputmode?: string;
};

const Textfield = forwardRef<any, Props>(
  (
    {
      onChange,
      label = '',
      name,
      value = '',
      type = 'text',
      currency = false,
      percentage = false,
      className = '',
      placeholder = '',
      disabled = false,
      onBlur = () => {},
      onFocus = () => {},
      rows = undefined,
      inputmode = '',
      mobile = false,
      ...rest
    },
    ref
  ) => {
    const onChangeAction = (evt: any) => {
      let targetValue = evt.target.value;
      if (currency) {
        targetValue = Number(targetValue.replace(/[^0-9.]+/g, '')).toString();
      }
      if (typeof value === 'string') {
        targetValue = targetValue.trimStart();
      }
      return targetValue;
    };
    return (
      <div className={className}>
        <label htmlFor={name}>
          <FormLabel label={label} />
          {!rows && (
            <Input
              value={currency ? formatCurrency(value, false) : value}
              id={name}
              type={type}
              currency={currency}
              percentage={percentage}
              onChange={evt => onChange(onChangeAction(evt), evt)}
              placeholder={placeholder}
              disabled={disabled}
              onBlur={() => onBlur(name)}
              onFocus={() => onFocus(name)}
              autoFocus
              ref={ref}
              {...rest}
            />
          )}
          {rows && (
            <TextArea
              id={name}
              value={value}
              onChange={evt => onChange(evt.target.value, evt)}
              placeholder={placeholder}
              disabled={disabled}
              onBlur={() => onBlur(name)}
              onFocus={() => onFocus(name)}
              ref={ref}
              {...rest}
            />
          )}
        </label>
      </div>
    );
  }
);

Textfield.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
  currency: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  rows: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

const StyledTextfield = styled(Textfield)`
  label {
    display: flex;
    flex-direction: column;
  }
`;

export default StyledTextfield;
