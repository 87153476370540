import React from 'react';
import styled from 'styled-components';

import { HeadingSpacedContainer } from 'Common/UI/Text/Headings';
import { HighlightedLabel } from 'Common/UI/Text/Label';

const ApprovedWrap = styled(HeadingSpacedContainer)`
  display: flex;
  padding: 13px 0;
  position: relative;
  top: 15px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid transparent;
  margin-bottom: 0;
  &::after {
    position: absolute;
    content: '';
    display: block;
    height: 1px;
    top: 0;
    left: -16px;
    right: -16px;
    background-color: ${props => props.theme.colours.clickableGrey};
  }
`;

const AutoApproveFooter = () => (
  <ApprovedWrap>
    <HighlightedLabel>This section does not require agreement</HighlightedLabel>
  </ApprovedWrap>
);

export default AutoApproveFooter;
