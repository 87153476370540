export default password => {
  const checkPassword = password || '';

  const moreThanEight = checkPassword.length >= 8;
  const oneUpperCase = checkPassword.match(/[A-Z]/) !== null;
  const oneLowerCase = checkPassword.match(/[a-z]/) !== null;
  const oneNumber = checkPassword.match(/[0-9]/) !== null;
  /* eslint no-useless-escape: "no-alert" */
  const oneSpecial =
    checkPassword.match(/[\^$*.\[\]{}()?\-"!@#%&\/\\,><':;|_~`]/) !== null;

  const pass =
    moreThanEight && oneUpperCase && oneLowerCase && oneNumber && oneSpecial;

  return {
    moreThanEight,
    oneUpperCase,
    oneLowerCase,
    oneNumber,
    oneSpecial,
    pass,
  };
};
