import styled from 'styled-components';

import IconClose from 'Common/Assets/images/icon-close-lightgrey.svg';
import RoundButton from './RoundButton';

const CloseButton = styled(RoundButton)`
  background-color: transparent;
  background-image: url('${IconClose}');
  background-repeat: no-repeat;
  color: ${({ theme }) => theme.colours.black};
  position: absolute;
  right: ${({ theme }) => theme.padding.medium}px;
  top: ${({ theme }) => theme.padding.medium}px;
  font-weight: 600;
  z-index: 2;
  min-width: 0;
  font-size: 0;
  width: 40px;
  height: 40px;
  border-width: 0px;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    background-color: ${({ theme }) => theme.colours.clickableGrey};
    background-image: url('${props => props.theme.backIconInline}');
    background-position: ${({ theme }) => theme.padding.medium}px center;
    padding-left: 40px;
    padding-right: ${({ theme }) => theme.padding.medium}px;
    font-size: inherit;
    width: auto;
    height: auto;
    border-radius: 60px;
    border-width: 2px;

    &:hover {
      background-color: ${({ theme }) => theme.colours.clickableGrey};
    }
  }

  span {
    display: none;

    @media ${({ theme }) => theme.sizes.md.breakpoint} {
      display: inline-block;
    }
  }
`;

export default CloseButton;

export const CloseButtonQuestionsHeader = styled(CloseButton)`
  position: absolute;
  top: 12px;
  z-index: 10;
  text-decoration: none;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    top: ${({ theme }) => theme.spacing[16]};
  }

  ${({ theme }) => theme.tokens.closeButtonPosition}
`;
