/**
 * Omit
 * To get an object with properties omitted
 * obj - is the object you want to modify
 * arr - is an arary of properties you want to omit from the object
 * */

const omit = (obj, arr) =>
  Object.keys(obj)
    .filter(k => !arr.includes(k))
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});

export default omit;
