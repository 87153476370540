import React from 'react';
import formatCurrency from 'Common/Utils/formatCurrency';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  checkStartsWithVowel,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';

import Paragraph from 'Common/UI/Text/Paragraph';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import { H1Conversational } from 'Common/UI/Text/Headings';
import { PARTY_A } from 'Common/constants';

export const yourSuperannuationLabel = 'Your Superannuation';

// eslint-disable-next-line import/prefer-default-export
export const yourSuperannuationQuestionsA: QuestionAny[] = [
  {
    name: 'intro',
    heading: () => <H1Conversational>Your superannuation</H1Conversational>,
    content: () => (
      <>
        <Paragraph>
          We&apos;re going to ask you a few questions to help you value your
          superannuation.
        </Paragraph>
        <Paragraph>
          Before you get started, make sure you have the total amount of your
          superannuation balance as at separation.
        </Paragraph>
        <Paragraph>
          For amica one, it&apos;s okay if this is an estimate. You can only add
          your total superannuation amount in amica one.
        </Paragraph>
      </>
    ),
    help: {
      label: 'Learn more',
      content: (
        <>
          <H1Conversational>Learn more</H1Conversational>

          <Paragraph large>
            You can add multiple superannuation accounts in order to see the
            total value of you and your former partner’s superannuation. But if
            you want to create a consent order application using amica, you will
            only be able to do this if your super account is one of these types:
          </Paragraph>
          <Paragraph large>accumulation interest</Paragraph>
          <Paragraph large>partially vested accumulation interest</Paragraph>
          <Paragraph large>defined benefit interest</Paragraph>
          <Paragraph large>
            For more information on superannuation types, see our{' '}
            <ReferralLink href="https://amica.gov.au/info-about-separating/money-and-property">
              Money and Property page
            </ReferralLink>
            . Or contact your local free{' '}
            <ReferralLink href="https://amica.gov.au/help-and-support">
              Legal Help Line
            </ReferralLink>
            .
          </Paragraph>
        </>
      ),
    },
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'haveAnySuperannuation',
    label: 'Do you have superannuation?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 1,
  },
  {
    name: 'superannuationType',
    label: 'What type of superannuation fund do you have?',
    hint: '',
    type: 'select',
    visible: answer => !!answer.haveAnySuperannuation,
    options: () => [
      { label: 'Accumulation interest', value: 'Accumulation interest' },
      {
        label: 'Partially vested accumulation interest',
        value: 'Partially vested accumulation interest',
      },
      { label: 'Defined benefit interest', value: 'Defined benefit interest' },
    ],
    defaultValue: '',
    index: 2,
  },
  {
    name: 'superannuationValue',
    label: 'What is the value of your fund(s)?',
    hint: '',
    type: 'currency',
    defaultValue: '',
    visible: answer => !!answer.haveAnySuperannuation,
    index: 3,
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 4,
  },
];

export const yourSuperannuationStatementA = ({
  matter,
  data,
  card,
  plainText = false,
  withUpdateLink = false,
  hideSensitive = false,
}: Statement) => {
  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'haveAnySuperannuation',
    'superannuationType',
    'superannuationValue',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(
    yourSuperannuationQuestionsA,
    data,
    hideSensitive
  );

  const firstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);

  // Data
  const {
    haveAnySuperannuation,
    superannuationType: superType,
    superannuationValue,
  } = data;

  if (!haveAnySuperannuation) {
    if (plainText) {
      return `${firstName} ${'do not'} have any superannuation.`;
    }
    return highlight`${firstName} ${updated(
      'haveAnySuperannuation',
      redact('superTypeDescription', 'do not')
    )} have any superannuation.`;
  }

  const superTypeDescription = `${superType} superannuation account`;
  const aOrAn = checkStartsWithVowel(superType) ? 'an' : 'a';

  // Currency format
  const superValue = ` ${formatCurrency(
    parseFloat(superannuationValue as string)
  )} `;

  if (plainText) {
    return `${firstName} has ${aOrAn} ${superTypeDescription} valued at ${superValue}.`;
  }

  const highlightedText = highlight`${firstName} has ${aOrAn} ${updated(
    'superannuationType',
    redact('superannuationType', superTypeDescription)
  )} valued at ${updated(
    'superannuationValue',
    redact('superannuationValue', superValue)
  )}.`;

  return highlightedText;
};
