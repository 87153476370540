import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import Drawer from './Drawer';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.flexDrawerBackgroundColour};
  border-radius: 24px 24px 0 0;
  bottom: 0;
  height: auto;
  justify-content: center;
  left: 0;
  min-height: 120px;
  opacity: 0;
  display: none;
  position: absolute;
  right: 0;
  transform: translateY(100%);
  transition: transform 0.2s ease-out;
  z-index: 100;

  ${props =>
    props.transitionState === 'entering' &&
    `
    transform: translateY(100%);
    opacity: 1;
    display: flex;
  `};

  ${props =>
    props.transitionState === 'entered' &&
    `
      transform: none;
      opacity: 1;
      display: flex;
    `}
`;

const StyledDrawer = styled(Drawer)`
  color: ${({ theme }) => theme.textColour};
  border: none;
`;

const FlexDrawer = ({ isVisible, children, className, dataCy }) => (
  <Transition in={isVisible} appear timeout={200}>
    {state => (
      <Wrapper transitionState={state} className={className} data-cy={dataCy}>
        <StyledDrawer>{children}</StyledDrawer>
      </Wrapper>
    )}
  </Transition>
);

FlexDrawer.propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  dataCy: PropTypes.string,
};

FlexDrawer.defaultProps = {
  isVisible: false,
  className: '',
  dataCy: '',
};

export default FlexDrawer;
