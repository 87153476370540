import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Paragraph from 'Common/UI/Text/Paragraph';
import FormLabel from 'Common/UI/Form/FormLabel';
import ListItem from './ListItem';

const defaultValue = { text: '', number: 0 };

const StyledListContainer = styled.div`
  overflow: auto;
  height: 100%;
`;

const StyledListUl = styled.ul`
  margin: 0;
  padding: 0;
`;

const StyledListLi = styled.li`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.padding.small}px;
  position: relative;
  z-index: 1;
`;

const StyledParagraph = styled(Paragraph)`
  margin-top: 0;
`;

const Popup = ({ label, name, className, onChange, value, formAttributes }) => {
  const list = value.length < 1 ? [defaultValue] : value;
  const scrollEl = useRef(null);
  const { hintText, multiLimit } = formAttributes;

  const handleAdd = (event, index) => {
    event.preventDefault();
    const newArr = [...list];

    if (multiLimit && newArr.length >= multiLimit) {
      return;
    }

    newArr.splice(index + 1, 0, defaultValue);
    onChange(newArr);
    // Need to have the container scroll to the bottom on click
    if (scrollEl.current) {
      scrollEl.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
      // Need to time out in order to get new scrollEl height on state change
      setTimeout(() => {
        scrollEl.current.scrollTop = scrollEl.current.scrollHeight;
      }, 100);
    }
  };

  const handleOnChange = (listValue, index) => {
    const newArr = [...list];
    newArr[index] = listValue;
    onChange(newArr);
  };

  const handleRemove = (event, index) => {
    event.preventDefault();
    const newArr = [...list];
    newArr.splice(index, 1);
    onChange(newArr);
  };

  return (
    <div className={className}>
      <label htmlFor={name}>
        <FormLabel label={label} />
        <StyledListContainer ref={scrollEl}>
          {hintText && <StyledParagraph>{hintText}</StyledParagraph>}
          <StyledListUl>
            {list.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <StyledListLi key={index}>
                <ListItem
                  value={item}
                  onRemove={event => handleRemove(event, index)}
                  onChange={changeValue => handleOnChange(changeValue, index)}
                  onAdd={event => handleAdd(event, index)}
                  formAttributes={formAttributes}
                  isLastItem={index === list.length - 1}
                />
              </StyledListLi>
            ))}
          </StyledListUl>
        </StyledListContainer>
      </label>
    </div>
  );
};

Popup.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      number: PropTypes.number,
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  formAttributes: PropTypes.object,
};

Popup.defaultProps = {
  type: 'value',
  className: '',
  value: [defaultValue],
  formAttributes: {},
};

export default Popup;
