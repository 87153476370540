import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const DashboardContext = createContext();

export const DashboardProvider = ({
  appState,
  updateAppState,
  children,
  section,
  tab,
}) => {
  const [openOptionsDrawer, setOpenOptionsDrawer] = useState(false);
  const [optionsForCard, setOptionsForCard] = useState({});
  const [optionsDrawerItems, setOptionsDrawerItems] = useState([]);

  const showOptionsDrawer = (card, options) => {
    setOpenOptionsDrawer(true);
    setOptionsForCard(card);
    setOptionsDrawerItems(options || optionsDrawerItems);
  };

  const closeOptionsDrawer = () => setOpenOptionsDrawer(false);

  return (
    <DashboardContext.Provider
      value={{
        appState,
        updateAppState,
        openOptionsDrawer,
        optionsForCard,
        optionsDrawerItems,
        setOpenOptionsDrawer,
        showOptionsDrawer,
        closeOptionsDrawer,
        section,
        tab,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

DashboardProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const DashboardConsumer = ({ children }) => (
  <DashboardContext.Consumer>{children}</DashboardContext.Consumer>
);

DashboardConsumer.propTypes = {
  children: PropTypes.func.isRequired,
};
