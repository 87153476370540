import RoundButton from 'Common/UI/Button/RoundButton';
import { Theme } from 'Common/Utils/theme';
import styled from 'styled-components';

const FloatingCloseButton = styled(RoundButton)<{
  theme: Theme;
}>`
  z-index: 10;
  position: absolute;
  right: ${({ theme }) => theme.padding.medium}px;
  top: ${({ theme }) => theme.padding.medium}px;
  padding: 5px 20px;
  height: 32px;
  background-color: ${({ theme }) => theme.colours.clickableGrey};
  border-radius: 60px;
  border-width: 2px;
  color: ${({ theme }) => theme.colours.black};
  font-size: 14px;
  text-decoration: none;

  &:hover {
    background-color: ${({ theme }) => theme.colours.clickableGrey};
  }

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    top: ${({ theme }) => theme.padding.xlarge}px;
  }
`;

export default FloatingCloseButton;
