import { DataSection } from 'Common/Data/Types/appSections';
import Matter from 'Common/Data/Types/matter';
import { DATA_PAGE } from 'Common/constants';

export type DashboardTabs = ReturnType<typeof generateDashboardTabs>;

const generateDashboardTabs = ({
  sections,
  matter,
}: {
  sections: DataSection[];
  matter: Matter;
}) =>
  sections.map((section: DataSection) => {
    // Currently only supports one page per dashboard
    const page = section.children.filter(child => child.type === DATA_PAGE)[0];
    const { title, route, generateStatus, id, lockedTooltip } = page;

    return {
      label: title,
      to: route,
      status: generateStatus(matter),
      testId: `${id}-tab`,
      lockedTooltip,
    };
  });

export default generateDashboardTabs;
