import PropTypes from 'prop-types';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { CARD_NORMAL } from 'Common/constants';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Heading3 } from 'Common/UI/Text/Headings';
import { withSpacedBox } from 'Common/UI/Layout/SpacedBox';
import LearnMoreButton from 'Common/UI/Button/LearnMoreButton';
import CheckboxField from 'Common/UI/Form/CheckboxField';
import theme, { cardThemes } from 'Common/Utils/theme';
import CardContext from 'Common/UI/Card/context';

import Button from 'Common/UI/Card/components/Button';

import Statement from 'App/UI/Card/components/Statement';

import MainHeading from 'Common/UI/Card/components/MainHeading';
import HeadingWithSideText from 'Common/UI/Card/components/HeadingWithSideText';
import ApprovedFooter from 'Common/UI/Card/components/ApprovedFooter';
import LatestMessage from 'Common/UI/Card/components/LatestMessage';
import HeadingWithIcon from 'Common/UI/Card/components/HeadingWithIcon';
import AutoApproveFooter from 'Common/UI/Card/components/AutoApproveFooter';

const StyledParagraph = styled(Paragraph)`
  margin-top: ${props => props.theme.padding.xsmall}px;
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundColour};
  background-image: url('${props => props.theme.backgroundImage}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
  border-radius: 4px;
  padding: ${props => props.theme.padding.small}px;
  ${props =>
    props.theme.borderColour &&
    `border: 1px solid ${props.theme.borderColour};`}
  margin-bottom: 16px;
  vertical-align: top;
  width: 100%;
  position: relative;
`;

const StyledHeading = styled(Heading3)`
  margin: 0 0 ${props => props.theme.padding.xsmall}px 0;
`;

const CardLearnMoreButton = styled(LearnMoreButton).attrs(() => ({
  subtle: true,
}))``;

class Card extends React.Component {
  static Heading = withSpacedBox(StyledHeading);

  static HeadingWithSideText = withSpacedBox(HeadingWithSideText);

  static MainHeading = withSpacedBox(MainHeading);

  // used for the `Dividing assets` card - bold heading with icon on the right
  static HeadingWithIcon = withSpacedBox(HeadingWithIcon);

  static Paragraph = withSpacedBox(StyledParagraph);

  static LearnMoreButton = withSpacedBox(CardLearnMoreButton);

  static Button = withSpacedBox(Button);

  static CheckBox = withSpacedBox(CheckboxField);

  static ApprovedFooter = withSpacedBox(ApprovedFooter);

  static AutoApproveFooter = withSpacedBox(AutoApproveFooter);

  static Statement = withSpacedBox(Statement);

  static LatestMessage = withSpacedBox(LatestMessage);

  render() {
    const { cardTheme, children, className, data, active = true } = this.props;
    const { type = CARD_NORMAL, cardID } = data;

    // If active is set to null or if active is true
    const selectedTheme = active ? cardTheme : `${type}Inactive`;

    return (
      <ThemeProvider theme={{ ...theme, ...cardThemes[selectedTheme] }}>
        <StyledCard data-cy="card" data-cy-id={cardID} className={className}>
          <CardContext.Provider value={{ ...data, active }}>
            {children}
          </CardContext.Provider>
        </StyledCard>
      </ThemeProvider>
    );
  }
}

Card.propTypes = {
  data: PropTypes.shape({ type: PropTypes.string, cardID: PropTypes.string }),
  children: PropTypes.node.isRequired,
  cardTheme: PropTypes.string,
  active: PropTypes.bool,
  className: PropTypes.string,
};

Card.defaultProps = {
  data: {},
  cardTheme: null,
  active: true,
  className: '',
};

export default Card;
