import React from 'react';
import GenericButton from 'Common/UI/Button/GenericButton';
import ResponsiveBannerContainer from 'Common/UI/Banners/ResponsiveBannerContainer';
import BannerContainer from 'Common/UI/Banners/BannerContainer';
import { sessionStorageSave } from 'Common/Utils/sessionStorage';
import { SHOW_AMICA_DEMO_BANNER_DISMISSED } from 'Common/constants';

type Props = {
  showBanner: boolean;
  onBannerButtonClick: () => void;
  sessionId?: string;
  buttonText?: string;
  children: JSX.Element;
  dataCy?: string;
};
const DemoBanner: React.FC<Props> = ({
  showBanner,
  onBannerButtonClick,
  sessionId = SHOW_AMICA_DEMO_BANNER_DISMISSED,
  buttonText = 'Got it',
  children,
  dataCy = 'banner-top',
}) => {
  if (!showBanner) return null;

  return (
    <BannerContainer data-cy={dataCy}>
      <ResponsiveBannerContainer>
        {children}
        <GenericButton
          fullWidth
          onClick={() => {
            onBannerButtonClick();
            sessionStorageSave(sessionId, true);
          }}
          small
          data-cy="banner-top-button"
        >
          {buttonText}
        </GenericButton>
      </ResponsiveBannerContainer>
    </BannerContainer>
  );
};

export default DemoBanner;
