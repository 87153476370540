import {
  CARD_AGREEMENT,
  CARD_CONTINUE,
  CARD_CUSTOM,
  CARD_JURISDICTION,
  CARD_NORMAL,
  CARD_STATEMENT,
  CARD_VIEW,
  CARD_YOUR_SUPERANNUATION,
} from 'Common/constants';
import MatterPropsJS from 'Common/Utils/MatterProps';
import { createdByYou } from 'Common/Utils/dashboardHelpers';
import {
  CardAny,
  QuestionCard,
  QuestionStateCard,
  EphemeralStateCard,
  EphemeralCard,
} from 'Common/Data/Types/appSections';
import Matter from 'Common/Data/Types/matter';
import getDate from '../../../Utils/getDate';
import { getLatestUserMessage } from '../../../Utils/getMessages';
import getYouOrThem from '../../../Utils/getYouOrThem';

type Props = {
  card: CardAny;
  matter: Matter;
  navigate: (route: string, state?: any) => void;
  cardOptions: {
    showOptionsDrawer: () => void;
    hasOptions: boolean;
  };
};

const isCardQuestionType = (card: CardAny): card is QuestionCard => {
  const questionTypes = [
    CARD_NORMAL,
    CARD_CUSTOM,
    CARD_CONTINUE,
    CARD_VIEW,
    CARD_JURISDICTION,
    CARD_STATEMENT,
    CARD_AGREEMENT,
    CARD_YOUR_SUPERANNUATION,
  ];

  if (questionTypes.includes(card.type)) {
    return true;
  }

  return false;
};

const generateStateCardData = ({
  card,
  matter,
  navigate,
  cardOptions,
}: Props):
  | QuestionStateCard<QuestionCard>
  | EphemeralStateCard<EphemeralCard> => {
  const { cardID } = card;

  if (isCardQuestionType(card)) {
    const {
      title,
      data: cardData,
      statement,
      questionsRoute,
      viewRoute,
    } = card;

    return {
      ...card,
      otherParty: MatterPropsJS('other.party'),
      otherName: getYouOrThem(MatterPropsJS('other.party')),
      creator: cardData.creator,
      label: title,
      isCreatedByYou: createdByYou(cardData.creator),
      date: getDate(cardData.created).shortFormat(),
      getLatestUserMessage: () => getLatestUserMessage(matter.messages, cardID),
      navToQuestions: () => navigate(questionsRoute || ''),
      navToView: () => navigate(viewRoute),
      ...(statement && {
        statement: () =>
          statement({
            data: cardData,
            matter,
            card,
          }),
      }),
      ...('summaryComponentGenerator' in card && {
        summaryComponent: (props: { isActive: boolean }) =>
          card.summaryComponentGenerator({ values: cardData, ...props }),
      }),
      ...(cardOptions && cardOptions),
    };
  }

  let navToRoute;

  if ('buttonRoute' in card) {
    navToRoute = () => navigate(card.buttonRoute);
  }

  if ('title' in card) {
    const { title = '' } = card;

    return {
      ...card,
      otherName: getYouOrThem(MatterPropsJS('other.party')),
      label: title,
      navToRoute,
      ...(cardOptions && cardOptions),
    };
  }

  return {
    ...card,
    otherName: getYouOrThem(MatterPropsJS('other.party')),
    navToRoute,
    ...(cardOptions && cardOptions),
  };
};

export default generateStateCardData;
