import {
  STATUS_NEW_BOTH_PARTIES,
  DATA_SECTION,
  DATA_GROUP,
  CARD_NORMAL,
  SECTION_PROPERTY,
  DATA_PAGE,
  PAGE_PROPERTY_MAIN,
  STATUS_NEW_ONLY_SELF,
  STATUS_NEW_ONLY_THEM,
  SECTION_FINANCES,
  SECTION_RELATIONSHIP,
} from 'Common/constants';
import MatterPropsJS from 'Common/Utils/MatterProps';
import {
  ODRS_PROPERTIES_ROUTE,
  ODRS_DASH_PROPERTY_ROUTE,
  ODRS_BASE_ROUTE,
  ODRS_VEHICLES_ROUTE,
  ODRS_SUA_YOURSUPERANNUATION_SELF_ROUTE,
  ODRS_SUA_YOURSUPERANNUATION_OTHER_ROUTE,
  ODRS_SUA_YOURCONTRIBUTIONS_SELF_ROUTE,
  ODRS_SUA_YOURCONTRIBUTIONS_OTHER_ROUTE,
} from 'Common/routes';
import { getNextAvailableIndex } from 'Common/Utils/getNextAvailableIndex';
import { getNavItemStatus } from 'Common/UI/Navigation';
import IconProperty from 'Common/Assets/images/icon-property.svg';
import BackgroundQuestionFlowProperty from 'Common/Assets/images/demo-header.svg';
import colours from 'Common/Utils/colours';
import {
  getPageStatus,
  hasSectionBeenCompleted,
} from 'App/Utils/sectionHelpers';
import { history, reduxStore } from 'App/State/Store';
import {
  CardNormal,
  DataSection,
  CardIndex,
} from 'Common/Data/Types/appSections';
import { Item } from 'Common/Data/Types/matter';
import IconVehicles from 'Common/Assets/images/icon-vehicles.svg';
import IconSuperannuation from 'Common/Assets/images/icon-superannuation.svg';
import IconContributions from 'Common/Assets/images/icon-contributions.svg';
import {
  addPropertyAction,
  addVehicleAction,
  setPartyFlagsAction,
  // eslint-disable-next-line import/no-relative-packages
} from '../../../../singleuser/frontend/src/app/State/MatterActions';

import {
  propertiesQuestions,
  propertiesStatement,
  vehiclesQuestions,
  vehiclesStatement,
  yourSuperannuationQuestionsA,
  yourSuperannuationStatementA,
  yourSuperannuationQuestionsB,
  yourSuperannuationStatementB,
  contributionsQuestionsA,
  contributionsStatementA,
  contributionsQuestionsB,
  contributionsStatementB,
} from '../Questions/Property';
import { buildCard } from '../buildCard';

const generateProperty = ({
  existingProperties = [],
  existingVehicles = [],
}: {
  existingProperties: Item[];
  existingVehicles: Item[];
}): DataSection => {
  const generateStatus = () => {
    const { completed } = getPageStatus({
      section: SECTION_PROPERTY,
      page: PAGE_PROPERTY_MAIN,
    });

    const { app } = reduxStore.getState();

    let isLocked = app.flags.isDemoComplete;
    if (!isLocked) {
      isLocked = !(
        hasSectionBeenCompleted({ section: SECTION_RELATIONSHIP }) &&
        hasSectionBeenCompleted({ section: SECTION_FINANCES })
      );
    }

    return getNavItemStatus({
      isCompleted: completed,
      isLocked,
    });
  };
  return {
    type: DATA_SECTION,
    id: SECTION_PROPERTY,
    title: 'Property?',
    route: ODRS_DASH_PROPERTY_ROUTE,
    questionFlowBackgroundImage: BackgroundQuestionFlowProperty,
    questionFlowBackgroundColour: colours.offWhite,
    children: [
      {
        type: DATA_PAGE,
        id: PAGE_PROPERTY_MAIN,
        title: 'Property',
        route: ODRS_DASH_PROPERTY_ROUTE,
        subTitle: 'Property',
        description:
          'You will need to create and agree on statements about the different types of money and property you and your former partner owned or shared.',
        generateStatus,
        children: [
          {
            type: DATA_GROUP,
            id: 'properties',
            title: 'Property',
            description:
              'You will need to add any properties that you solely or jointly own.',
            icon: IconProperty,
            isRepeatable: true,
            isIndividual: false,
            singular: 'property',
            plural: 'properties',
            canAddCard: !MatterPropsJS('self.hasNoItemsProperties'),
            promptCardData: {
              hasNoneState: true,
              initial: {
                title: 'Do you own any property?',
                content:
                  'You will need to add any property that you and your former partner jointly and/or solely own.',
                yesButton: 'Yes, we do',
                noButton: "We don't own property",
                onClickYes: async () => {
                  const { dispatch } = reduxStore;

                  await dispatch(addPropertyAction());

                  history.push(`${ODRS_BASE_ROUTE}/properties/1`, {
                    previous: reduxStore.getState().router.location.pathname,
                  });
                },
                onClickNo: async () => {
                  const { dispatch } = reduxStore;

                  await dispatch(
                    setPartyFlagsAction({ hasNoItemsProperties: true })
                  );
                },
              },
              none: {
                title: 'You do not have any property',
                content:
                  'You have selected that you and your former partner do not have any property.',
                onClickEdit: async () => {
                  const { dispatch } = reduxStore;

                  await dispatch(
                    setPartyFlagsAction({ hasNoItemsProperties: false })
                  );
                },
              },
            },
            defaultRoute: `${ODRS_PROPERTIES_ROUTE}`,
            children: (() => {
              /** See notes above about children card generation */
              const takenIndexes: number[] = [];

              const items = MatterPropsJS('items');
              const setup =
                items.find((item: Item) => item.SectionID === 'setup') || {};

              return Array.from(
                Array(setup.numProperties || 0),
                (_el, arrayIndex) => {
                  const item = existingProperties[arrayIndex] || {};
                  let index: CardIndex;
                  let displayIndex;
                  let cardID;

                  if (item.SectionID) {
                    index = item.CardIndex;
                    displayIndex = index;
                    cardID = item.SectionID;
                  } else {
                    index = getNextAvailableIndex(takenIndexes);
                    displayIndex = arrayIndex + 1;
                    cardID = `properties${index}`;
                  }

                  takenIndexes.push(Number(index));

                  return buildCard<CardNormal>({
                    type: CARD_NORMAL,
                    baseID: 'properties',
                    cardID,
                    title: item.propertyAddress || `Property ${displayIndex}`,
                    sensitiveTitle: `Property ${displayIndex}`,
                    questions: propertiesQuestions,
                    statement: propertiesStatement,
                    index,
                    isRepeatable: true,
                    questionsRoute: `${ODRS_PROPERTIES_ROUTE}/${index}`,
                    updateRoute: `${ODRS_PROPERTIES_ROUTE}/${index}/update`,
                    dashboardRoute: ODRS_DASH_PROPERTY_ROUTE,
                    status: STATUS_NEW_BOTH_PARTIES,
                    autoApprove: true,
                    singleUserApproved: true,
                  });
                }
              );
            })(),
          },
          {
            type: DATA_GROUP,
            id: 'vehicles',
            title: 'Vehicles',
            description:
              'You will need to add any vehicles that you solely or jointly own.',
            icon: IconVehicles,
            isRepeatable: true,
            isIndividual: false,
            canAddCard: !MatterPropsJS('self.hasNoItemsVehicles'),
            promptCardData: {
              hasNoneState: true,
              initial: {
                title: 'Do you own any vehicles?',
                content:
                  'You will need to add any vehicles that you and your former partner jointly and/or solely own.',
                yesButton: 'Yes, we do',
                noButton: "We don't own a vehicle",
                onClickYes: async () => {
                  const { dispatch } = reduxStore;

                  await dispatch(addVehicleAction());

                  history.push(`${ODRS_BASE_ROUTE}/vehicles/1`, {
                    previous: reduxStore.getState().router.location.pathname,
                  });
                },
                onClickNo: async () => {
                  const { dispatch } = reduxStore;

                  await dispatch(
                    setPartyFlagsAction({ hasNoItemsVehicles: true })
                  );
                },
              },
              none: {
                title: 'You do not have any vehicles',
                content:
                  'You have selected that you and your former partner do not have any vehicles.',
                onClickEdit: async () => {
                  const { dispatch } = reduxStore;

                  await dispatch(
                    setPartyFlagsAction({ hasNoItemsVehicles: false })
                  );
                },
              },
            },
            singular: 'vehicle',
            plural: 'vehicles',
            defaultRoute: `${ODRS_VEHICLES_ROUTE}`,

            children: (() => {
              /** See notes above about children card generation */
              const takenIndexes: number[] = [];

              const items = MatterPropsJS('items');
              const setup =
                items.find((item: Item) => item.SectionID === 'setup') || {};

              return Array.from(
                Array(setup.numVehicles || 0),
                (_el, arrayIndex) => {
                  const item = existingVehicles[arrayIndex] || {};
                  let index: CardIndex;
                  let displayIndex;
                  let cardID;

                  if (item.SectionID) {
                    index = item.CardIndex;
                    displayIndex = index;
                    cardID = item.SectionID;
                  } else {
                    index = getNextAvailableIndex(takenIndexes);
                    displayIndex = arrayIndex + 1;
                    cardID = `vehicles${index}`;
                  }

                  takenIndexes.push(Number(index));
                  return buildCard<CardNormal>({
                    type: CARD_NORMAL,
                    baseID: 'vehicles',
                    cardID,
                    title: item.vehicleMakeModel || `Vehicle ${displayIndex}`,
                    sensitiveTitle: `Vehicle ${displayIndex}`,
                    questions: vehiclesQuestions,
                    statement: vehiclesStatement,
                    index,
                    isRepeatable: true,
                    questionsRoute: `${ODRS_VEHICLES_ROUTE}/${index}`,
                    updateRoute: `${ODRS_VEHICLES_ROUTE}/${index}/update`,
                    dashboardRoute: ODRS_DASH_PROPERTY_ROUTE,
                    status: STATUS_NEW_BOTH_PARTIES,
                    autoApprove: true,
                    singleUserApproved: true,
                  });
                }
              );
            })(),
          },
          {
            type: DATA_GROUP,
            id: 'superannuation',
            title: 'Superannuation',
            icon: IconSuperannuation,
            description:
              'We are going to ask you a few questions to help you value your superannuation. Before you get started, make sure you have the amount of your superannuation balance.',
            children: [
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'yoursuperannuation',
                cardID: `yoursuperannuation${MatterPropsJS('self.party', 'A')}`,
                title: 'Your superannuation',
                sensitiveTitle: `Party ${MatterPropsJS(
                  'self.party',
                  'A'
                )}'s Superannuation`,
                questions: yourSuperannuationQuestionsA,
                statement: yourSuperannuationStatementA,
                index: MatterPropsJS('self.party', 'A'),
                questionsRoute: ODRS_SUA_YOURSUPERANNUATION_SELF_ROUTE,
                updateRoute: `${ODRS_SUA_YOURSUPERANNUATION_SELF_ROUTE}/update`,
                dashboardRoute: ODRS_DASH_PROPERTY_ROUTE,
                status: STATUS_NEW_ONLY_SELF,
                autoApprove: true,
                singleUserApproved: true,
              }),
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'yoursuperannuation',
                cardID: `yoursuperannuation${MatterPropsJS(
                  'other.party',
                  'B'
                )}`,
                title: "Your former partner's superannuation",
                sensitiveTitle: `Party ${MatterPropsJS(
                  'other.party',
                  'B'
                )}'s Supperannuation`,
                questions: yourSuperannuationQuestionsB,
                statement: yourSuperannuationStatementB,
                index: MatterPropsJS('other.party', 'B'),
                questionsRoute: ODRS_SUA_YOURSUPERANNUATION_OTHER_ROUTE,
                updateRoute: `${ODRS_SUA_YOURSUPERANNUATION_OTHER_ROUTE}/update`,
                dashboardRoute: ODRS_DASH_PROPERTY_ROUTE,
                status: STATUS_NEW_ONLY_THEM,
                autoApprove: true,
                singleUserApproved: true,
              }),
            ],
          },
          {
            type: DATA_GROUP,
            id: 'contributions2',
            title: 'Contributions',
            icon: IconContributions,
            description:
              'We are going to ask some questions about what contributions were made by each of you throughout the relationship.',
            children: [
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'contributions',
                cardID: `contributions${MatterPropsJS('self.party', 'A')}`,
                title: 'Your contributions',
                sensitiveTitle: `Party ${MatterPropsJS(
                  'self.party',
                  'A'
                )}'s Contributions`,
                questions: contributionsQuestionsA,
                statement: contributionsStatementA,
                index: MatterPropsJS('self.party', 'A'),
                questionsRoute: ODRS_SUA_YOURCONTRIBUTIONS_SELF_ROUTE,
                updateRoute: `${ODRS_SUA_YOURCONTRIBUTIONS_SELF_ROUTE}/update`,
                dashboardRoute: ODRS_DASH_PROPERTY_ROUTE,
                status: STATUS_NEW_ONLY_SELF,
                autoApprove: true,
                singleUserApproved: true,
              }),
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'contributions',
                cardID: `contributions${MatterPropsJS('other.party', 'B')}`,
                title: "Your former partner's contributions",
                sensitiveTitle: `Party ${MatterPropsJS(
                  'other.party',
                  'B'
                )}'s Contributions`,
                questions: contributionsQuestionsB,
                statement: contributionsStatementB,
                index: MatterPropsJS('other.party', 'B'),
                questionsRoute: ODRS_SUA_YOURCONTRIBUTIONS_OTHER_ROUTE,
                updateRoute: `${ODRS_SUA_YOURCONTRIBUTIONS_OTHER_ROUTE}/update`,
                dashboardRoute: ODRS_DASH_PROPERTY_ROUTE,
                status: STATUS_NEW_ONLY_THEM,
                autoApprove: true,
                singleUserApproved: true,
              }),
            ],
          },
        ],
      },
    ],
  };
};

export default generateProperty;
