import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring/web.cjs';
import theme from 'Common/Utils/theme';

const Wrapper = styled.div`
  position: relative;
  max-width: 100%;
`;

const Svg = styled.svg`
  display: block;
  max-width: 100%;
  border-radius: 20px;
`;

const Indicator = React.forwardRef(
  (
    {
      primary,
      secondary,
      baseColour,
      indicatorColour,
      secondaryIndicatorColour,
      animate,
      children,
      size,
      dataCy,
      ...rest
    },
    ref
  ) => {
    const width = window.innerWidth - 54;
    const padding = size > 20 ? 12 : 6;
    const { primaryDash, secondaryDash } = useSpring({
      from: {
        primaryDash: width,
        secondaryDash: width,
      },
      primaryDash: width - primary * width || 0,
      secondaryDash: width - secondary * width || 0,
      config: {
        immediate: !animate,
        duration: animate ? 800 : 0,
        // easing is linear by default
      },
    });

    return (
      <Wrapper ref={ref} {...rest} data-cy={dataCy}>
        <Svg
          width={width}
          height={size}
          viewBox={`0 0 ${width} ${size}`}
          fill="none"
        >
          <line
            x1={padding}
            y1={size / 2}
            x2={width - padding}
            y2={size / 2}
            stroke={baseColour}
            strokeWidth={size}
            strokeLinecap="round"
          />
          <>
            <animated.line
              x1={padding}
              y1={size / 2}
              x2={width}
              y2={size / 2}
              stroke={secondaryIndicatorColour}
              strokeWidth={size}
              strokeLinecap="round"
              strokeDasharray={width}
              strokeDashoffset={secondaryDash}
            />
            <animated.line
              x1={padding}
              y1={size / 2}
              x2={width}
              y2={size / 2}
              stroke={indicatorColour}
              strokeWidth={secondary ? size - 8 : size}
              strokeLinecap="round"
              strokeDasharray={`${width} ${width}`}
              strokeDashoffset={primaryDash}
            />
          </>
        </Svg>
        {children}
      </Wrapper>
    );
  }
);

Indicator.propTypes = {
  primary: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  secondary: PropTypes.number,
  baseColour: PropTypes.string,
  indicatorColour: PropTypes.string,
  indicatorTextColour: PropTypes.string,
  baseStrokeWidth: PropTypes.number,
  secondaryIndicatorColour: PropTypes.string,
  animate: PropTypes.bool,
  width: PropTypes.number,
  size: PropTypes.number,
  children: PropTypes.node,
  dataCy: PropTypes.string,
};

Indicator.defaultProps = {
  primary: 0,
  secondary: 0,
  baseColour: theme.colours.transparentGray20,
  baseStrokeWidth: 16,
  indicatorColour: theme.colours.midDarkOrange,
  indicatorTextColour: theme.colours.orange,
  secondaryIndicatorColour: theme.colours.lightOrange,
  animate: false,
  width: 320,
  size: 12,
  children: undefined,
  dataCy: undefined,
};

export default Indicator;
