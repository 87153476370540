import React from 'react';
import { useLocation } from 'react-router-dom';

import { getPage } from 'Common/Data/SingleUser/appSections';
import {
  CARD_NORMAL,
  SECTION_FINANCES,
  PAGE_FINANCES_MAIN,
} from 'Common/constants';
import { ODRS_DASH_RELATIONSHIP_ROUTE } from 'Common/routes';
import DashboardContent from 'App/UI/Dashboard/common/DashboardContent';
import { generateNormalCard } from 'App/UI/Dashboard/StateCards';
import { DashboardPageProps } from 'Common/Data/Types/types';
import { CardNormal, QuestionStateCard } from 'Common/Data/Types/appSections';

const Main: React.FC<DashboardPageProps> = ({
  matter,
  navigate,
  handleAddItemById,
}) => {
  const location = useLocation();

  const customNavigate = (route: string) =>
    navigate(route, {
      backRoute: ODRS_DASH_RELATIONSHIP_ROUTE,
      location,
    });

  const pageData = getPage(SECTION_FINANCES, PAGE_FINANCES_MAIN);

  return (
    <DashboardContent
      pageData={pageData}
      navigate={customNavigate}
      matter={matter}
      cardHandlers={{
        [CARD_NORMAL]: data =>
          generateNormalCard(data as QuestionStateCard<CardNormal>, matter),
      }}
      handleAddItemById={handleAddItemById}
    />
  );
};

export default Main;
