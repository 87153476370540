import React from 'react';
import styled from 'styled-components';
import GenericButton from './GenericButton';

const IconMore = () => (
  <svg
    width="26"
    height="21"
    viewBox="0 0 26 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(0 -3)">
      <circle
        cx="23"
        cy="3"
        r="3"
        transform="rotate(-180 23 3)"
        fill="currentColor"
      />
      <circle
        cx="13"
        cy="3"
        r="3"
        transform="rotate(-180 13 3)"
        fill="currentColor"
      />
      <circle
        cx="3"
        cy="3"
        r="3"
        transform="rotate(-180 3 3)"
        fill="currentColor"
      />
    </g>
  </svg>
);

const MoreButton = ({ customIcon: CustomIcon, ...props }) => (
  <GenericButton data-cy="button-card-options" type="button" {...props}>
    {CustomIcon ? <CustomIcon alt="More actions" /> : <IconMore />}
  </GenericButton>
);

const MoreButtonStyled = styled(MoreButton)`
  background-color: transparent;
  padding: 0;
  align-self: flex-start;
  height: 32px;
  overflow: hidden;
  color: ${({ theme }) => theme.colours.midLightGrey};
  transition: color 0.2s;

  &:hover {
    background: transparent;
    color: ${({ theme }) => theme.colours.midDarkGrey};
    border-color: transparent;
  }
`;

export default MoreButtonStyled;
