import React from 'react';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import { UList } from 'Common/UI/Text/List';
import LearnMoreButton from 'Common/UI/Button/LearnMoreButton';
import HelpButton from 'Common/UI/Button/HelpButton';

const HelpContent = (
  <>
    <H1Conversational>
      What does &apos;separated couple&apos; mean?
    </H1Conversational>
    <Paragraph>
      A separated couple is one that was either married or in a de facto
      relationship, which has some of the same legal status of a marriage. To
      decide whether you were in a de facto relationship, courts want to know
      whether you were together on a ‘genuine domestic basis’, which is when any
      of these statements are true:
    </Paragraph>
    <UList>
      <li>
          You were in a relationship for at least 2 years
      </li>
      <li>
          You had a child in the relationship.
      </li>
      <li>
          The relationship was registered under law in a State or Territory of
          Australia.
      </li>
      <li>
          You or your former partner made significant contributions and if this
        contribution were not recognised a serious injustice would result.
      </li>
    </UList>

    <Paragraph>
      You will also need to have lived in Australia for at least part of your
      relationship, or live here now.
    </Paragraph>
  </>
);

const WhatDoesSeparatedCoupleMeanHelpButton: React.FC = () => (
    <LearnMoreButton
      ui={HelpButton}
      popUpContent={HelpContent}
      className="step-transition-fade-down"
    >
      What does &apos;separated couple&apos; mean?
    </LearnMoreButton>
  );

export default WhatDoesSeparatedCoupleMeanHelpButton;
