import React from 'react';
import moment, { MomentInput } from 'moment';

import {
  DATE_FORMAT_LONG,
  DEFAULT_OTHER_TEXT,
  PARTY_A,
  PARTY_B,
} from 'Common/constants';

import MatterPropsJS from 'Common/Utils/MatterProps';

import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational, H2Conversational } from 'Common/UI/Text/Headings';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
  getSingleUserFullName,
} from 'Common/Utils/statementHelpers';

import isDate from 'Common/Utils/isDate';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import { Statement, QuestionAny } from 'Common/Data/Types/appSections';
import Matter from 'Common/Data/Types/matter';
import HeadingLinkText from 'Common/UI/Text/HeadingLinkText';

export const relationshipLabel = 'Relationship';

export const relationshipQuestions: QuestionAny[] = [
  {
    name: 'intro',
    heading: (matter: Matter) => (
      <H1Conversational>
        Hi {getSingleUserFullName(matter)}, we’re going to ask you about your
        relationship.
      </H1Conversational>
    ),
    content: () => (
      <Paragraph>
        These questions are important because we will base our suggestions for a
        fair division of your money and property on the information you give us.
      </Paragraph>
    ),
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'uniondate',
    label: () =>
      `When did you and ${MatterPropsJS(
        'other.firstname',
        DEFAULT_OTHER_TEXT
      )} start living together?`,
    hint: '',
    type: 'date',
    defaultValue: '',
    index: 1,
    validate: value =>
      isDate(value) && !isDate(value, { inPast: true })
        ? [true]
        : [false, 'Please enter a valid date'],
    formAttributes: { max: new Date() },
    help: {
      label: 'Why are you asking?',
      content: (
        <>
          <H1Conversational>Why are you asking?</H1Conversational>
          <Paragraph large>
            This is important because how long you lived together may affect how
            you will share your money and property. It is okay if you do not
            know the exact date but you will need to have the month and year.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'separationdate',
    label: () =>
      `What date did you and ${MatterPropsJS('other.firstname')} separate?`,
    hint: '',
    type: 'date',
    defaultValue: '',
    index: 2,
    validate: (value, { uniondate } = {}) =>
      isDate(value, { after: uniondate as MomentInput })
        ? [true]
        : [
            false,
            `Please enter a valid date after ${
              moment(uniondate as MomentInput).isValid()
                ? moment(uniondate as MomentInput).format(DATE_FORMAT_LONG)
                : ''
            }`,
          ],
    formAttributes: { max: new Date() },
    help: {
      label: 'What counts as a date of separation?',
      content: (
        <>
          <H1Conversational>
            What counts as a date of separation?
          </H1Conversational>
          <Paragraph large>
            This might be the date you or your former partner moved out, the
            date you told your friends and family you had broken up or any other
            date you and your former partner agree on. In some instances, you
            can be separated and still living under the same roof. If you are
            unsure whether you are separated, see the{' '}
            <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
              <HeadingLinkText>Help and Support page</HeadingLinkText>
            </ReferralLink>
            .
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'info1',
    label:
      "If you've been separated for more than 2 years and were not married, you will need to apply for an extension when you file your consent order. At the moment, amica cannot help you with this so you will need to seek legal advice. If you were married, you can still continue.",
    hint: '',
    type: 'info',
    defaultValue: '',
    index: 3,
    visible: answers => isDate(answers.separationdate, { atLeast: 2 }),
  },
  {
    name: 'married',
    label: 'Were you married?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 4,
  },
  {
    name: 'marriedDate',
    label: 'What date did you get married?',
    hint: '',
    type: 'date',
    visible: answers => !!answers.married,
    defaultValue: '',
    index: 5,
    formAttributes: { max: new Date() },
  },
  {
    name: 'divorced',
    label: 'Okay. And have you divorced?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 6,
    visible: answers => !!answers.married,
    help: {
      label: 'Why is this important?',
      content: (
        <>
          <H1Conversational>Why is this important?</H1Conversational>
          <Paragraph large>
            You can finalise your property settlement regardless of whether you
            have divorced or not.
          </Paragraph>

          <Paragraph large>
            <strong>
              Important: Once your divorce is finalised, you have 12 months
              within which you are eligible to apply to the Federal Circuit and
              Family Court of Australia to resolve your property settlement.
            </strong>
          </Paragraph>

          <Paragraph large>
            If you live in Western Australia, you can apply to the Family Court
            of WA to resolve your property settlement.
          </Paragraph>

          <Paragraph large>
            If you do not apply within 12 months, you will have to seek leave
            from the court to apply for financial orders out of time. You may
            lose the right to apply for a property settlement and may not
            receive what you are entitled to under the Family Law Act 1975.
          </Paragraph>

          <H2Conversational>
            Using amica when you are already divorced
          </H2Conversational>

          <Paragraph large>
            If you are already divorced, you should only use amica if:
          </Paragraph>

          <ul>
            <li>
              there are at least 3 months remaining on the 12 month time limit
              mentioned above, and
            </li>
            <li>
              you are confident that you and your ex-partner can use amica to
              fairly divide your money and property before the 12 month time
              limit expires.
            </li>
          </ul>

          <Paragraph large>
            Still not sure? See the{' '}
            <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
              <HeadingLinkText>Help and Support page</HeadingLinkText>
            </ReferralLink>
            .
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'divorcedate',
    label: () =>
      `When did you and ${MatterPropsJS(
        'other.firstname'
      )} finalise your divorce?`,
    hint: '',
    type: 'date',
    defaultValue: '',
    index: 7,
    visible: answers => !!answers.divorced,
    validate: (value, { uniondate } = {}) =>
      isDate(value, { after: uniondate as MomentInput })
        ? [true]
        : [
            false,
            `Please enter a valid date after ${
              moment(uniondate as MomentInput).isValid()
                ? moment(uniondate as MomentInput).format(DATE_FORMAT_LONG)
                : ''
            }`,
          ],
    formAttributes: { max: new Date() },
  },
  {
    name: 'b1',
    label:
      'As you divorced more than 9 months ago, you will likely need to apply for an extension of time before you can file your application for consent orders. At the moment, amica cannot help you with this so you will need to seek legal advice.',
    hint: '',
    type: 'blocker',
    defaultValue: '',
    index: 8,
    visible: answers => isDate(answers.divorcedate, { atLeastMonths: 9 }),
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 9,
  },
];

export const relationshipStatement = ({
  matter,
  data,
  card,
  withUpdateLink = false,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'uniondate',
    'separationdate',
    'marriedDate',
    'divorcedate',
    'married',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(relationshipQuestions, data, hideSensitive);

  const partyNames = {
    A: getFirstName(matter.partyA, PARTY_A, hideSensitive),
    B: getFirstName(matter.partyB, PARTY_B, hideSensitive),
  };

  const relationshipPeriod = highlight`${partyNames.A} and ${
    partyNames.B
  } started living together on ${updated(
    'uniondate',
    redact('uniondate', moment(data.uniondate).format(DATE_FORMAT_LONG))
  )} 
    and separated on ${updated(
      'separationdate',
      moment(data.separationdate).format(DATE_FORMAT_LONG)
    )}.`;

  let married;

  const hasMarried = data.married && data.marriedDate;
  const hasDivorced = data.divorced && data.divorcedate;
  if (hasMarried) {
    const marriedDate = updated(
      'marriedDate',
      redact('marriedDate', moment(data.marriedDate).format(DATE_FORMAT_LONG))
    );
    if (hasDivorced) {
      const divorcedDate = updated(
        'divorcedate',
        redact('divorcedate', moment(data.divorcedate).format(DATE_FORMAT_LONG))
      );
      married = highlight`You married on ${marriedDate}. You finalised your divorce on ${divorcedDate}.`;
    } else {
      married = highlight`You married on ${marriedDate}. You have not finalised your divorce.`;
    }
  } else {
    married = highlight`You are ${updated('married', 'not')} married.`;
  }

  if (plainText) {
    return `${relationshipPeriod} ${married}`;
  }

  return (
    <>
      {relationshipPeriod} {married}
    </>
  );
};
