import { useState } from 'react';

const useForm = (callback: () => void) => {
  const [inputs, setInputs] = useState<Record<string, unknown>>({});
  const handleSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    callback();
  };
  const handleInputChange = (value: unknown, event: any) => {
    event.persist();
    setInputs(i => ({ ...i, [event.target.id]: value }));
  };
  const updateInputValue = (name: string, value: unknown) => {
    setInputs(i => ({ ...i, [name]: value }));
  };
  return {
    handleSubmit,
    handleInputChange,
    updateInputValue,
    inputs,
  };
};

export default useForm;
