export type Colours = {
  // purples
  purple: string;
  darkPurple: string;
  midPurple: string;
  midDarkPurple: string;
  midLightPurple: string;
  midLighterPurple: string;
  lighterPurple: string;
  pastelPurple: string;
  lightPurple: string;

  // aquas
  aqua: string;
  darkAqua: string;
  mediumAqua: string;
  lightAqua: string;
  lightestGreen: string;
  lightBlue: string;
  midTeal: string;
  darkTeal: string;
  midDarkGreen: string;
  highlightAqua: string;

  // reds
  redLight: string;

  // oranges
  orange: string;
  darkOrange: string;
  midDarkOrange: string;
  lightOrange: string;
  lightOrange2: string;
  beige: string;
  red: string;

  // greys
  black: string;
  darkerBlack: string;
  darkGrey: string;
  midDarkGrey: string;
  mediumGrey: string;
  midLightGrey: string;
  lightGrey: string;
  inactiveGrey: string;
  backgroundGrey: string;
  clickableGrey: string;
  offWhite: string;
  white: string;
  lightestGrey: string;
  lightesterGrey: string;

  // transparent
  transparentGray20: string;
  transparentGray10: string;
  transparent: string;
};

export default {
  // purples
  purple: '#393189',
  darkPurple: '#16133D',
  midPurple: '#665CD0',
  midDarkPurple: '#27235B',
  midLightPurple: '#8E86E2', // aka purple
  midLighterPurple: '#847BDD',
  lighterPurple: '#CCC7F5', // 150 - pastel purple
  pastelPurple: '#DEDEF5', // 100 - pastel purple
  lightPurple: '#B8B3E7',

  // aquas
  aqua: '#92D2D6', // aka MediumTeal
  darkAqua: '#036268', // aka darkTeal
  mediumAqua: '#74BABE', // aka midDarkTeal
  lightAqua: '#C2E7EA', // aka LightTeal
  lightestGreen: '#DCEDEE', // 100/Pastel teal
  lightBlue: '#92D2D6', // aka light teal --consent orders
  midTeal: '#26B0B8',
  darkTeal: '#036268', // aka Medium teal --consent orders
  midDarkGreen: '#004246',
  highlightAqua: '#E1EFF0',

  // reds
  redLight: '#F6CBC5',

  // oranges
  orange: '#F1BF91',
  darkOrange: '#B26B2A',
  midDarkOrange: '#DFAA79',
  lightOrange: '#F5DBC2',
  lightOrange2: '#FFFBF8',
  beige: '#F5F2EC',
  red: '#EE988C',

  // greys
  darkerBlack: '#181823', // Neutrals/900
  black: '#21202C', // Neutrals/800, Text/Default, Black
  darkGrey: '#41404B', // Neutrals/600
  midDarkGrey: '#5B596B', // Neutrals/500
  mediumGrey: '#7B798E', // Neutrals/400
  midLightGrey: '#ACABBB', // Neutrals/300
  inactiveGrey: '#D0D0DA', // Neutrals/200
  clickableGrey: '#E4E4F0', // Neutrals/150
  lightGrey: '#EAEAF0', // Neutrals/100
  backgroundGrey: '#F0F2F5', // Neutrals/50
  offWhite: '#F7F7FC', // Neutrals/25, Surface/Subdued
  white: '#FFFFFF', // Neutrals/White
  lightestGrey: '#F1F0F6',
  lightesterGrey: '#F5F5F7',

  // transparent
  transparentGray20: 'rgba(192, 192, 197, 0.2)',
  transparentGray10: 'rgba(192, 192, 197, 0.1)',
  transparent: 'transparent',
} as Colours;
