import React from 'react';
import { Column, Row } from 'Common/UI/Layout/Flex';
import { BigNumber } from 'Common/UI/Text/Headings';
import styled from 'styled-components';
import Paragraph from 'Common/UI/Text/Paragraph';
import Theme from 'Common/Utils/theme';
import formatCurrency from 'Common/Utils/formatCurrency';
import Indicator from './Indicator';

const StyledRow = styled(Row)`
  display: inline-flex;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.textColour};
  width: 100%;
`;

const StyledDivisionSlider = styled.div`
  background-color: ${({ theme }) => theme.colours.white};
  padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[6]}`};
  border-radius: 20px;
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`;

const StyledSmallHeading = styled.h4`
  margin-top: ${({ theme }) => theme.spacing[2]};
  margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

const StyledBigNumber = styled(BigNumber)`
  margin-bottom: 0;
`;

const DivisionSlider = ({ lower, upper, tap }) => (
  <StyledDivisionSlider>
    <Column alignItems="flex-start">
      <StyledBigNumber margin="0" data-cy="suggestedDivisionRange">
        {Math.round(lower * 100)}% - {Math.round(upper * 100)}%
      </StyledBigNumber>
      <StyledSmallHeading>of the total asset pool</StyledSmallHeading>
      <StyledRow justifyContent="center" margin="24px 0">
        <Indicator
          indicatorColour={Theme.colours.purple}
          baseColour={Theme.colours.pastelPurple}
          size={30}
          primary={upper}
          secondary={0}
        />
      </StyledRow>
      <Paragraph>
        Total asset pool:{' '}
        <span data-cy="indicator-tap">{formatCurrency(tap)}</span>
      </Paragraph>
    </Column>
  </StyledDivisionSlider>
);

export default DivisionSlider;
