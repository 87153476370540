import { VERSION_DEFAULT } from 'Common/constants';
import MatterProps from './MatterProps';

/**
 * Takes an object containing versions of function groups and a function name, and returns the correct version of that function
 * See `Common/Data/appSections.ts` for example of how it's used
 */
export default <T>(functions: any = {}, functionName: string): T => {
  const version = MatterProps('version', VERSION_DEFAULT);

  if (!functions[`v${version}`]) {
    throw new Error(`Version ${version} does not exist in the app`);
  }

  return functions[`v${version}`][functionName];
};
