import { Party, PartyKeys, PARTY_A } from 'Common/constants';
import MatterProps from 'Common/Utils/MatterProps';

const getPartyKeyFromParty = (party: Party): PartyKeys => {
  const selfParty = MatterProps('self.party', PARTY_A);

  return selfParty === party ? 'self' : 'other';
};

export default getPartyKeyFromParty;
