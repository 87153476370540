import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import GenericButton from './GenericButton';

const BackButton = styled(GenericButton)`
  min-height: 40px;
  min-width: 40px;
  border-radius: 40px;
  padding: 0;
  background-image: url('${props => props.theme.backIcon}');
  background-position: 10px center;
  background-repeat: no-repeat;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 0;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-left: 48px;
    font-size: inherit;
  }
`;

const BackButtonComp = ({ children, ...props }) => (
  <BackButton {...props}>{children}</BackButton>
);

BackButtonComp.propTypes = {
  children: PropTypes.string,
};

BackButtonComp.defaultProps = {
  children: 'Back',
};

export default BackButtonComp;
