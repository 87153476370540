import React from 'react';

import { SECTION_FINANCES, PAGE_FINANCES_MAIN } from 'Common/constants';
import { ODRS_DASH_PROPERTY_ROUTE, ODRS_DASH_RELATIONSHIP_ROUTE } from 'Common/routes';
import { Redirect } from 'react-router';
import { getPage } from 'Common/Data/SingleUser/appSections';
import { useAppSelector } from 'App/State/Store';
import Dashboard from '../UI/Dashboard/Dashboard';
import Main from './DashboardFinances/Main';

const DashboardFinances: React.FC = () => {
  
  const pageData = getPage(SECTION_FINANCES, PAGE_FINANCES_MAIN);
  const pageStatus = pageData && pageData.generateStatus();
  const { isDemoComplete } = useAppSelector(state => state.app.flags);

  if (pageStatus === 'locked' && !isDemoComplete) {
    // this dashboard is locked and the demo is not complete - redirect to the property dashboard
    return <Redirect to={ODRS_DASH_RELATIONSHIP_ROUTE} />;
  }
  
  return (
  <Dashboard
    tab={PAGE_FINANCES_MAIN}
    section={SECTION_FINANCES}
    pages={{
      [PAGE_FINANCES_MAIN]: Main,
    }}
    nextPage={ODRS_DASH_PROPERTY_ROUTE}
  />
)};

export default DashboardFinances;
