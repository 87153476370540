import React from 'react';
import styled from 'styled-components';

import GenericButton from './GenericButton';
import BinWhiteIcon from '../Icons/BinWhite.svg';

const DeleteButton = styled(GenericButton)`
  background-color: ${({ theme }) => theme.colours.darkGrey};
  span {
    padding-right: 10px;
  }
  img {
    vertical-align: middle;
  }
`;

export default props => (
  <DeleteButton {...props}>
    <span>Delete</span>
    <img alt="" aria-hidden="true" src={BinWhiteIcon} />
  </DeleteButton>
);
