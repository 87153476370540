import {
  CARD_NORMAL,
  CARD_AGREEMENT,
  CARD_CONTINUE,
  CARD_STATEMENT,
  CARD_CUSTOM,
} from 'Common/constants';
import BackIconInline from 'Common/Assets/images/icon-close-inline.svg';
import backgroundImagePurple from 'Common/Assets/images/divi-background-shapes-purple.png';
import backgroundImageBlack from 'Common/Assets/images/divi-background-shapes-black.png';
import backgroundImageOrange from 'Common/Assets/images/divi-background-shapes-orange.svg';
import backgroundImagePaperPlane from 'Common/Assets/images/divi-background-shapes-paper-plane-orange.svg';
import aeroplaneClipped from 'Common/Assets/images/icon-aeroplane-clipped.svg';
import BackIconLight from 'Common/UI/Icons/Back_arrow_white.svg';
import BackIconDark from 'Common/UI/Icons/Back_arrow.svg';
import colours, { Colours } from 'Common/Utils/colours';

/**
 * Breakpoints
 *
 * How to use
 *
 * import { sizes } from 'Common/Utils/theme';
 *
 * const Page = styled.div`
 * margin: auto;
 * font-family: "sans-serif";
 * text-align: center;
 *
 * @media ${sizes.md.breakpoint} {
 *    max-width: 800px;
 * }
 * `;
 * */

enum Breakpoints {
  default = 'default',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

type Breakpoint = {
  breakpoint: string;
  container: string;
  text: string;
  banner: string;
  buttons: string;
};

type Sizes = {
  [Breakpoints.sm]: Breakpoint;
  [Breakpoints.md]: Breakpoint;
  [Breakpoints.lg]: Breakpoint;
  [Breakpoints.xl]: Breakpoint;
  [Breakpoints.xxl]: Breakpoint;
};

export const sizes: Sizes = {
  [Breakpoints.sm]: {
    breakpoint: '(min-width: 375px)',
    container: '375px',
    text: '100%',
    banner: '100%',
    buttons: '100%',
  },
  [Breakpoints.md]: {
    breakpoint: '(min-width: 834px)',
    container: '834px',
    text: '640px',
    banner: '440px',
    buttons: '400px',
  },
  [Breakpoints.lg]: {
    breakpoint: '(min-width: 1024px)',
    container: '1024px',
    text: '640px',
    banner: '440px',
    buttons: '400px',
  },
  [Breakpoints.xl]: {
    breakpoint: '(min-width: 1280px)',
    container: '1280px',
    text: '640px',
    banner: '440px',
    buttons: '400px',
  },
  [Breakpoints.xxl]: {
    breakpoint: '(min-width: 1440px)',
    container: '1440px',
    text: '640px',
    banner: '440px',
    buttons: '400px',
  },
};

export const fonts = {
  default: 'SF UI Display, sans-serif',
  conversational: 'Founders Grotesk, sans-serif',
};

type Spacing = {
  0: string;
  2: string;
  3: string;
  4: string;
  6: string;
  8: string;
  10: string;
  12: string;
  16: string;
  20: string;
  24: string;
  32: string;
  40: string;
  64: string;
};

const spacing: Spacing = {
  0: '0px',
  2: '8px',
  3: '12px',
  4: '16px',
  6: '24px',
  8: '32px',
  10: '40px',
  12: '48px',
  16: '64px',
  20: '80px',
  24: '96px',
  32: '128px',
  40: '160px',
  64: '256px',
};

type PageSpacingHorizontalSizes = {
  default: string;
  md: string;
  lg: string;
};

// the actual sizes used below
// this should only be needed to be used in components for edge cases
const pageSpacingHorizontalSizes: PageSpacingHorizontalSizes = {
  default: spacing[4],
  md: spacing[12],
  lg: spacing[16],
};

// used for all main page component padding
const pageSpacingHorizontal = `
  padding-left: ${pageSpacingHorizontalSizes.default};
  padding-right: ${pageSpacingHorizontalSizes.default};

  @media ${sizes.md.breakpoint} {
    padding-left: ${pageSpacingHorizontalSizes.md};
    padding-right: ${pageSpacingHorizontalSizes.md};
  }

  @media ${sizes.lg.breakpoint} {
    padding-left: ${pageSpacingHorizontalSizes.lg};
    padding-right: ${pageSpacingHorizontalSizes.lg};
  }
`;

// used for absolutely positioned buttons in the top right corner of things
// so that it lines up with the padding in `pageSpacingHorizontal`
const closeButtonPosition = `
  right: ${pageSpacingHorizontalSizes.default};

  @media ${sizes.md.breakpoint} {
    right: ${pageSpacingHorizontalSizes.md};
  }

  @media ${sizes.lg.breakpoint} {
    right: ${pageSpacingHorizontalSizes.lg};
  }
`;

const questionsHorizontalSpacing = `
  margin-left: ${spacing[6]};
  margin-right: ${spacing[6]};

  @media ${sizes.md.breakpoint} {
    padding-left: ${spacing[12]};
  }

  @media ${sizes.lg.breakpoint} {
    padding-left: 152px;
  }
`;

type Font = {
  fontFamily?: string;
  fontSize?: string;
  lineHeight?: string | number;
  fontWeight?: string | number;
  textDecoration?: string;
  letterSpacing?: string;
  sm?: Font;
  md?: Font;
  lg?: Font;
  xl?: Font;
  xxl?: Font;
};

type Tokens = {
  groupWrapMinHeight: number;
  pageSpacingHorizontalSizes: PageSpacingHorizontalSizes;
  pageSpacingHorizontal: string;
  closeButtonPosition: string;
  questionsHorizontalSpacing: string;
};

/**
 * Theme
 * */

export type Theme = {
  t: string;
  containerPadding: number;
  sizes: Sizes;
  spacing: Spacing;
  padding: {
    xxxsmall: number;
    xxsmall: number;
    xsmall: number;
    small: number;
    medium: number;
    medium2: number;
    large: number;
    xlarge: number;
  };
  tokens: Tokens;
  typography: {
    fonts: {
      default: string;
      conversational: string;
    };
    headings: {
      h1: Font;
      h2: Font;
      h2Inactive: Font;
      h3: Font;
      h3Inactive: Font;
      h4: Font;
      subHeadingBold: Font;
      h1Conversational: Font;
      h2Conversational: Font;
      title: Font;
    };
    text: {
      label: Font;
      paragraph: Font;
      paragraphLarge: Font;
      callout: Font;
      highlighted: {
        fontWeight: string;
      };
    };
    number: {
      bigNumber: Font;
      regularNumber: Font;
    };
    clickable: {
      button: Font;
      button2: Font;
      textLink: Font;
    };
    forms: {
      placeholder: Font;
      input: Font;
    };
  };
  shadow: {
    medium: string;
  };
  altBackgroundColour: string;
  backButtonColour?: string;
  backgroundColour: string;
  backgroundImage?: string;
  backgroundPosition?: string;
  backIcon: string;
  backIconDark?: string;
  backIconInline?: string;
  buttonActiveBackgroundHelp?: string;
  buttonActiveBackgroundPrimary?: string;
  buttonActiveBackgroundReverse?: string;
  buttonActiveBackgroundSubtle?: string;
  buttonBackgroundHelp?: string;
  buttonBackgroundPrimary: string;
  buttonBackgroundReverse: string;
  buttonBackgroundSubtle: string;
  buttonHoverBackgroundHelp?: string;
  buttonHoverBackgroundPrimary?: string;
  buttonHoverBackgroundReverse?: string;
  buttonHoverBackgroundSubtle?: string;
  buttonHoverBorderHelp?: string;
  buttonHoverBorderPrimary?: string;
  buttonHoverBorderReverse?: string;
  buttonHoverBorderSubtle?: string;
  buttonInactiveColour: string;
  buttonTextHelp?: string;
  buttonTextInactive: string;
  buttonTextPrimary: string;
  buttonTextReverse: string;
  buttonTextSubtle: string;
  buttonTextTransparent: string;
  carouselcontrolHeight?: string;
  carouselHeight?: string;
  checkBoxTickBackground: string;
  checkBoxTickOutline: string;
  checkBoxTickOutlineChecked: string;
  colours: Colours;
  continueProgressMeterColour?: string;
  flexDrawerBackgroundColour: string;
  headerNavBackground?: string;
  headerNavBackgroundActive?: string;
  headerNavBorderActive?: string;
  headerNavBorderHover?: string;
  headingSideTextColour: string;
  infoBubbleBackground: string;
  infoBubbleButton: string;
  inputBackground: string;
  logoColour?: string;
  messageBackground: string;
  navBorderColour?: string;
  navHighlightBorderColour?: string;
  odieBackground?: string;
  otherPartyIndicatorBackgroundColour: string;
  otherPartyIndicatorBackgroundColourHover: string;
  otherPartyIndicatorColour: string;
  otherPartyIndicatorTextColour: string;
  partyColours: {
    self: string;
    other: string;
    divi: string;
  };
  primary: string;
  progressBarBackgroundColour?: string;
  progressBarColour?: string;
  secondary: string;
  selectColour?: string;
  selfPartyIndicatorBackgroundColour: string;
  selfPartyIndicatorBackgroundColourHover: string;
  selfPartyIndicatorTextColour: string;
  statementBackground: string;
  tertiary: string;
  text?: string;
  textColour: string;
  textColourDisabled: string;
  textPrimary: string;
  textReverse?: string;
  textSecondary: string;
  topMessageColour?: string;
};

const theme: Theme = {
  t: 'theme',
  containerPadding: 24,
  sizes,
  padding: {
    xxxsmall: 4,
    xxsmall: 8,
    xsmall: 12,
    small: 16,
    medium: 24,
    medium2: 32,
    large: 48,
    xlarge: 64,
  },
  spacing,
  tokens: {
    groupWrapMinHeight: 205,
    pageSpacingHorizontalSizes,
    pageSpacingHorizontal,
    closeButtonPosition,
    questionsHorizontalSpacing,
  },
  typography: {
    fonts,
    headings: {
      title: {
        fontFamily: fonts.conversational,
        fontSize: '26px',
        fontWeight: 500,
        lineHeight: 1.08,
        md: {
          fontSize: '34px',
          lineHeight: 1.06,
        },
        xl: {
          fontSize: '40px',
          lineHeight: 1.05,
        },
      },
      h1Conversational: {
        fontFamily: fonts.conversational,
        fontSize: '21px',
        lineHeight: 1.05,
        fontWeight: 500,
        letterSpacing: '-0.01em',
        md: {
          fontSize: '28px',
          lineHeight: 1.04,
        },
        xl: {
          fontSize: '30px',
          lineHeight: 1.07,
        },
      },
      h2Conversational: {
        fontFamily: fonts.conversational,
        fontSize: '19px',
        lineHeight: 1.05,
        fontWeight: 500,
        md: {
          fontSize: '24px',
          lineHeight: 1.17,
        },
        xl: {
          fontSize: '26px',
          lineHeight: 1.08,
        },
      },
      h1: {
        fontFamily: fonts.default,
        fontSize: '21px',
        lineHeight: 1.29,
        fontWeight: 700,
        md: {
          fontSize: '28px',
          lineHeight: 1.14,
        },
        xl: {
          fontSize: '30px',
          lineHeight: 1.07,
        },
      },
      h2: {
        fontFamily: fonts.default,
        fontSize: '19px',
        lineHeight: 1.26,
        fontWeight: 700,
        md: {
          fontSize: '24px',
        },
      },
      h2Inactive: {
        fontFamily: fonts.default,
        fontSize: '19px',
        lineHeight: 1.26,
        fontWeight: 400,
        md: {
          fontSize: '24px',
          lineHeight: 1.2,
        },
      },
      h3: {
        fontFamily: fonts.default,
        fontSize: '17px',
        lineHeight: 1.29,
        fontWeight: 700,
        letterSpacing: '-0.01em',
        md: {
          fontSize: '20px',
          lineHeight: 1.3,
        },
        xl: {
          fontSize: '21px',
          lineHeight: 1.33,
        },
      },
      h3Inactive: {
        fontFamily: fonts.default,
        fontSize: '17px',
        lineHeight: 1.29,
        fontWeight: 400,
        md: {
          fontSize: '20px',
          lineHeight: 1.24,
        },
        xl: {
          fontSize: '21px',
          lineHeight: 1.33,
        },
      },
      h4: {
        fontFamily: fonts.default,
        fontSize: '15px',
        lineHeight: '140%',
        fontWeight: 'bold',
        xl: {
          fontSize: '18px',
          lineHeight: 1,
        },
      },
      subHeadingBold: {
        fontFamily: fonts.default,
        fontSize: '18px',
        lineHeight: '140%',
        fontWeight: 'bold',
      },
    },
    text: {
      label: {
        fontFamily: fonts.default,
        fontSize: '14px',
        lineHeight: '140%',
        fontWeight: 'normal',
        letterSpacing: '0.01em',
        md: {
          fontSize: '15px',
          lineHeight: '20px',
        },
        xl: {
          fontSize: '16px',
          lineHeight: '21px',
        },
      },
      // main content
      paragraph: {
        fontFamily: fonts.default,
        fontSize: '14px',
        lineHeight: 1.43,
        fontWeight: 'normal',
        md: {
          fontSize: '16px',
          lineHeight: 1.4,
        },
        xl: {
          fontSize: '18px',
          lineHeight: 1.39,
        },
      },
      paragraphLarge: {
        fontFamily: fonts.default,
        fontSize: '16px',
        lineHeight: 1.31,
        fontWeight: 'normal',
        md: {
          fontSize: '18px',
          lineHeight: 1.4,
        },
        xl: {
          fontSize: '20px',
        },
      },
      // larger main content
      callout: {
        fontFamily: fonts.default,
        fontSize: '16px',
        lineHeight: 1.4,
        fontWeight: 'normal',
        md: {
          fontSize: '18px',
        },
        xl: {
          fontSize: '20px',
        },
      },
      highlighted: {
        fontWeight: '600',
      },
    },
    number: {
      bigNumber: {
        fontFamily: fonts.default,
        fontSize: '32px',
        lineHeight: '100%',
        fontWeight: 'bold',
      },
      regularNumber: {
        fontFamily: fonts.default,
        fontSize: '28px',
        lineHeight: '100%',
        fontWeight: 'normal',
      },
    },
    clickable: {
      button: {
        fontFamily: fonts.default,
        fontSize: '15px',
        lineHeight: '140%',
        fontWeight: '600',
        md: {
          fontSize: '18px',
        },
      },
      button2: {
        fontFamily: fonts.default,
        fontSize: '13px',
        lineHeight: '123%',
        fontWeight: '600',
        letterSpacing: '0.01em',
        md: {
          fontSize: '15px',
          lineHeight: '140%',
        },
      },
      textLink: {
        fontFamily: fonts.default,
        fontSize: '14px',
        lineHeight: '144%',
        fontWeight: 'bold',
        textDecoration: 'underline',
      },
    },
    forms: {
      placeholder: {
        fontFamily: fonts.default,
        fontSize: '16px',
        lineHeight: '130%',
        fontWeight: 'normal',
      },
      input: {
        fontFamily: fonts.default,
        fontSize: '16px',
        lineHeight: '130%',
        fontWeight: '500',
      },
    },
  },
  colours,
  logoColour: colours.purple,
  buttonBackgroundPrimary: colours.purple,
  buttonBackgroundSubtle: colours.clickableGrey,
  buttonBackgroundReverse: colours.clickableGrey,
  buttonBackgroundHelp: colours.beige,
  buttonInactiveColour: colours.inactiveGrey,
  buttonTextPrimary: colours.white,
  buttonTextSubtle: colours.black,
  buttonTextReverse: colours.black,
  buttonTextTransparent: colours.black,
  buttonTextHelp: colours.black,
  buttonTextInactive: colours.mediumGrey,
  buttonHoverBackgroundPrimary: colours.midDarkPurple,
  buttonHoverBackgroundSubtle: colours.clickableGrey,
  buttonHoverBackgroundReverse: colours.clickableGrey,
  buttonHoverBackgroundHelp: colours.lightOrange,
  buttonHoverBorderPrimary: colours.lightPurple,
  buttonHoverBorderSubtle: colours.midLightPurple,
  buttonHoverBorderReverse: colours.midLightPurple,
  buttonHoverBorderHelp: colours.midDarkOrange,
  buttonActiveBackgroundPrimary: colours.midDarkPurple,
  buttonActiveBackgroundSubtle: colours.lighterPurple,
  buttonActiveBackgroundReverse: colours.lightPurple,
  buttonActiveBackgroundHelp: colours.lightOrange,
  backgroundColour: colours.white,
  topMessageColour: colours.midLighterPurple,
  navHighlightBorderColour: colours.midLighterPurple,
  navBorderColour: colours.purple,
  altBackgroundColour: colours.offWhite,
  flexDrawerBackgroundColour: colours.white,
  textColour: colours.black,
  headingSideTextColour: colours.mediumGrey,
  textColourDisabled: colours.mediumGrey,
  inputBackground: colours.offWhite,
  infoBubbleBackground: colours.lightOrange,
  infoBubbleButton: colours.orange,
  otherPartyIndicatorColour: colours.mediumAqua,
  otherPartyIndicatorTextColour: colours.midDarkGreen,
  otherPartyIndicatorBackgroundColour: colours.lightAqua,
  otherPartyIndicatorBackgroundColourHover: colours.aqua,
  selfPartyIndicatorTextColour: colours.purple,
  selfPartyIndicatorBackgroundColour: colours.pastelPurple,
  selfPartyIndicatorBackgroundColourHover: colours.lighterPurple,
  shadow: {
    medium: '1px 8px 24px rgba(0, 0, 0, 0.16)',
  },
  messageBackground: colours.lightAqua,
  statementBackground: colours.offWhite,
  checkBoxTickBackground: colours.purple,
  checkBoxTickOutline: colours.mediumGrey,
  checkBoxTickOutlineChecked: colours.purple,
  primary: colours.purple,
  secondary: colours.purple,
  tertiary: colours.orange,
  textPrimary: colours.black,
  textSecondary: colours.white,
  partyColours: {
    self: colours.purple,
    other: colours.aqua,
    divi: colours.orange,
  },
  backIcon: BackIconDark,
  backIconDark: BackIconDark,
  backIconInline: BackIconInline,
  progressBarColour: colours.midLighterPurple,
  selectColour: colours.midLighterPurple,
  carouselHeight: '664px',
  carouselcontrolHeight: '80px',
  odieBackground: colours.beige,
  headerNavBackground: colours.midDarkPurple,
  headerNavBorderHover: colours.midLightPurple,
  headerNavBackgroundActive: colours.purple,
  headerNavBorderActive: colours.midLightPurple,
  progressBarBackgroundColour: colours.pastelPurple,
};

export default theme;

// 2 uses
export const darkTheme: Theme = {
  ...theme,
  t: 'darkTheme',
  buttonBackgroundSubtle: colours.darkPurple,
  buttonBackgroundReverse: colours.lightesterGrey,
  buttonHoverBackgroundSubtle: colours.darkPurple,
  buttonHoverBackgroundReverse: colours.lightesterGrey,
  buttonHoverBorderSubtle: colours.purple,
  buttonHoverBorderReverse: colours.lightPurple,
  buttonActiveBackgroundSubtle: colours.darkPurple,
  buttonActiveBackgroundReverse: colours.darkPurple,
  buttonTextPrimary: colours.white,
  buttonTextSubtle: colours.white,
  buttonTextReverse: colours.black,
  backgroundColour: colours.midDarkPurple,
  topMessageColour: colours.midDarkPurple,
  backgroundImage: backgroundImagePurple,
  textColour: colours.white,
  backIcon: BackIconLight,
  otherPartyIndicatorColour: colours.aqua,
};

export const orangeTheme: Theme = {
  ...theme,
  t: 'orangeTheme',
  backgroundColour: colours.lightOrange,
  altBackgroundColour: colours.beige,
  topMessageColour: colours.lightOrange,
  buttonBackgroundPrimary: colours.orange,
  buttonHoverBackgroundPrimary: colours.midDarkOrange,
  buttonHoverBorderPrimary: colours.darkOrange,
  buttonActiveBackgroundPrimary: colours.midDarkOrange,
  buttonTextPrimary: colours.black,
  primary: colours.lightOrange,
  tertiary: colours.orange,
  text: colours.black,
  textReverse: colours.white,
  flexDrawerBackgroundColour: colours.lightOrange,
};

// 1 use
export const redTheme: Theme = {
  ...theme,
  t: 'redTheme',
  backgroundColour: colours.redLight,
  topMessageColour: colours.redLight,
  buttonBackgroundPrimary: colours.red,
  buttonHoverBackgroundPrimary: colours.red,
  buttonHoverBorderPrimary: colours.midLightGrey,
  buttonActiveBackgroundPrimary: colours.red,
  buttonTextPrimary: colours.black,
  primary: colours.redLight,
  tertiary: colours.redLight,
  text: colours.black,
  textReverse: colours.white,
};

// 1 use
export const dataExportTheme = {
  default: {
    ...orangeTheme,
    t: 'dataExportDefault',
    backgroundImage: backgroundImageOrange,
    backgroundPosition: '100% 100%',
  },
  resendEmail: {
    ...orangeTheme,
    t: 'dataExportResend',
    buttonInactiveColour: colours.orange,
    backgroundColour: colours.beige,
    topMessageColour: colours.beige,
    backgroundImage: backgroundImagePaperPlane,
    backgroundPosition: '94% 75%',
  },
};

export const blueGreyMobileTheme: Theme = {
  ...theme,
  t: 'blueGreyTheme',
  textColour: colours.black,
  backgroundColour: colours.backgroundGrey,
  topMessageColour: colours.inactiveGrey,
  backIcon: BackIconLight,
  logoColour: colours.purple,
  backButtonColour: colours.purple,
  odieBackground: colours.offWhite,
};

// 1 use
export const offWhiteTheme: Theme = {
  ...theme,
  t: 'offWhiteTheme',
  backgroundColour: colours.offWhite,
  odieBackground: colours.offWhite,
  topMessageColour: colours.offWhite,
  flexDrawerBackgroundColour: colours.offWhite,
};

// 3 uses
export const pastelPurpleTheme: Theme = {
  ...theme,
  t: 'pastelPurpleTheme',
  backgroundColour: colours.pastelPurple,
  altBackgroundColour: colours.pastelPurple,
  topMessageColour: colours.pastelPurple,
};

export const darkerTheme: Theme = {
  ...theme,
  t: 'darkerTheme',
  buttonBackgroundSubtle: colours.purple,
  buttonBackgroundReverse: colours.lightesterGrey,
  buttonHoverBackgroundSubtle: colours.purple,
  buttonHoverBackgroundReverse: colours.clickableGrey,
  buttonHoverBorderSubtle: colours.midLightPurple,
  buttonHoverBorderReverse: colours.midLightGrey,
  buttonActiveBackgroundSubtle: colours.clickableGrey,
  buttonActiveBackgroundReverse: colours.clickableGrey,
  buttonTextPrimary: colours.white,
  buttonTextSubtle: colours.white,
  buttonTextReverse: colours.black,
  backgroundColour: colours.darkPurple,
  topMessageColour: colours.darkPurple,
  backgroundImage: backgroundImagePurple,
  textColour: colours.white,
  backIcon: BackIconLight,
  otherPartyIndicatorColour: colours.aqua,
  logoColour: colours.clickableGrey,
};

export const darkerNoBackground: Theme = {
  ...darkerTheme,
  t: 'darkerNoBackground',
  backgroundImage: '',
  buttonBackgroundPrimary: colours.lightestGrey,
  buttonHoverBackgroundPrimary: colours.clickableGrey,
  buttonHoverBorderPrimary: colours.midLightGrey,
  buttonActiveBackgroundPrimary: colours.clickableGrey,
  buttonTextPrimary: colours.black,
};

export const blackTheme: Theme = {
  ...theme,
  t: 'blackTheme',
  buttonBackgroundSubtle: colours.clickableGrey,
  buttonBackgroundReverse: colours.lightesterGrey,
  buttonHoverBackgroundSubtle: colours.clickableGrey,
  buttonHoverBackgroundReverse: colours.clickableGrey,
  buttonHoverBorderSubtle: colours.midLightPurple,
  buttonHoverBorderReverse: colours.midLightGrey,
  buttonActiveBackgroundSubtle: colours.lightPurple,
  buttonActiveBackgroundReverse: colours.clickableGrey,
  buttonTextPrimary: colours.white,
  buttonTextSubtle: colours.black,
  buttonTextReverse: colours.black,
  backgroundColour: colours.black,
  topMessageColour: colours.black,
  altBackgroundColour: colours.darkerBlack,
  backgroundImage: backgroundImageBlack,
  textColour: colours.white,
  flexDrawerBackgroundColour: colours.darkerBlack,
  backIcon: BackIconLight,
  otherPartyIndicatorColour: colours.aqua,
  logoColour: colours.white,
};

const greenButtons = {
  buttonTextTransparent: colours.clickableGrey,
  buttonBackgroundSubtle: colours.aqua,
  buttonHoverBorderSubtle: colours.midTeal,
  buttonHoverBackgroundSubtle: colours.mediumAqua,
  buttonHoverBorderReverse: colours.midTeal,
  buttonActiveBackgroundReverse: colours.inactiveGrey,
  buttonActiveBackgroundSubtle: colours.lightAqua,
  buttonBackgroundPrimary: colours.darkTeal,
  buttonHoverBackgroundPrimary: colours.midDarkGreen,
  buttonHoverBorderPrimary: colours.midTeal,
  buttonActiveBackgroundPrimary: colours.midDarkGreen,
};

export const greenTheme: Theme = {
  ...theme,
  t: 'greenTheme',
  backgroundColour: '#023235',
  altBackgroundColour: '#004246',
  topMessageColour: '#004246',
  textColour: colours.white,
  backIcon: BackIconLight,
  navHighlightBorderColour: colours.lightBlue,
  navBorderColour: colours.darkTeal,
  selectColour: colours.darkTeal,
  continueProgressMeterColour: colours.mediumAqua,
  progressBarColour: colours.midTeal,
  progressBarBackgroundColour: colours.lightAqua,
  headerNavBackground: colours.midDarkGreen,
  headerNavBorderHover: colours.midTeal,
  headerNavBackgroundActive: colours.darkTeal,
  headerNavBorderActive: colours.midTeal,
  logoColour: colours.white,
  odieBackground: colours.offWhite,

  ...greenButtons,
};

export const greenQuestionTheme: Theme = {
  ...greenTheme,
  t: 'greenQuestionTheme',
  textColour: colours.black,
  backgroundColour: colours.white,
};

export const darkGreenTheme: Theme = {
  ...theme,
  t: 'darkGreenTheme',
  backgroundColour: '#002C2F',
  topMessageColour: '#002C2F',
  headerNavBorderHover: colours.darkTeal,

  ...greenButtons,
};

// currently only used for the light coloured consent order dashboard banners
export const lightGreenTheme: Theme = {
  ...theme,
  t: 'lightGreenTheme',
  topMessageColour: colours.lightAqua,
  backgroundColour: colours.lightAqua,
  buttonBackgroundPrimary: colours.darkTeal,
  buttonHoverBackgroundPrimary: colours.midDarkGreen,
  buttonHoverBorderPrimary: colours.midTeal,
  buttonActiveBackgroundPrimary: colours.midDarkGreen,
};

// 1 use
export const greyTextTheme: Theme = {
  ...theme,
  t: 'greyTextTheme',
  textColour: colours.darkGrey,
};

// 2 uses
export const greyHelpButtonTheme: Theme = {
  ...theme,
  t: 'greyHelpButtonTheme',
  buttonBackgroundHelp: colours.clickableGrey,
  buttonHoverBackgroundHelp: colours.clickableGrey,
  buttonHoverBorderHelp: colours.midLightPurple,
  buttonActiveBackgroundHelp: colours.lighterPurple,
};

export const singleUserTheme: Theme = {
  ...blueGreyMobileTheme,
  t: 'singleUserTheme',
  buttonBackgroundHelp: colours.orange,
  buttonHoverBackgroundHelp: colours.clickableGrey,
  buttonHoverBorderHelp: colours.midLightPurple,
  buttonActiveBackgroundHelp: colours.lighterPurple,
};

// separate out
export const cardThemes = {
  default: {
    backgroundColour: colours.white,
    color: colours.black,
  },
  greenTheme: {
    buttonBackgroundPrimary: colours.darkTeal,
    buttonHoverBackgroundPrimary: colours.midDarkGreen,
    buttonHoverBorderPrimary: colours.midTeal,
    buttonActiveBackgroundPrimary: colours.midDarkGreen,
    buttonBackgroundSubtle: colours.aqua,
    buttonHoverBorderSubtle: colours.midTeal,
    buttonHoverBackgroundSubtle: colours.mediumAqua,
    buttonActiveBackgroundSubtle: colours.lightAqua,
  },
  inactive: {
    color: colours.darkGrey,
  },
  self: {
    messageBackground: colours.mediumGrey,
    buttonBackgroundPrimary: colours.clickableGrey,
    buttonActiveBackgroundPrimary: colours.lighterPurple,
    buttonHoverBackgroundPrimary: colours.clickableGrey,
    buttonTextPrimary: colours.black,
  },
  other: {
    buttonBackgroundPrimary: colours.lightBlue,
    buttonHoverBackgroundPrimary: colours.mediumAqua,
    buttonHoverBorderPrimary: colours.midTeal,
    buttonActiveBackgroundPrimary: colours.midTeal,
    buttonActiveBorderPrimary: colours.midTeal,
    buttonTextPrimary: colours.black,
  },
  orange: {
    buttonBackgroundPrimary: colours.orange,
    buttonHoverBackgroundPrimary: colours.midDarkOrange,
    buttonHoverBorderPrimary: colours.darkOrange,
    buttonActiveBackgroundPrimary: colours.midDarkOrange,
    buttonTextPrimary: colours.black,
  },
  emptyOption: {
    backgroundColour: colours.offWhite,
    borderColour: colours.lightGrey,
  },
  defaultOnlyThem: {
    backgroundColour: colours.inactiveGrey,
    color: colours.darkGrey,
  },
  approved: {
    backgroundColour: colours.offWhite,
    statementBackground: colours.white,
    borderColour: colours.lightGrey,
  },
  removed: {
    backgroundColour: colours.offWhite,
    statementBackground: colours.lightestGrey,
  },
  subtle: {
    backgroundColour: colours.pastelPurple,
  },
  // AgreementCards
  agreement: {
    buttonBackgroundPrimary: colours.beige,
    buttonTextPrimary: colours.black,
    statementBackground: colours.offWhite,
    checkBoxTickOutline: colours.mediumGrey,
  },
  [`${CARD_AGREEMENT}Inactive`]: {
    backgroundColour: colours.inactiveGrey,
    buttonBackgroundPrimary: colours.clickableGrey,
    buttonTextPrimary: colours.black,
    statementBackground: colours.clickableGrey,
    checkBoxTickOutline: colours.midLightGrey,
    checkBoxTickBackground: colours.midLightGrey,
  },
  [`${CARD_NORMAL}Inactive`]: {
    backgroundColour: colours.inactiveGrey,
    statementBackground: colours.clickableGrey,
    buttonInactiveColour: colours.midLightGrey,
    buttonTextPrimary: colours.lightGrey,
  },
  [`${CARD_CUSTOM}Inactive`]: {
    backgroundColour: colours.inactiveGrey,
    statementBackground: colours.clickableGrey,
    buttonInactiveColour: colours.midLightGrey,
    buttonTextPrimary: colours.lightGrey,
  },
  [`${CARD_CONTINUE}Inactive`]: {
    backgroundColour: colours.inactiveGrey,
    statementBackground: colours.clickableGrey,
    buttonInactiveColour: colours.midLightGrey,
    buttonTextPrimary: colours.lightGrey,
    continueProgressMeterColour: colours.transparent,
  },
  [`${CARD_STATEMENT}Inactive`]: {
    backgroundColour: colours.inactiveGrey,
    statementBackground: colours.clickableGrey,
    buttonInactiveColour: colours.midLightGrey,
    buttonTextPrimary: colours.lightGrey,
  },
  // Invite cards
  inviteInitial: {
    backgroundColour: colours.white,
  },
  inviteSent: {
    backgroundColour: colours.pastelPurple,
    backgroundImage: aeroplaneClipped,
  },
};
