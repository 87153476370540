import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useNProgress } from '@tanem/react-nprogress';
import {
  DOCUMENT_STATUS_UPLOADED,
  DOCUMENT_STATUS_NEW,
  DOCUMENT_STATUS_INPROGRESS,
  DOCUMENT_STATUS_FAILED,
  DOCUMENT_STATUS_EXISTING,
  DOCUMENT_STATUS_DELETED,
} from 'Common/constants';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Label } from 'Common/UI/Text/Label';
import GenericButton from 'Common/UI/Button/GenericButton';
import FileUploadButton from 'Common/UI/Button/FileUploadButton';
import DeleteIconButton from 'Common/UI/Button/DeleteIconButton';
import { getDocumentUploadURLAction } from 'App/State/MatterActions';
import getBlobUrl from '../../Utils/getBlobUrl';
import DocIcon from '../Icons/DocIcon.svg';
import DocumentViewer from '../UploadedDocuments/DocumentViewer';
import {
  getPresignedUrlForFile,
  generateS3FilePath,
} from '../../Utils/presignedUrlMaker';

const StyledUploadAnotherButton = styled(FileUploadButton)`
  margin-top: 7px;
  margin-bottom: 7px;
  min-width: 167px;
  padding: 6px 15px 5px;
`;

const StyledFileUploadButton = styled(FileUploadButton)`
  margin-top: ${props => props.theme.padding.xlarge}px;
`;

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
  max-height: 172px;
  @media (min-height: 600px) {
    max-height: initial;
  }
`;

const StyledDocument = styled.div`
  width: 100%;
  border-top: ${props =>
    props.fileIndex !== 0
      ? `1px solid ${props.theme.colours.clickableGrey}`
      : 'none'};
  padding: ${props => props.theme.padding.small}px 0;
`;

const StyledDocumentInner = styled.div`
  position: relative;
  min-height: 56px;
  display: inline-flex;
`;

const ThumbnailWrap = styled.div`
  width: 56px;
  height: 72px;
  border-radius: 4px;
  padding-right: 8px;
  background-color: ${({ theme }) => theme.inputBackground};
  background-position: center center;
  background-size: cover;
  background-image: ${({ src }) => (src ? `url(${src})` : `url(${DocIcon})`)};
  margin-right: ${({ theme }) => theme.padding.small}px;
`;

const DocBody = styled.div`
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  align-content: space-between;
`;

const DocName = styled(Paragraph)`
  margin: 0;
  font-weight: bold;
  flex: 2;
  width: 50vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DocStatus = styled(Label)`
  font-weight: normal;
  flex: 1;
  margin-bottom: 7px;
`;

const DocProgressBarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 24px;
  background: ${props => props.theme.colours.lightestGrey};
`;

const DocProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(${props => props.progress} * 100%);
  height: 100%;
  border-radius: 24px;
  background: ${props => props.theme.colours.midLightPurple};
  transition: width ${props => props.animationDuration}ms;
`;

const AddButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const StyledCancelButton = styled(GenericButton)`
  position: absolute;
  padding: 0;
  text-align: right;
  font-size: 13px;
  width: 108px;
  height: 16px;
  color: ${props => props.theme.colours.purple};
  background-color: transparent;
  border: none;
  right: 0;
  bottom: 13px;
`;

const StyledDeleteIconButton = styled(DeleteIconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const baseName = str => {
  const base = str.substring(str.lastIndexOf('/') + 1);
  if (base.lastIndexOf('.') !== -1) {
    return base.substring(0, base.lastIndexOf('.'));
  }
  return base;
};

const Document = ({ file, viewDocument, fileIndex, onDelete, dataCy }) => {
  const { status, path, imagePath, type } = file;

  const [thumbnailSrc, setThumbnailSrc] = useState(null);

  useEffect(() => {
    (async () => {
      if (type !== undefined && type !== 'application/pdf') {
        const blobImage = await getBlobUrl(file);
        await setThumbnailSrc(blobImage);
      }
    })();
  }, [status]);

  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating:
      status !== DOCUMENT_STATUS_UPLOADED &&
      status !== DOCUMENT_STATUS_EXISTING,
    minimum: 0.4,
    animationDuration: 300,
    incrementDuration: 400,
  });

  let displayStatus;

  switch (status) {
    case DOCUMENT_STATUS_UPLOADED:
      displayStatus = 'Uploaded';
      break;

    case DOCUMENT_STATUS_INPROGRESS:
      displayStatus = 'Uploading';
      break;

    case DOCUMENT_STATUS_NEW:
      displayStatus = 'Uploaded';
      break;

    case DOCUMENT_STATUS_EXISTING:
      displayStatus = '';
      break;

    case DOCUMENT_STATUS_FAILED:
    default:
      displayStatus = 'Error';
      break;
  }

  const fileName =
    status === DOCUMENT_STATUS_EXISTING ? baseName(imagePath) : path;

  if (file.status === DOCUMENT_STATUS_DELETED) {
    return false;
  }

  return (
    <StyledDocument
      fileIndex={fileIndex}
      data-cy={dataCy}
      data-cy-status={file.status}
    >
      <StyledDocumentInner>
        <ThumbnailWrap
          src={thumbnailSrc}
          alt="Document supplied as proof"
          onClick={() => viewDocument()}
        />
        <DocBody>
          <DocName>{fileName}</DocName>
          <DocStatus>{displayStatus}</DocStatus>
          <DocProgressBarContainer
            animationDuration={animationDuration}
            isFinished={isFinished}
          >
            {status === DOCUMENT_STATUS_INPROGRESS && (
              <>
                <DocProgressBar
                  animationDuration={animationDuration}
                  progress={progress}
                />
                <StyledCancelButton onClick={event => onDelete(event, file)}>
                  Cancel
                </StyledCancelButton>
              </>
            )}
          </DocProgressBarContainer>
        </DocBody>
        {(status === DOCUMENT_STATUS_UPLOADED ||
          status === DOCUMENT_STATUS_EXISTING) && (
          <StyledDeleteIconButton onClick={event => onDelete(event, file)} />
        )}
      </StyledDocumentInner>
    </StyledDocument>
  );
};

const DocumentUploader = ({
  sectionID,
  getDocumentUploadURL,
  onChange,
  value: filelist = [],
  focus,
}) => {
  const [documentURL, setDocumentURL] = useState('');
  // Doc currently open in detail view
  const [viewingDocument, setViewingDocument] = useState(null);
  const [files, updateFiles] = useState([]);

  const isInprogress = doc => {
    const file = files.find(file => file.imagePath === doc.imagePath);
    return file.status === DOCUMENT_STATUS_INPROGRESS;
  };

  // update the status of a file in our files array
  const updateStatus = (doc, status) => {
    const newFiles = files.map(file => {
      if (file.imagePath === doc.imagePath) {
        // this is needed because we can't spread on a File object
        // eslint-disable-next-line no-param-reassign
        file.status = status;
      }
      return file;
    });

    // Store the uploaded files as the 'response' to the question.
    onChange(newFiles);
    // setFiles(newFiles);
  };

  const onDrop = pendingImages => {
    const allFiles = [
      ...files,
      ...pendingImages.map(file =>
        Object.assign(file, {
          status: DOCUMENT_STATUS_NEW,
        })
      ),
    ];

    onChange(allFiles);
  };

  const getDocumentStatus = responseStatus => {
    switch (Math.floor(responseStatus / 100)) {
      case 2:
        return DOCUMENT_STATUS_UPLOADED;
      case 4:
      case 5:
      default:
        return DOCUMENT_STATUS_FAILED;
    }
  };

  // do the image upload
  const handleImageUpload = async image => {
    updateStatus(image, DOCUMENT_STATUS_INPROGRESS);
    const imagePath = generateS3FilePath(sectionID, image.name);
    const presignedURL = await getDocumentUploadURL(imagePath);

    const imageUpdated = Object.assign(image, { imagePath });

    const response = await fetch(
      new Request(presignedURL, {
        method: 'PUT',
        body: image,
        headers: new Headers({
          'Content-Type': image.type,
        }),
      })
    );

    // If you change status before response
    if (await !isInprogress(image)) {
      return false;
    }

    await updateStatus(imageUpdated, getDocumentStatus(response.status));

    return true;
  };

  useEffect(() => {
    if (filelist.length > 0) {
      // process any already-uploaded documents into objects with statuses
      const existingFiles = filelist.map(f =>
        typeof f === 'string'
          ? { status: DOCUMENT_STATUS_EXISTING, imagePath: f }
          : f
      );

      updateFiles(existingFiles);
    }
  }, [filelist]);

  // run upload on any files that haven't been started uploading yet
  useEffect(() => {
    files
      .filter(file => file && file.status === DOCUMENT_STATUS_NEW)
      .forEach(file => {
        handleImageUpload(file);
      });
  }, [files]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*,.pdf',
    multiple: true,
    onDrop,
  });

  const onDelete = (event, file) => {
    event.preventDefault();
    const newFiles = files.filter(f => f.imagePath !== file.imagePath);
    updateFiles(newFiles);
    updateStatus(file, DOCUMENT_STATUS_DELETED);
    onChange(newFiles);
  };

  const selectedDocuments = files
    .filter(file => file && file.status !== DOCUMENT_STATUS_DELETED)
    .map((file, index) => (
      <Document
        key={file.imagePath || file.name}
        file={file}
        fileIndex={index}
        onDelete={onDelete}
        viewDocument={async () => {
          setViewingDocument(file.imagePath);
          const presignedUrlData = await getPresignedUrlForFile(file.imagePath);
          if (presignedUrlData) {
            setDocumentURL(presignedUrlData.presignedUrl);
          }
        }}
        dataCy="uploaded-document"
      />
    ));

  const openFileChooser = e => {
    e.preventDefault();
    e.stopPropagation();
    open();
  };

  return (
    <div data-cy="document-uploader">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {/**
          Dropzone react says you should not have button in this format
          But leaving for accessibility reasons
          Should test this with aria later on
        * */}
        {files.length === 0 && (
          <StyledFileUploadButton onClick={openFileChooser} />
        )}
      </div>
      <DocumentContainer count={files.length}>
        {selectedDocuments}
        {/**
          Error: when changing answer from has super to don't have super, files returned becomes null
        * */}
        {files.length > 0 && files[0] !== null && focus && (
          <AddButtonContainer>
            <StyledUploadAnotherButton
              text="Upload another"
              onClick={openFileChooser}
              alt="Upload another file"
              data-cy="upload-another-file-button"
            />
          </AddButtonContainer>
        )}
      </DocumentContainer>
      <DocumentViewer
        deletable
        isOpen={viewingDocument !== null}
        handleClose={() => {
          setViewingDocument(null);
        }}
        handleDelete={() => {
          onChange(files.filter(f => f.imagePath !== viewingDocument));
          setViewingDocument(null);
        }}
        documentURL={documentURL}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  getDocumentUploadURL: filename =>
    dispatch(getDocumentUploadURLAction(filename)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUploader);
