import React, { ReactNode } from 'react';
import FlexDrawer from 'Common/UI/Layout/FlexDrawer';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import { QuestionCard } from 'Common/Data/Types/appSections';
import { Column } from 'Common/UI/Layout/Flex';

import SquareCross from 'Common/Assets/images/icon-squarecross.svg';
import NotePad from 'Common/Assets/images/icon-notepad.svg';
import OptionsItem from '../components/OptionsItem';

import { REOPEN_STATEMENT, REMOVE_STATEMENT } from '../constants';

type Props = {
  visible: boolean;
  optionItems: string[];
  onAccept: (item: 'reopen-statement' | 'remove-statement') => void;
  card: QuestionCard;
};

const OptionsInitialScreen: React.FC<Props> = ({
  visible = false,
  optionItems,
  onAccept,
  card,
}) => {
  const data: {
    [key: string]: {
      headingText: string;
      paragraph: string;
      Icon: () => ReactNode;
    };
  } = {
    [REOPEN_STATEMENT]: {
      headingText: 'Edit statement',
      paragraph: 'This will re-open the statement conversation',
      Icon: () => <img src={NotePad} alt="edit" />,
    },
    [REMOVE_STATEMENT]: {
      headingText: card.removeCardText || 'Remove statement',
      paragraph:
        'This will remove the statement and clear the statement conversation',
      Icon: () => <img src={SquareCross} alt="remove" />,
    },
  };
  return (
    <FlexDrawer isVisible={visible}>
      <ResponsiveTextContainer>
        <Column justifyContent="space-between">
          {optionItems &&
            optionItems.map((item: string) => {
              if (!Object.keys(data).includes(item)) {
                return <div>Option does not exist</div>;
              }
              const { headingText, paragraph, Icon } = data[item];
              return (
                <OptionsItem
                  key={item}
                  Icon={Icon}
                  headingText={headingText}
                  paragraph={paragraph}
                  optionClick={() => onAccept(item as any)}
                />
              );
            })}
        </Column>
      </ResponsiveTextContainer>
    </FlexDrawer>
  );
};

export default OptionsInitialScreen;
