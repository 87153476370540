import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import {
  QuestionCarouselSlideWithHelpButton,
  QuestionCarouselSlideWithoutHelpButton,
} from 'Common/Data/Types/appSections';
import { Theme, blueGreyMobileTheme } from 'Common/Utils/theme';
import { Heading3, H2Conversational } from 'Common/UI/Text/Headings';
import HelpButton from 'Common/UI/Button/HelpButton';
import LearnMoreButton from 'Common/UI/Button/LearnMoreButton';
import CarouselWrapper from 'Common/UI/Carousel';
import {
  CarouselButton,
  SlideInner,
} from 'Common/UI/Carousel/ImageSlideWrapper';

const Wrapper = styled.div<{
  theme: Theme;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  display: flex;

  background: ${({ theme }) => theme.backgroundColour};
`;

const StyledNumber = styled(Heading3)<{
  theme: Theme;
}>`
  margin-bottom: ${({ theme }) => theme.padding.xsmall}px;
  font-weight: 400;

  @media (min-width: 400px) {
    margin-bottom: ${({ theme }) => theme.padding.small}px;
  }
`;

const SlideText = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing[16]} ${theme.spacing[8]} ${theme.spacing[6]} ${theme.spacing[8]}`};
  overflow-y: auto;
`;

type Props = {
  slides: (
    | QuestionCarouselSlideWithHelpButton
    | QuestionCarouselSlideWithoutHelpButton
  )[];
  goBack: (e?: any) => void;
  goNext: (e?: any) => void;
};

const Carousel: React.FC<Props> = ({ slides, goBack, goNext }) => {
  const LastSlideControls = () => (
    <CarouselButton onClick={goNext} data-cy="carousel-finish-button">
      Next
    </CarouselButton>
  );

  return (
    <ThemeProvider theme={blueGreyMobileTheme}>
      <Wrapper>
        <CarouselWrapper onClose={goBack} LastSlideControls={LastSlideControls}>
          {slides.map((slide, index) => (
            <SlideInner key={slide.title}>
              <SlideText>
                <StyledNumber>0{index + 1}</StyledNumber>
                <H2Conversational>{slide.title}</H2Conversational>
                {slide.content}
                {slide.showHelpButton && (
                  <LearnMoreButton
                    ui={HelpButton}
                    popUpContent={slide.helpContent}
                    className="step-transition-fade-down"
                  >
                    {slide.helpButtonText}
                  </LearnMoreButton>
                )}
              </SlideText>
            </SlideInner>
          ))}
        </CarouselWrapper>
      </Wrapper>
    </ThemeProvider>
  );
};

export default Carousel;
