import React from 'react';
import CardQuestions from 'App/UI/Questions/CardQuestionsFlow';
import MatterGuard from './Guard/MatterGuard';

type Props = {
  props: any;
};

const ODRSAboutYouA: React.FC<Props> = props => (
  <MatterGuard>
    <CardQuestions baseID="aboutyou" index="self" {...props} />
  </MatterGuard>
);

export default ODRSAboutYouA;
