import React from 'react';
import styled from 'styled-components';
import { Theme } from 'Common/Utils/theme';
import GenericButton from 'Common/UI/Button/GenericButton';
import TextButton from 'Common/UI/Button/TextButton';
import PreviousButton from 'Common/UI/Button/PreviousButton';

const ButtonWrapper = styled.div<{
  theme: Theme;
}>`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-bottom: ${({ theme }) => theme.spacing[16]};
  }
`;

const QuestionNavButton = styled(GenericButton).attrs(props => ({
  ...props,
  wide: true,
}))<{
  theme: Theme;
  subtle?: boolean;
}>`
  margin-right: ${({ theme }) => theme.spacing[8]};
`;

const StyledTextButton = styled(TextButton)<{
  theme: Theme;
}>`
  font-family: ${props => props.theme.typography.fonts.default};
  color: ${props => props.theme.colours.darkGrey};
  font-weight: 500;
  text-align: center;
  padding: 12px 20px;

  &:disabled {
    border: 0;
    opacity: 0.5;
  }
`;

type Props = {
  hasPreviousQuestion: boolean;
  onBack: (e: any) => void; // TODO update type
  onNext: (e: any) => void; // TODO update type
  isQuestionTypeAutoSubmit?: boolean;
  isQuestionTypeCode?: boolean;
  isQuestionOptional?: boolean;
  isInputValid: boolean;
  nextButtonLabel: string;
  hasQuestionBeenTouched?: boolean;
  className?: string;
};

const PreviousNextButtons: React.FC<Props> = ({
  hasPreviousQuestion,
  onBack,
  onNext,
  isQuestionTypeCode = false,
  isQuestionOptional = false,
  isInputValid,
  nextButtonLabel,
  hasQuestionBeenTouched = false,
  isQuestionTypeAutoSubmit = false,
  className,
}) => {
  if (isQuestionTypeCode) {
    return (
      <StyledTextButton type="button" onClick={onNext}>
        Resend code
      </StyledTextButton>
    );
  }

  const showNextButton =
    !isQuestionTypeAutoSubmit &&
    (!isQuestionOptional || (isQuestionOptional && hasQuestionBeenTouched));
  const showSkipButton = isQuestionOptional && !hasQuestionBeenTouched;

  return (
    <ButtonWrapper className={className}>
      {hasPreviousQuestion && (
        <PreviousButton
          type="submit"
          onClick={onBack}
          data-cy="questions-button-back"
          disabled={!hasPreviousQuestion}
        >
          Previous
        </PreviousButton>
      )}

      {showNextButton && (
        <QuestionNavButton
          type="submit"
          onClick={onNext}
          data-cy="questions-button-next"
          disabled={!isInputValid}
        >
          {nextButtonLabel}
        </QuestionNavButton>
      )}

      {showSkipButton && (
        <StyledTextButton
          type="button"
          onClick={onNext}
          disabled={!isInputValid}
          data-cy="questions-button-next"
        >
          Skip
        </StyledTextButton>
      )}
    </ButtonWrapper>
  );
};

export default PreviousNextButtons;
