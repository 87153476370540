import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring/web.cjs';

const BackgroundOverlay = ({ instant = false, ...rest }) => {
  const options = instant ? { duration: 0 } : {};

  const animateProps = useSpring({
    config: options,
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <animated.div style={animateProps}>
      <div {...rest} />
    </animated.div>
  );
};

const StyledBackgroundOverlay = styled(BackgroundOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.4);
`;

export default StyledBackgroundOverlay;
