/* eslint no-restricted-globals: 0 */
export default obj => {
  const testObj = typeof obj === 'string' ? obj.replace(/,/g, '') : obj;

  return (
    !isNaN(parseFloat(testObj)) &&
    isFinite(testObj) &&
    Object.prototype.toString.call(testObj).toLowerCase() !== '[object array]'
  );
};
