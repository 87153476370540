import { getDocumentViewURL } from 'App/State/MatterActions';
import { sessionStorageSave, sessionStorageLoad } from './sessionStorage';

// Expiry
const expiry = 60 * 5 * 1000;

const isExpired = time => Date.now() - time > expiry;

export const generateS3FilePath = (sectionID, imageName) =>
  `${sectionID}/${Date.now()}/${imageName}`;
export const isS3FilePathLength = imagePath =>
  imagePath.split('/').length === 3;
export const getImagePathFromS3FilePath = imagePath =>
  imagePath && isS3FilePathLength(imagePath)
    ? imagePath.split('/').pop()
    : imagePath;

export const getPresignedUrlForFile = async path => {
  let data;
  const presignedUrlsData = (await sessionStorageLoad('presignedUrls')) || {};
  let presignedData = presignedUrlsData[path];

  if (
    !presignedData ||
    (presignedData && isExpired(presignedData.created_time))
  ) {
    // Get
    presignedData = await getDocumentViewURL(path);

    data = {
      name: getImagePathFromS3FilePath(path),
      filePath: path,
      presignedUrl: presignedData[0],
      presignedUrlThumb: presignedData[1],
      created_time: Date.now(),
    };

    // Save
    await sessionStorageSave('presignedUrls', {
      ...presignedUrlsData,
      ...{
        [path]: {
          ...data,
        },
      },
    });

    return data;
  }

  return presignedData;
};
