import React, { useContext } from 'react';
import styled from 'styled-components';
import { Theme } from 'Common/Utils/theme';
import { Link } from 'react-router-dom';
import { CardQuestionFlowContext } from 'Common/UI/Questions/CardQuestionsFlowContext';
import FloatingCloseButton from 'Common/UI/Button/FloatingCloseButton';

export const QuestionFlowHeader = styled.div<{
  theme: Theme;
}>`
  padding-bottom: ${({ theme }) => theme.spacing[16]};
  position: relative;
  height: 82;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    height: 124px;
  }
`;

const StyledCardQuestionFlowHeader = styled.div<{
  theme: Theme;
  bgImage: string;
  bgColour: string;
}>`
  padding-bottom: ${({ theme }) => theme.spacing[16]};
  position: relative;
  background: ${props => props.bgColour} url('${props => props.bgImage}')
    no-repeat top left;
  background-size: contain;
  height: 82px;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    height: 124px;
  }
`;

type Props = {
  backgroundImage: string;
  backgroundColour: string;
  previousRoute: string;
  textClose?: string;
  external?: boolean;
};

const CardQuestionFlowHeader: React.FC<Props> = ({
  backgroundImage,
  backgroundColour,
  previousRoute,
  textClose = 'Close',
  external = false,
}) => {
  const { headerVisibility } = useContext(CardQuestionFlowContext);

  return headerVisibility ? (
    <StyledCardQuestionFlowHeader
      bgImage={backgroundImage}
      bgColour={backgroundColour}
    >
      <FloatingCloseButton
        as={external ? 'a' : Link}
        to={previousRoute}
        href={previousRoute}
      >
        &nbsp;&nbsp;{textClose}
      </FloatingCloseButton>
    </StyledCardQuestionFlowHeader>
  ) : null;
};

export default CardQuestionFlowHeader;
