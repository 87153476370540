import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Paragraph from 'Common/UI/Text/Paragraph';
import { Heading4 } from 'Common/UI/Text/Headings';

const OptionsItemWrapper = styled.a`
  display: flex;
  align-items: flex-start;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  :nth-child(2) {
    border-top: 1px solid ${props => props.theme.colours.lightGrey};
    padding-top: ${props => props.theme.padding.small}px;
  }
`;

const OptionsItemMedia = styled.span`
  margin-right: 10px;
`;

const OptionsItemBody = styled.span`
  flex: 1;
`;

const OptionsHeading = styled(Heading4)`
  margin: 0;
`;

const OptionsParagraph = styled(Paragraph)`
  margin-top: ${props => props.theme.padding.xxsmall}px;
`;

const OptionsItem = ({ Icon, headingText, paragraph, optionClick }) => (
  <OptionsItemWrapper onClick={optionClick}>
    <OptionsItemMedia>
      <Icon />
    </OptionsItemMedia>
    <OptionsItemBody>
      <OptionsHeading>{headingText}</OptionsHeading>
      <OptionsParagraph>{paragraph}</OptionsParagraph>
    </OptionsItemBody>
  </OptionsItemWrapper>
);

OptionsItem.propTypes = {
  Icon: PropTypes.func.isRequired,
  headingText: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  optionClick: PropTypes.func.isRequired,
};

export default OptionsItem;
