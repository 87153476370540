import styled from 'styled-components';

import Drawer from './Drawer';

const MainDrawer = styled(Drawer)`
  position: relative;
  z-index: 10;
  background: ${({ theme, colour }) => colour || theme.colours.backgroundGrey};
  color: ${({ theme }) => theme.textColour};
  padding-top: ${({ theme }) => theme.spacing[4]};
  padding-bottom: ${({ theme }) => theme.spacing[8]};
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
  flex: 1;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-top: ${({ theme }) => theme.spacing[16]};
  }
`;

export default MainDrawer;
