import { DashboardPages, Sections } from 'Common/constants';
import Matter from 'Common/Data/Types/matter';

/**
 * Checks if the cards on a given page/section are locked or unlocked
 *
 * This just returns true for single user as the cards are always unlocked
 *
 * @param matter              the matter object
 * @param section             the section to check
 * @param page (optional)     the page to check
 * @returns boolean           true if the page contents are unlocked, false otherwise
 */
const isPageContentsUnlocked = (
  _matter: Matter,
  _section?: Sections,
  _page?: DashboardPages
) => true;

export default isPageContentsUnlocked;
