import React from 'react';
import styled from 'styled-components';
import { Theme } from 'Common/Utils/theme';

const StyledProgressBar = styled.div<{
  theme: Theme;
}>`
  height: 24px;
`;

const StyledProgressBarInner = styled.div<{
  theme: Theme;
}>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ProgressVisualBar = styled.div<{
  theme: Theme;
  distance: number;
}>`
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 12px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: ${props => props.distance * 100}%;
    background: ${props => props.theme.progressBarColour};
    transition: width 0.3s;
    border-radius: 12px;
  }
`;

const ProgressBar: React.FC<{ distance: number }> = ({ distance = 10 }) => (
  <StyledProgressBar>
    <StyledProgressBarInner>
      <ProgressVisualBar distance={distance} />
    </StyledProgressBarInner>
  </StyledProgressBar>
);

export default ProgressBar;
